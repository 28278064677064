import { Component, DestroyRef, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AnimatedLoaderService } from '../service/animated-loader.service';
import { interval, takeWhile } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthDataService } from '../../../services/auth-data.service';

@Component({
  selector: 'app-animated-loader',
  templateUrl: './animated-loader.component.html',
  styleUrls: ['./animated-loader.component.scss'],
})
export class AnimatedLoaderComponent implements OnInit {
  public isFirstPart = false;
  public isSecondPart = false;

  @ViewChild('firstPart') firstPart: ElementRef<HTMLElement>;

  constructor(
    private animatedLoaderService: AnimatedLoaderService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.animatedLoaderService.showFirst
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.isFirstPart = true);


    this.animatedLoaderService.showSecond
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.showSecondPart());
  }

  private showSecondPart() {
    this.firstPart.nativeElement.classList.add('fade-out');
    setTimeout(() => {
      this.isFirstPart = false;
      this.isSecondPart = true;
      window.setTimeout(() => {
        this.isSecondPart = false;
        this.animatedLoaderService.stop();
      }, 2760);
    }, 400);
  }
}
