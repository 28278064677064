import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { IAlert } from "../interfaces/alert-interface";
import {
  ICard,
  ICustomer,
  IInvoice,
  ILogin,
  User,
  UserFullInfo,
  UserRole,
} from "../interfaces/auth-interfaces";
import {
  AllFeatures,
  ChosenFeatures,
  ICity,
  ICountry,
  IIndustry,
  IProject,
  IProjectInfo,
  IState,
} from "../interfaces/project";
import { IProvider, LocationModel, SubscriptionModel, SubscriptionProduct } from '../interfaces/provider';
import { ITestimonial } from "../interfaces/testimonial.interface";
import { Mapper } from "./mapper";

@Injectable({
  providedIn: "root",
})
export class AuthApiService {
  constructor(private httpClient: HttpClient) {}

  public addPaymentMethod(paymentMethodId: string) {
    return this.httpClient.post(`${environment.apiUrl}customer/add-payment-method`, {
      paymentMethodId
    });
  }

  public markProjectAsViewed(id: string) {
    return this.httpClient.post(`${environment.apiUrl}project/eventOpenFromManageProjectsPage`, {
      projectId: id
    });
  }

  public uploadLogo(userType: string, payload: FormData, options: any) {
    return this.httpClient
      .post(`${environment.apiUrl}${userType}/uploadLogo/`, payload, options);
  }

  public getIsDocReady(id: string) {
    return this.httpClient.get<{ isDocReady: boolean }>(
      `${environment.apiUrl}pdf/isPdfDocReady/${id}`
    );
  }

  public getIsDocWithoutBriefReady(id: string) {
    return this.httpClient.get<{ isDocReady: boolean }>(
      `${environment.apiUrl}pdf/isPdfDocWithoutBriefReady/${id}`
    );
  }

  public getDocRequirementsWithoutBriefById(id: string) {
    return this.httpClient.get<IProject>(
      `${environment.apiUrl}docWithoutBrief/getById/${id}`
    );
  }

  public saveFeats(body: { _id: string } | ChosenFeatures) {
    return this.httpClient.patch<IProject>(
      `${environment.apiUrl}project/saveFeats`,
      body
    );
  }
  public saveFeatsForDocWithoutBrief(body: { _id: string } | ChosenFeatures) {
    return this.httpClient.patch<IProject>(
      `${environment.apiUrl}docWithoutBrief/saveFeatsForDocWithoutBrief`,
      body
    );
  }
  public getFeatures() {
    return this.httpClient.get<AllFeatures>(
      `${environment.apiUrl}project/getAllFeatSections`
    );
  }

  public getClosedAndDeclinedBriefs() {
    return this.httpClient.get<IProject[]>(
      `${environment.apiUrl}project/getAllMyProject/24`
    );
  }
  public getOpenAndPendingBriefs() {
    return this.httpClient.get<IProject[]>(
      `${environment.apiUrl}project/getAllMyProject/13`
    );
  }

  public getDocsAndProjects(status) {
    return this.httpClient.get<IProject[]>(
      `${environment.apiUrl}docWithoutBrief/getAllMyDocWithoutBriefAndProjects/${status}`
    );
  }

  public createBrief(credential) {
    return this.httpClient.post(
      `${environment.apiUrl}project/create`,
      credential
    );
  }

  public createDoc(credential) {
    return this.httpClient.post(
      `${environment.apiUrl}docWithoutBrief/create`,
      credential
    );
  }

  public sendBrief(credential) {
    return this.httpClient.post(
      `${environment.apiUrl}project/sendBrief`,
      credential
    );
  }

  public sendGetListed(credential) {
    return this.httpClient.post(
      `${environment.apiUrl}provider/sendGetListed`,
      credential
    );
  }

  public sendLetUsKnow(credential) {
    return this.httpClient.post(
      `${environment.apiUrl}provider/sendLetUsKnow`,
      credential
    );
  }

  public sendBriefToProvider(providerId, projectId): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}project/sendAllDataBrief/${providerId}/${projectId}`,
      {}
    );
  }

  public getProjectAlertStatus(projectId, providerId): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}project/project-response-exists/${projectId}/${providerId}`,
      {}
    );
  }

  public checkEmail(credential) {
    return this.httpClient.post(
      `${environment.apiUrl}user/checkEmail`,
      credential
    );
  }

  public editBrief(credential): Observable<IProjectInfo> {
    return this.httpClient.patch<IProjectInfo>(
      `${environment.apiUrl}project/edit`,
      credential
    );
  }

  public editDoc(credential): Observable<IProjectInfo> {
    return this.httpClient.patch<IProjectInfo>(
      `${environment.apiUrl}docWithoutBrief/edit`,
      credential
    );
  }

  public closeProject(credential) {
    return this.httpClient.patch(
      `${environment.apiUrl}project/changeStatusToClosed/${credential}`,
      {}
    );
  }

  public getAlertsSent(skip = 0, limit = 20): Observable<IAlert[]> {
    return this.httpClient
      .get(
        `${environment.apiUrl}alert/getAllByStatus/0/?skip=${skip}&limit=${limit}`
      )
      .pipe(map((alerts: any) => Mapper.mapAlertsToIAlerts(alerts)));
  }

  public getAlertsOpen(skip = 0, limit = 20): Observable<IAlert[]> {
    return this.httpClient
      .get(
        `${environment.apiUrl}alert/getAllByStatus/1/?skip=${skip}&limit=${limit}`
      )
      .pipe(map((alerts: any[]) => Mapper.mapAlertsToIAlerts(alerts)));
  }

  public getAlertsInterest(skip = 0, limit = 20): Observable<IAlert[]> {
    return this.httpClient
      .get(
        `${environment.apiUrl}alert/getAllByStatus/2/?skip=${skip}&limit=${limit}`
      )
      .pipe(map((alerts: any[]) => Mapper.mapAlertsToIAlerts(alerts)));
  }

  public getUnreviewedAlertsAmount() {
    return this.httpClient.get<{ totalCountFiltered: number }>(
      `${environment.apiUrl}alert/getAllUnViewedByUser`
    );
  }

  public updateAlertsViewStatus(alertIds: string[]) {
    return this.httpClient.patch(`${environment.apiUrl}alert/changeToViewed`, {
      alertIds,
    });
  }

  public getSpec() {
    return this.httpClient.get<string[]>(
      `${environment.apiUrl}provider/getSpecializationAreasAndIndustrySpecializations`
    );
  }

  public getSkills() {
    return this.httpClient.get<string[]>(`${environment.apiUrl}skill/getAll`);
  }

  public getAccept() {
    return this.httpClient.get(
      `${environment.apiUrl}acceptCompensation/getAll`
    );
  }

  public getPrep() {
    return this.httpClient.get(`${environment.apiUrl}prep/getAllForProject`);
  }

  public getCountries(): Observable<ICountry[]> {
    return this.httpClient.get<ICountry[]>(`${environment.apiUrl}country/`);
  }

  public getStates(countryId: string): Observable<IState[]> {
    return this.httpClient.get<IState[]>(
      `${environment.apiUrl}country/states/${countryId}`
    );
  }

  public getCities(stateId: string): Observable<ICity[]> {
    return this.httpClient.get<ICity[]>(
      `${environment.apiUrl}country/cities/${stateId}`
    );
  }

  public getCompensation(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${environment.apiUrl}acceptCompensation/getAllActive`
    );
  }

  public getSpecialization(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${environment.apiUrl}provider/getSpecializationAreasAndIndustrySpecializations`
    );
  }

  public getProvidersCountry(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${environment.apiUrl}provider/getProvidersCountry`
    );
  }

  public getIndustries(): Observable<IIndustry[]> {
    return this.httpClient
      .get<any[]>(`${environment.apiUrl}industry/getAll`)
      .pipe(
        catchError(() => of([])),
        map((industry: any[]) => Mapper.mapIndustriesToIIndustries(industry))
      );
  }

  public getProviderIndustries(): Observable<IIndustry[]> {
    return this.httpClient.get<IIndustry[]>(
      `${environment.apiUrl}industry/getAllIndustriesForProviderUser`
    );
  }

  public getDetails() {
    return this.httpClient.get(
      `${environment.apiUrl}projectDetails/getAllActive`
    );
  }

  public getProjectTypes() {
    return this.httpClient.get(
      `${environment.apiUrl}project-type/getAllProjectType`
    );
  }

  public getDocRequirementsTypes() {
    return this.httpClient.get(
      `${environment.apiUrl}doc-without-brief-type/getAllDocWithoutBriefType`
    );
  }

  public getBriefs(item): Observable<IProject[]> {
    return this.httpClient
      .get(`${environment.apiUrl}project/getAllMyProject/${item}/`)
      .pipe(map((briefs: any[]) => Mapper.mapBriefsToIBriefs(briefs)));
  }

  public getBriefsSentedToProvider(providerId): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}provider/getNumberBriefs/${providerId}`
    );
  }

  public getBriefsSentByUserToProvider(providerId: string): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}provider/getNumberBriefsForCurrentUser/${providerId}`
    );
  }

  public login(credential: ILogin) {
    return this.httpClient
      .post(`${environment.apiUrl}user/signIn/`, credential)
      .pipe(
        tap((token: any) => {
          localStorage.setItem("token", token.tokenId);
        })
      );
  }

  public changePassword(credential) {
    return this.httpClient
      .post(`${environment.apiUrl}user/resetPassword`, credential)
      .pipe(
        tap((token: any) => {
          localStorage.setItem("token", token.tokenId);
        })
      );
  }

  public confirmEmail(token) {
    return this.httpClient.post(
      `${environment.apiUrl}user/confirmEmail/${token}`,
      {}
    );
  }

  public doSearch(credential?, skip = 0, limit = 20) {
    return this.httpClient
      .post(
        `${environment.apiUrl}provider/getFilteredProviders?skip=${skip}&limit=${limit}`,
        credential ? credential : ""
      )
      .pipe(
        catchError(() => of([])),
        map((providers: any) => Mapper.mapProvidersToIProviders(providers))
      );
  }

  public createCustomer(credential) {
    return this.httpClient.post(
      `${environment.apiUrl}customer/v1/create`,
      credential
    );
  }

  public afterCustomerPaymentSuccess(credential) {
    return this.httpClient.post(
      `${environment.apiUrl}customer/after-customer-payment-success`,
      credential
    );
  }

  public addTokenToCustomer(credential) {
    return this.httpClient.post(
      `${environment.apiUrl}customer/token`,
      credential
    );
  }

  public chargeCustomerFirstPrice() {
    return this.httpClient.post(
      `${environment.apiUrl}customer/chargeCustomerFirstPrice`,
      {}
    );
  }

  public chargeCustomerSecondPrice() {
    return this.httpClient.post(
      `${environment.apiUrl}customer/chargeCustomerSecondPrice`,
      {}
    );
  }

  public chargeCustomerAddCard() {
    return this.httpClient.post(
      `${environment.apiUrl}customer/add/userCard`,
      {}
    );
  }

  public chargeCustomerThirdPrice() {
    return this.httpClient.post(
      `${environment.apiUrl}customer/chargeCustomerThirdPrice`,
      {}
    );
  }

  public chargeCustomerOnlyDoc() {
    return this.httpClient.post(
      `${environment.apiUrl}customer/chargeCustomerOnlyDoc`,
      {}
    );
  }

  public chargeCustomerDocRequirements() {
    return this.httpClient.post(
      `${environment.apiUrl}customer/chargeCustomerDocWithoutBriefFlow`,
      {}
    );
  }

  public chargeCustomerFirstPriceAndDoc() {
    return this.httpClient.post(
      `${environment.apiUrl}customer/chargeCustomerFirstPriceAndDoc`,
      {}
    );
  }

  public chargeCustomerSecondPriceAndDoc() {
    return this.httpClient.post(
      `${environment.apiUrl}customer/chargeCustomerSecondPriceAndDoc`,
      {}
    );
  }

  public chargeCustomerThirdPriceAndDoc() {
    return this.httpClient.post(
      `${environment.apiUrl}customer/chargeCustomerThirdPriceAndDoc`,
      {}
    );
  }

  public getCustomer() {
    return this.httpClient.get<ICustomer>(
      `${environment.apiUrl}customer/getOne`
    );
  }

  public getCustomerCard() {
    return this.httpClient.get<ICard>(`${environment.apiUrl}customer/cards`);
  }

  public getCustomerInvoices() {
    return this.httpClient.get<IInvoice[]>(
      `${environment.apiUrl}customer/invoices`
    );
  }

  public setupIntent() {
    return this.httpClient.get<{ clientSecret: string }>(
      `${environment.apiUrl}customer/setup-intent`
    );
  }

  public deleteCustomerCard() {
    return this.httpClient.delete(
      `${environment.apiUrl}customer/deleteCurrentPaymentMethod`
    );
  }

  public resetPassword(credential: string) {
    return this.httpClient.post(
      `${environment.apiUrl}user/forgotPassword/?email=${encodeURIComponent(
        credential
      )}`,
      {}
    );
  }

  public checkToken(credential: string): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}user/checkToken/${credential}`,
      {}
    );
  }

  public sendVerifyData(credential) {
    return this.httpClient
      .post(`${environment.apiUrl}user/verify-phone/`, credential)
      .pipe(
        tap((token: any) => {
          localStorage.setItem("token", token.token);
        })
      );
  }

  public editPreferences(credential) {
    return this.httpClient.patch(
      `${environment.apiUrl}user/editPreferences`,
      credential
    );
  }

  public sendVerifyDataEdit(credential) {
    return this.httpClient
      .post(
        `${environment.apiUrl}user/editProfile/verify-phone-number/`,
        credential
      )
      .pipe(
        tap((token: any) => {
          // localStorage.setItem('token', token.token);
        })
      );
  }

  public sendVerifyPhoneNumber(phoneNumber) {
    return this.httpClient.post(`${environment.apiUrl}user/sendVerifyCode/`, {
      phone: phoneNumber,
    });
  }

  public signUpFirstStep(credential: User): Observable<User> {
    return this.httpClient
      .post(`${environment.apiUrl}user/signUp/`, credential)
      .pipe(
        tap((res: any) => localStorage.setItem("token", res.token)),
        map((user: any) => new User(user.user))
      );
  }

  public signUpSecondStep(credential: any, link: string): Observable<UserRole> {
    return this.httpClient
      .post(`${environment.apiUrl}${link}/create/`, credential)
      .pipe(map((user: any) => new UserRole(user)));
  }

  public getUserProfile(): Observable<UserFullInfo> {
    return this.httpClient
      .get(`${environment.apiUrl}user/getProfile/`)
      .pipe(map((response: any) => new UserFullInfo(response)));
  }

  public getProviderById(id) {
    return this.httpClient.get<IProvider>(
      `${environment.apiUrl}provider/getById/${id}`
    );
  }

  public providerSaveChanges(body) {
    return this.httpClient.patch(
      `${environment.apiUrl}provider/saveChanges`,
      body
    );
  }

  public deleteBrief(id: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}project/deleteOne/${id}`
    );
  }

  public deleteDoc(id: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}docWithoutBrief/deleteOne/${id}`
    );
  }

  public deleteUser(credential: any): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}user/deleteOne/`,
      credential
    );
  }

  public getBriefById(id) {
    return this.httpClient.get(`${environment.apiUrl}project/getById/${id}`);
  }

  public getAllProviders(
    skip = 0,
    limit = 20
  ): Observable<{ data: IProvider[]; totalCount: number }> {
    return this.httpClient
      .get(`${environment.apiUrl}provider/getAll?skip=${skip}&limit=${limit}`)
      .pipe(
        catchError(() => of([])),
        map((providers: any[]) => Mapper.mapProvidersToIProviders(providers))
      );
  }

  public getAllNewlyAddedAndFeatured() {
    return this.httpClient
      .get(`${environment.apiUrl}provider/getAllNewlyAddedAndFeatured`)
      .pipe(catchError(() => of([])));
  }

  public editAccount(user, role): Observable<User> {
    return this.httpClient
      .patch(`${environment.apiUrl}${role}/edit/`, user)
      .pipe(map((response: Partial<User>) => new User(response)));
  }

  public verifyEmail(user): Observable<User> {
    return this.httpClient
      .patch(`${environment.apiUrl}user/verifyEmail/`, user)
      .pipe(map((response: Partial<User>) => new User(response)));
  }

  public getAllCountries(): Observable<ICountry[]> {
    return this.httpClient.get(`${environment.apiUrl}country/`).pipe(
      catchError(() => of([])),
      map((countries: any[]) => Mapper.mapCountriesToICountries(countries))
    );
  }

  public getAllCities(id: string): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}country/getAllCity/${id}`);
  }

  public getAllTestimonials(): Observable<ITestimonial[]> {
    return this.httpClient.get(`${environment.apiUrl}testimonial/getAll`).pipe(
      catchError(() => of([])),
      map((testimonials: any[]) =>
        Mapper.mapTestimonialsToITestimonials(testimonials)
      )
    );
  }

  public contactUs(userMessage): Observable<any> {
    return this.httpClient.post(
      `${environment.apiUrl}user/contactUs`,
      userMessage
    );
  }

  public getDoc(projectId: string) {
    return this.httpClient.get<Blob>(
      `${environment.apiUrl}pdf/getPdfDoc/${projectId}`,
      { responseType: "blob" as "json" }
    );
  }

  public getDocWithoutBrief(projectId: string) {
    return this.httpClient.get<Blob>(
      `${environment.apiUrl}pdf/getPdfDocWithoutBrief/${projectId}`,
      { responseType: "blob" as "json" }
    );
  }

  public getExampleDoc() {
    return this.httpClient.get<Blob>(
      `${environment.apiUrl}pdf/download-example-doc`,
      { responseType: "blob" as "json" }
    );
  }

  public getUserById(id: string) {
    return this.httpClient.get<UserFullInfo>(
      `${environment.apiUrl}user/getById/${id}`
    );
  }

  public editHeadquarterLocation(credential, id) {
    return this.httpClient.patch<LocationModel>(
      `${environment.apiUrl}headquarter/edit/${id}`,
      credential
    );
  }

  public editOtherLocation(credential, id) {
    return this.httpClient.patch<IProvider>(
      `${environment.apiUrl}headquarter/editMany/${id}`,
      credential
    );
  }

  public deleteOtherLocation(otherLocationId, id) {
    return this.httpClient.delete<IProvider>(
      `${environment.apiUrl}headquarter/deleteById/${otherLocationId}/${id}`
    );
  }

  public createSubscription(data, saveCard?: boolean) {
    return this.httpClient.post<SubscriptionModel>(
      `${environment.apiUrl}customer/createSubscription/`,
      data,
      {
        params: {
          isCheckboxChecked: saveCard ?? false
        }
      }
    );
  }

  public getAllSubscriptions() {
    return this.httpClient.get<SubscriptionProduct[]>(`${environment.apiUrl}customer/getAllProductsForSubscription`);
  }

  public getCustomerCurrentSubscription(subId: string) {
    return this.httpClient.get<SubscriptionModel>(`${environment.apiUrl}customer/getCurrentSubscription/${subId}`);
  }

  public cancelCurrentSubscription() {
    return this.httpClient.post<SubscriptionModel>(`${environment.apiUrl}customer/cancelCurrentSubscription`, {});
  }

  public resumeCurrentSubscription() {
    return this.httpClient.post<SubscriptionModel>(`${environment.apiUrl}customer/resumeSubscription`, {});
  }
}
