<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Profile Update Request</p>

  <form class="login-content-form" [formGroup]="letUsForm">
    <div class="left">
      <label class="paragraph2" for="email"><b>Name</b> *</label>
      <input
        [ngClass]="{
          'error-input': letUsForm.controls['name'].invalid && submitted
        }"
        class="data-input"
        id="email"
        type="text"
        placeholder="What's your full name?"
        required
        formControlName="name"
      />
      <div
        class="error-text error-text-area"
        *ngIf="submitted && letUsForm.controls['name'].invalid"
      >
        <p *ngIf="letUsForm.controls['name'].errors.required">
          This field is required.
        </p>
        <p *ngIf="letUsForm.controls['name'].errors.minlength">
          This field needs to be at least 5 characters long.
        </p>
      </div>

      <label class="paragraph2" for="password"><b>My Role</b> *</label>
      <input
        [ngClass]="{
          'error-input': letUsForm.controls['myRole'].invalid && submitted
        }"
        class="data-input"
        id="password"
        type="text"
        placeholder="What's your position at this company?"
        required
        formControlName="myRole"
      />
      <div
        class="error-text error-text-area"
        *ngIf="submitted && letUsForm.controls['myRole'].invalid"
      >
        <p *ngIf="letUsForm.controls['myRole'].errors.required">
          This field is required.
        </p>
      </div>

      <label class="paragraph2" for="email"><b>Email</b> *</label>
      <input
        [ngClass]="{
          'error-input': letUsForm.controls['email'].invalid && submitted
        }"
        class="data-input"
        id="email"
        type="email"
        placeholder="What's your email address?"
        required
        formControlName="email"
      />
      <div
        class="error-text error-text-area"
        *ngIf="submitted && letUsForm.controls['email'].invalid"
      >
        <p *ngIf="letUsForm.controls['email'].errors?.required">
          This field is required.
        </p>
        <p *ngIf="letUsForm.controls['email'].errors?.email || letUsForm.controls['email'].errors?.pattern">
          Please enter the correct email address is required.
        </p>
      </div>

      <label class="paragraph2" for="password"
        ><b>Contact Phone Number</b> *</label
      >
      <input
        [ngClass]="{
          'error-input':
            letUsForm.controls['contactPhoneNumber'].invalid && submitted
        }"
        class="data-input"
        id="password"
        type="number"
        placeholder="Enter a contact phone number so we can reach you."
        required
        formControlName="contactPhoneNumber"
      />
      <div
        class="error-text error-text-area"
        *ngIf="submitted && letUsForm.controls['contactPhoneNumber'].invalid"
      >
        <p *ngIf="letUsForm.controls['contactPhoneNumber'].errors.required">
          This field is required.
        </p>
      </div>
      <label class="paragraph2" for="providerName"><b>Service Provider Name</b> *</label>
      <div>
        <input type="text"
               class="data-input"
               [ngClass]="{
          'error-input':
            letUsForm.controls['providerName'].invalid && submitted
        }"
               formControlName="providerName"
               id="providerName"
               placeholder="Search the company name."
               (ngModelChange)="doSearch($event)"
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option
            *ngFor="let item of filteredProviders"
            [value]="item"
          >{{ item }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div
        class="error-text"
        *ngIf="submitted && letUsForm.controls['providerName'].invalid"
      >
        <p *ngIf="letUsForm.controls['providerName'].errors.required">
          This field is required.
        </p>
      </div>
    </div>
    <div class="right">
      <label class="paragraph2" for="website"><b>Company Website</b> *</label>
      <input
        [ngClass]="{
          'error-input':
            letUsForm.controls['companyWebSite'].invalid && submitted
        }"
        class="data-input"
        id="website"
        type="text"
        placeholder="What's the company's website?"
        required
        formControlName="companyWebSite"
      />
      <div
        class="error-text error-text-area"
        *ngIf="submitted && letUsForm.controls['companyWebSite'].invalid"
      >
        <p *ngIf="letUsForm.controls['companyWebSite'].errors.required">
          This field is required.
        </p>
        <p *ngIf="letUsForm.controls['companyWebSite'].errors.pattern">
          Enter a valid website by entering https://www or http://www before the domain name.
        </p>
      </div>

      <label class="paragraph2" for="password"
        ><b>What do you need updated?</b> *</label
      >
      <textarea
        [ngClass]="{
          'error-input': letUsForm.controls['needUpdate'].invalid && submitted
        }"
        class="data-input last"
        id="password"
        type="text"
        placeholder="Tell us what elements you are requesting to have updated...."
        required
        formControlName="needUpdate"
      ></textarea>
      <div
        class="error-text error-text-area"
        *ngIf="submitted && letUsForm.controls['needUpdate'].invalid"
      >
        <p *ngIf="letUsForm.controls['needUpdate'].errors.required">
          This field is required.
        </p>
        <p *ngIf="letUsForm.controls['needUpdate'].errors.minlength">
          This field needs to be at least 5 characters long.
        </p>
      </div>
    </div>
  </form>

  <button [disabled]=isValidForm class="primary-btn" type="submit" (click)="send()">Submit Request</button>
</div>
