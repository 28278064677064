import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { IProject } from "../../../interfaces/project";
import { IProvider, IProviderDashboardInfo } from '../../../interfaces/provider';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class ProviderPortalService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  public getProjects(providerId: string, status: string[]) {
    let params = new HttpParams();
    params = params.append('statuses', status.join(','));
    return this.http.get<any>(
      `${this.apiUrl}project/provider-projects/${providerId}`, {
        params
      }
    );
  }

  public setProjectAsInteresting(providerId: string, projectId: string) {
    return this.http.post(
      `${this.apiUrl}project/interested-project/${projectId}/${providerId}`,
      {}
    );
  }

  public setProjectAsNotInteresting(providerId: string, projectId: string) {
    return this.http.post(
      `${this.apiUrl}project/not-interested-project/${projectId}/${providerId}`,
      {}
    );
  }

  public uploadProviderCoverPhoto(data: FormData) {
    return this.http.post(`${this.apiUrl}provider/uploadCoverPhoto`, data);
  }

  public uploadProviderLogo(data: FormData) {
    return this.http.post(
      `${this.apiUrl}provider/uploadLogoUserProvider`,
      data
    );
  }

  public profileUpdate(data: Partial<IProvider>) {
    return this.http.patch<IProvider>(
      `${this.apiUrl}provider/saveChanges`,
      data
    );
  }

  public deleteAccount(
    id: string,
    body: { userId: string; userPassword: string }
  ) {
    return this.http.delete(
      `${this.apiUrl}provider/deleteProviderUserAcc/${id}`,
      { body }
    );
  }

  public toggleEmailNotifications(providerId: string) {
    return this.http.patch(
      `${this.apiUrl}provider/toggleEmailNotifications/${providerId}`,
      {}
    );
  }

  public addAdditionalEmail(data: { _id: string; additionalEmail: string }) {
    return this.http.post(`${this.apiUrl}provider/addAdditionalEmail`, data);
  }

  public deleteAdditionalEmail(providerId: string) {
    return this.http.delete(
      `${this.apiUrl}provider/deleteAdditionalEmail/${providerId}`,
      {}
    );
  }

  public getInfoForProviderDashboard() {
    // return new Observable<IProviderDashboardInfo>((subscriber) => {
    //   setTimeout(() => subscriber.next(
    //     {
    //       numNewProjects: 2,
    //       numInterestedProjects: 1,
    //       numNotInterestedProjects: 1,
    //       numClosedProjects: 20,
    //       numPublishedArticles: 9,
    //       numInDraftArticles: 46,
    //       numUnpublishedArticles: 0,
    //       subscriptionPlan: 'Premium',
    //       billingInfo: 4242
    //     }
    //   ), 150);
    // });
    return this.http.get<IProviderDashboardInfo>(
      `${this.apiUrl}provider/getInfoForProviderDashboard`
    );
  }
}
