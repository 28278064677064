import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { MessageSentComponent } from "src/app/modules/popups/message-sent/message-sent.component";
import { ILogin } from "../../../interfaces/auth-interfaces";
import { AuthDataService } from "../../../services/auth-data.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent {
  public contactUsForm: UntypedFormGroup = this.fb.group({
    email: ["", [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    name: ["", [Validators.required, Validators.minLength(5)]],
    message: ["", [Validators.required, Validators.minLength(5)]],
    subject: ["", [Validators.required, Validators.minLength(5)]],
  });
  public submitted = false;
  public isValidForm = false;

  constructor(
    public dialogRef: MatDialogRef<ContactUsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ILogin,
    private authDS: AuthDataService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog
  ) {}

  @Output() sendLoginEmitter: EventEmitter<ILogin> = new EventEmitter<ILogin>();

  onNoClick(): void {
    this.dialogRef.close();
  }

  public sendMessage() {
    this.submitted = true;
    if (this.contactUsForm.valid) {
      this.isValidForm = true;

      this.authDS
        .contactUs(this.contactUsForm.value)
        .pipe(first())
        .subscribe(() => {
          this.dialog.open(MessageSentComponent, {
            autoFocus: false,
            height: "600px",
            width: "500px",
            data: {
              title: "Message Sent!",
              message: "Your inquiry for contacting us has been sent. A representative from our team will be in touch!",
              button: "Great!",
            }
          });

          this.onNoClick();
        });
    }
  }
}
