<div class="login-content">
  <img
      class="close-icon"
      src="./../../../../assets/icons/x.svg"
      alt=""
      mat-dialog-close
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">{{ data.title }}</p>

  <pre class="paragraph2">
    {{ data.text }}
  </pre>

  <button class="primary-btn" [mat-dialog-close]="false">
    {{ data.confirmBtn }}
  </button>

  <button class="transparent-button" [mat-dialog-close]="true">
    {{ data.dismissBtn }}
  </button>
</div>
