import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { IProvider } from '../../../../interfaces/provider';
import { UserFullInfo, UserTypesAccount } from '../../../../interfaces/auth-interfaces';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';
import { AuthDataService } from '../../../../services/auth-data.service';
import { UnverifiedAccountComponent } from '../../../popups/unverified-account/unverified-account.component';
import { LoginComponent } from '../../../popups/login/login.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-provider-info',
  templateUrl: './provider-info.component.html',
  styleUrls: ['./provider-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderInfoComponent implements OnInit {
  @Input({ required: true }) provider: IProvider;
  @Input() previewMode = false;

  private authDS = inject(AuthDataService);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private destroyRef = inject(DestroyRef);

  public user: UserFullInfo;

  ngOnInit() {
    this.authDS.user$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((user) => !!user),
      )
      .subscribe((user) => (this.user = user));

  }

  public onSendBriefClick() {
    if (this.user) {
      if (this.user?.user.isPhoneVerified && this.user?.user.confirmed) {
        this.router
          .navigate(['/create-brief'], {
            queryParams: {
              company: this.provider?._id,
              type: 'provider',
              name: this.provider?.companyName,
            },
          })
          .then();
      } else {
        this.dialog.open(UnverifiedAccountComponent, {
          autoFocus: false,
          width: '450px',
          disableClose: true,
        });
      }
    } else {
      this.dialog.open(LoginComponent, {
        autoFocus: false,
        width: '500px',
        data: {
          title: ' ',
          message: 'You need to be authorized to be able to send a project brief.',
          button: 'Got it!',
        },
      });
    }
  }

  public isNotProvider() {
    if (!this.user) return false;
    return this.user.user.typeAccount !== UserTypesAccount.Provider;
  }
}
