import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {AuthDataService} from './auth-data.service';
import {UserFullInfo} from '../interfaces/auth-interfaces';

@Injectable()
export class AuthGuardService  {
  user: UserFullInfo;

  constructor(public router: Router) {}
  async canActivate(): Promise<boolean> {
    this.user = await JSON.parse(localStorage.getItem('userProfile'));
    if (this.user?.user.isPhoneVerified && this.user?.user.confirmed) {
      return true;
    }
    this.router.navigate(['/']).then();
    return false;
  }
}
