import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeatureSection } from '../../../../interfaces/project';

@Component({
  selector: 'app-features-part-two',
  templateUrl: './features-part-two.component.html',
  styleUrls: ['./features-part-two.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturesPartTwoComponent implements OnInit {
  @Input({ required: true }) allFeatures: FeatureSection;
  @Input({ required: true }) chosenFeatures: string[] | string;
  @Input({ required: true }) currentFeatureSubstep: number;
  @Input({ required: true }) maxFeatureSubstep: number;
  @Input() totalSteps = 7;
  @Input() currentStep = 4;
  @Output('nextClick') nextClicked = new EventEmitter();
  @Output('saveClick') saveClicked = new EventEmitter();
  @Output('prevClick') prevClicked = new EventEmitter();

  showError = false;
  otherFieldValue: string = '';
  ngOnInit() {
    if(typeof this.chosenFeatures === 'string') {
      this.otherFieldValue = this.chosenFeatures;
    }
  }

  handleNextClick() {
    if(!this.isValid) {
      this.showError = true;
      return;
    }
    this.nextClicked.emit(this.createDataToEmit());
  }

  handleSaveClick(){
    if(!this.isValid) {
      this.showError = true;
      return;
    }
    this.saveClicked.emit(this.createDataToEmit());
  }

  handlePreviousClick() {
    this.prevClicked.emit();
  }

  handleCheckbox(e: Event) {
    const target = e.target as HTMLInputElement;
    if (Array.isArray(this.chosenFeatures)) {
      if (target.checked) {
        this.chosenFeatures.push(target.value);
      } else {
        this.chosenFeatures = this.chosenFeatures.filter(el => el !== target.value);
      }
    }
  }

  private createDataToEmit() {
    return {
      [this.allFeatures.key]: {
        isChosen: true,
        feats: this.allFeatures.key === 'other' ? this.otherFieldValue : this.chosenFeatures
      }
    };
  }

  get isValid() {
    return this.allFeatures.key === 'other'
        ? this.otherFieldValue.length >= 50
        : !!this.chosenFeatures.length;
  }
}
