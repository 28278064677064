<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Are you sure?</p>

  <p class="paragraph2">
    {{ data.message }}
  </p>

  <button class="primary-btn" type="submit" (click)="onNoClick()">
    Continue with Purchase
  </button>
  <a (click)="onCancelClick()" class="cancel">Yes, Cancel Purchase</a>
</div>
