import { Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ICity, ICountry, IState } from '../../../../interfaces/project';
import { AuthDataService } from '../../../../services/auth-data.service';
import { UserFullInfo } from '../../../../interfaces/auth-interfaces';
import { locationAutocompleteValidator } from '../../../shared/validators/locationAutocompleteValidator';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin, Observable, of } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { map, startWith } from 'rxjs/operators';
import { UserRoleEnum } from '../../../../enums/user-role.enum';
import { AccountSettingsService } from '../../account-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-startup-enterprise-profile',
  templateUrl: './startup-enterprise-profile.component.html',
  styleUrls: ['./startup-enterprise-profile.component.scss'],
})
export class StartupEnterpriseProfileComponent implements OnInit {
  @ViewChild('countryAutocomplete') countryAutocomplete: MatAutocomplete;
  @ViewChild('regionAutocomplete') regionAutocomplete: MatAutocomplete;
  @ViewChild('cityAutocomplete') cityAutocomplete: MatAutocomplete;
  @ViewChild('inboxPopupMsg') inboxPopupMsg: TemplateRef<any>;
  @ViewChild('industryAutocomplete') industryAutocomplete: MatAutocomplete;
  @ViewChild('companySizeAutocomplete') companySizeAutocomplete: MatAutocomplete;

  public accountSettingsService = inject(AccountSettingsService);
  private loader = inject(NgxUiLoaderService);
  private authDS = inject(AuthDataService);
  private snackBar = inject(MatSnackBar);
  private destroyRef = inject(DestroyRef);

  public user: UserFullInfo;

  public countries: ICountry[] = [];
  public states: IState[] = [];
  public cities: ICity[] = [];
  public filteredCountries$: Observable<ICountry[]>;
  public filteredStates$: Observable<IState[]>;
  public filteredCities$: Observable<ICity[]>;

  public industries: any[] = [];
  public companySizes = [
    { value: 0, viewValue: '1 - 5 employees' },
    { value: 1, viewValue: '5 - 10 employees' },
    { value: 2, viewValue: '10 - 20 employees' },
    { value: 3, viewValue: '20 - 50 employees' },
    { value: 4, viewValue: '50 - 100 employees' },
    { value: 5, viewValue: '100 - 300 employees' },
    { value: 6, viewValue: '300 - 500 employees' },
    { value: 7, viewValue: '500 - above employees' },
  ];

  profileInfoForm = new FormGroup({
    companyName: new FormControl<string>('', Validators.required),
    companyWebSite: new FormControl<string>('', [Validators.required, Validators.minLength(6), Validators.pattern('^(?:http(s)?:\\/\\/)[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$')]),
    primaryContact: new FormControl('', [Validators.required, Validators.minLength(1)]),
    headOfficeAddress: new FormControl<string>(''),
    city: new FormControl<ICity>(null, [Validators.required, locationAutocompleteValidator()]),
    state: new FormControl<IState>(null, [Validators.required, locationAutocompleteValidator()]),
    country: new FormControl<ICountry>(null, [Validators.required, locationAutocompleteValidator()]),
    companyPhoneNumber: new FormControl(null, Validators.required),
  });
  profileInfoControls = this.profileInfoForm.controls;

  industryForm = new FormGroup({
    industry: new FormControl(null, Validators.required),
    companySize: new FormControl(null, Validators.required),
  });
  industryControls = this.industryForm.controls;

  email = new FormControl<string>('', [Validators.required, Validators.email]);
  phoneNumber = new FormControl(null, Validators.required);

  ngOnInit() {
    this.loader.start();
    this.authDS.user$.subscribe({
      next: (user) => {
        if (!user) {
          return;
        }
        this.user = user;
        this.initFormValues();
        this.loader.stop();
      },
    });
  }

  private initFormValues() {
    const { country, state, city } = this.user?.userRole;

    forkJoin([
      this.authDS.getCountries(),
      this.authDS.getIndustries(),
      country?._id ? this.authDS.getStates(country._id) : of([]),
      state?._id ? this.authDS.getCities(state._id) : of([]),
    ]).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ([countries, industries, states, cities]) => {
          this.countries = countries;
          this.industries = industries;
          this.states = states;
          this.cities = cities;
          this.initFilteredOptions();

          const {
            companyName,
            companyWebSite,
            primaryContact,
            companyPhoneNumber,
            industry,
            companySize,
            headOfficeAddress,
          } = this.user?.userRole;
          this.profileInfoForm.patchValue({
            companyName,
            companyWebSite,
            primaryContact,
            companyPhoneNumber,
            headOfficeAddress,
            country,
            state,
            city,
          });
          this.industryForm.setValue({
            industry: {
              _id: industry._id,
              name: industry.title,
              description: industry.description,
            },
            companySize,
          });
          const { email, phoneNumber } = this.user?.user;
          this.email.setValue(email);
          this.phoneNumber.setValue(phoneNumber);

          if (!state) {
            this.profileInfoControls.state.clearValidators();
            this.profileInfoControls.state.setValue(null);
          }
          if (!city) {
            this.profileInfoControls.city.clearValidators();
            this.profileInfoControls.city.setValue(null);
          }

          setTimeout(() => {
            this.countryAutocomplete.options
              .find((el) => el.value._id === country._id)
              ?.select();
            this.regionAutocomplete?.options
              .find((el) => el.value._id === state?._id)
              ?.select();
            this.cityAutocomplete?.options
              .find(el => el.value._id === city?._id)
              ?.select();
            this.industryAutocomplete.options.find(
              el => el.value._id === industry?._id,
            )?.select();
            this.companySizeAutocomplete.options.find(
              el => {
                if (el.value.value !== companySize) {
                  return false;
                }
                this.industryControls.companySize.setValue(el.value);
                return true;
              },
            )?.select();
          });
        },
      });
  }

  profileSaveChanges() {
    console.log(this.profileInfoForm, this.profileInfoForm.invalid);
    if (this.profileInfoForm.invalid) {
      return this.profileInfoForm.markAllAsTouched();
    }
    const { country, state, city } = this.profileInfoForm.value;
    const data = {
      _id: this.user?.userRole?._id,
      ...this.profileInfoForm.value,
      country: country._id,
      state: state?._id,
      city: city?._id,
    };
    this.accountSettingsService.saveChanges(data, UserRoleEnum[this.user?.user.role])
      .subscribe({
        next: () => this.snackBar.open('Changes saved!', 'OK', { duration: 1500 })
      });
  }

  industrySaveChanges() {
    if (this.industryForm.invalid) {
      return this.industryForm.markAllAsTouched();
    }
    const { industry, companySize } = this.industryForm.value;
    console.log(industry);
    const data = {
      _id: this.user?.userRole?._id,
      industry: industry._id,
      companySize: companySize.value,
    };
    this.accountSettingsService.saveChanges(data, UserRoleEnum[this.user?.user.role])
      .subscribe({
        next: () => this.snackBar.open('Changes saved!', 'OK', { duration: 1500 })
      });
  }

  emailSaveChanges() {
    this.accountSettingsService.emailSaveChanges(this.email, this.inboxPopupMsg);
  }

  verifyEmail() {
    this.accountSettingsService.verifyEmail(this.email, this.inboxPopupMsg);
  }

  phoneNumberSaveChanges() {
    this.accountSettingsService.phoneNumberSaveChanges(this.phoneNumber);
  }

  verifyPhoneNumber() {
    this.accountSettingsService.verifyPhoneNumber(this.user);
  }

  private initFilteredOptions() {
    this.filteredCountries$ = this.profileInfoControls.country.valueChanges.pipe(
      startWith((this.profileInfoControls.country.value as ICountry)?.name ?? ''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name
          ? this._filter(name as string, this.countries)
          : this.countries.slice();
      }),
    );
    this.filteredStates$ = this.profileInfoControls.state.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name
          ? this._filter(name as string, this.states)
          : this.states.slice();
      }),
    );
    this.filteredCities$ = this.profileInfoControls.city.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name
          ? this._filter(name as string, this.cities)
          : this.cities.slice();
      }),
    );
  }

  private _filter<T extends { name?: string }>(
    name: string,
    options: T[],
  ): T[] {
    const filterValue = name.toLowerCase();
    return options.filter((option) =>
      option.name.toLowerCase().includes(filterValue),
    );
  }

  public onCountrySelection(event: MatAutocompleteSelectedEvent) {
    this.authDS
      .getStates(event.option.value._id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((states) => {
        this.states = states;
        if (!states.length) {
          this.profileInfoControls.state.clearValidators();
          this.profileInfoControls.city.clearValidators();
        } else {
          this.profileInfoControls.state.addValidators(Validators.required);
          this.profileInfoControls.city.addValidators(Validators.required);
        }
        this.profileInfoControls.state.setValue(null);
        this.profileInfoControls.city.setValue(null);
      });
  }

  public onStateSelection(event: MatAutocompleteSelectedEvent) {
    this.authDS
      .getCities(event.option.value._id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((cities) => {
        this.cities = cities;
        if (!cities.length) {
          this.profileInfoControls.city.clearValidators();
        } else {
          this.profileInfoControls.city.addValidators(Validators.required);
        }
        this.profileInfoControls.city.setValue(null);
      });
  }

  numericOnlyWithPlus(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode === 101 || charCode === 69 || charCode === 45 || charCode === 43 || charCode === 189);
  }

  public displayName<T extends { name: string }>(el: T) {
    return el?.name;
  }

  public displayViewValue<T extends { viewValue: string }>(el: T) {
    return el?.viewValue;
  }
}
