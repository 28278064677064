import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthDataService } from '../../../services/auth-data.service';
import { Router } from '@angular/router';
import { UserTypesAccount } from '../../../interfaces/auth-interfaces';

@Component({
  selector: 'app-choose-account-type',
  templateUrl: './choose-account-type.component.html',
  styleUrls: ['./choose-account-type.component.scss']
})
export class ChooseAccountTypeComponent {

  protected readonly UserTypesAccount = UserTypesAccount;

  constructor(
    public dialogRef: MatDialogRef<ChooseAccountTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private authDS: AuthDataService,
    private router: Router,
  ) {}

  public login(typeAccount: UserTypesAccount.Client | UserTypesAccount.Provider) {
    this.authDS.setActiveAccount(typeAccount);
    localStorage.setItem('currentAccountType', this.authDS.getCurrentAccountType());
    let redirectTo =
      typeAccount === UserTypesAccount.Provider
        ? this.data?.redirectTo ?? '/provider/dashboard'
        : this.data?.redirectTo ?? '/';
    this.dialogRef.close(true);
    if (typeAccount !== UserTypesAccount.Provider && this.data?.fromCreateBrief) {
      redirectTo += '?fromCreateBrief=true';
    }
    this.router.navigateByUrl(redirectTo);
  }
}
