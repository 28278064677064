import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-token-invalid',
  templateUrl: './token-invalid.component.html',
  styleUrls: ['./token-invalid.component.scss']
})
export class TokenInvalidComponent {
  constructor(
    public dialogRef: MatDialogRef<TokenInvalidComponent>,
    private router: Router
  ) {}

  onNoClick(): void {
    this.router.navigate(['/']).then();
    this.dialogRef.close();
  }
}
