<div class="paginator">
    <ng-container *ngTemplateOutlet="prevButtons"></ng-container>

    <ng-container *ngFor="let pageNumber of getPageRange()">
        <ng-container *ngIf="pageNumber === -1; else pageLink">
            <span>...</span>
        </ng-container>
        <ng-template #pageLink>
            <button
                [class.active]="currentPage === pageNumber"
                (click)="setPage(pageNumber)"
            >
                {{ pageNumber }}
            </button>
        </ng-template>
    </ng-container>

    <ng-container *ngTemplateOutlet="nextButtons"></ng-container>
</div>

<ng-template #prevButtons>
    <div class="first-page-button" (click)="setPage(1)">
        <div class="prev-button" [class.disabled]="isFirstPage"></div>
        <div class="prev-button" [class.disabled]="isFirstPage"></div>
    </div>
    <div
        class="prev-button"
        [class.disabled]="isFirstPage"
        (click)="prevPage()"
    ></div>
</ng-template>

<ng-template #nextButtons>
    <div
        class="next-button"
        [class.disabled]="isLastPage"
        (click)="nextPage()"
    ></div>
    <div class="last-page-button" (click)="setPage(totalPages)">
        <div class="next-button" [class.disabled]="isLastPage"></div>
        <div class="next-button" [class.disabled]="isLastPage"></div>
    </div>
</ng-template>
