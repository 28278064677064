import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { AuthDataService } from '../../../../services/auth-data.service';
import { UserFullInfo } from '../../../../interfaces/auth-interfaces';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgOtpInputComponent } from 'ng-otp-input/lib/components/ng-otp-input/ng-otp-input.component';
import { AccountSettingsService } from '../../account-settings.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-code-verify',
  templateUrl: './code-verify.component.html',
  styleUrls: ['./code-verify.component.scss'],
})
export class CodeVerifyComponent implements OnInit {
  @ViewChild('ngOtpInput') ngOtpInput: NgOtpInputComponent;
  private accountSettingsService = inject(AccountSettingsService);
  private authDS = inject(AuthDataService);
  private route = inject(ActivatedRoute);
  private destroyRef = inject(DestroyRef);
  private snackBar = inject(MatSnackBar);
  private router = inject(Router);

  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '50px',
      height: '50px',
    },
  };
  user: UserFullInfo;
  showOtpComponent = true;
  verifyCode: string;

  ngOnInit() {
    this.authDS.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
      next: (user) => {
        if (!user) {
          return;
        }
        this.user = user;
      },
    });
    this.accountSettingsService.$hasAccessToVerification.set(false);
  }

  public sendVerifyPhoneNumber() {
    this.authDS.sendVerifyPhoneNumber(this.user?.user.phoneNumber)
      .pipe(first())
      .subscribe({
        next: () => {
          this.snackBar.open('We texted you the 4-digit code to your mobile.', '', {
            duration: 2500,
          });
        },
      });
  }

  public changePhoneNumber() {
    this.ngOtpInput.setValue(null);
    this.accountSettingsService.stepper.next();
  }

  public sendCode() {
    if (!this.verifyCode || this.verifyCode?.length !== 4) {
      this.snackBar.open('Enter the 4-digit code we sent to your mobile number.', '', {
        duration: 1500,
      });
      return;
    }
    const verifyData = {
      phone: this.user?.user.phoneNumber,
      phoneCode: this.verifyCode,
    };
    this.authDS
      .sendVerifyDataEdit(verifyData)
      .pipe(first())
      .subscribe({
        next: () => {
          this.authDS.getUserProfile().pipe(first())
            .subscribe({
              next: () => {
                this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: {
                    activeTab: 'Profile'
                  },
                  queryParamsHandling: 'merge',
                });
                this.ngOtpInput.setValue(null);
              },
            });
        },
        error: (error) => this.snackBar.open(error.error.message, '', {
          duration: 1500,
        }),
      });
  }

  public onOtpChange(otp) {
    this.verifyCode = otp;
  }
}
