<div class='block-container'>
  <div class='title'>Verification</div>
  <hr />
  <div class='main-section'>
    <div>
    <h2 class='sub-title'>Let's Verify You</h2>
    <p class='paragraph2'>
      Enter the 4-digit number that was sent to <br />
      <b><{{ this.user?.user.phoneNumber }}> </b>
      to authenticate your account.
    </p>
    <div class='number-input-section'>
      <ng-otp-input
          #ngOtpInput
          (onInputChange)='onOtpChange($event)'
          *ngIf='showOtpComponent'
          [config]='config'
      >
      </ng-otp-input>
    </div>
    <p (click)='sendVerifyPhoneNumber()' class='paragraph verification-text'>
      Re-send Verification Code
    </p>
    <p (click)='changePhoneNumber()' class='paragraph verification-text mt-1'>
      Change Phone Number
    </p>
    </div>
    <div class='image-section'>
      <img
        src="../../../../../assets/auth/entercode.png"
        alt="enter code"
      />
    </div>
  </div>
  <hr />
  <button (click)='sendCode()' class='primary-btn'>
    Let's Go!
  </button>
</div>
