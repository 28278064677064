<section class="filters">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="closePopup()"
  />
  <h2 class="sub-title">Filter Your Search</h2>
  <div class="filters-content">
    <form [formGroup]="formCountries" novalidate>
      <div class="filters-content-box">
        <p class="paragraph2">Countries where service providers should be from
          <mat-icon matTooltip="If you would like to be matched with service providers from a particular place around the world, select the desired countries of choice.">info</mat-icon>
        </p>
        <ul class="filters-content-box-wrap filters-countries">
          <li class="text-field checkbox">
            <label>
              <input
                (click)="clearFiltersCountries($event)"
                [checked]="formCountries?.value?.checkArray?.length <= 0"
                [value]="null"
                type="checkbox"
                class="myCheckbox countriesAny"
              />
            </label>
            Any
          </li>
          <li
            class="text-field checkbox"
            *ngFor="let country of data.countries"
          >
            <label>
              <input
                (click)="clearFiltersCountriesAny()"
                [checked]="
                  formCountries?.value?.checkArray?.includes(country.name)
                "
                [value]="country.name"
                (change)="onCheckboxChange($event, formCountries)"
                type="checkbox"
                class="myCheckbox countries"
              />
            </label>
            {{ country?.name }}
          </li>
        </ul>
      </div>
    </form>

    <form [formGroup]="formSpecialize" novalidate class="form-industry">
      <div class="filters-content-box">
        <p class="paragraph2">Industry expertise service providers should have
          <mat-icon matTooltip="If you would like to be matched with service providers who have specific expertise, select the desired preferences.">info</mat-icon>
        </p>

        <ul class="filters-content-box-wrap">
          <li class="text-field checkbox">
            <label>
              <input
                (click)="clearFiltersSpec($event)"
                [checked]="formSpecialize?.value?.checkArray?.length <= 0"
                [value]="null"
                type="checkbox"
                class="myCheckbox specAny"
              />
            </label>
            Any
          </li>
          <li
            class="text-field checkbox"
            *ngFor="let category of data.specializationAreas"
          >
            <label>
              <input
                (click)="clearFiltersSpecAny()"
                [checked]="
                  formSpecialize?.value?.checkArray?.includes(category)
                "
                [value]="category"
                (change)="onCheckboxChange($event, formSpecialize)"
                type="checkbox"
                class="myCheckbox spec"
              />
            </label>
            {{ category }}
          </li>
        </ul>
      </div>
    </form>
  </div>
  <div class="filters-content-box-buttons">
    <div class="clear-button">
      <img src="./../../../../assets/icons/Trash.svg" alt="" />
      <p class="paragraph2" (click)="clearFilters()">Clear Filters</p>
    </div>
    <!--      <input class="primary-btn" type="submit" value="Apply Filters" (click)="onNoClick()">-->

    <button class="primary-btn" (click)="sendFilter()">Apply Filters</button>
  </div>
</section>
