import { Component, DestroyRef, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountDeletedComponent } from '../account-deleted/account-deleted.component';
import { AuthDataService } from '../../../services/auth-data.service';
import { ProviderPortalService } from '../../provider-portal/services/provider-portal.service';
import { catchError, filter, finalize } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserFullInfo } from '../../../interfaces/auth-interfaces';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { pipe, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-delete-provider-account',
  templateUrl: './delete-provider-account.component.html',
  styleUrls: ['./delete-provider-account.component.scss']
})
export class DeleteProviderAccountComponent implements OnInit {
  public passwordForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6)]]
  });
  get password() {
    return this.passwordForm.get('password');
  }

  public showPassword = false;
  private user: UserFullInfo;

  constructor(
    public dialogRef: MatDialogRef<DeleteProviderAccountComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private authDS: AuthDataService,
    private providerService: ProviderPortalService,
    private dr: DestroyRef,
    private loader: NgxUiLoaderService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.authDS.user$
      .pipe(filter(user => !!user), takeUntilDestroyed(this.dr))
      .subscribe(user => this.user = user);
  }

  public changePasswordType() {
    this.showPassword = !this.showPassword;
  }

  public onDeleteAccount() {
    if (this.passwordForm.invalid) {
      this.passwordForm.markAllAsTouched();
      return;
    } else {
      this.loader.start();
      const data = {
        userId: this.user.user._id,
        userPassword: this.password.value
      };
      this.providerService.deleteAccount(this.user.userProvider._id, data)
        .pipe(
          catchError(err => {
            this.snackbar.open(err.error.message, '', {
              duration: 4500,
            });
            return throwError(err);
          }),
          finalize(() => this.loader.stop()),
        )
        .subscribe(() => {
          this.dialog.open(AccountDeletedComponent, {
            autoFocus: false,
            disableClose: true,
            height: '500px',
            width: '500px',
          });
          this.dialogRef.close();
        });
    }
  }
}
