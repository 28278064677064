import { ChangeDetectionStrategy, Component, computed, EventEmitter, Input, Output, signal, WritableSignal } from '@angular/core';
import { AllFeatures, ChosenFeatures, FeaturesKeys } from '../../../../interfaces/project';

@Component({
  selector: 'app-features-part-one',
  templateUrl: './features-part-one.component.html',
  styleUrls: ['./features-part-one.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturesPartOneComponent {
  @Input({ required: true }) set features(features: ChosenFeatures) {
    this.featuresSignal.set(features);
  }
  @Input() totalSteps = 7;
  @Input() currentStep = 4;
  @Input({ required: true }) allFeatures: AllFeatures = [];
  @Output('nextClick') nextClicked: EventEmitter<ChosenFeatures> = new EventEmitter();
  @Output('saveClick') saveClicked: EventEmitter<ChosenFeatures> = new EventEmitter();

  featuresSignal: WritableSignal<ChosenFeatures> = signal(null);
  featuresKeys = computed(() => Object.keys(this.featuresSignal() ?? {}) as FeaturesKeys[]);
  isValid = computed(() => Object.values(this.featuresSignal()).map(el => el.isChosen).some(el => el === true))

  public getNameSection(key: string) {
    return this.allFeatures.find(el => el.key === key).nameSection;
  }

  showError: boolean = false;

  tooltipText: Record<FeaturesKeys, string> = {
    accountManagement: 'Account Management related features are ones that are associated to anything regarding the a user\'s ability to manage their profile or account. Examples include settings, password resets, usernames, account verifications, languages and preferences.',
    apiIntegration: 'API related features are ones that are associated to connectivity between systems. Think of it as a bridge that allows different software programs to talk to each other and share information. For integrations related features, think of it as combining different tools or apps so they work together seamlessly.',
    communicationMessaging: 'Communication & Messaging related features are ones that help user/entities communicate between each other. Whether it\'s through text, email, or share alerts and information, these features make it easy to connect and transmit information. These features help you stay in touch and exchange information.',
    multimedia: 'Multimedia related features are ones that let you work with pictures, videos, and audio. Examples include editing photos, watch videos, and listening to audio. These features make an experience engaging.',
    onboarding: 'Onboarding related features are ones that resemble apps that walk you through setting up an account/profile when you start using a new app or website. They show you around, help you create an account, and explain how things work.',
    privacySecurity: 'Privacy & Security related features are like locks and shields for your digital world. They keep your personal information safe and make sure only the right people can access it. These features help you feel secure when using apps and websites.',
    social: 'Social related features are ones that help you connect with others, share things you like, and see what friends/people are up to. These features make using apps and websites more interactive.',
    subscriptionsBilling: 'Subscriptions & Billing related features are ones that allow you to choose different plans, like a free trial or a paid subscription, and handle payments/invoices/receipts for services. ',
    uiux: 'User Experience/User Interface Design are key activities/tasks that ensure that when people use an app or website, it\'s both pleasant to look at (User Interface) and easy to use (User Experience).',
    other: 'Select this checkbox if your project requires other features and functionality not mentioned above. You\'ll be able to provide additional details later on.'
  }

  handleCheckbox(e: Event, key: FeaturesKeys) {
    const target = e.target as HTMLInputElement;
    this.featuresSignal.update(
      prev => ({ ...prev, [key]: { ...this.featuresSignal()[key], isChosen: target.checked } }));
  }

  handleNextClick() {
    if (this.isValid()) {
      this.nextClicked.emit(this.featuresSignal());
    } else {
      this.showError = true;
    }
  }

  handleSaveClick() {
    if (this.isValid()) {
      this.saveClicked.emit(this.featuresSignal());
    } else {
      this.showError = true;
    }
  }
}
