import { DestroyRef, inject, Injectable, signal, TemplateRef } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { forkJoin, of, Subject } from 'rxjs';
import { UserFullInfo, UserTypesAccount } from '../../interfaces/auth-interfaces';
import { MessageComponent } from '../popups/message/message.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthDataService } from '../../services/auth-data.service';
import { first, tap } from 'rxjs/operators';
import { UserRoleEnum } from '../../enums/user-role.enum';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AccountSettingsService {
  private authDS = inject(AuthDataService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private snackBar = inject(MatSnackBar);
  private destroyRef = inject(DestroyRef);
  private dialog = inject(MatDialog);

  public stepper: MatStepper;
  private user: UserFullInfo;

  public $activeTab = signal<string>(null);
  public $hasAccessToVerification = signal<boolean>(false);

  public stepChange$ = new Subject();

  constructor() { }

  init(stepper: MatStepper) {
    this.stepper = stepper;
    this.authDS.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (user) => {
          if (!user) {
            return;
          }
          this.user = user;
        },
      });
  }

  stepChange(event: any) {
    this.stepChange$.next(null);
  }

  emailSaveChanges(email: FormControl<any>, inboxPopupMsg: TemplateRef<any>) {
    if (email.invalid) {
      return email.markAllAsTouched();
    }
    if (email.value === this.user.user.email) {
      return;
    }
    const editUser = {
      _id: this.user.user._id,
      email: email.value.toLowerCase(),
    };
    const editProvider = {
      _id: this.user.userProvider?._id,
      email: email.value.toLowerCase(),
    };
    if (this.user.user.typeAccount === UserTypesAccount.Multiple) {
      const saveEmailRef = this.dialog.open(MessageComponent, {
        autoFocus: false,
        width: '400px',
        disableClose: true,
        data: {
          title: 'Save New Email?',
          message: 'Changes will apply to your provider account as well. A verification link will be sent to this new email to finalize the change of email.',
          successMessage: 'Yes! Save Changes',
          cancelMessage: 'No, Abandon Changes',
        },
      });
      saveEmailRef.afterClosed().subscribe({
        next: (res) => {
          if (res === false) {
            return this.authDS.getUserProfile()
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe();
          }
          this.saveNewEmailOrPassword(editUser, editProvider).subscribe();
          this.sendEmail(email).subscribe();
          this.snackBar.open('Changes saved!', '', { duration: 1500 });
        },
      });
      return;
    }
    const msgRef = this.dialog.open(MessageComponent, {
      autoFocus: false,
      width: '400px',
      disableClose: true,
      data: {
        title: 'Check your inbox!',
        messageRef: inboxPopupMsg,
        successMessage: 'Got it!',
      },
    });
    this.saveNewEmailOrPassword(editUser).subscribe();
    this.sendEmail(email).subscribe();
    msgRef.afterClosed().subscribe({
      next: () => this.snackBar.open('Changes saved', '', { duration: 1500 }),
    });
  }

  verifyEmail(email: FormControl<any>, inboxPopupMsg: TemplateRef<any>) {
    this.sendEmail(email).pipe(first()).subscribe({
      next: () => {
        this.dialog.open(MessageComponent, {
          autoFocus: false,
          width: '400px',
          disableClose: true,
          data: {
            title: 'Check your inbox!',
            messageRef: inboxPopupMsg,
            successMessage: 'Got it!',
          },
        });
      },
      error: (err) => {
        this.snackBar.open(err.error.message, '', { duration: 1500 });
      },
    });
  }

  private sendEmail(email: FormControl<any>) {
    const data = {
      _id: this.user?.user?._id,
      email: email.value,
    };
    return this.authDS.verifyEmail(data);
  }

  private saveNewEmailOrPassword(editUser: any, editProvider: any = null) {
    return forkJoin([
      this.authDS.editAccount(editUser, 'user'),
      !editProvider ? of([]) : this.authDS.providerSaveChanges(editProvider),
    ]).pipe(tap(
        () => this.authDS.getUserProfile()
          .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(),
      ),
    );
  }

  saveChanges(data: object, role: string) {
    return this.authDS.editAccount(data, role)
      .pipe(
        first(),
        tap(() => this.authDS.getUserProfile()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe()
        )
      );
  }

  phoneNumberSaveChanges(phoneNumber: FormControl<any>) {
    if (phoneNumber.invalid) {
      return phoneNumber.markAllAsTouched();
    }
    const data = {
      _id: this.user?.user._id,
      phoneNumber: Number(phoneNumber.value),
    };
    this.saveNewEmailOrPassword(data).subscribe({
      next: () => this.snackBar.open('Changes saved', '', { duration: 1500 })
    });
  }

  verifyPhoneNumber(user: UserFullInfo) {
    this.authDS
      .sendVerifyPhoneNumber(user?.user.phoneNumber)
      .pipe(first())
      .subscribe({
        next: () => {
          this.$activeTab.set('Verification');
          this.$hasAccessToVerification.set(true);
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              activeTab: this.$activeTab()
            },
            queryParamsHandling: 'merge',
          });
          this.snackBar.open('We texted you the 4-digit code to your mobile.', '', {
            duration: 2500,
          });
        }
      });
  }
}
