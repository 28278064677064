import {NgModule} from '@angular/core';
import {CreateBriefComponent} from './create-brief.component';
import {CreateBriefRoutingModule} from './create-brief-routing.module';
import {SharedModule} from '../shared/shared.module';
import {MatStepperModule} from '@angular/material/stepper';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {NgCircleProgressModule} from 'ng-circle-progress';
import { BriefStepHeaderComponent } from './components/brief-step-header/brief-step-header.component';
import { BriefStepperComponent } from './components/brief-stepper/brief-stepper.component';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectFilterModule} from '@grothar/mat-select-filter';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FeaturesPartOneComponent } from './components/features-part-one/features-part-one.component';
import { MatDividerModule } from '@angular/material/divider';
import { FeaturesPartTwoComponent } from './components/features-part-two/features-part-two.component';

@NgModule({
  declarations: [CreateBriefComponent, BriefStepHeaderComponent, BriefStepperComponent, FeaturesPartOneComponent, FeaturesPartTwoComponent],
  exports: [
    BriefStepperComponent,
    BriefStepHeaderComponent,
    FeaturesPartOneComponent,
    FeaturesPartTwoComponent
  ],
  imports: [
    CreateBriefRoutingModule,
    SharedModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    FormsModule,
    MatDatepickerModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatNativeDateModule,
    NgCircleProgressModule,
    MatSelectFilterModule,
    MatIconModule,
    InfiniteScrollModule,
    MatDividerModule,
  ]
})
export class CreateBriefModule {
}
