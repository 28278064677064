import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { Article, ArticleStatus } from '../../../models/knowledge-hub/article';
import { IProvider } from '../../../../../interfaces/provider';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleComponent implements OnInit {
    @Input({ required: true }) article: Article;
    @Input({ required: true }) provider: IProvider;
    @Input() previewMode = false;

    protected safeHtml = '';

    protected readonly ArticleStatus = ArticleStatus;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
            this.article.body
        ) as string;
    }
}
