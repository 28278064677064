import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { BriefTypeSend } from 'src/app/enums/brief.enum';

@Component({
  selector: 'app-brief-stepper',
  templateUrl: './brief-stepper.component.html',
  styleUrls: ['./brief-stepper.component.scss']
})
export class BriefStepperComponent {
  @Input() briefTypeSend: BriefTypeSend = BriefTypeSend.All;
  @Input() percent: number = 0;
  @Input() answeredSteps: number[] = [];
  @Input() currentStep: number = 0;
  @Input() briefName: string;
  @Input() showFeatureSubsteps: boolean = false;
  @Input() featureSubstepsLabels: string[] = [];
  @Input() answeredFeatureSubstep: number[];
  @Input() companyName: string;
  @Input() steps: string[] = [
    'The Basics',
    'Preferences',
    'The Details',
    'Features',
    'Requirements',
    'Budget and Timelines',
    'Preview and Submit',
  ];
  @Input() deleteText = 'Delete Brief';
  @Output() moveToStep = new EventEmitter<number>();
  @Output() moveToSubstep = new EventEmitter<number>();
  @Output() delete = new EventEmitter<void>();

  public moveTo(step: number): void {
    if (this.answeredSteps.length >= step) {
      this.moveToStep.emit(step);
    }
  }

  public moveToSubStep(e: Event, step: number): void {
    e.stopPropagation();
    if (this.answeredFeatureSubstep.length >= step) {
      this.moveToSubstep.emit(step);
    }
  }

  public deleteBrief(): void {
    this.delete.emit();
  }
}
