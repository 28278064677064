import {
  AfterViewInit,
  Component,
  Input,
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthDataService } from "../../../services/auth-data.service";
// import Swiper from "swiper";
import { first } from "rxjs/operators";
import {IProvider} from '../../../interfaces/provider';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper-v-13';

declare var Swiper: any;

@Component({
  selector: "app-main-added",
  templateUrl: "./main-added.component.html",
  styleUrls: ["./main-added.component.scss"],
})
export class MainAddedComponent implements AfterViewInit {
  @Input() slides: any[];
  @Input() type: any;

  public config: SwiperConfigInterface = {
    navigation: {
      nextEl: ".swiper-button-next2",
      prevEl: ".swiper-button-prev2",
    },
    slidesPerView: 4,
    speed: 700,
    loop: true,
    updateOnWindowResize: true,
  };
  constructor(private router: Router, private authDS: AuthDataService) {}

  ngAfterViewInit(): void {
    const swiperContainer = document.querySelector(
      '.swiper-container.swiper-container2'
    );
    const swiper = new Swiper(swiperContainer, this.config);
    swiper.init();
    swiper.update();
  }

  search(item) {
    localStorage.setItem("search", item);
    const search = {
      filterOptions: {
        search: item,
      },
    };
    this.authDS
      .doSearch(search)
      .pipe(first())
      .subscribe((res: {data: IProvider[], totalCount: number}) => {
        this.authDS.setProviders(res);
      });
    this.router.navigate(["/search-results"], {
      queryParams: { search: item },
    });
  }
}
