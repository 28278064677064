import { Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthDataService } from '../../../../services/auth-data.service';
import { Observable } from 'rxjs';
import { ICountry } from '../../../../interfaces/project';
import { map, startWith } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserFullInfo } from '../../../../interfaces/auth-interfaces';
import { UserRoleEnum } from '../../../../enums/user-role.enum';
import { AccountSettingsService } from '../../account-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'individual-app-profile',
  templateUrl: './individual-profile.component.html',
  styleUrls: ['./individual-profile.component.scss'],
})
export class IndividualProfileComponent implements OnInit {
  @ViewChild('inboxPopupMsg') inboxPopupMsg: TemplateRef<any>;
  private accountSettingsService = inject(AccountSettingsService);
  private authDS = inject(AuthDataService);

  private destroyRef = inject(DestroyRef);
  private snackBar = inject(MatSnackBar);

  public user: UserFullInfo;

  public filteredCountries$: Observable<ICountry[]>;
  public countries: ICountry[] = [];

  profileInfoForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    country: new FormControl<ICountry>(null, Validators.required),
  });
  profileInfoControls = this.profileInfoForm.controls;

  occupationForm = new FormGroup({
    occupation: new FormControl<string>(''),
    education: new FormControl<string>(''),
    linkedin: new FormControl<string>('', [Validators.pattern('https?://([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
  });
  occupationControls = this.occupationForm.controls;

  email = new FormControl('', [Validators.required, Validators.email]);
  phoneNumber = new FormControl(null, Validators.required);

  ngOnInit() {
    this.authDS.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
      next: (user) => {
        if (!user) {
          return;
        }
        this.user = user;
        const { firstName, lastName, occupation, education, linkedin } = this.user.userRole;
        const { email, country, phoneNumber } = this.user.user;
        this.profileInfoForm.setValue({
          firstName,
          lastName,
          country,
        });
        this.occupationForm.setValue({
          occupation: occupation || '',
          education: education || '',
          linkedin: linkedin || '',
        });
        this.email.setValue(email);
        this.phoneNumber.setValue(phoneNumber);
      },
    });
    this.authDS.getCountries()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.countries = res;
          this.initFilteredOptions();
        },
      });
  }

  profileSaveChanges() {
    if (this.profileInfoForm.invalid) {
      return this.profileInfoForm.markAllAsTouched();
    }
    const { firstName, lastName, country } = this.profileInfoForm.value;
    const data = {
      _id: this.user?.userRole?._id,
      firstName,
      lastName,
      country: country._id,
    };
    this.accountSettingsService.saveChanges(data, UserRoleEnum[this.user?.user.role])
      .subscribe({
        next: () => this.snackBar.open('Changes saved!', 'OK', { duration: 1500 })
      });
  }

  occupationSaveChanges() {
    if (this.occupationForm.invalid) {
      return this.occupationForm.markAllAsTouched();
    }
    console.log(this.occupationForm.value);
    const { occupation, education, linkedin } = this.occupationForm.value;
    const data = {
      _id: this.user?.userRole?._id,
      occupation,
      education,
      linkedin,
    };
    this.accountSettingsService.saveChanges(data, UserRoleEnum[this.user?.user.role])
      .subscribe({
        next: () => this.snackBar.open('Changes saved!', 'OK', { duration: 1500 })
      });
  }

  emailSaveChanges() {
    this.accountSettingsService.emailSaveChanges(this.email, this.inboxPopupMsg);
  }

  verifyEmail() {
    this.accountSettingsService.verifyEmail(this.email, this.inboxPopupMsg);
  }

  phoneNumberSaveChanges() {
    this.accountSettingsService.phoneNumberSaveChanges(this.phoneNumber);
  }

  verifyPhoneNumber() {
    this.accountSettingsService.verifyPhoneNumber(this.user);
  }

  private initFilteredOptions() {
    this.filteredCountries$ = this.profileInfoControls.country.valueChanges.pipe(
      startWith((this.profileInfoControls.country.value as ICountry).name ?? ''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name
          ? this._filter(name as string, this.countries)
          : this.countries.slice();
      }),
    );
  }

  private _filter<T extends { name?: string }>(
    name: string,
    options: T[],
  ): T[] {
    const filterValue = name.toLowerCase();
    return options.filter((option) =>
      option.name.toLowerCase().includes(filterValue),
    );
  }

  displayName<T extends { name?: string }>(el: T) {
    return el.name;
  }
}
