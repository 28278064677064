import { Component, Directive, HostBinding, HostListener, inject, OnInit, Pipe, PipeTransform } from '@angular/core';
import { first, tap } from 'rxjs/operators';
import { AuthDataService, ClientOrProvider } from './services/auth-data.service';
import { MobileDetectedComponent } from './modules/popups/mobile-detected/mobile-detected.component';
import { NavigationStart, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserTypesAccount } from './interfaces/auth-interfaces';
import { ChooseAccountTypeComponent } from './modules/popups/choose-account-type/choose-account-type.component';
import { NavigationService } from './services/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private navigationService = inject(NavigationService);

  private token: string;
  private currentAccountType: ClientOrProvider;

  constructor(
    public snackbar: MatSnackBar,
    private authDS: AuthDataService,
    public dialog: MatDialog,
    public router: Router,
  ) {
    this.token = localStorage.getItem('token');
    this.currentAccountType = localStorage.getItem('currentAccountType') as ClientOrProvider;
  }

  ngOnInit() {
    this.navigationService.startSaveHistory();
    this.router.events.subscribe(value => {
      if (value instanceof NavigationStart) {
        if (
          ['/terms-of-use', '/privacy-policy'].every(
            i => i !== value.url.toString(),
          )
        ) {
          this.callMobileDetect();
        }
      }
    });

    if (this.token) {
      this.authDS
        .getUserProfile()
        .pipe(
          first(),
          tap(user => {
            if (
              user.user.typeAccount ===
              UserTypesAccount.Multiple &&
              window.location.pathname.length <= 1
            ) {
              this.dialog
                .open(ChooseAccountTypeComponent, {
                  autoFocus: false,
                  width: '800px',
                })
                .afterClosed()
                .subscribe(res => {
                  if (!res) {
                    this.authDS.forceLogout();
                  }
                });
            } else if (
              user.user.typeAccount ===
              UserTypesAccount.Multiple &&
              window.location.pathname.length > 1
            ) {
              if (this.currentAccountType) this.authDS.setActiveAccount(this.currentAccountType);
              else {
                this.authDS.setActiveAccount(
                  window.location.pathname.includes('provider')
                    ? UserTypesAccount.Provider
                    : UserTypesAccount.Client,
                );
              }
            }
            if (user.userProvider?.subscriptionStripeId) {
              this.authDS.getCustomerCurrentSubscription(user.userProvider.subscriptionStripeId).subscribe({
                next: (res) => {
                  if (res) return;
                  this.authDS.getUserProfile().subscribe();
                }
              });
            }
          }),
        )
        .subscribe();
    }
    const history = localStorage.getItem('url-history');
    if (history) {
      this.navigationService.setHistory(JSON.parse(history));
    }
  }

  public callMobileDetect(): void {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      const dialogRef = this.dialog.open(MobileDetectedComponent, {
        autoFocus: false,
        height: '600px',
        width: '500px',
      });
    }
  }

  public sendMessage(event) {
    if (event) {
      this.snackbar.open('Massage was sent !', '', {
        duration: 2500,
        horizontalPosition: 'end',
        verticalPosition: 'top',
        panelClass: 'contactUsPopup',
      });
    }
  }
}

@Pipe({
  name: 'cardNumber',
})
export class CardNumberPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      return value
        .replace(/\s+/g, '')
        .replace(/(\d{4})/g, '$1 ')
        .trim();
    }
  }
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[credit-card]',
})
export class CreditCardDirective {
  @HostBinding('style.border')
  border: string;

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed = input.value.replace(/\s+/g, '');
    if (trimmed.length > 16) {
      trimmed = trimmed.substr(0, 16);
    }

    let numbers = [];
    for (let i = 0; i < trimmed.length; i += 4) {
      numbers.push(trimmed.substr(i, 4));
    }

    input.value = numbers.join(' ');

    this.border = '';
    if (/[^\d]+/.test(trimmed)) {
      this.border = '1px solid red';
    }
  }
}
