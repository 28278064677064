import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertsComponent} from './alerts.component';
import {AlertsRoutingModule} from './alerts-routing.module';
import {SharedModule} from '../shared/shared.module';
import {MatStepperModule} from '@angular/material/stepper';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {MatTableModule} from '@angular/material/table';


@NgModule({
  declarations: [AlertsComponent],
  imports: [
    CommonModule,
    AlertsRoutingModule,
    SharedModule,
    MatStepperModule,
    MatTableModule,
    InfiniteScrollModule
  ]
})
export class AlertsModule {
}
