import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { AuthDataService } from '../services/auth-data.service';

export const authGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const authService = inject(AuthDataService);
  if (authService.getUser()) return true;
  const user = localStorage.getItem('userProfile');
  if (!user) return router.navigate(['/']);
  authService.setUser(JSON.parse(user));
  const token = localStorage.getItem('token');
  if (token) {
    authService.setUserToken(token);
  }
  return authService.getUser() ? true : router.navigate(['/']);
};
