<app-header></app-header>
<section class="briefs-page">
  <div class="container">
    <header>
      <h2 class="title">My Projects</h2>
      <hr />
      <button
        (click)="changeTab('Draft', 1)"
        [ngClass]="{ activeTab: this.activeTab === 'Draft' }"
        class="tab"
      >
        Draft
      </button>
      <button
        (click)="changeTab('Open', 0)"
        [ngClass]="{ activeTab: this.activeTab === 'Open' }"
        class="tab"
      >
        Open
      </button>
      <button
        (click)="changeTab('Closed', 2)"
        [ngClass]="{ activeTab: this.activeTab === 'Closed' }"
        class="tab"
      >
        Closed
      </button>
      <hr />
      <div class="creation-menu-container">
        <button
          class="primary-btn"
          (click)="isCreationMenuShown = !isCreationMenuShown"
        >
          Create
          <span *ngIf="!isCreationMenuShown" class="arrowDown"></span>
          <span *ngIf="isCreationMenuShown" class="arrowUp"></span>
        </button>
        <div class="creation-menu" *ngIf="isCreationMenuShown">
          <button (click)="navigateToCreateBrief()">Project Brief</button>
          <button (click)="navigateToCreateDocRequirements()">Requirements Document</button>
        </div>
      </div>
    </header>
    <mat-horizontal-stepper #stepper>
      <mat-step>
        <div class="step-content">
          <p class="paragraph2 info" *ngIf="briefsOpen?.length > 0">
            Here are the perfect briefs shared with providers. Those who show interest will reach out to you.
          </p>

          <div class="briefs-wrap" *ngIf="briefsOpen?.length > 0">
            <header>
              <p class="paragraph2 start">ID</p>
              <p class="paragraph2">PROJECT NAME</p>
              <p class="paragraph2">PROJECT TYPE</p>
              <p class="paragraph2">PROVIDERS INTERESTED</p>
<!--              <p class="paragraph2">ACCEPTING UNTIL</p>-->
              <p class="paragraph2">SUBMITTED ON</p>
              <p class="paragraph2">REQUIREMENTS</p>
              <p class="view"></p>
            </header>

            <div
              *ngFor="let brief of briefsOpen"
              class="brief-wrap-item"
              [routerLink]="['/my-briefs/preview']"
              [queryParams]="{ briefId: brief?._id }"
            >
              <p class="paragraph2 start">{{ brief?.briefId }}</p>
              <p class="paragraph2">{{ brief?.name }}</p>
              <p class="paragraph2">
                {{ brief?.types[0]?.title }} {{ brief?.types.length > 1 ? '+' + (brief?.types.length - 1).toString() : '' }}
              </p>
              <p class="paragraph2">{{ brief?.providersInterested.length }}</p>
<!--              <p class="paragraph2">{{ brief?.proposalValidUntil | date: "MMM dd, yyyy" }}</p>-->
              <p class="paragraph2">
                {{ brief?.lastEdit | date: "MMM dd, yyyy" }}
              </p>
              <div class="paragraph2 document_block">
                <p *ngIf="brief.isDocumentPurchased" (click)="onDownloadClick($event, brief?._id, brief?.name)">
                  <img src="../../../assets/icons/downloadIcon.svg" alt="download">View Document
                </p>
                <div *ngIf="!brief.isDocumentPurchased">
<!--                  <p  (click)="openDocumentPreview($event, brief?.name, brief?._id)">-->
<!--                    <img src="../../../assets/icons/eye.svg" alt="download">View-->
<!--                  </p>-->
                  <p  (click)="onPurchaseClick($event, brief?._id)">
                    <img src="../../../assets/icons/purchaseIcon.svg" alt="download">Buy Document
                  </p>
                </div>
              </div>
              <p class="view">
                View Project
              </p>
            </div>
          </div>

          <div class="not-found" *ngIf="briefsOpen?.length === 0">
            <img src="./../../../assets/open.png" alt="" />

            <p class="sub-title">
              You don’t have any projects on the go right now.
            </p>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div class="step-content">
          <p class="paragraph2 info" *ngIf="briefsDraft.length > 0">
            Here are the perfect briefs and requirement documents you've drafted.
          </p>

          <div class="briefs-wrap" *ngIf="briefsDraft.length > 0">
            <header>
              <p class="paragraph2 start">ID</p>
              <p class="paragraph2">PROJECT NAME</p>
              <p class="paragraph2">PROJECT TYPE</p>
              <p class="paragraph2">% COMPLETED</p>
              <p class="paragraph2">LAST SAVED ON</p>
              <p class="view"></p>
            </header>

            <div
              *ngFor="let brief of briefsDraft"
              class="brief-wrap-item"
              (click)="brief?.briefId
                ? navigateToCreateBrief(brief?._id, brief?.provider ? 'provider' : 'all', brief?.completionPercentage)
                : navigateToCreateDocRequirements(brief?._id)"
            >
              <p class="paragraph2 start">{{ brief?.briefId ?? brief.docId }}</p>
              <p class="paragraph2">{{ brief?.name }}</p>
              <p class="paragraph2">
                {{ brief?.types[0]?.title }} {{ brief?.types.length > 1 ? '+' + (brief?.types.length - 1).toString() : '' }}
              </p>
              <p class="paragraph2">{{ brief?.completionPercentage }}%</p>
              <p class="paragraph2">
                {{ brief?.lastEdit | date: "MMM dd, yyyy" }}
              </p>
              <p class="view">
                {{ brief?.briefId ? 'Continue Brief' : 'Continue Doc' }}
                <span class="delete-action"
                      (click)="brief?.briefId
                              ? deleteBrief($event, brief?._id, brief?.name)
                              : deleteDoc($event, brief?._id)">
                  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Icons/16px/Trash"><g id="trash-simple">
                      <path id="Combined Shape" fill-rule="evenodd" clip-rule="evenodd" d="M30.8079 6.88932H23.8079V3.38932C23.8079 2.74499 23.2856 2.22266 22.6413 2.22266H13.3079C12.6636 2.22266 12.1413 2.74499 12.1413 3.38932V6.88932H5.14128C4.49694 6.88932 3.97461 7.41166 3.97461 8.05599C3.97461 8.70032 4.49694 9.22266 5.14128 9.22266H30.8079C31.4523 9.22266 31.9746 8.70032 31.9746 8.05599C31.9746 7.41166 31.4523 6.88932 30.8079 6.88932ZM14.4746 4.55599H21.4746V6.88932H14.4746V4.55599ZM7.47461 11.556V26.7227C7.47461 28.6557 9.04161 30.2227 10.9746 30.2227H24.9746C26.9076 30.2227 28.4746 28.6557 28.4746 26.7227V11.556H7.47461ZM14.4746 16.2227V24.3893H12.1413V16.2227H14.4746ZM16.8079 24.3893H19.1413V16.2227H16.8079V24.3893ZM23.8079 24.3893H21.4746V16.2227H23.8079V24.3893Z" />
                    </g></g>
                  </svg>
                </span>
              </p>
            </div>
          </div>

          <div class="not-found" *ngIf="briefsDraft.length === 0">
            <img src="./../../../assets/draft.png" alt="" />

            <p class="sub-title">
              You don’t have any projects in draft right now.
            </p>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div class="step-content">
          <p class="paragraph2 info" *ngIf="briefsClosed.length > 0">
            Here are perfect briefs that were closed or cancelled and requirement documents purchased.
          </p>

          <div class="briefs-wrap" *ngIf="briefsClosed.length > 0">
            <header>
              <p class="paragraph2 start">ID</p>
              <p class="paragraph2">PROJECT NAME</p>
              <p class="paragraph2">PROJECT TYPE</p>
              <p class="paragraph2">PROVIDERS INTERESTED</p>
              <p class="paragraph2">SUBMITTED ON</p>
              <p class="paragraph2">CLOSED ON</p>
              <p class="paragraph2">REQUIREMENTS</p>
              <p class="view"></p>
            </header>

            <ng-container *ngFor="let brief of briefsClosed">
              <div
                *ngIf="brief.briefId"
                class="brief-wrap-item"
                [routerLink]="['/my-briefs/preview']"
                [queryParams]="{ briefId: brief?._id }"
              >
                <p class="paragraph2 start">{{ brief?.briefId }}</p>
                <p class="paragraph2">{{ brief?.name }}</p>
                <p class="paragraph2">
                  {{ brief?.types[0]?.title }} {{ brief?.types.length > 1 ? '+' + (brief?.types.length - 1).toString() : '' }}
                </p>
                <p class="paragraph2">{{ brief?.providersInterested?.length }}</p>
                <p class="paragraph2">
                  {{ brief?.briefCreatedDate | date: "MMM dd, yyyy" }}
                </p>
                <p class="paragraph2">
                  {{ brief?.lastEdit | date: "MMM dd, yyyy" }}
                </p>
                <div class="paragraph2 document_block" style="width: 15rem">
                  <p *ngIf="brief.isDocumentPurchased" (click)="onDownloadClick($event, brief?._id, brief?.name)">
                    <img src="../../../assets/icons/downloadIcon.svg" alt="download">View Document
                  </p>
                </div>
                <p class="view">View Project</p>
              </div>
              <div
                *ngIf="brief.docId"
                class="brief-wrap-item"
              >
                <p class="paragraph2 start">{{ brief?.docId }}</p>
                <p class="paragraph2">{{ brief?.name }}</p>
                <p class="paragraph2">
                  {{ brief?.types[0]?.title }} {{ brief?.types.length > 1 ? '+' + (brief?.types.length - 1).toString() : '' }}
                </p>
                <p class="paragraph2"></p>
                <p class="paragraph2">
                  {{ brief?.docCreatedDate | date: "MMM dd, yyyy" }}
                </p>
                <p class="paragraph2"></p>
                <div class="paragraph2 document_block" style="width: 15rem">
                  <p *ngIf="brief.isDocumentPurchased" (click)="onDownloadDocClick($event, brief?._id)">
                    <img src="../../../assets/icons/downloadIcon.svg" alt="download">View Document
                  </p>
                </div>
                <p class="view"></p>
              </div>
            </ng-container>

          </div>

          <div class="not-found" *ngIf="briefsClosed.length === 0">
            <img src="./../../../assets/done.png" alt="" />

            <p class="sub-title">You’ve yet to create a project brief.</p>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</section>
