import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-article-save',
  templateUrl: './article-save.component.html',
  styleUrls: ['./article-save.component.scss']
})
export class ArticleSaveComponent {
  constructor(
      public dialogRef: MatDialogRef<ArticleSaveComponent>,
      @Inject(MAT_DIALOG_DATA) public data
  ) {}
}
