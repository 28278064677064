<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />
  <p class="title">Reset Password</p>

  <p class="paragraph2">
    Enter the email associated to your <br />
    account and we’ll send you instructions <br />
    for resetting your password.
  </p>
  <form class="login-content-form" [formGroup]="loginForm">
    <label class="paragraph2" for="email"><b>Email</b> *</label>
    <input
      [ngClass]="{
        'error-input':
          loginForm.controls['email'].invalid &&
          loginForm.controls['email'].touched
      }"
      class="data-input"
      id="email"
      type="text"
      placeholder="Enter email"
      required
      formControlName="email"
    />
    <div
      class="error-text"
      *ngIf="submitted && loginForm.controls['email'].errors"
    >
      Enter a valid email address to reset the password.
    </div>
    <span></span>
  </form>

  <button
    class="primary-btn"
    type="submit"
    mat-raised-button
    (click)="openLoginPopup()"
  >
    Reset Password
  </button>
</div>
