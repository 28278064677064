import { inject, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private router = inject(Router);

  private history: string[] = [];

  public startSaveHistory() {
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.history.push(event.url);
          localStorage.setItem('url-history', JSON.stringify(this.history));
        }
      },
    });
  }

  public getPreviousUrl() {
    if (this.history.length < 0) return null;
    const prevUrl = this.history[this.history.length - 2];
    const curUrl = this.history[this.history.length - 1];
    if (!prevUrl || !curUrl) return undefined;
    if (this.getBaseRoute(prevUrl) === this.getBaseRoute(curUrl)) {
      this.history = this.history.slice(0, this.history.length - 2);
    }
    return prevUrl;
  }

  private getBaseRoute(url: string) {
    const urlParts = url.split('/');
    return urlParts.slice(0, -1).join('/');
  }

  public deleteLast() {
    this.history = this.history.slice(0, this.history.length - 1);
  }

  public setHistory(history: string[]) {
    this.history = history;
  }
}
