import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: "app-project-save",
  templateUrl: "./project-save.component.html",
  styleUrls: ["./project-save.component.scss"],
})
export class ProjectSaveComponent {
  constructor(
    public dialogRef: MatDialogRef<ProjectSaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  public dismiss(): void {
    this.dialogRef.close();
  }

  public goBriefs() {
    this.dialogRef.close({ goBriefs: true });
  }
}
