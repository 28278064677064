<div class="container"
     *ngIf="isFirstPart">
  <dotlottie-player
    #firstPart
    autoplay
    loop
    mode="normal"
    src="/assets/animatedLoader/Loading_Circle.lottie"
    style="width: 500px"
  >
  </dotlottie-player>
</div>
<div class="container"
     *ngIf="isSecondPart">
  <dotlottie-player
    autoplay
    loop
    mode="normal"
    src="/assets/animatedLoader/Unicorn.lottie"
    style="width: 500px"
  >
  </dotlottie-player>
</div>
