import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MyBriefsComponent} from './my-briefs.component';
import {MyBriefsRoutingModule} from './my-briefs-routing.module';
import {SharedModule} from '../shared/shared.module';
import {MatStepperModule} from '@angular/material/stepper';
import {PreviewSubmitComponent} from './preview-submit/preview-submit.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxStarRatingModule} from 'ngx-star-rating';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
  declarations: [MyBriefsComponent, PreviewSubmitComponent],
  imports: [
    CommonModule,
    MyBriefsRoutingModule,
    SharedModule,
    MatStepperModule,
    MatTableModule,
    ReactiveFormsModule,
    NgxStarRatingModule,
    MatButtonModule
  ]
})
export class MyBriefsModule {
}
