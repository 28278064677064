import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ILogin } from "../../../interfaces/auth-interfaces";
import { AuthDataService } from "../../../services/auth-data.service";
import { MessageSentComponent } from "../message-sent/message-sent.component";
import { first } from "rxjs/operators";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: "app-get-listed",
  templateUrl: "./get-listed.component.html",
  styleUrls: ["./get-listed.component.scss"],
})
export class GetListedComponent {
  public getListedForm: UntypedFormGroup = this.fb.group({
    email: ["", [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    name: ["", [Validators.required, Validators.minLength(5)]],
    serviceProviderName: ["", [Validators.required]],
    message: ["", [Validators.required, Validators.minLength(5)]],
    companyWebSite: [
      "",
      [
        Validators.required,
        Validators.pattern(
          '^(?:http(s)?:\\/\\/)[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$'
        ),
      ],
    ],
    myRole: ["", [Validators.required]],
    contactPhoneNumber: ["", [Validators.required, Validators.minLength(6)]],
  });
  public submitted = false;

  constructor(
    public dialogRef: MatDialogRef<GetListedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ILogin,
    private authDS: AuthDataService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public snackbar: MatSnackBar
  ) {}

  @Output() sendLoginEmitter: EventEmitter<ILogin> = new EventEmitter<ILogin>();

  onNoClick(): void {
    this.dialogRef.close();
  }

  public send() {
    this.submitted = true;
    if (this.getListedForm.valid) {
      this.getListedForm
        .get("contactPhoneNumber")
        .setValue(String(this.getListedForm.value.contactPhoneNumber));
      this.authDS
        .sendGetListed(this.getListedForm.value)
        .pipe(first())
        .subscribe((res: any) => {
          this.dialog.open(MessageSentComponent, {
            autoFocus: false,
            height: "600px",
            width: "500px",
            data: {
              title: "Message Sent!",
              message: "Your inquiry for getting listed has been sent. A representative from our team will be in touch!",
              button: "Great!",
            }
          });
        });
      this.onNoClick();
    }
  }
}
