<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="dialogRef.close()"
  />

  <p class="title">Delete Account</p>

  <p class="paragraph2">If you decide to delete your account, all aspects about your account will be permanently deleted such as your profile, account settings, brides and history. If you want to re-join, you will have to sign up again.</p>

  <form class="login-content-form" [formGroup]="passwordForm">
    <div class="password-inputs">
      <label class="paragraph2" for="password"><b>Type in your login password to confirm account deletion</b></label>
      <input
        [class.error-input]="password.invalid && password.touched"
        class="data-input"
        id="password"
        [type]="showPassword ? 'text' : 'password'"
        formControlName="password"
      />
      <button class="mask" (click)="changePasswordType()">
        <img
          *ngIf="!showPassword"
          src="../../../../assets/eye.svg"
          alt=""
        />
        <img
          *ngIf="showPassword"
          src="../../../../assets/Eye.slash.svg"
          alt=""
        />
      </button>
    </div>
  </form>

  <button
    class="primary-btn"
    [class.disabled]="passwordForm.invalid"
    type="submit"
    (click)="onDeleteAccount()"
  >
    Delete My Account
  </button>
</div>
