import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthDataService } from '../../../../services/auth-data.service';
import { UserFullInfo } from '../../../../interfaces/auth-interfaces';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IPreferences } from '../../../../interfaces/preferences.interface';

@Component({
  selector: 'individual-app-notification-preferences',
  templateUrl: './individual-notification-preferences.component.html',
  styleUrls: ['./individual-notification-preferences.component.scss']
})
export class IndividualNotificationPreferencesComponent implements OnInit {
  private authDS = inject(AuthDataService);
  private snackBar = inject(MatSnackBar);

  private user: UserFullInfo;

  preferencesForm = new FormGroup({
    viewsProposalEmail: new FormControl<boolean>(false),
    showsInterestEmail: new FormControl<boolean>(false),
  });

  ngOnInit() {
    this.authDS.user$.subscribe({
      next: (user) => {
        if (!user) {
          return;
        }
        this.user = user;
        const { viewsProposalEmail, showsInterestEmail} = this.user?.user.preferences;
        this.preferencesForm.setValue({
          viewsProposalEmail,
          showsInterestEmail,
        });
      },
    });
  }

  preferencesSaveChanges() {
    if (this.preferencesForm.invalid) {
      return this.preferencesForm.markAllAsTouched();
    }
    const {showsInterestNotification, viewsProposalNotification} = this.user.user.preferences;
    const data = {
      ...this.preferencesForm.value,
      showsInterestNotification,
      viewsProposalNotification
    } as IPreferences;
    this.authDS.editPreferences(data)
      .pipe(first())
      .subscribe({
        next: () => {
          this.snackBar.open('The Preferences were updated', '', {
            duration: 1500,
          });
          this.authDS.getUserProfile().pipe(first()).subscribe();
        },
        error: (err) => {
          this.snackBar.open(err.error.message, '', {
            duration: 1500,
          });
        }
      });
  }
}
