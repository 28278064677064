import { Component, DestroyRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthDataService } from '../../../services/auth-data.service';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserTypesAccount } from '../../../interfaces/auth-interfaces';

@Component({
  selector: 'app-account-deleted',
  templateUrl: './account-deleted.component.html',
  styleUrls: ['./account-deleted.component.scss']
})
export class AccountDeletedComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AccountDeletedComponent>,
    private ngxService: NgxUiLoaderService,
    private authDS: AuthDataService,
    public router: Router,
    private destroyRef: DestroyRef
  ) {}

  private user;

  ngOnInit(): void {
    this.authDS.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(user => {
        this.user = user;
    });
  }

  public dismiss() {
    this.dialogRef.close();
    if (this.user.user.typeAccount === UserTypesAccount.Multiple) {
      const accountType = this.authDS.switchAccount();
      this.authDS.setUser({ ...this.user, user: { ...this.user.user, typeAccount: accountType }});
      const redirectTo =
        accountType === UserTypesAccount.Client ? '/' : '/provider/manage-projects';
      this.router.navigate([redirectTo]);
    } else {
      this.authDS.forceLogout();
    }
  }
}
