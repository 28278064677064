<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Get Listed</p>

  <form class="login-content-form" [formGroup]="getListedForm">
    <div class="left">
      <label class="paragraph2" for="email"><b>Name</b> *</label>
      <input
        [ngClass]="{
          'error-input': getListedForm.controls['name'].invalid && submitted
        }"
        class="data-input"
        id="email"
        type="text"
        placeholder="What's your full name?"
        required
        formControlName="name"
      />
      <div
        class="error-text"
        *ngIf="submitted && getListedForm.controls['name'].invalid"
      >
        <p *ngIf="getListedForm.controls['name'].errors.required">
          This field is required.
        </p>
        <p *ngIf="getListedForm.controls['name'].errors.minlength">
          This field needs to be at least 5 characters long.
        </p>
      </div>

      <label class="paragraph2" for="password"><b>My Role</b> *</label>
      <input
        [ngClass]="{
          'error-input': getListedForm.controls['myRole'].invalid && submitted
        }"
        class="data-input"
        id="password"
        type="text"
        placeholder="What's your position at this company?"
        required
        formControlName="myRole"
      />
      <div
        class="error-text"
        *ngIf="submitted && getListedForm.controls['myRole'].invalid"
      >
        <p *ngIf="getListedForm.controls['myRole'].errors.required">
          This field is required.
        </p>
      </div>

      <label class="paragraph2" for="email"><b>Email</b> *</label>
      <input
        [ngClass]="{
          'error-input': getListedForm.controls['email'].invalid && submitted
        }"
        class="data-input"
        id="email"
        type="email"
        placeholder="What's your email address?"
        required
        formControlName="email"
      />
      <div
        class="error-text"
        *ngIf="submitted && getListedForm.controls['name'].invalid"
      >
        <p *ngIf="getListedForm.controls['email'].errors?.required">
          This field is required.
        </p>
        <p *ngIf="getListedForm.controls['email'].errors?.email || getListedForm.controls['email'].errors?.pattern">
          Please enter the correct email address is required.
        </p>
      </div>

      <label class="paragraph2" for="password"
        ><b>Contact Phone Number</b> *</label
      >
      <input
        [ngClass]="{
          'error-input':
            getListedForm.controls['contactPhoneNumber'].invalid && submitted
        }"
        class="data-input"
        id="password"
        type="number"
        placeholder="What's your work phone number?"
        required
        formControlName="contactPhoneNumber"
      />
      <div
        class="error-text"
        *ngIf="
          submitted && getListedForm.controls['contactPhoneNumber'].invalid
        "
      >
        <p *ngIf="getListedForm.controls['contactPhoneNumber'].errors.required">
          This field is required.
        </p>
      </div>
    </div>
    <div class="right">
      <label class="paragraph2" for="email"><b>Company Website</b> *</label>
      <input
        [ngClass]="{
          'error-input':
            getListedForm.controls['companyWebSite'].invalid && submitted
        }"
        class="data-input"
        id="email"
        type="text"
        placeholder="What's the company's website?"
        required
        formControlName="companyWebSite"
      />
      <div
        class="error-text"
        *ngIf="submitted && getListedForm.controls['companyWebSite'].errors"
      >
        <p *ngIf="getListedForm.controls['companyWebSite'].errors.required">
          This field is required.
        </p>
        <p *ngIf="getListedForm.controls['companyWebSite'].errors.pattern">
          Enter a valid website by entering https://www or http://www before the domain name.
        </p>
      </div>

      <label class="paragraph2" for="companyName"><b>Company Name</b> *</label>
      <input
        [ngClass]="{
          'error-input':
            getListedForm.controls['serviceProviderName'].invalid && submitted
        }"
        class="data-input"
        id="companyName"
        type="text"
        placeholder="What's the company name?"
        required
        formControlName="serviceProviderName"
      />
      <div
        class="error-text"
        *ngIf="submitted && getListedForm.controls['serviceProviderName'].errors"
      >
        <p *ngIf="getListedForm.controls['serviceProviderName'].errors.required">
          This field is required.
        </p>
      </div>

      <label class="paragraph2" for="password"><b>Message</b> *</label>
      <textarea
        [ngClass]="{
          'error-input': getListedForm.controls['message'].invalid && submitted
        }"
        class="data-input last"
        id="password"
        type="text"
        placeholder="Why do you want to get listed?..."
        required
        formControlName="message"
      ></textarea>
      <div
        class="error-text error-text-area"
        *ngIf="submitted && getListedForm.controls['message'].errors"
      >
        <p *ngIf="getListedForm.controls['message'].errors.required">
          This field is required.
        </p>
        <p *ngIf="getListedForm.controls['message'].errors.minlength">
          This field needs to be at least 5 characters long.
        </p>
      </div>
    </div>
  </form>

  <button class="primary-btn" type="submit" (click)="send()">Submit</button>
</div>
