import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchResultsComponent} from './search-results.component';
import {SearchResultsRoutingModule} from './search-results-routing.module';
import {SharedModule} from '../shared/shared.module';
import {NgxStarRatingModule} from 'ngx-star-rating';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
  declarations: [SearchResultsComponent],
  imports: [
    CommonModule,
    SearchResultsRoutingModule,
    SharedModule,
    NgxStarRatingModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
    MatFormFieldModule,
    MatSelectModule,
    InfiniteScrollModule
  ]
})
export class SearchResultsModule {
}
