<div class="login-content">
  <img class="close-icon"
       src="./../../../../assets/icons/x.svg"
       alt=""
       mat-dialog-close
  />

  <div class="first-step">
    <div class="login-content-logo">
      <img src="../../../../assets/logo/Logo.svg" alt="" />
    </div>
    <p class="title">Are you sure?</p>

    <p class="paragraph2">
      If you close project before the proposal deadline, it will get closed and you’ll need to create a new brief.
    </p>

    <button class="primary-btn" type="submit" [mat-dialog-close]="false">
      Keep Project
    </button>
    <p class="view-list-btn" [mat-dialog-close]="true">Yes, Close Project</p>
  </div>

</div>
