import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'htmlTextExtractor',
})
export class HtmlTextExtractorPipe implements PipeTransform {
    transform(value: string): string {
        const regex = /<[^>]*>([^<]+)<[^>]*>/g;
        const matches = [];
        let match: string[] = [];

        while (match !== null) {
            matches.push(match[1]);
            match = regex.exec(value);
        }

        return matches.join(' ');
    }
}
