import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-link-deactivated',
  templateUrl: './link-deactivated.component.html',
  styleUrls: ['./link-deactivated.component.scss']
})
export class LinkDeactivatedComponent {
  constructor(
    public dialogRef: MatDialogRef<LinkDeactivatedComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
