import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsComponent } from './modules/terms/terms.component';
import { PrivacyComponent } from './modules/privacy/privacy.component';
import { AuthGuardService } from './services/auth-guard.service';
import { CheckoutComponent } from './modules/checkout/checkout.component';
import { requireAccountType } from './guards/accountTypeGuard';
import { UserTypesAccount } from './interfaces/auth-interfaces';
import { authGuard } from './guards/authGuard';
import { NotFoundComponent } from './modules/not-found/not-found.component';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'search-service-providers',
    loadChildren: () => import('./modules/search-results/search-results.module').then(m => m.SearchResultsModule),
  },
  {
    path: 'create-brief',
    canActivate: [AuthGuardService, requireAccountType(UserTypesAccount.Client)],
    loadChildren: () => import('./modules/create-brief/create-brief.module').then(m => m.CreateBriefModule),
  },
  {
    path: 'my-briefs',
    canActivate: [requireAccountType(UserTypesAccount.Client)],
    loadChildren: () => import('./modules/my-briefs/my-briefs.module').then(m => m.MyBriefsModule),
  },
  {
    path: 'alerts',
    canActivate: [requireAccountType(UserTypesAccount.Client)],
    loadChildren: () => import('./modules/alerts/alerts.module').then(m => m.AlertsModule),
  },
  {
    path: 'public-articles',
    loadChildren: () => import('./modules/articles/public-articles.module').then(m => m.PublicArticlesModule),
  },
  {
    path: 'confirmEmail',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  }, {
    path: 'resetPassword',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'account-settings',
    canActivate: [requireAccountType(UserTypesAccount.Client)],
    loadChildren: () => import('./modules/account-settings/account-settings.module').then(m => m.AccountSettingsModule),
  },
  {
    path: 'provider-profile',
    loadChildren: () => import('./modules/provider-profile/provider-profile.module').then(m => m.ProviderProfileModule),
  },
  {
    path: 'create-doc-requirements',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/create-doc-requirements/create-doc-requirements.module')
      .then(m => m.CreateDocRequirementsModule),
  },
  {
    path: 'provider',
    canActivate: [authGuard, requireAccountType(UserTypesAccount.Provider)],
    loadChildren: () => import('./modules/provider-portal/provider-portal.module')
      .then(m => m.ProviderPortalModule),
  },
  {
    path: 'knowledge-hub',
    loadChildren: () => import('./modules/knowledge-hub/knowledge-hub.module').then(m => m.KnowledgeHubModule),
  },
  { path: 'terms-of-use', component: TermsComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'privacy-policy', component: PrivacyComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
