import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-doc-dialog',
  templateUrl: './canceling-or-deleting-dialog.component.html',
  styleUrls: ['./canceling-or-deleting-dialog.component.scss']
})
export class CancelingOrDeletingDialog {
  constructor(private dialogRef: MatDialogRef<CancelingOrDeletingDialog>, @Inject(MAT_DIALOG_DATA) public data) { }

  public dismiss(): void {
    this.dialogRef.close(false);
  }

  public deleteIt(): void {
    this.dialogRef.close(true);
  }
}
