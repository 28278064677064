import { Component, inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthDataService } from '../../../services/auth-data.service';
import { UserFullInfo } from '../../../interfaces/auth-interfaces';
import { first } from 'rxjs/operators';
import { AccountDeletedComponent } from '../../popups/account-deleted/account-deleted.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {
  private authDS = inject(AuthDataService);
  private dialog = inject(MatDialog);
  private snackBar = inject(MatSnackBar);

  user: UserFullInfo;
  fieldType: 'text' | 'password' = 'password';

  password = new FormControl<string>(null, Validators.required);

  ngOnInit() {
    this.authDS.user$.subscribe({
      next: (user) => {
        if (!user) {
          return;
        }
        this.user = user;
      },
    });
  }

  public changePasswordType() {
    if (this.fieldType === 'password') {
      this.fieldType = 'text';
    } else {
      this.fieldType = 'password';
    }
  }

  deleteAccount() {
    this.authDS.deleteAccount({ userId: this.user.user._id, userPassword: this.password.value })
      .pipe(first())
      .subscribe({
        next: () => this.dialog.open(AccountDeletedComponent, {
            autoFocus: false,
            disableClose: true,
            height: '500px',
            width: '500px',
          }),
        error: (error) => this.snackBar.open(error.error.message, '', { duration: 2500 })
      });
  }
}
