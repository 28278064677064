import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProviderProfileComponent} from './provider-profile.component';
import { providerResolver } from './provider.resolver';

const routes: Routes = [
  {
    path: '',
    resolve: {
      provider: providerResolver
    },
    component: ProviderProfileComponent
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderProfileRoutingModule {
}
