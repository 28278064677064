export enum IndustryEnum {
  Development,
  Advertisement,
  Design,
  Marketing,
  Social,
  IoT,
  Research,
  Printing
}
