import {User, UserFullInfo, UserRole} from '../interfaces/auth-interfaces';
import {IProvider} from '../interfaces/provider';
import {IAlert} from '../interfaces/alert-interface';
import {ICountry, IIndustry, IProject} from '../interfaces/project';
import {ITestimonial} from '../interfaces/testimonial.interface';

export class Mapper {

    public static mapProviderToIProvider(provider: any): IProvider {
        return {
            companyName: provider.companyName,
            companyWebSite: provider.companyWebSite,
            city: provider.city,
            country: provider.country,
            clutchScore: provider.clutchScore,
            about: provider.about,
            phoneNumber: provider.phoneNumber,
            industry: provider.industry,
            companySize: provider.companySize,
            hourlyRate: provider.hourlyRate,
            minProjectAmount: provider.minProjectAmount,
            specializationAreas: provider.specializationAreas,
            industrySpecialization: provider.industrySpecialization,
            headquarterLocation: provider.headquarterLocation,
            otherLocations: provider.otherLocations,
            headquarterLocationModel: provider.headquarterLocationModel,
            logo: provider.logo,
            background: provider.background,
            _id: provider._id
        } as IProvider;
    }

    public static mapProvidersToIProviders(providers: any): {data: IProvider[], totalCount: number} {
      if (providers.data?.length) {
        return {data: providers.data.map((provider: any) => this.mapProviderToIProvider(provider)), totalCount: providers.totalCount};
      }
    }

    public static mapAlertToIAlert(alert: any): IAlert {
        return {
            _id: alert._id,
            name: alert.name,
            logo: alert.logo,
            status: alert.status,
            projectName: alert.projectName,
            user: alert.user,
            category: alert.category,
            event: alert.event,
            sg_message_id: alert.sg_message_id,
            timestamp: alert.timestamp,
            providerName: alert.providerName
        } as IAlert;
    }

    public static mapAlertsToIAlerts(alerts: any): IAlert[] {
      return alerts.data.map((alert: any) => this.mapAlertToIAlert(alert));
    }

    public static mapBriefToIBrief(brief: any): IProject {
        return {
            _id: brief._id,
            briefId: brief.briefId,
            name: brief.name,
            types: brief.types,
            country: brief.country,
            specialization: brief.specialization,
            skillAreas: brief.skillAreas,
            acceptCompensation: brief.acceptCompensation,
            projectDetails: brief.projectDetails,
            prep: brief.prep,
            otherThings: brief.otherThings,
            elseSomeAssist: brief.elseSomeAssist,
            proposalValidUntil: brief.proposalValidUntil,
            budget: brief.budget,
            specialMessage: brief.specialMessage,
            examples: brief.examples,
            status: brief.status,
            userId: brief.userId,
            lastEdit: brief.lastEdit,
            completionPercentage: brief.completionPercentage,
            provider: brief.provider,
            projectBriefDistributedTo: brief.projectBriefDistributedTo,
            providersWhoViewedYourProposal: brief.providersWhoViewedYourProposal,
            providersInterested: brief.providersInterested,
            done: brief.done,
            field: brief.field,
            briefCreatedDate: brief.briefCreatedDate,
        } as IProject;
    }

    public static mapBriefsToIBriefs(alerts: any[]): IProject[] {
        return alerts.map((briefs: any) => this.mapBriefToIBrief(briefs));
    }

    public static mapCountryToICountry(country: ICountry): ICountry {
      if (country) {
        return {
          _id: country._id,
          name: country.name,
          phoneCode: country.phoneCode,
          iso2: country.iso2
        } as ICountry;
      }
    }

  public static mapIndustryToIndustry(industry: any): IIndustry {
    if (industry) {
      return {
        _id: industry._id,
        name: industry.title,
        description: industry.description,
      } as IIndustry;
    }
  }

  public static mapIndustriesToIIndustries(industries: any[]): IIndustry[] {
    return industries.map((country: any) => this.mapIndustryToIndustry(country));
  }

    public static mapCountriesToICountries(countries: any[]): ICountry[] {
        return countries.map((country: any) => this.mapCountryToICountry(country));
    }

    public static mapTestimonialToITestimonial(testimonial: any): ITestimonial {
        return {
            id: testimonial._id,
            title: testimonial.title,
            description: testimonial.description,
            active: testimonial.active,
            imageUrl: testimonial.imageUrl,
        } as ITestimonial;
    }

    public static mapTestimonialsToITestimonials(testimonials: any[]): ITestimonial[] {
        return testimonials.map((testimonial: any) => this.mapTestimonialToITestimonial(testimonial));
    }
}
