import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CreateBriefComponent} from './create-brief.component';

const routes: Routes = [
  {path: '', component: CreateBriefComponent}
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreateBriefRoutingModule {
}
