<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Contact Us</p>

  <form class="login-content-form" [formGroup]="contactUsForm">
    <div class="left">
      <div class="input-container">
        <label class="paragraph2" for="email"><b>Name</b> *</label>
        <input
          [ngClass]="{
            'error-input': contactUsForm?.controls['name']?.invalid && submitted
          }"
          class="data-input"
          id="email"
          type="text"
          placeholder="What's your full name?"
          required
          formControlName="name"
        />
        <div
          class="error-text error-text-area"
          *ngIf="submitted && contactUsForm?.controls['name']?.invalid"
        >
          <p *ngIf="contactUsForm?.controls['name']?.errors?.required">
            This field is required.
          </p>
          <p *ngIf="contactUsForm?.controls['name']?.errors?.minlength">
            This field needs to be at least 5 characters long.
          </p>
        </div>
      </div>

      <div class="input-container">
        <label class="paragraph2" for="email"><b>Email</b> *</label>
        <input
          [ngClass]="{
            'error-input':
              contactUsForm?.controls['email']?.invalid && submitted
          }"
          class="data-input"
          id="email"
          type="email"
          placeholder="What's your email address?"
          required
          formControlName="email"
        />
        <div
          class="error-text error-text-area"
          *ngIf="submitted && contactUsForm?.controls['email']?.invalid"
        >
          <p *ngIf="contactUsForm?.controls['email']?.errors?.required">
            This field is required.
          </p>
          <p *ngIf="contactUsForm.controls['email'].errors?.email || contactUsForm.controls['email'].errors?.pattern">
            Please enter the correct email address is required.
          </p>
        </div>
      </div>

      <div class="input-container">
        <label class="paragraph2" for="email"><b>Subject</b> *</label>
        <input
          [ngClass]="{
            'error-input':
              contactUsForm?.controls['subject']?.invalid && submitted
          }"
          class="data-input"
          type="text"
          placeholder="Short description of why are you are contacting us."
          required
          formControlName="subject"
        />
        <div
          class="error-text error-text-area"
          *ngIf="submitted && contactUsForm?.controls['subject']?.invalid"
        >
          <p *ngIf="contactUsForm?.controls['subject']?.errors?.required">
            This field is required.
          </p>
          <p *ngIf="contactUsForm?.controls['subject']?.errors?.minlength">
            This field needs to be at least 5 characters long.
          </p>
        </div>
      </div>

      <div class="textarea-container">
        <label class="paragraph2" for="password"><b>Message</b> *</label>
        <textarea
          [ngClass]="{
            'error-input':
              contactUsForm?.controls['message']?.invalid && submitted
          }"
          class="data-input last"
          id="password"
          type="text"
          placeholder="How can we help you? Elaborate on your inquiry..."
          required
          formControlName="message"
        ></textarea>
        <div
          class="error-text error-text-area"
          *ngIf="submitted && contactUsForm?.controls['message']?.errors"
        >
          <p *ngIf="contactUsForm?.controls['message']?.errors?.required">
            This field is required.
          </p>
          <p *ngIf="contactUsForm?.controls['message']?.errors?.minlength">
            This field needs to be at least 5 characters long.
          </p>
        </div>
      </div>
    </div>
  </form>

  <button [disabled]=isValidForm class="primary-btn" type="submit" (click)="sendMessage()">
    Send
  </button>
</div>
