import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-social-media-icon',
    templateUrl: './social-media-icon.component.html',
    styleUrls: ['./social-media-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaIconComponent {
    @Input() icon: string;
}
