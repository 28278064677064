<div class="popup">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="close()"
  />

  <p class="title">Search a provider to share your project brief</p>
  <div class='body'>
    <div class='search-section'>
      <div class="search" (keyup.enter)="selectProvider()">
        <img class='search-icon' src="../../../assets/create-brief-popup/search.svg" alt="search" />
        <input
          class="data-input"
          type="text"
          [(ngModel)]="term"
          (ngModelChange)="doSearch(term, true)"
          placeholder="Search a company by name"
        />
        <img (click)='clear()' *ngIf='this.selectedProvider' class='cross-icon' src='../../../assets/create-brief-popup/cross.svg' width='10.5' height='10.5' alt='delete-choice-icon' />
        <div class="search-results" *ngIf="term.length >= 3 && !selectedProvider">
          <div
            class="providers-content"
            infinite-scroll
            [scrollWindow]="false"
            (scrolled)="onScrollDown()"
            (scrolledUp)="onScrollUp()"
          >
            <div
              class="search-results-item"
              *ngFor="let item of filteredProviders; let i = index"
              (click)="selectProvider(item)"
            >
              <p
                (click)="selectProvider(item)"
                class="paragraph2"
                #item
              >
                {{ item?.companyName }}, {{ item?.industry?.title }}
                <span>{{ '- ' + (item?.city?.name ? item.city.name + ', ' : '') + item?.country?.name }}</span>
              </p>
            </div>
          </div>
          <p
            *ngIf="!filteredProviders?.length && term.length >= 3"
            class="paragraph2 p-2"
            [ngClass]="{ resultNotFound: filteredProviders?.length && term.length >= 3 }"
          >
            No results match your search criteria
          </p>
        </div>
      </div>
    </div>
    <div class='results-section' [ngClass]='{ visible: selectedProvider }'>
      <p>Sharing Project With:</p>
      <div class='card' (click)='provider()'>
        <img [src]='selectedProvider?.logo || defaultLogo' alt='logo' />
        <div class='provider-name-city-section'>
          <label class='name'>{{ selectedProvider?.companyName }}</label>
          <label class='city'>{{ (selectedProvider?.city?.name ? selectedProvider.city.name + ', ' : '') + selectedProvider?.country?.name }}</label>
        </div>
        <div class='reputation-section'>
          <label>REPUTATION:</label>
          <div class="stars">
            <div class="rate">
              <input
                disabled
                [checked]="selectedProvider?.clutchScore === 5"
                type="radio"
                id="rating10"
                name="rating"
                value="10"
              />
              <label for="rating10" title="5 stars"></label>
              <input
                disabled
                [checked]="
                      selectedProvider?.clutchScore >= 4.5 && selectedProvider?.clutchScore != 5
                    "
                type="radio"
                id="rating9"
                name="rating"
                value="9"
              />
              <label class="half" for="rating9" title="4 1/2 stars"></label>
              <input
                disabled
                [checked]="selectedProvider?.clutchScore < 4.5 && selectedProvider?.clutchScore >= 4"
                type="radio"
                id="rating8"
                name="rating"
                value="8"
              />
              <label for="rating8" title="4 stars"></label>
              <input
                disabled
                [checked]="selectedProvider?.clutchScore >= 3.5 && selectedProvider?.clutchScore < 4"
                type="radio"
                id="rating7"
                name="rating"
                value="7"
              />
              <label class="half" for="rating7" title="3 1/2 stars"></label>

              <input
                disabled
                [checked]="selectedProvider?.clutchScore < 3.5 && selectedProvider?.clutchScore >= 3"
                type="radio"
                id="rating6"
                name="rating"
                value="6"
              />
              <label for="rating6" title="3 stars"></label>
              <input
                disabled
                [checked]="selectedProvider?.clutchScore >= 2.5 && selectedProvider?.clutchScore < 3"
                type="radio"
                id="rating5"
                name="rating"
                value="5"
              />
              <label class="half" for="rating5" title="2 1/2 stars"></label>

              <input
                disabled
                [checked]="selectedProvider?.clutchScore < 2.5 && selectedProvider?.clutchScore >= 2"
                type="radio"
                id="rating4"
                name="rating"
                value="4"
              />
              <label for="rating4" title="2 stars"></label>
              <input
                disabled
                [checked]="selectedProvider?.clutchScore >= 1.5 && selectedProvider?.clutchScore < 2"
                type="radio"
                id="rating3"
                name="rating"
                value="3"
              />
              <label class="half" for="rating3" title="1 1/2 stars"></label>

              <input
                disabled
                [checked]="selectedProvider?.clutchScore < 1.5 && selectedProvider?.clutchScore >= 1"
                type="radio"
                id="rating2"
                name="rating"
                value="2"
              />
              <label for="rating2" title="1 star"></label>
              <input
                disabled
                [checked]="selectedProvider?.clutchScore >= 0.5 && selectedProvider?.clutchScore < 1"
                type="radio"
                id="rating1"
                name="rating"
                value="1"
              />
              <label class="half" for="rating1" title="1/2 star"></label>
            </div>
            <p class="paragraph2">{{ selectedProvider?.clutchScore }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button class="primary-btn" (click)="next()">Next</button>
</div>
