<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Success!</p>

  <p class="paragraph2">
    {{ data.message }}
  </p>

  <button class="primary-btn" type="submit" (click)="onNoClick()">
    Great!
  </button>
</div>

<!--<div class="login-content">-->
<!--  <img class="close-icon" src="./../../../../assets/icons/x.svg" alt="" (click)="onNoClick()">-->

<!--  <div class="login-content-logo">-->
<!--    <img src="../../../../assets/logo/Logo.svg" alt="">-->
<!--  </div>-->
<!--  <p class="title">Successfully Sent !</p>-->

<!--  <p class="paragraph2">Your brief was delivered to an additional 10 providers. Visit the project brief for more info-->
<!--    and get more updates.</p>-->

<!--  <button class="primary-btn" type="submit" (click)="onNoClick()">Sounds Good</button>-->
<!--  <p class="view">View Project Status</p>-->
<!--</div>-->
