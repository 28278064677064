import { Component, inject, Input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { IProvider } from '../../../../../interfaces/provider';
import { Article, RelatedArticle } from '../../../models/knowledge-hub/article';
import { ArticleService } from '../../../services/article.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-related-articles',
  templateUrl: './related-articles.component.html',
  styleUrls: ['./related-articles.component.scss'],
})
export class RelatedArticlesComponent implements OnChanges {
  @Input({ required: true }) provider: IProvider;
  @Input({ required: true }) article: Article;

  private articleService = inject(ArticleService);

  $relatedArticles = signal<RelatedArticle[]>(null);

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.article) {
      return;
    }
    this.articleService.getArticleWithRelatedArticles(this.article._id)
      .pipe(map(item => item.relatedArticles))
      .subscribe({
        next: res => {
          this.$relatedArticles.set(res);
        },
      });
  }
}
