<div
    class="coverImg"
    [style.background-image]="'url(' + article.coverImgUrl + ')'"
></div>

<div class="article-container">
    <ng-container *ngTemplateOutlet="articleContent"></ng-container>
    <ng-container *ngTemplateOutlet="sidebar"></ng-container>
</div>

<hr />

<p class="about-provider-title">About the service provider author</p>
<app-provider-info [provider]="provider" [previewMode]='previewMode'></app-provider-info>

<ng-template #articleContent>
    <div class="article-content">
        <p class="category">{{ article.category }}</p>
        <h2 class="title">{{ article.title }}</h2>
        <p class="body" [innerHTML]="safeHtml"></p>
    </div>
</ng-template>

<ng-template #sidebar>
    <div class="sidebar">
        <div class="author">
            <div
                class="author-logo"
                [style.background-image]="'url(' + article.authorPhotoUrl + ')'"
            ></div>
            <div>
                <p>Authored by:</p>
                <p class="author-name">
                    {{ article.authorName }}
                    <img [src]="provider.country.flag" alt="flag" />
                </p>
                <p class="author-company" [routerLink]="['/provider-profile']" [queryParams]="{ 'id' : provider._id  }">
                    From {{ provider.companyName }}
                    <img src="assets/articles/verified.svg" alt="verified" />
                </p>
            </div>
        </div>
        <div class="article-meta">
            <div class="text-with-icon" *ngIf="article.lastSaved">
                <img src="assets/articles/calendar.svg" alt="calendar" />
                <span>{{ article | articleDate }}</span>
            </div>
            <div class="text-with-icon">
                <img src="assets/articles/clock.svg" alt="clock" />
                <span>{{ article.readingTime }}</span>
            </div>
        </div>
        <div class="info-block" *ngIf="previewMode">
            <p>Search Meta Tags</p>
            <div class="search-tags">
                <p class="tag" *ngFor="let tag of article.searchMetaTags">
                    {{ tag }}
                </p>
            </div>
        </div>
        <div
            class="info-block performance"
            *ngIf="previewMode && article.status !== ArticleStatus.Draft"
        >
            <p>performance stats</p>
            <div>
                <div class="text-with-icon">
                    <img src="assets/icons/grey_eye.svg" alt="eye" />
                    <span>Viewed {{ article.viewed | viewCounter }}</span>
                </div>
                <div class="text-with-icon">
                    <img src="assets/articles/share.svg" alt="share" />
                    <span>Shared {{ article.shared }}</span>
                </div>
            </div>
        </div>
        <app-share-article
            *ngIf="article.status === ArticleStatus.Published"
            [previewMode]='previewMode'
            [article]='article'
        ></app-share-article>
        <app-related-articles *ngIf='!previewMode'
                              [article]='article'
                              [provider]='provider'>
        </app-related-articles>
    </div>
</ng-template>
