<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Oops! <br>This verification link expired</p>

  <p class="paragraph2">To get a new link, navigate to your profile and click on the method to resend a verification link.</p>

  <button class="primary-btn" type="submit" (click)="onNoClick()">Got it!</button>
</div>
