<div class="content">
    <img
        class="close-icon"
        src="./../../../../assets/icons/x.svg"
        alt=""
        (click)="dialogRef.close()"
    />

    <h2>Which account did you want to access?</h2>

    <div class="account-types">
        <div
            class="account-types-item"
            (click)="login(UserTypesAccount.Client)"
        >
            <img src="assets/bulb.svg" alt="bulb" class="icon" />
            <p class="title">My Client Account</p>
            <p class="description">
                Simplify your search/submission of projects to service providers
                vetted for quality. We also help create requirements
                documentation.
            </p>
        </div>
        <div class="divider"></div>
        <div
            class="account-types-item"
            (click)="login(UserTypesAccount.Provider)"
        >
            <img src="assets/gear.svg" alt="gear" class="icon" />
            <p class="title">Service Provider Account</p>
            <p class="description">
                Track projects sent to your company. Only the best companies
                from around the world are featured on this platform. Are you one
                of them?
            </p>
        </div>
    </div>
</div>
