import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, inject, OnInit, Output, ViewChildren } from '@angular/core';
import { LoginComponent } from '../popups/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { delay, first, skipWhile, switchMap } from 'rxjs/operators';
import { AuthDataService } from '../../services/auth-data.service';
import { SignUpPopupComponent } from '../popups/sign-up/sign-up-popup.component';
import { ILogin, UserFullInfo, UserTypesAccount } from '../../interfaces/auth-interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { SlidersTypeEnum } from '../../enums/sliders.enum';
import { IProvider } from '../../interfaces/provider';
import { ConfirmEmailComponent } from '../popups/confirm-email/confirm-email.component';
import { ChangePasswordComponent } from '../popups/change-phone-number/change-password.component';
import { IndustryEnum } from '../../enums/industry.enum';
import { LetUsComponent } from '../popups/let-us/let-us.component';
import { GetListedComponent } from '../popups/get-listed/get-listed.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ITestimonial } from '../../interfaces/testimonial.interface';
import { combineLatest, Observable, of, timer } from 'rxjs';
import { IsInterestedComponent } from '../popups/is-interested/is-interested.component';
import { IsNotInterestedComponent } from '../popups/is-not-interested/is-not-interested.component';
import { TokenInvalidComponent } from '../popups/token-invalid/token-invalid.component';
import { LinkDeactivatedComponent } from '../popups/link-deactivated/link-deactivated.component';
import { UnverifiedAccountComponent } from '../popups/unverified-account/unverified-account.component';
import { IProject } from '../../interfaces/project';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationService } from '../../services/navigation.service';
import { SpecificMultipleProviderComponent } from '../popups/specific-multiple-provider/specific-multiple-provider.component';
import { SearchProviderForBriefComponent } from '../popups/search-provider-for-brief/search-provider-for-brief.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChildren('item', { read: ElementRef })
  @Output()
  sendLoginEmitter: EventEmitter<ILogin> = new EventEmitter<ILogin>();

  private navigationService = inject(NavigationService);

  protected readonly UserTypesAccount = UserTypesAccount;
  public token: string;
  public user: UserFullInfo;
  public providers: IProvider[] = [];
  public filteredProviders: IProvider[] = [];
  public industries = IndustryEnum;
  public term = '';
  public unreviewedAlertsAmount$: Observable<number>;
  public readonly services = [
    {
      img: '../../../assets/home/pc.png',
      title: 'Wordpress',
      link: 'Wordpress',
      text: 'Customize your website',
    },
    {
      img: '../../../assets/home/design.jpg',
      title: 'Logo Design',
      link: 'Branding',
      text: 'Build Your Brand',
    },
    {
      img: '../../../assets/home/social.jpg',
      title: 'Social media',
      link: 'Social Media',
      text: 'Reach More Customers',
    },
    {
      img: '../../../assets/home/304.jpg',
      title: 'SEO',
      link: 'SEO',
      text: 'Unlock growth online',
    },
  ];
  public slidersTypeEnum = SlidersTypeEnum;
  public newlyAdded: IProvider[];
  public featured: IProvider[];
  public testimonials: ITestimonial[];
  skip = 0;
  limit = 20;
  direction = '';

  constructor(
    // private socket: Socket,
    public dialog: MatDialog,
    public authDS: AuthDataService,
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private destroyRef: DestroyRef,
  ) {
    this.token = localStorage.getItem('token');
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.ngxService.start();
    this.authDS.user$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((user: UserFullInfo) => {
          if (user) {
            return of(user);
          } else if (!!this.token) {
            return this.authDS.getUserProfile();
          } else {
            return of(null);
          }
        }),
        switchMap(user => {
          this.user = user;
          if (this.user) {
            this.unreviewedAlertsAmount$ = this.authDS.getUnreviewedAlertsAmount();
          }
          return combineLatest([
            this.authDS.getAllTestimonials(),
            this.authDS.getAllNewlyAddedAndFeatured(),
          ]);
        }),
      ).subscribe(this.setAllData.bind(this));
    this.route.queryParams.subscribe({
      next: (params) => {
        if (!params.fromCreateBrief) return;
        this.createBriefOrLogin();
        this.router.navigate([]);
      }
    });
  }

  ngAfterViewInit(): void {
    if (
      !!this.route.snapshot.queryParamMap.get('isInterested') &&
      this.route.snapshot.queryParamMap.get('isInterested').toLowerCase() === 'true'
    ) {
      const projectId = this.route.snapshot.queryParamMap.get('projectId');
      const providerId = this.route.snapshot.queryParamMap.get('providerId');
      this.checkIsBriefActive(projectId, providerId, true);
    }

    if (
      !!this.route.snapshot.queryParamMap.get('isInterested') &&
      this.route.snapshot.queryParamMap.get('isInterested').toLowerCase() === 'false'
    ) {
      const projectId = this.route.snapshot.queryParamMap.get('projectId');
      const providerId = this.route.snapshot.queryParamMap.get('providerId');
      this.checkIsBriefActive(projectId, providerId, false);
    }

    if (this.route.snapshot.queryParamMap.get('confirmEmail') && this.route.snapshot.queryParamMap.get('token')) {
      this.authDS
        .confirmEmail(this.route.snapshot.queryParamMap.get('token'))
        .pipe(delay(1000), first())
        .subscribe((res: any) => {
          if (res?.message === 'Invalid link token') {
            this.openTokenInvalid();
          } else {
            this.openConfirmEmail();
            this.authDS.getUserProfile().subscribe();
          }
        });
    }

    if (this.route.snapshot.queryParamMap.get('resetPassword') && this.route.snapshot.queryParamMap.get('token')) {
      this.authDS.checkToken(this.route.snapshot.queryParamMap.get('token'))
        .pipe(delay(1000), first())
        .subscribe((res) => {
          if (res?.isActive) {
            localStorage.setItem('tokenReset', this.route.snapshot.queryParamMap.get('token'));
            this.openChangePassword();
          } else {
            this.openLinkDeactivatedModal();
          }
        });
    }

    if (this.route.snapshot.queryParamMap.get('popupSignUp')) {
      timer(1000).pipe(first()).subscribe(this.openSignUpPopup.bind(this));
    }

    const video = document.querySelector('video');
    video.muted = true;
    video.play().then();
    if (this.route.snapshot.queryParamMap.get('searchProviderPopup')) {
      this.searchProviderPopup();
      this.router.navigate([]);
    }
    if (this.route.snapshot.queryParamMap.get('selectProviderTypePopup')) {
      this.createBriefOrLogin();
      this.router.navigate([]);
    }
    if (!this.checkForPrevBrief()) return;
    if (this.route.snapshot.queryParamMap.get('fromSwitchAccount')) return;
    this.openLoginPopup();
  }

  canViewArticlesPage() {
    const typeAccount = this.user?.user?.typeAccount;
    if (typeAccount !== UserTypesAccount.Provider && typeAccount !== UserTypesAccount.Multiple) return false;
    const subType: number = this.authDS.getUserSubscriptionType();
    return !!subType;
  }

  private checkIsBriefActive(briefID, providerId, status) {
    this.authDS.getBriefById(briefID)
      .pipe(delay(1000), first())
      .subscribe((res: { project: IProject }) => {
        if (res.project.status === 2) {
          this.openLinkDeactivatedModal();
        } else {
          this.confirmOpinion(briefID, providerId, status);
        }
      });
  }

  private confirmOpinion(projectId, providerId, isInterested) {
    this.authDS
      .getProjectAlertStatus(projectId, providerId)
      .pipe(first())
      .subscribe((result) => {
        if (!result) {
          if (isInterested) {
            this.authDS
              .sendBriefToProvider(projectId, providerId)
              .pipe(first())
              .subscribe((result) => {
                if (result?.hasError) {
                  this.openLinkDeactivatedModal();
                } else {
                  this.openIsInterested();
                }
              });
          } else if (!isInterested) {
            this.openIsNotInterested(projectId, providerId);
          }
        } else {
          this.openLinkDeactivatedModal();
        }
      });
  }

  private setAllData([testimonials, newlyAddedAndFeatured]: [
    ITestimonial[],
    { newlyAdded: { data: IProvider[] }; featured: { data: IProvider[] } }
  ]): void {
    // this.providers = providers ? [...providers] : [];
    // this.filteredProviders = providers ? [...providers] : [];
    this.testimonials = testimonials.filter((testimonial) => testimonial.active);
    this.newlyAdded = newlyAddedAndFeatured?.newlyAdded.data;
    this.featured = newlyAddedAndFeatured?.featured.data;

    this.ngxService.stop();
  }

  public openLoginPopup(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      ...this.checkForPrevBrief(),
      autoFocus: false,
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        skipWhile((result) => !result),
      )
      .subscribe((result) => this.sendLoginEmitter.emit(result));
  }

  checkForPrevBrief() {
    const prevUrl = this.navigationService.getPreviousUrl();
    if (!prevUrl || !prevUrl.includes('/provider/project?briefId')) {
      return null;
    }
    return {
      data: {
        redirectTo: prevUrl,
      },
    };
  }

  public openLetUs(): void {
    const dialogRef = this.dialog.open(LetUsComponent, {
      autoFocus: false,
      height: '800px',
      width: '1000px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        skipWhile((result) => !result),
      )
      .subscribe((result) => this.sendLoginEmitter.emit(result));
  }

  public openGetListed(): void {
    const dialogRef = this.dialog.open(GetListedComponent, {
      autoFocus: false,
      height: '800px',
      width: '1000px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        skipWhile((result) => !result),
      )
      .subscribe((result) => this.sendLoginEmitter.emit(result));
  }

  public openIsInterested(): void {
    const dialogRef = this.dialog.open(IsInterestedComponent, {
      autoFocus: false,
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        this.router.navigate(['/']).then();
      });
  }

  public openIsNotInterested(projectId: string, providerId: string): void {
    const dialogRef = this.dialog.open(IsNotInterestedComponent, {
      autoFocus: false,
      disableClose: true,
      width: '500px',
      data: {
        projectId,
        providerId,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result?.openInterestedPopup) {
          this.router.navigate(['/']).then(() => this.openIsInterested());
        }
      });
  }

  public openLinkDeactivatedModal() {
    const dialogRef = this.dialog.open(LinkDeactivatedComponent, {
      autoFocus: false,
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        this.router.navigate(['/']).then();
      });
  }

  public openChangePassword(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      autoFocus: false,
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        skipWhile((result) => !result),
      )
      .subscribe((result) => this.sendLoginEmitter.emit(result));
  }

  public openConfirmEmail(): void {
    const dialogRef = this.dialog.open(ConfirmEmailComponent, {
      autoFocus: false,
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
      )
      .subscribe(() => {
        this.router.navigate(['/']).then();
      });
  }

  public openTokenInvalid(): void {
    const dialogRef = this.dialog.open(TokenInvalidComponent, {
      autoFocus: false,
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
      )
      .subscribe(() => {
        this.router.navigate(['/']).then();
      });
  }

  onScrollDown(ev: any) {
    if (this.providers.length < this.limit) {
      return;
    }
    this.skip += 20;
    this.limit += 20;

    this.appendItems();

    this.direction = 'scroll down';
  }

  onScrollUp(ev: any) {
    if (this.limit <= 20) {
      return;
    }
    this.limit -= 20;
    this.prependItems();

    this.direction = 'scroll up';
  }

  appendItems() {
    this.addItems('push');
  }

  prependItems() {
    this.addItems('unshift');
  }

  addItems(method: string) {
    if (method === 'push') {
      this.doSearch(this.term);
    }
  }

  public doSearch(item, isChange?) {
    if (item.length < 3) {
      return;
    }
    if (isChange) {
      this.filteredProviders = [];
      this.skip = 0;
      this.limit = 20;
    }
    const sortOf = 0;
    const filters: any = {
      filterOptions: {
        search: item,
      },
      sortingOptions: sortOf >= 0 ? { sortType: sortOf } : { sortType: 0 },
    };

    this.authDS
      .doSearch(filters, this.skip, this.limit)
      .pipe(first())
      .subscribe((res: { data: IProvider[], totalCount: number }) => {
        this.filteredProviders = [];
        const providers: IProvider[] = res?.data ? res.data : [];
        this.filteredProviders.push(...providers);
      });
  }


  public clearSearch(filter?) {
    this.term = this.term.trim();

    if (filter) {
      this.term = filter.companyName;
    }
    if (this.term.length > 0) {
      this.router
        .navigate(['/search-service-providers'], {
          queryParams: { search: this.term },
        })
        .then();
    }
  }



  public createBriefOrLogin(): void {
    if (this.user) {
      if (this.user?.user.isPhoneVerified && this.user?.user.confirmed) {
        const dialogRef = this.dialog.open(SpecificMultipleProviderComponent, {
          width: '560px'
        });
        dialogRef.afterClosed()
            .subscribe({
              next: (res) => {
                if (res === undefined) return;
                if (!res) return this.router.navigate(['/create-brief']);
                this.searchProviderPopup();
              }
            });
      } else {
        this.dialog.open(UnverifiedAccountComponent, {
          autoFocus: false,
          width: '450px',
          disableClose: true,
        });
      }
    } else {
      this.dialog.open(LoginComponent, {
        autoFocus: false,
        width: '500px',
        data: {
          title: ' ',
          message: 'You need to be authorized to be able to send a project brief.',
          button: 'Got it!',
          fromCreateBrief: true,
        },
      });
    }
  }

  private searchProviderPopup() {
    const dialogRef2 = this.dialog.open(SearchProviderForBriefComponent, {
      width: '750px'
    });
    dialogRef2.afterClosed()
      .subscribe({
        next: (provider: IProvider) => {
          if (!provider) return;
          this.router
            .navigate(['/create-brief'], {
              queryParams: {
                company: provider?._id,
                type: 'provider',
                name: provider?.companyName
              },
            })
            .then();
        }
      });
  }

  public createDocOrLogin(): void {
    if (this.user) {
      if (this.user?.user.isPhoneVerified && this.user?.user.confirmed) {
        this.router.navigate(['/create-doc-requirements']).then();
      } else {
        this.dialog.open(UnverifiedAccountComponent, {
          autoFocus: false,
          width: '450px',
          disableClose: true,
        });
      }
    } else {
      this.dialog.open(LoginComponent, {
        autoFocus: false,
        width: '500px',
        data: {
          title: ' ',
          message: 'You need to be authorized to be able to send a project brief.',
          button: 'Got it!',
          redirectTo: '/create-doc-requirements',
        },
      });
    }
  }

  public openSignUpPopup(): void {
    const dialogRef = this.dialog.open(SignUpPopupComponent, {
      autoFocus: false,
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        skipWhile((result) => !result),
      )
      .subscribe((result) => this.sendLoginEmitter.emit(result));
  }

  public logout() {
    this.ngxService.start();
    localStorage.clear();
    this.token = '';
    this.ngxService.stop();
    location.reload();
  }

  public scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  public onSwitchAccountClick() {
    const accountType = this.authDS.switchAccount();
    const redirectTo =
      accountType === UserTypesAccount.Client ? '/' : '/provider/dashboard';
    this.router.navigate([redirectTo], { queryParams: { fromSwitchAccount: true }});
  }
}
