import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UserFullInfo } from '../../../../interfaces/auth-interfaces';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthDataService } from '../../../../services/auth-data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Observable } from 'rxjs';
import { ICountry } from '../../../../interfaces/project';
import { first, map, startWith, tap } from 'rxjs/operators';
import { AccountSettingsService } from '../../account-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-number',
  templateUrl: './change-number.component.html',
  styleUrls: ['./change-number.component.scss'],
})
export class ChangeNumberComponent implements OnInit {
  private accountSettingsService = inject(AccountSettingsService);
  private authDS = inject(AuthDataService);
  private destroyRef = inject(DestroyRef);
  private snakeBar = inject(MatSnackBar);

  public user: UserFullInfo;

  public filteredCountries$: Observable<ICountry[]>;
  public countries: ICountry[] = [];

  formGroup = new FormGroup({
    selectCountryChangePhone: new FormControl(null, Validators.required),
    country: new FormControl(null, Validators.required),
    phoneNumber: new FormControl(null, Validators.required),
  });
  formControls = this.formGroup.controls;

  ngOnInit() {
    this.accountSettingsService.stepChange$.subscribe({
      next: () => this.formGroup.reset()
    });
    this.authDS.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (user) => {
          if (!user) {
            return;
          }
          this.user = user;
        },
      });
    this.authDS.getCountries()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.countries = res;
          this.initFilteredOptions();
        },
      });
  }

  submit() {
    if (this.formGroup.invalid) {
      return this.formGroup.markAllAsTouched();
    }
    const { selectCountryChangePhone, phoneNumber } = this.formGroup.value;
    const phone = Number(selectCountryChangePhone + phoneNumber);
    const data = {
      _id: this.user?.user._id,
      phoneNumber: phone,
    };
    this.authDS.editAccount(data, 'user')
      .pipe(tap(
        () => this.authDS.getUserProfile()
          .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(),
      )).subscribe({
      next: () => {
        this.authDS
          .sendVerifyPhoneNumber(phone)
          .pipe(first())
          .subscribe({
            next: () => {
              this.accountSettingsService.stepper.previous();
              this.snakeBar.open('We texted you the 4-digit code to your mobile.', '', {
                duration: 2500,
              });
            },
          });
      },
    });
  }

  public back() {
    this.accountSettingsService.stepper.previous();
  }

  public chooseCountryValueChangeNumber(event: MatAutocompleteSelectedEvent | Event): void {
    if (event instanceof MatAutocompleteSelectedEvent) {
      for (const country of this.countries) {
        if (this._normalizeValue(country.name) === this._normalizeValue(event.option.value.name)) {
          this.formControls.selectCountryChangePhone.setValue(
            country.phoneCode.replace(/[^\d]/g, ''),
          );
        }
      }
    }
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  private initFilteredOptions() {
    this.filteredCountries$ = this.formControls.country.valueChanges.pipe(
      startWith((this.formControls.country.value as ICountry)?.name ?? ''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name
          ? this._filter(name as string, this.countries)
          : this.countries.slice();
      }),
    );
  }

  private _filter<T extends { name?: string }>(name: string, options: T[]): T[] {
    const filterValue = name.toLowerCase();
    return options.filter((option) =>
      option.name.toLowerCase().includes(filterValue),
    );
  }

  numericOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode === 101 || charCode === 69 || charCode === 45 || charCode === 43 || charCode === 189 || charCode === 187);
  }

  displayName<T extends { name?: string }>(el: T) {
    return el?.name;
  }
}
