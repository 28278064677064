<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="dismiss()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Are you sure?!</p>

  <p class="paragraph2">
    If you will delete brief <b>"{{ data.briefName }}"</b> you would not able to restore it.
  </p>

  <div class="buttons-wrapper">
    <button class="primary-btn-transparent" (click)="dismiss()">
      Cancel
    </button>
    <button class="primary-btn" (click)="deleteIt()">
      Delete
    </button>
  </div>
</div>
