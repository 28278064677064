import { Component, Input } from '@angular/core';
import {
    SortingOptions,
    SortingTypes,
} from '../../../models/knowledge-hub/filter-articles-request';

export interface Option {
    displayName: string;
    value: SortingOptions;
}

@Component({
    selector: 'app-sorting-articles-by',
    templateUrl: './sorting-articles-by.component.html',
    styleUrls: ['./sorting-articles-by.component.scss'],
})
export class SortingArticlesByComponent {
    @Input() set initialValue(value: SortingOptions) {
        this.value = value;
    }

    @Input() options: Option[] = [
        {
            displayName: 'Newest to Oldest',
            value: {
                sortType: SortingTypes.createdAt,
                ascending: false,
            },
        },
        {
            displayName: 'Oldest to Newest',
            value: {
                sortType: SortingTypes.createdAt,
                ascending: true,
            },
        },
        {
            displayName: 'Most Viewed First',
            value: {
                sortType: SortingTypes.viewed,
                ascending: false,
            },
        },
        {
            displayName: 'Least Viewed First',
            value: {
                sortType: SortingTypes.viewed,
                ascending: true,
            },
        },
    ];
    public value: SortingOptions = this.options[0].value;

    protected compareOptions(option, value): boolean {
        return (
            option.sortType === value.sortType &&
            option.ascending === value.ascending
        );
    }
}
