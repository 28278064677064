import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-brief-step-header',
  templateUrl: './brief-step-header.component.html',
  styleUrls: ['./brief-step-header.component.scss']
})
export class BriefStepHeaderComponent {
  @Input() title: string;
  @Input() currentStep: number;
  @Input() totalSteps: number;
}
