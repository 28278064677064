export interface Article {
  _id: string;
  title: string;
  body: string;
  category: string;
  country?: string;
  providerName?: string;
  searchMetaTags: string[];
  authorName: string;
  authorPhoto?: string;
  flag?: string;
  status: ArticleStatus;
  coverImgUrl: string;
  authorPhotoUrl: string;
  readingTime: string;
  viewed: number;
  shared: number;
  createdAt: string;
  lastSaved: string;
  providerId: string;
}

export interface RelatedArticleList {
  currentArticle: Article;
  relatedArticles: RelatedArticle[];
}

export interface RelatedArticle {
  id: string;
  title: string;
  coverImgUrl: string;
  category: string;
}

export interface ICountryName {
  _id: string;
  name: string;
}

export interface IArticleCount {
  companyName: string;
  articleCount: string;
}

export interface ShareLinkToIcon {
  shareLink: string | null;
  icon: string;
}

export interface ArticleCategory {
  _id: string;
  key: string;
  nameCategory: string;
  topCategory?: boolean;
  articleCount?: number;
}

export enum ArticleStatus {
  Draft = 'draft',
  Published = 'published',
  Unpublished = 'unpublished',
}
