import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccountSettingsRoutingModule} from './account-settings-routing.module';
import {SharedModule} from '../shared/shared.module';
import {MatStepperModule} from '@angular/material/stepper';
import {FormGroupDirective, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgOtpInputModule} from 'ng-otp-input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AccountSettingsComponent } from './account-settings.component';
import { IndividualProfileComponent } from './individual/individual-profile/individual-profile.component';
import {
  IndividualNotificationPreferencesComponent
} from './individual/individual-notification-preferences/individual-notification-preferences.component';
import {
  StartupEnterpriseProfileComponent
} from './startup-enterprise/startup-enterprise-profile/startup-enterprise-profile.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { CodeVerifyComponent } from './phone-verification/code-verify/code-verify.component';
import { ChangeNumberComponent } from './phone-verification/change-number/change-number.component';


@NgModule({
    declarations: [
      AccountSettingsComponent,
      IndividualProfileComponent,
      IndividualNotificationPreferencesComponent,
      StartupEnterpriseProfileComponent,
      DeleteAccountComponent,
      CodeVerifyComponent,
      ChangeNumberComponent,
    ],
    providers: [FormGroupDirective],
  imports: [
    CommonModule,
    AccountSettingsRoutingModule,
    SharedModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    FormsModule,
    NgOtpInputModule,
    MatTooltipModule
  ]
})
export class AccountSettingsModule {
}
