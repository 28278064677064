<div class="wrapper">
  <div class="header">
    <h1 class="title">Project Requirements Document Example</h1>
  </div>

  <div class="doc-preview-container">
    <div class="doc-preview">
      <pdf-viewer [src]="uint"
                  [original-size]="false"
                  [zoom]="0.95"
                  [show-borders]="true"
                  style="width: 100%; height: 100%"
      ></pdf-viewer>
    </div>
  </div>

<!--  <div class="btn-container" *ngIf="isShowButtons()">-->
<!--    <button class="primary-btn-transparent confirm-btn" [mat-dialog-close]="true">-->
<!--      Yes, I want to buy this document-->
<!--    </button>-->
<!--    <p class="decline-btn" [mat-dialog-close]="false">No, skip it for now</p>-->
<!--    <p class="info-text">You’ll be able to buy it later</p>-->
<!--  </div>-->
</div>
