import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-are-you-sure',
  templateUrl: './are-you-sure.component.html',
  styleUrls: ['./are-you-sure.component.scss']
})
export class AreYouSureComponent {
  constructor(
    public dialogRef: MatDialogRef<AreYouSureComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCancelClick(): void {
    const queryParams: any = {};
    if (this.data.docRequirements) {
      queryParams.activeTab = 'Draft';
    } else {
      queryParams.briefId = this.data.briefId;
    }
    this.router.navigate(
        [this.data.docRequirements ? '/my-briefs' : '/my-briefs/preview'],
        { queryParams }
    ).then(() => this.dialogRef.close());
  }
}
