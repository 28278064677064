<footer>
  <div class="container">
    <ul class="footer-menu">
      <li class="paragraph" (click)="openAbout()">About Us</li>
      <li class="paragraph" (click)="openContact()">Contact Us</li>
      <li class="paragraph" (click)="goToLink('/terms-of-use')">Terms of Use</li>
      <li class="paragraph" (click)="goToLink('/privacy-policy')">Privacy Policy</li>
    </ul>
    <p class="paragraph2">
      © {{ currentYear }} The Unicorn App. All rights reserved.
    </p>
  </div>
</footer>
