import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-brief-dialog',
  templateUrl: './delete-brief-dialog.component.html',
  styleUrls: ['./delete-brief-dialog.component.scss']
})
export class DeleteBriefDialogComponent {

  constructor(private dialogRef: MatDialogRef<DeleteBriefDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  public dismiss(): void {
    this.dialogRef.close(false);
  }

  public deleteIt(): void {
    this.dialogRef.close(true);
  }

}
