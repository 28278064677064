import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-specific-multiple-provider',
  templateUrl: './specific-multiple-provider.component.html',
  styleUrls: ['./specific-multiple-provider.component.scss']
})
export class SpecificMultipleProviderComponent {
  public dialogRef = inject(MatDialogRef<SpecificMultipleProviderComponent>);
  selected = false;

  changeSelected(val: boolean) {
    this.selected = val;
  }

  next() {
    this.dialogRef.close(this.selected);
  }

  close() {
    this.dialogRef.close(undefined);
  }
}
