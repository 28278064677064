<ul class="checkboxes-container-wrap scroll-wrap">
    <li class="text-field checkbox">
        <label>
            <input
                #defaultOptionRef
                [value]="defaultOption"
                [checked]="!value.length"
                type="checkbox"
                (change)="handleDefaultCheckbox($event)"
            />
            {{ defaultOption }}
        </label>
    </li>
    <li class="text-field checkbox" *ngFor="let option of options">
        <label>
            <input
                #checkboxRef
                [value]="option"
                [checked]="value.includes(option)"
                type="checkbox"
                (change)="onChange($event)"
            />
            {{ option }}
        </label>
    </li>
</ul>
