<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="dismiss()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Document Saved!</p>

  <p class="paragraph2">
    We’ve successfully saved your requirements document so you can continue later.
    You can also continue now.
  </p>

  <button class="primary-btn" (click)="dismiss()">
    Continue
  </button>

  <button class="transparent-button" (click)="goBriefs()">
    Continue Later
  </button>
</div>
