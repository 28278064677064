import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { Article, ArticleStatus } from '../../models/knowledge-hub/article';
import { formatDate } from '@angular/common';

@Pipe({
    name: 'articleDate',
})
export class ArticleDatePipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private locale: string) {}

    transform(article: Article): string {
        const format = 'MMM dd, yyyy';
        const articleDate = article.lastSaved ?? article.createdAt;
        switch (article.status) {
            case ArticleStatus.Draft:
                return `Last saved on ${formatDate(articleDate, format, this.locale)}`;
            case ArticleStatus.Published:
                return `Published on ${formatDate(articleDate, format, this.locale)}`;
            case ArticleStatus.Unpublished:
                return `Unpublished on ${formatDate(articleDate, format, this.locale)}`;
        }
    }
}
