import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type FilterButtonVariants = 'outlined' | 'filled';

@Component({
    selector: 'app-articles-filter-button',
    templateUrl: './articles-filter-button.component.html',
    styleUrls: ['./articles-filter-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlesFilterButtonComponent {
    @Input() set variant(variant: FilterButtonVariants) {
        if (variant === 'outlined') {
            this.props.class = 'outlined';
            this.props.img = 'assets/articles/filter_purple.svg';
        } else if (variant === 'filled') {
            this.props.class = 'filled';
            this.props.img = 'assets/articles/filter_white.svg';
        }
    }

    protected props = {
        class: 'outlined',
        img: 'assets/articles/filter_purple.svg',
    };
}
