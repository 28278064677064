import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimatedLoaderComponent } from './animated-loader/animated-loader.component';
import '@dotlottie/player-component';

@NgModule({
  declarations: [
    AnimatedLoaderComponent
  ],
  exports: [
    AnimatedLoaderComponent
  ],
  imports: [
    CommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AnimatedLoaderModule { }
