import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: "app-oh-no",
  templateUrl: "./oh-no.component.html",
  styleUrls: ["./oh-no.component.scss"],
})
export class OhNoComponent {
  constructor(
    public dialogRef: MatDialogRef<OhNoComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
