import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChildren,
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {AuthDataService} from '../../../services/auth-data.service';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-filters',
  templateUrl: './filters-popup.component.html',
  styleUrls: ['./filters-popup.component.scss'],
})
export class FiltersPopupComponent implements OnInit {
  @ViewChildren('checkbox') checkbox;

  public formCountries: UntypedFormGroup = this.fb.group({
    checkArray: this.fb.array([]),
  });
  public formSpecialize: UntypedFormGroup = this.fb.group({
    checkArray: this.fb.array([]),
  });
  private filterFromParent: any;

  constructor(
    private elRef: ElementRef,
    private authDS: AuthDataService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<FiltersPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.authDS.filters$.pipe(first()).subscribe((res) => {
      if (res) {
        this.filterFromParent = res;
        this.formSpecialize.setControl(
          'checkArray',
          this.fb.array(res.specializationAreas)
        );
        this.formCountries.setControl(
          'checkArray',
          this.fb.array(res.countries)
        );
      } else if (localStorage.getItem('filters')) {
        this.filterFromParent = JSON.parse(localStorage.getItem('filters'));

        this.formSpecialize.setControl(
          'checkArray',
          this.fb.array(this.filterFromParent.specializationAreas)
        );
        this.formCountries.setControl(
          'checkArray',
          this.fb.array(this.filterFromParent.countries)
        );
      }
    });
  }

  public sendFilter(): void {
    const filter: any = {
      countries:
        this.formCountries.value.checkArray[0]?.length > 0 ||
        this.formCountries.value.checkArray?.length === 0
          ? this.formCountries.value.checkArray
          : [],

      specializationAreas:
        this.formSpecialize.value.checkArray[0]?.length > 0 ||
        this.formSpecialize.value.checkArray?.length === 0
          ? this.formSpecialize.value.checkArray
          : [],
    };
    if (JSON.stringify(this.filterFromParent) === JSON.stringify(filter)) {
      this.dialogRef.close();
    } else {
      this.authDS.setFilters(filter);
      this.dialogRef.close(filter);
    }
  }

  public closePopup(): void {
    this.dialogRef.close();
  }

  onCheckboxChange(e, form) {
    const checkArray: UntypedFormArray = form.get('checkArray') as UntypedFormArray;
    if (!checkArray.at(0)?.value.length) {
      checkArray.removeAt(0);
    }
    if (e.target.checked) {
      checkArray.push(new UntypedFormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: UntypedFormControl) => {
        if (item.value === e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  public clearFilters(): void {
    const checkbox = this.elRef.nativeElement.querySelectorAll('.myCheckbox');
    for (let i = 0; i < checkbox.length; i++) {
      checkbox[i].checked = false;
    }
    this.elRef.nativeElement.querySelector('.countriesAny').checked = true;
    this.elRef.nativeElement.querySelector('.specAny').checked = true;
  }

  public clearFiltersCountries(e): void {
    if (e.target.checked) {
      const checked = this.formCountries.get('checkArray') as UntypedFormArray;
      checked.clear();
      checked.push(new UntypedFormControl(e.target.value));
      const checkbox = this.elRef.nativeElement.querySelectorAll('.countries');
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = false;
      }
    } else {
      const checkbox = this.elRef.nativeElement.querySelector('.countriesAny');
      checkbox.checked = true;
    }
  }

  public clearFiltersSpec(e): void {
    if (e.target.checked) {
      const checked = this.formSpecialize.get('checkArray') as UntypedFormArray;
      checked.clear();
      checked.push(new UntypedFormControl(e.target.value));
      const checkbox = this.elRef.nativeElement.querySelectorAll('.spec');
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = false;
      }
    } else {
      const checkbox = this.elRef.nativeElement.querySelector('.specAny');
      checkbox.checked = true;
    }
  }

  public clearFiltersCountriesAny(): void {
    const checkbox = this.elRef.nativeElement.querySelector('.countriesAny');
    checkbox.checked = false;
  }


  public clearFiltersSpecAny(): void {
    const checkbox = this.elRef.nativeElement.querySelector('.specAny');
    checkbox.checked = false;
  }
}
