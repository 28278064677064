<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>

  <form class="login-content-form" [formGroup]="loginForm">
    <div class="field">
      <label class="paragraph2" for="new-password"><b>New Password</b> *</label>
      <input
        [ngClass]="{
          'error-input': loginForm.controls['newPassword'].invalid && submitted
        }"
        class="data-input"
        id="new-password"
        type="{{ typePassword }}"
        placeholder="Create a login password"
        required
        formControlName="newPassword"
      />
      <button class="mask" (click)="changePasswordType()">
        <img
          *ngIf="!showPassword"
          src="../../../../assets/eye.svg"
          alt=""
        />
        <img
          *ngIf="showPassword"
          src="../../../../assets/Eye.slash.svg"
          alt=""
        />
      </button>
      <div
        class="error-text"
        *ngIf="loginForm.controls['newPassword'].errors && submitted"
      >
        <p *ngIf="loginForm.controls['newPassword'].errors.minlength">
          Password must be at least 6 characters.
        </p>
        <p *ngIf="loginForm.controls['newPassword'].errors.required">
          New Password is required.
        </p>
        <p *ngIf="loginForm.controls['newPassword'].errors.mustMatch">
          Passwords must match.
        </p>
      </div>
    </div>
    <div class="field">
      <label class="paragraph2" for="passwordConfirm"><b>Confirm Password</b> *</label>
      <input
        [ngClass]="{
          'error-input': loginForm.controls['passwordConfirm'].invalid && submitted
        }"
        class="data-input"
        id="passwordConfirm"
        type="password"
        type="{{ typeConfirmPassword }}"
        placeholder="Re-enter password"
        required
        formControlName="passwordConfirm"
      />
      <button class="mask" (click)="changeConfirmPasswordType()">
        <img
          *ngIf="!showConfirmPassword"
          src="../../../../assets/eye.svg"
          alt=""
        />
        <img
          *ngIf="showConfirmPassword"
          src="../../../../assets/Eye.slash.svg"
          alt=""
        />
      </button>
      <div
        class="error-text"
        *ngIf="loginForm.controls['passwordConfirm'].errors && submitted"
      >
        <p *ngIf="loginForm.controls['passwordConfirm'].errors.minlength">
          Password must be at least 6 characters.
        </p>
        <p *ngIf="loginForm.controls['passwordConfirm'].errors.required">
          Confirm Password is required.
        </p>
        <p *ngIf="loginForm.controls['passwordConfirm'].errors.mustMatch">
          Passwords must match.
        </p>
      </div>
    </div>
  </form>
  <button
    class="primary-btn"
    type="submit"
    (click)="doLogin()">
    Change Password
  </button>
</div>
