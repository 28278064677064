import { ChangeDetectionStrategy, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { IArticleCount } from '../../../models/knowledge-hub/article';

@Component({
  selector: 'app-multiple-checkboxes-array',
  templateUrl: './multiple-checkboxes-array.component.html',
  styleUrls: ['./multiple-checkboxes-array.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleCheckboxesArrayComponent {
  @ViewChildren('checkboxRef') checkboxes: QueryList<
    ElementRef<HTMLInputElement>
  >;
  @ViewChild('defaultOptionRef')
  defaultOptionRef: ElementRef<HTMLInputElement>;

  @Input({ required: true }) options: IArticleCount[] = [];
  @Input() defaultOption = 'Any';

  @Input() set value(val: string[]) {
    if (!val.length && this.defaultOptionRef) {
      timer(10)
        .pipe(first())
        .subscribe(() => {
          this.defaultOptionRef.nativeElement.checked = true;
        });
    }
    this._value = [...val];
  }

  get value() {
    return this._value;
  }

  private _value: string[] = [];

  protected onChange(e: Event) {
    const target = e.target as HTMLInputElement;
    if (target.checked) {
      this.defaultOptionRef.nativeElement.checked = false;
      this.value.push(target.value);
    } else {
      this.value = this.value.filter(el => el !== target.value);
      if (!this.value.length) {
        this.defaultOptionRef.nativeElement.checked = true;
      }
    }
  }

  protected handleDefaultCheckbox(e: Event) {
    const target = e.target as HTMLInputElement;
    target.checked = true;
    this.checkboxes.forEach(el => (el.nativeElement.checked = false));
    this.value = [];
  }
}
