<div class="container">
    <app-brief-step-header
            [title]="'Features: Part One'"
            [currentStep]="currentStep"
            [totalSteps]="totalSteps"
    ></app-brief-step-header>
    <p class="sub-title">
        Select all essential components that is needed *
        <mat-error *ngIf="showError && !isValid()">Select at least one option.</mat-error>
    </p>

    <ul class="features-container">
        <li class="text-field"
            *ngFor="let key of featuresKeys()">
            <label>
                <input type="checkbox"
                       [value]="key"
                       (change)="handleCheckbox($event, key)"
                       [checked]="featuresSignal()[key].isChosen"/>
                {{ getNameSection(key) }}
                <mat-icon [matTooltip]=tooltipText[key]
                          matTooltipPosition="right"
                          matTooltipClass="tooltip">
                    info
                </mat-icon>
            </label>
        </li>
    </ul>

    <hr>

    <div class="button-container">
        <button class="primary-btn-transparent outlined-btn"
                mat-stroked-button
                (click)="handleSaveClick()">
            Save
        </button>
        <button class="filled-btn"
                mat-stroked-button
                (click)="handleNextClick()">
            Next
        </button>
    </div>
</div>
