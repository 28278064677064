import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './shared-components/header/header.component';
import { FooterComponent } from './shared-components/footer/footer.component';
import { MainSliderComponent } from '../sliders/main-slider/main-slider.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ServicesSliderComponent } from '../sliders/services-slider/services-slider.component';
import { MainAddedComponent } from '../sliders/main-added/main-added.component';
import { CreditCardDirective } from 'src/app/modules/shared/directives/credit-card.directive';
import { TrimDirective } from 'src/app/modules/shared/directives/trim.directive';
import { MatMenuModule } from '@angular/material/menu';
import { SwiperModule } from 'ngx-swiper-wrapper-v-13';
import { ViewsCounterPipe } from './pipe/views-counter/views-counter.pipe';
import { HtmlTextExtractorPipe } from './pipe/html-text-extractor/html-text-extractor.pipe';
import { PaginatorComponent } from './shared-components/paginator/paginator.component';
import { SortingArticlesByComponent } from './shared-components/knowledge-hub/sorting-articles-by/sorting-articles-by.component';
import { ArticlesFilterButtonComponent } from './shared-components/knowledge-hub/articles-filter-button/articles-filter-button.component';
import { FiltersModalComponent } from './shared-components/knowledge-hub/filters-modal/filters-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { StringCheckboxesArrayComponent } from './shared-components/string-checkboxes-array/string-checkboxes-array.component';
import { FilterButtonsComponent } from './shared-components/knowledge-hub/filter-buttons/filter-buttons.component';
import { BackButtonComponent } from './shared-components/back-button/back-button.component';
import { ArticleComponent } from './shared-components/knowledge-hub/article/article.component';
import { ProviderInfoComponent } from './shared-components/provider-info/provider-info.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShareArticleComponent } from './shared-components/knowledge-hub/article/share-article/share-article.component';
import { SocialMediaIconComponent } from './shared-components/social-media-icon/social-media-icon.component';
import { ArticleDatePipe } from './pipe/article-date/article-date.pipe';
import { StringSectionsArrayComponent } from './shared-components/string-sections-array/string-sections-array.component';
import { MultipleCheckboxesArrayComponent } from './shared-components/articles-public/multiple-checkboxes-array/multiple-checkboxes-array.component';
import { RelatedArticlesComponent } from './shared-components/knowledge-hub/related-articles/related-articles.component';
import { RelatedArticleItemComponent } from './shared-components/knowledge-hub/related-articles/related-article-item/related-article-item.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        MainSliderComponent,
        ServicesSliderComponent,
        MainAddedComponent,
        CreditCardDirective,
        TrimDirective,
        ViewsCounterPipe,
        HtmlTextExtractorPipe,
        PaginatorComponent,
        SortingArticlesByComponent,
        ArticlesFilterButtonComponent,
        FiltersModalComponent,
        StringCheckboxesArrayComponent,
        FilterButtonsComponent,
        BackButtonComponent,
        ArticleComponent,
        ProviderInfoComponent,
        ShareArticleComponent,
        SocialMediaIconComponent,
        ArticleDatePipe,
        StringSectionsArrayComponent,
        MultipleCheckboxesArrayComponent,
        RelatedArticlesComponent,
        RelatedArticleItemComponent,
    ],
  exports: [
    FooterComponent,
    MainSliderComponent,
    HeaderComponent,
    ServicesSliderComponent,
    MainAddedComponent,
    CreditCardDirective,
    TrimDirective,
    CommonModule,
    ViewsCounterPipe,
    HtmlTextExtractorPipe,
    PaginatorComponent,
    ArticlesFilterButtonComponent,
    SortingArticlesByComponent,
    StringCheckboxesArrayComponent,
    FilterButtonsComponent,
    BackButtonComponent,
    ArticleComponent,
    ArticleDatePipe,
    StringSectionsArrayComponent,
    MultipleCheckboxesArrayComponent,
  ],
    imports: [
        CommonModule,
        MatMenuModule,
        RouterModule,
        FormsModule,
        SwiperModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatTooltipModule,
    ],
})
export class SharedModule {}
