import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper-v-13';

declare var Swiper: any;

@Component({
    selector: 'app-services-slider',
    templateUrl: './services-slider.component.html',
    styleUrls: ['./services-slider.component.scss']
})
export class ServicesSliderComponent implements AfterViewInit {
  @Input() slides: any[];
  @Input() type: any;

    public config: SwiperConfigInterface = {
        loop: true,
        slidesPerView: 4,
        speed: 700,
        updateOnWindowResize: true,
        navigation: {
          nextEl: '.swiper-button-next3',
          prevEl: '.swiper-button-prev3',
        },
    };

  constructor(private router: Router) {
    }

    ngAfterViewInit(): void {
      const swiperContainer = document.querySelector(
        '.swiper-container.swiper-container3'
      );
      const swiper = new Swiper(swiperContainer, this.config);
      swiper.init();
      swiper.update();
    }

    search(item) {
        this.router.navigate(['/search-service-providers'], {queryParams: {search: item}}).then();
    }
}
