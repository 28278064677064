import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericConfirmationData } from '../../shared/models/popups/generic-confirmation-data';

@Component({
    selector: 'app-generic-confirmation',
    templateUrl: './generic-confirmation.component.html',
    styleUrls: ['./generic-confirmation.component.scss'],
})
export class GenericConfirmationComponent {
    constructor(
        private dialogRef: MatDialogRef<GenericConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: GenericConfirmationData
    ) {}

    public confirm(): void {
        this.dialogRef.close(true);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
