import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { first } from 'rxjs/operators';

export interface IOption {
  name: string;
  hot?: boolean;
}

@Component({
  selector: 'app-string-sections-array',
  templateUrl: './string-sections-array.component.html',
  styleUrls: ['./string-sections-array.component.scss']
})
export class StringSectionsArrayComponent implements OnInit {
  @ViewChildren('optionRef') sections: QueryList<
      ElementRef<HTMLElement>
  >;
  @ViewChild('defaultOptionRef')
  defaultOptionRef: ElementRef<HTMLElement>;

  private optionsSubject = new BehaviorSubject<IOption[]>([]);
  @Input({ required: true }) set options(value: IOption[]) {
    this.optionsSubject.next(value);
  }

  @Output() changeSelected = new EventEmitter<string[]>();

  get options() {
    return this.optionsSubject.value;
  }

  @Input() defaultOption = 'Any';

  title = 'Show more';
  count = 0;

  toggleListSize() {
    if (this.title === 'Show more') {
      this.count = this.options.length;
      this.title = 'Show less';
    } else {
      this.count = this.options.length / 4;
      this.title = 'Show more';
    }
  }

  @Input() set value(val: string[]) {
    if (!val.length) {
      timer(10)
          .pipe(first())
          .subscribe(() => {
            this.defaultOptionRef.nativeElement.classList.add('selected');
          });
    }
    this._value = [...val];
  }

  ngOnInit() {
    this.optionsSubject.subscribe(options => {
      if (!options) return;
      this.count = Math.ceil(options.length / 4);
    });
  }

  get value() {
    return this._value;
  }

  private _value: string[] = [];

  onChange(e: Event) {
    const target = e.target as HTMLElement;
    console.log(target);
    if (target.classList.contains('selected')) {
      this.value = this.value.filter(el => el !== target.innerText);
      if (!this.value.length) {
        this.defaultOptionRef.nativeElement.classList.add('selected');
      }
    } else {
      this.value.push(target.innerText);
      this.defaultOptionRef.nativeElement.classList.remove('selected');
    }
    this.changeSelected.emit(this.value);
  }

  protected handleDefaultSection(e: Event) {
    const target = e.target as HTMLElement;
    target.classList.add('selected');
    this.sections.forEach(el => (el.nativeElement.classList.remove('selected')));
    this.value = [];
    this.changeSelected.emit(this.value);
  }
}
