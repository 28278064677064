<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Oh ow!</p>

  <p class="paragraph2">
    You may clicked on an action button or link that has already expired or already used.
  </p>

  <button class="primary-btn" type="submit" (click)="onNoClick()">
    OK!
  </button>
</div>
