import { Component, OnInit, ViewChild } from '@angular/core';
import { first, switchMap } from 'rxjs/operators';
import { IProvider } from '../../interfaces/provider';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthDataService } from '../../services/auth-data.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotAvilableComponent } from '../popups/not-avilable/not-avilable.component';
import { IProject, ProjectStatusEnum, RequirementsTypes } from '../../interfaces/project';
import { Observable, of, Subscription } from 'rxjs';
import { CongartsBriefComponent } from '../popups/congarts-brief/congarts-brief.component';
import { StripeCardComponent, StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { Appearance, StripeCardElementChangeEvent, StripeElementsOptions } from '@stripe/stripe-js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserFullInfo } from '../../interfaces/auth-interfaces';
import { AreYouSureComponent } from '../popups/are-you-sure/are-you-sure.component';
import { AnimatedLoaderService } from '../animated-loader/service/animated-loader.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent  implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authDS: AuthDataService,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    private stripeService: StripeService,
    private ngxService: NgxUiLoaderService,
    private animatedLoader: AnimatedLoaderService,
  ) {
    this.briefId = this.route.snapshot.queryParamMap.get('briefId');
    this.onlyDocument = this.router.getCurrentNavigation()?.extras?.state?.onlyDocument;
    this.onlyProviders = this.router.getCurrentNavigation()?.extras?.state?.onlyProviders;
    this.docRequirements = this.router.getCurrentNavigation()?.extras?.state?.docRequirements;
  }

  get providersForDistributionTo() {
    return this.briefInfo.status === ProjectStatusEnum.pending
      ? this.briefInfo.projectBriefWillBeDistributedTo : this.briefInfo.projectBriefDistributedTo;
  }
  onlyDocument = false;
  docRequirements = false;
  onlyProviders = false;
  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent;
  public activePlan: number;
  public activePlanStr: number;
  public availableProviders: number;
  public filteredProviders: any[] = [];
  public firstStep = true;
  public secondStep = false;
  public listView = false;
  public checkoutStep = false;
  public payStep = false;
  public isFormValid = false;
  public selectedProviders: string[] = [];
  public selectedProvidersData: any[] = [];
  isRedirect = true;
  showSpinner = true;
  listArray: any[] = [];
  sum = 40;
  skip = 0;
  direction = '';
  search = '';
  public provider: IProvider;
  public subscription = new Subscription();
  public briefInfo: IProject;
  public countProviders: number;
  private matchedProviders: [];
  public user!: UserFullInfo;
  private briefId: string;
  private customerId: string;

  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  public submitted = false;
  public isStartVerify = false;
  public stripe;
  public elementsOptions: StripeElementsOptions = {
    locale: 'auto',
    appearance: {
      theme: 'flat'
    }
  };

  appearance: Appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      colorText: '#31325F',
      fontLineHeight: '20px',
      fontSizeBase: '18px',
      colorTextSecondary: '#A8B4D1',
      colorTextPlaceholder: '#A8B4D1',
    },
  };
  public stripeFrom: UntypedFormGroup;

  protected readonly Math = Math;
  skipActivePlan = false;

  protected readonly RequirementsTypes = RequirementsTypes;
  ngOnInit(): void {
    this.ngxService.start();
    this.getUser()
      .pipe(first())
      .subscribe((user) => (this.user = user));

    this.getBrief();

    this.stripeFrom = this.fb.group({
      name: ['', [Validators.required]],
    });

    if (this.onlyDocument || this.docRequirements) {
      this.firstStep = false;
      this.secondStep = false;
      this.checkoutStep = true;
      this.skipActivePlan = true;
    }
  }

  public availableProvidersCount(): number {
    return this.countProviders - this.briefInfo.projectBriefDistributedTo.length;
  }

  private getBrief() {
    this.subscription.add(this.authDS
      .getBriefById(this.briefId)
      .pipe(first())
      .subscribe((data: { project: IProject, providers: [], countProviders: any }) => {
        this.briefInfo = data.project;
        this.matchedProviders = data.providers;
        this.countProviders = data.countProviders;
        this.ngxService.stop();
      }));
  }

  private getUser(): Observable<UserFullInfo> {
    return this.authDS.user$.pipe(
      switchMap((user) => {
        if (user) { return of(user); }
        else { return this.authDS.getUserProfile(); }
      })
    );
  }

  onScrollDown(ev: any) {
    if (this.countProviders <= this.sum) { return; }
    this.skip += 40;
    this.appendItems();

    this.direction = 'scroll down';
  }

  onScrollUp(ev: any) {
    if (this.sum <= 40) { return; }
    this.skip -= 40;
    this.prependItems();

    this.direction = 'scroll up';
  }

  appendItems() {
    this.addItems('push');
  }

  prependItems() {
    this.addItems('unshift');
  }

  addItems(method: string) {
    if (method === 'push') {
      this.doSearch();
    }
  }

  public doSearch(isSearch?) {
    const filters: any = {
      filterOptions: {
        country: this.briefInfo.country.map(i => i.name),
        specializationAreas: this.briefInfo.specialization,
      },
      sortingOptions: { sortType: 0 },
      usedProvidersIds: this.providersForDistributionTo.map(p => p._id),
    };

    if (this.search?.length) {
      filters.filterOptions.name = this.search.toLowerCase();
    }

    this.authDS
      .doSearch(filters, this.skip, this.sum)
      .pipe(first())
      .subscribe((res: {data: IProvider[], totalCount: number}) => {
        const providers: IProvider[] = res?.data ? res.data : [];
        if (isSearch) {
          this.listArray = [];
        }
        const availableProviders: IProvider[] = providers.filter(mp => this.briefInfo.projectBriefDistributedTo.every(dp => dp._id !== mp._id));
        this.listArray.push(...availableProviders);
        this.filteredProviders.push(...availableProviders);
        this.showSpinner = false;
      });
  }

  public openCompanyPopup(): void {
    this.search = '';
    this.listArray = [];
    this.filteredProviders = [];
    if (
      (this.activePlan === 0 && this.countProviders < 5) ||
      (this.activePlan === 1 && this.countProviders < 10) ||
      (this.activePlan === 2 && this.countProviders < 15)
    ) {
      this.dialog.open(NotAvilableComponent, {
        autoFocus: false,
        width: '500px',
      });
    } else if (
      (this.activePlan === 0 && this.countProviders >= 5) ||
      (this.activePlan === 1 && this.countProviders >= 10) ||
      (this.activePlan === 2 && this.countProviders >= 15)
    ) {
      this.checkoutStep = true;
      this.secondStep = false;
    }
  }

  onNoClick(): void {
    if (
      this.briefInfo.typeRequirements !== RequirementsTypes.non
      && !this.briefInfo.isDocumentPurchased
      && !this.onlyProviders
    ) {
      this.firstStep = false;
      this.onlyDocument = true;
      this.checkoutStep = true;
      return;
    }
    if (this.isRedirect) {
      this.router.navigate(
          ['/my-briefs/preview'],
          {
            queryParams: {
              briefId: this.briefId
            }}
      ).then();
    }
  }

  onCancelClick(): void {
    this.dialog.open(AreYouSureComponent, {
      autoFocus: false,
      width: '500px',
      disableClose: true,
      data: {
        briefId: this.briefId,
        message: this.docRequirements
          ? 'You\'re about to cancel your request to purchase requirements document.'
          : 'You\'re about to cancel your request to purchase additional services.',
        docRequirements: this.docRequirements
      }
    });
  }

  private isNotAvailable(plan: number, countProviders: number) {
    if (plan === 0 && countProviders < 5) {
      return true;
    } else if (plan === 1 && countProviders < 10) {
      return true;
    } else if (plan === 2 && countProviders < 15) {
      return true;
    } else {
      return false;
    }
  }

  public choosePlan(plan: number, planStr: number) {
    if (this.isNotAvailable(plan, this.countProviders - this.briefInfo.projectBriefDistributedTo.length)) {
      this.dialog.open(NotAvilableComponent, {
        autoFocus: false,
        width: '500px',
      });
    } else {
      this.activePlan = plan;
      this.activePlanStr = planStr;
    }
  }

  public changeStep(state: boolean) {
    this.search = '';
    if (this.countProviders > this.activePlanStr && (this.countProviders - this.briefInfo.projectBriefDistributedTo.length) < this.activePlanStr) {
      this.dialog.open(NotAvilableComponent, {
        autoFocus: false,
        width: '500px',
      });
    } else {
      this.firstStep = !state;
      this.secondStep = state;
      this.skip = 0;
      this.sum = 40;
      this.listArray = [];
      this.filteredProviders = [];
      this.selectedProvidersData = [];
      this.selectedProviders = [];
      if (state) {
        this.appendItems();
      }
    }
  }

  public showList(state: boolean) {
    this.listView = state;
    this.search = '';
  }

  getProviderLocation(provider): string {
    if (provider?.headquarterLocationModel) {
      return `${(provider?.city?.name ? provider.city.name + ', ' : '')}${provider?.country.name}`;
    } else {
      return provider?.headquarterLocation;
    }
  }

  public selectProvider(id): void {
    if (this.selectedProviders.includes(id)) {
      this.selectedProviders = this.selectedProviders.filter(p => p !== id);
      this.selectedProvidersData = this.selectedProvidersData.filter(p => p._id !== id);
    } else if (this.selectedProviders.length < this.activePlanStr) {
      this.selectedProviders.push(id);
      this.selectedProvidersData.push(this.listArray.find(i => i._id === id));
    }
  }

  public filterValue(value) {
    if (value.target.value.length < 3 && value.target.value.length !== 0) { return; }
    this.skip = 0;
    this.sum = 40;
    this.listArray = [];
    this.search = value.target.value;
    this.doSearch(true);
  }

  public returnToPlane() {
    if (this.onlyDocument) {
      this.router.navigate(
          ['/my-briefs/preview'],
          {
            queryParams: {
              briefId: this.briefId
            }}
      );
    } else if (this.docRequirements) {
      this.router.navigate(['/create-doc-requirements'],
        {
          queryParams: {
            docId: this.briefId
          }
        });
    } else {
      this.checkoutStep = false;
      this.changeStep(true);
    }
  }

  public returnToCheckout() {
    this.payStep = false;
    this.checkoutStep = true;
    this.secondStep = false;
    this.firstStep = false;
  }

  private chargeCustomerPrice() {
    if (this.onlyDocument) {
      return this.authDS.chargeCustomerOnlyDoc();
    } else if (this.docRequirements) {
      return this.authDS.chargeCustomerDocRequirements();
    } else if (this.briefInfo.typeRequirements !== RequirementsTypes.non && !this.onlyProviders) {
      switch (this.activePlan) {
        case 0:
          return this.authDS.chargeCustomerFirstPriceAndDoc();
        case 1:
          return this.authDS.chargeCustomerSecondPriceAndDoc();
        case 2:
          return this.authDS.chargeCustomerThirdPriceAndDoc();
        default:
          return of(null);
      }
    } else {
      switch (this.activePlan) {
        case 0:
          return this.authDS.chargeCustomerFirstPrice();
        case 1:
          return this.authDS.chargeCustomerSecondPrice();
        case 2:
          return this.authDS.chargeCustomerThirdPrice();
        default:
          return of(null);
      }
    }
  }

  public showLoader() {
    if (this.docRequirements) {
      this.animatedLoader.showLoadingDoc(this.briefId);
    } else {
      this.animatedLoader.showLoadingBrief(this.briefId);
    }
    this.animatedLoader.onEnd.subscribe(() => {
      if (this.docRequirements) {
        this.router.navigate(
          ['/my-briefs'],
          {
            queryParams: {
              activeTab: 'Closed'
            }}
        );
      } else {
        this.router.navigate(
          ['/my-briefs/preview'],
          {
            queryParams: {
              briefId: this.briefId
            }}
        );
      }
    });
  }

  public afterSuccessPayment() {
    if (this.docRequirements) {
      return this.authDS.editDoc({
        isDocumentPurchased: true,
        done: true,
        _id: this.briefId,
      });
    } else {
      return this.authDS.sendBrief({
        payment: this.activePlan,
        projectId: this.briefInfo._id,
        providerIds: this.selectedProviders,
        isPaidDoc: this.onlyProviders ? !this.onlyProviders : (this.briefInfo?.typeRequirements !== RequirementsTypes.non && !this.briefInfo?.isDocumentPurchased)
          || this.onlyDocument
      });
    }
  }

  public submit() {
    this.submitted = true;

    if (this.stripeFrom.invalid) { return; }

    this.ngxService.start();
    this.stripeService
      .confirmPayment({
        elements: this.paymentElement.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: this.stripeFrom.get('name').value
            },
          },
        },
        redirect: 'if_required',
      }).subscribe({
      next: (result) => {
        this.submitted = false;
        if (result.error) {
          this.ngxService.stop();
          this.snackbar.open(result.error.message, '', {
            duration: 2500,
          });
        } else if (result.paymentIntent.status === 'succeeded') {

          this.afterSuccessPayment().subscribe((res) => {
            const afterData = {
              paymentIntentId: result.paymentIntent.id,
              customerId: this.customerId
            };
            this.ngxService.stop();
            this.authDS.afterCustomerPaymentSuccess(afterData);
            const dialog = this.dialog.open(CongartsBriefComponent, {
              autoFocus: false,
              width: '500px',
              disableClose: true,
              data: {
                message: this.docRequirements
                  ? 'Your payment was successful. \n' + 'To get your document, check your project briefs area.'
                  : 'Your payment was successful.\n' + 'To view the status, check your project briefs area.'
              }
            });

            dialog.afterClosed().subscribe(() => {
              this.submitted = false;
              this.isStartVerify = false;
              this.checkoutStep = false;
              this.secondStep = false;
              this.firstStep = true;
              this.payStep = false;
              this.activePlan = undefined;
              this.activePlanStr = undefined;
              if (
                ((this.briefInfo?.typeRequirements !== RequirementsTypes.non || this.onlyDocument)
                && !this.briefInfo?.isDocumentPurchased
                && !this.onlyProviders) || this.docRequirements
              ) {
                this.showLoader();
              } else {
                this.router.navigate(
                  ['/my-briefs/preview'],
                  {
                    queryParams: {
                      briefId: this.briefId
                    }}
                );
              }
            });
          });
        }
      },
      error: (err) => {
        this.ngxService.stop();
        this.snackbar.open(err.error.message, '', {
          duration: 2500,
        });
        this.submitted = false;
        this.isStartVerify = false;
      },
    });
  }

  async createSecret() {
    this.authDS
      .createCustomer({ name: this.user.user.name })
      .pipe(
        switchMap(() => this.chargeCustomerPrice()),
        first()
      ).subscribe((data: {clientSecret: string, customerId: string}) => {
      this.elementsOptions.clientSecret = data.clientSecret;
      this.customerId = data.customerId;
      this.payStep = true;
      this.checkoutStep = false;
      this.secondStep = false;
      this.firstStep = false;
    });
  }

  onChange(event: StripeCardElementChangeEvent) {
    if (event.complete) {
      this.isFormValid = event.complete;
    } else {
      this.isFormValid = event.complete;
    }
  }

  calculatePrice() {
    if (this.onlyDocument || this.docRequirements) {
      return '9.99';
    } else if (this.briefInfo?.typeRequirements !== RequirementsTypes.non && !this.briefInfo?.isDocumentPurchased && !this.onlyProviders) {
      return (+(this.activePlan === 0 ? '9.99' : this.activePlan === 1 ? '14.99' : '19.99') + 9.99).toFixed(2);
    } else {
      return this.activePlan === 0 ? '9.99' : this.activePlan === 1 ? '14.99' : '19.99';
    }
  }
}
