import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/internal/operators/tap';
import {AuthDataService} from './auth-data.service';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  constructor(private authDS: AuthDataService) {
  }

  /**
   * Append token to request headers
   * Log Request and Response to console
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const headers = {};
    const headersKeys = req.headers.keys();
    headersKeys.forEach((key) => {
      headers[key] = req.headers.get(key);
    });

    const token = localStorage.getItem('token');

    if (!req.url.includes('wirefreethought')) {
      if (!!token) {
        headers['Authorization'] = 'Bearer ' + token;
      }
    }

    req = req.clone({setHeaders: headers});

    return next.handle(req).pipe(this.log(req));
  }

  private log(req: HttpRequest<any>): any {
    return tap(
      (res: HttpEvent<any>) => {
      },
      (res) => {
        if (res && res.status === 401) {
          this.authDS.forceLogout();
        }
      }
    );
  }

}
