import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { AuthDataService } from '../../../services/auth-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRoleEnum } from '../../../enums/user-role.enum';
import {Observable, of} from 'rxjs';
import {first, map, skipWhile, startWith} from 'rxjs/operators';
import countries from './../../../files/countries.json';
import {MatSnackBar} from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatStepper } from '@angular/material/stepper';
import { LoginComponent } from '../../popups/login/login.component';
import {MatDialog} from '@angular/material/dialog';
import { ILogin } from '../../../interfaces/auth-interfaces';
import {projectConfig} from '../../../../projectConfig';
import {ICity, ICountry, IIndustry, IState} from '../../../interfaces/project';

// TODO: needs to refactor globally

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, AfterViewInit {
  @Output() sendLoginEmitter: EventEmitter<ILogin> = new EventEmitter<ILogin>();
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('stepper') stepper: MatStepper;

  selectedFile: File;
  public activeForm: UntypedFormGroup;
  public imgForm: UntypedFormGroup = this.fb.group({
    img: ['', []],
  });
  public selectedCountryId = '';
  public selectedStateId = '';
  public selectedCityId = '';
  public statusImg = false;
  public captchaResolved = false;
  public selectCountry;
  public selectCountryChangePhone;
  public countryList = countries;

  public role;
  public roleId;

  public filteredCountry: Observable<ICountry[]>;
  public filteredState: Observable<IState[]>;
  public filteredCity: Observable<ICity[]>;
  public filteredIndustries: Observable<IIndustry[]>;
  public filteredCountryChangePhoneNumber: Observable<string[]>;
  public firstStepForm: UntypedFormGroup;
  public firstStepForm1: UntypedFormGroup;
  public secondStepForm: UntypedFormGroup;
  public changeForm: UntypedFormGroup;
  public roles = UserRoleEnum;
  public isLinear = true;
  public verifyCode: string;
  public statusAgree = false;
  public isStartVerify = false;
  public step = 1;
  public submitted = false;
  public typePassword = 'password';
  public typeConfirmPassword = 'password';
  showPassword = false;
  showConfirmPassword = false;
  public showOtpComponent = true;
  public config = projectConfig.otpConfig;
  public getCountries = [];
  public getStates = [];
  public getCities = [];
  public getIndustries = [];
  public nextStepClicked = false;
  public hideField = false;
  public hideState = false;

  public readonly companySizes = [
    { value: 0, viewValue: '1 - 5' },
    { value: 1, viewValue: '5 - 10' },
    { value: 2, viewValue: '10 - 20' },
    { value: 3, viewValue: '20 - 50' },
    { value: 4, viewValue: '50 - 100' },
    { value: 5, viewValue: '100 - 300' },
    { value: 6, viewValue: '300 - 500' },
    { value: 7, viewValue: '500 - above' },
  ];

  public readonly industries = [
    { value: 0, viewValue: 'Dev' },
    { value: 1, viewValue: 'Advertisement' },
    { value: 2, viewValue: 'Marketing' },
    { value: 3, viewValue: 'Social media ' },
    { value: 4, viewValue: 'IoT' },
    { value: 5, viewValue: 'Research' },
    { value: 6, viewValue: '3D printing' },
  ];

  public url;
  public msg = '';

  constructor(
    private fb: UntypedFormBuilder,
    private authDS: AuthDataService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
  ) {}

  selectFile(event) {
    if (!event.target.files[0] || event.target.files[0].length === 0) {
      return;
    }
    this.selectedFile = event.target.files[0];
    this.activeForm.get('img').setValue(this.selectedFile ? this.selectedFile.name : '');
    this.statusImg = true;
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.msg = '';
      this.url = reader.result;
    };
  }

  onUpload(link) {
    if (!this.selectedFile) {
      return;
    }

    const uploadData = new FormData();
    uploadData.append('fileLogo', this.selectedFile, this.selectedFile.name);
    // TODO: move it into separate service
    this.httpClient
      .post(`${environment.apiUrl}${link}/uploadLogo/`, uploadData, {
        reportProgress: true,
        observe: 'events',
      })
      .toPromise()
      .then(() => {
        this.router.navigate(['/']).then();
      });
  }

  private _filterCountries(value: any, isChangePhone: boolean = false): any[] {
    const filterValue = this._normalizeValue(typeof value === 'string' ? value : value?.name);
    for (let i = 0; i < this.getCountries.length; i++) {
      if (this._normalizeValue(this.getCountries[i].name) === filterValue) {
        if (isChangePhone) {
          this.selectCountryChangePhone = this.getCountries[i].phoneCode.split(' ').join('');
        } else {
          this.selectCountry = this.getCountries[i].phoneCode.split(' ').join('');
        }
      }
    }

    for (let z = 0; z < this.getCountries.length; z++) {
      if (filterValue === this._normalizeValue(this.getCountries[z]?.name) ) {
        this.selectedCountryId = this.getCountries[z]?._id;
      }
    }

    return this.getCountries.filter((country) => {
        return this._normalizeValue(country.name).includes(filterValue);
      }

    );
  }

  private _filterState(value: any): any[] {
    const filterValue = this._normalizeValue(value);

    return this.getStates.filter((state) => {
        return this._normalizeValue(state.name).includes(filterValue);
      }
    );
  }

  private _filterCity(value: any): any[] {
    const filterValue = this._normalizeValue(value);

    return this.getCities.filter((city) => {
        return this._normalizeValue(city.name).includes(filterValue);
      }
    );
  }

  private _filterIndustries(value: any): any[] {
    const filterValue = this._normalizeValue(value);

    return this.getIndustries.filter((industry) => {
        return this._normalizeValue(industry.name).includes(filterValue);
      }
    );
  }

  private _normalizeValue(value: string): string {
    return value && value.toLowerCase().replace(/\s/g, '');
  }

  ngOnInit() {
    this.ngxService.start();
    this.role = this.route.snapshot.queryParamMap.get('role');
    this.roleId = Number(this.route.snapshot.queryParamMap.get('id'));
    this.createFirstStepForm();
    this.createSecondStepForm();
    this.createChangeForm();
    this.authDS
      .getCountries()
      .pipe(first())
      .subscribe((res: any) => {
        this.getCountries = res.filter(c => c != null);
        this.countryList = res.map(i => i && i.name).filter(c => c != null).sort((a, b) => a.localeCompare(b));

        this.filteredCountry = this.activeForm?.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterCountries(value.country))
        );

        this.filteredCountryChangePhoneNumber = this.changeForm?.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterCountries(value.country, true))
        );

      });
    this.authDS
      .getIndustries()
      .pipe(first())
      .subscribe((res: IIndustry[]) => {
        this.getIndustries = res;

        this.filteredIndustries = this.secondStepForm?.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterIndustries(String(value.industry)))
        );
      });

    this.filteredState = this.secondStepForm?.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterState(String(value.state)))
    );

    this.filteredCity = this.secondStepForm?.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterCity(String(value.city)))
    );

    this.ngxService.stop();
  }

  ngAfterViewInit(): void {
    const input = document.getElementById('phoneNumber');
    input.addEventListener('mousewheel', function(event) {
      this.blur();
    });
  }

  getDisplayName(data: ICountry | IState | ICity | string | IIndustry) {
    if (!data) {
      return null;
    }
    return typeof data === 'string' ? data : data.name;
  }

  displayFn(size: {value: number, viewValue: string}): string {
    return size && size.viewValue ? size.viewValue : '';
  }

  public onCountrySelected(data: ICountry) {
    this.authDS.getStates(data._id) .pipe(first())
      .subscribe((res: any) => {
        if (res.length) {
          this.getStates = res;
          this.hideField = false;
          this.hideState = false;
          this.secondStepForm.controls.state.setValidators([Validators.required]);
          this.secondStepForm.controls.state.updateValueAndValidity();
        } else {
          this.secondStepForm.controls.state.clearValidators();
          this.secondStepForm.controls.state.updateValueAndValidity();
          this.hideField = true;
          this.hideState = true;
          this.secondStepForm.controls.headOfficeAddress.enable();
        }
      });

    const filterValue = this._normalizeValue(data.name);

    if (typeof data === 'string' && !(data as undefined as string).length) {
      this.secondStepForm.controls.state.setValue('');
      this.secondStepForm.controls.state.disable();
      this.secondStepForm.controls.city.disable();
      this.secondStepForm.controls.city.setValue('');
      this.secondStepForm.controls.headOfficeAddress.setValue('');
      this.secondStepForm.controls.headOfficeAddress.disable();
    }
    if (!filterValue) {return; }
    for (let i = 0; i < this.getCountries.length; i++) {
      if (filterValue === this._normalizeValue(this.getCountries[i]?.name)) {
        this.selectCountry = this.getCountries[i].phoneCode.split(' ').join('');
      }
    }

    for (let z = 0; z < this.getCountries.length; z++) {
      if (filterValue === this._normalizeValue(this.getCountries[z]?.name)) {
        this.selectedCountryId = this.getCountries[z]?._id;
        this.secondStepForm.controls.state.enable();
        this.secondStepForm.controls.state.setValue('');
      }
    }
  }

  public onStateSelected(data: ICity) {
    const filterValue = this._normalizeValue(data.name);
    if (typeof data === 'string' && !(data as undefined as string).length) {
      this.secondStepForm.controls.city.disable();
      this.secondStepForm.controls.city.setValue('');
      this.secondStepForm.controls.headOfficeAddress.setValue('');
      this.secondStepForm.controls.headOfficeAddress.disable();
    }
    if (!filterValue) {return; }
    this.authDS.getCities(data._id)
      .pipe()
      .subscribe(res => {
        if (res.length) {
          this.getCities = res;
          this.hideField = false;
          this.secondStepForm.controls.city.setValidators([Validators.required]);
          this.secondStepForm.controls.city.updateValueAndValidity();
          this.secondStepForm.controls.city.enable();
          this.secondStepForm.controls.headOfficeAddress.setValue('');
          this.secondStepForm.controls.headOfficeAddress.disable();
        } else {
          this.secondStepForm.controls.city.clearValidators();
          this.secondStepForm.controls.city.updateValueAndValidity();
          this.hideField = true;
          this.secondStepForm.controls.headOfficeAddress.enable();
        }
      });

    for (let z = 0; z < this.getStates.length; z++) {
      if (filterValue === this._normalizeValue(this.getStates[z]?.name) ) {
        this.selectedStateId = this.getStates[z]?._id;
      }
    }
  }

  public onCitySelected(data: ICity) {
    const filterValue = this._normalizeValue(data.name);
    if (typeof data === 'string' && !(data as undefined as string).length) {
      this.secondStepForm.controls.headOfficeAddress.disable();
      this.secondStepForm.controls.headOfficeAddress.setValue('');
    }
    if (!filterValue) {return; }

    for (let z = 0; z < this.getCities.length; z++) {
      if (filterValue === this._normalizeValue(this.getCities[z]?.name) ) {
        this.selectedCityId = this.getCities[z]?._id;
      }
    }
    this.secondStepForm.controls.headOfficeAddress.setValue('');
    this.secondStepForm.controls.headOfficeAddress.enable();
  }

  public onOtpChange(otp) {
    this.verifyCode = otp;
  }

  public setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  public changePasswordType() {
    if (this.typePassword === 'password') {
      this.typePassword = 'text';
      this.showPassword = true;
    } else {
      this.typePassword = 'password';
      this.showPassword = false;
    }
  }

  public changeConfirmPasswordType() {
    if (this.typeConfirmPassword === 'password') {
      this.typeConfirmPassword = 'text';
      this.showConfirmPassword = true;
    } else {
      this.typeConfirmPassword = 'password';
      this.showConfirmPassword = false;
    }
  }

  public onConfigChange() {
    this.showOtpComponent = false;
    this.verifyCode = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

  public nextStep(form: UntypedFormGroup, skipAgree: boolean = false) {
    if (skipAgree) {
      this.statusAgree = true;
    }
    this.activeForm = form;
    if (!this.statusAgree && this.activeForm.invalid) {
      return;
    }

    if (this.nextStepClicked) {
      return;
    }
    this.submitted = true;
    if (!this.captchaResolved) { return; }
    if (typeof form.get('country').value === 'string' && form.get('country').value.length) {
      form.get('country').setErrors({invalidValue: true});
      return;
    } else if (form.get('country')?.value.name) {
      form.get('country').clearValidators();
      // this.secondStepForm.value.country = this.selectedCountryId;
    }

    const checkEmail = {
      email: this.activeForm.controls.email.value.toLowerCase(),
    };
    this.nextStepClicked = true;

    this.authDS
      .checkEmail(checkEmail)
      .pipe(first())
      .subscribe((res: any) => {
        if (this.activeForm.invalid) {
          this.nextStepClicked = false;
          return;
        }
        if (res.status === 'Hey! There\'s already an account with this email address. Login or use another email address to sign up.') {
          this.nextStepClicked = false;
          this.snackbar.open(
            'Hey! There\'s already an account with this email address. Login or use another email address to sign up.',
            '',
            {
              panelClass: 'about',
              duration: 2500,
            }
          );
        }
        if (
          this.activeForm.valid &&
          this.activeForm.controls.password.value === this.activeForm.controls.confirmPassword.value &&
          this.role !== 'individual' &&
          res?.status !== 'Hey! There\'s already an account with this email address. Login or use another email address to sign up.'
        ) {
          this.step++;
          this.nextStepClicked = false;
          this.submitted = false;
          this.secondStepForm.controls.country;
          this.stepper.next();
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        } else if (
          this.activeForm.valid &&
          this.activeForm.controls.password.value === this.activeForm.controls.confirmPassword.value &&
          this.role === 'individual' &&
          res?.status !== 'Hey! There\'s already an account with this email address. Login or use another email address to sign up.'
        ) {
          this.submitted = false;
          this.sendVerifyPhoneNumber();
          this.stepper.next();
          this.nextStepClicked = false;
        }
      });
    // stop here if form is invalid
  }

  public sendVerifyPhoneNumber(skipStep: boolean = false, isFromChangeNumber: boolean = false) {
    this.submitted = true;

    if (isFromChangeNumber && !this.changeForm.valid || this.isStartVerify) {
      return;
    }
    if (typeof this.secondStepForm.get('state').value === 'string' && this.secondStepForm.get('state').value.length && this.role !== 'individual') {
      this.secondStepForm.get('state').setErrors({invalidValue: true});
      return;
    }

    if (typeof this.secondStepForm.get('city').value === 'string' && this.secondStepForm.get('city').value.length && this.role !== 'individual') {
      this.secondStepForm.get('city').setErrors({invalidValue: true});
      return;
    }

    if (typeof this.secondStepForm.get('industry')?.value === 'string' && this.secondStepForm.get('industry').value.length && this.role !== 'individual') {
      this.secondStepForm.get('industry').setErrors({invalidValue: true});
      return;
    }

    if ((this.secondStepForm.valid && this.role !== 'individual') || this.role === 'individual') {
      if (this.step <= 2 && this.role === 'individual' && !skipStep) {
        this.step++;
      } else if (this.step <= 3 && this.role !== 'individual' && !skipStep) {
        this.step++;
      }

      if (this.role !== 'individual') {
        localStorage.setItem('userName', this.firstStepForm.value.name);
      }

      if (this.role === 'individual') {
        localStorage.setItem('userName', this.firstStepForm1.value.firstName);
        localStorage.setItem('lastName', this.firstStepForm1.value.lastName);
      }

      let phone;
      if (this.activeForm.get('phoneNumber').value.toString().startsWith(Number(this.selectCountry).toString())) {
        phone = this.activeForm.get('phoneNumber').value.toString();
      } else {
        phone = this.selectCountry + this.activeForm.get('phoneNumber').value.toString();
      }

      if (!this.changeForm.valid) {
        this.activeForm.get('phoneNumber').setValue(+phone);
      }

      if (this.changeForm.value.phoneNumber && !skipStep) {
        const phone = this.selectCountryChangePhone + this.changeForm.get('phoneNumber').value.toString();
        this.activeForm.get('phoneNumber').setValue(+phone);
        this.step--;
      }

      this.isStartVerify = true;
      this.authDS
        .sendVerifyPhoneNumber(this.activeForm.value.phoneNumber)
        .pipe(first())
        .subscribe(() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          if (isFromChangeNumber) {
            this.returnToVerification();
          }
          this.snackbar.open('We texted you the 4-digit code to your mobile.', '', {
            duration: 2500,
          });
          this.submitted = false;
          if (!isFromChangeNumber && this.role !== 'individual' && !skipStep) {
            this.stepper.next();
          }
          this.isStartVerify = false;
        });
    }
  }

  public changePhoneNumber() {
    const input: HTMLInputElement = document.querySelector('#changeCountry');
    input.value = '';
    this.changeForm.reset();
    this.selectCountryChangePhone = null;
    this.submitted = false;
    this.stepper.next();
  }

  public returnToVerification() {
    this.submitted = false;
    this.stepper.previous();
  }

  checkCaptcha(captchaResponse: string) {
    this.captchaResolved = (captchaResponse && captchaResponse.length > 0) ? true : false;
  }

  public sendCode() {
    if (this.verifyCode?.length === 4 && this.activeForm.value.phoneNumber) {
      this.ngxService.start();
      const verifyData = {
        phone: this.activeForm.value.phoneNumber,
        phoneCode: this.verifyCode,
      };
      this.authDS
        .sendVerifyData(verifyData)
        .pipe(first())
        .subscribe(
          (res) => {
            this.activeForm.value.isPhoneVerified = res;
            this.secondStepForm.value.country = this.selectedCountryId;
            this.secondStepForm.value.companyName = this.firstStepForm.get('name').value;
            delete this.activeForm.value.confirmPassword;
            delete this.activeForm.value.name;
            delete this.activeForm.value.firstName;
            delete this.activeForm.value.lastName;
            delete this.activeForm.value.country;
            delete this.activeForm.value.img;
            this.authDS
              .signUpFirstStep(this.activeForm.value)
              .pipe(first())
              .subscribe((user) => {
                if (this.role !== 'individual') {
                  this.secondStepForm.value.companyName = localStorage.getItem('userName');
                  this.secondStepForm.value.email = this.firstStepForm.get('email').value.toLowerCase();
                  this.selectedStateId.length ? this.secondStepForm.value.state = this.selectedStateId : delete this.secondStepForm.value.state;
                  this.selectedCityId.length ? this.secondStepForm.value.city = this.selectedCityId : delete this.secondStepForm.value.city;
                  this.secondStepForm.get('industry').value._id.length ? this.secondStepForm.value.industry = this.secondStepForm.get('industry').value._id : delete this.secondStepForm.value.industry;
                  this.authDS
                    .signUpSecondStep(this.secondStepForm.value, this.roles[this.roleId])
                    .pipe(first())
                    .subscribe((result) => {
                      if (this.statusImg) {
                        this.onUpload(this.roles[this.roleId]);
                      } else {
                        this.ngxService.stop();
                        this.router.navigate(['/']).then();
                      }
                    });
                } else {
                  const individual = {
                    firstName: localStorage.getItem('userName'),
                    lastName: localStorage.getItem('lastName'),
                    country: this.selectedCountryId,
                  };
                  this.authDS
                    .signUpSecondStep(individual, this.roles[this.roleId])
                    .pipe(first())
                    .subscribe((result) => {
                      if (this.statusImg) {
                        this.onUpload(this.roles[this.roleId]);
                      } else {
                        this.ngxService.stop();
                        this.router.navigate(['/']).then();
                      }
                    });
                }
              });
          },
          (error1) => {
            this.snackbar.open(error1.error.message, '', {
              duration: 2500,
            });
            this.ngxService.stop();
          }
        );
    } else {
      this.snackbar.open('Enter the 4-digit code we sent to your mobile number.', '', {
        duration: 2500,
      });
    }
  }

  public signUp() {
    this.ngxService.start();
    this.secondStepForm.value.country = this.selectedCountryId;
    this.secondStepForm.value.companyName = this.firstStepForm.get('name').value;
    this.activeForm.value.isPhoneVerified = false;
    this.activeForm.value.email = this.activeForm.value.email.toLowerCase();
    delete this.activeForm.value.confirmPassword;
    delete this.activeForm.value.name;
    delete this.activeForm.value.firstName;
    delete this.activeForm.value.lastName;
    delete this.activeForm.value.country;
    delete this.activeForm.value.img;
    this.authDS
      .signUpFirstStep(this.activeForm.value)
      .pipe(first())
      .subscribe(
        (user) => {
          if (this.role !== 'individual') {
            this.secondStepForm.value.companyName = localStorage.getItem('userName');
            this.selectedStateId.length ? this.secondStepForm.value.state = this.selectedStateId : delete this.secondStepForm.value.state;
            this.selectedCityId.length ? this.secondStepForm.value.city = this.selectedCityId : delete this.secondStepForm.value.city;
            this.secondStepForm.get('industry').value._id.length ? this.secondStepForm.value.industry = this.secondStepForm.get('industry').value._id : delete this.secondStepForm.value.industry;
            this.secondStepForm.value.email = this.firstStepForm.get('email').value.toLowerCase();
            this.authDS
              .signUpSecondStep(this.secondStepForm.value, this.roles[this.roleId])
              .pipe(first())
              .subscribe((result) => {
                if (this.statusImg) {
                  this.onUpload(this.roles[this.roleId]);
                } else {
                  this.ngxService.stop();
                  this.router.navigate(['/']).then();
                }
              });
          } else {
            const individual = {
              firstName: localStorage.getItem('userName'),
              lastName: localStorage.getItem('lastName'),
              country: this.selectedCountryId,
            };
            this.authDS
              .signUpSecondStep(individual, this.roles[this.roleId])
              .pipe(first())
              .subscribe(
                (result) => {
                  if (this.statusImg) {
                    this.onUpload(this.roles[this.roleId]);
                  } else {
                    this.ngxService.stop();
                    this.router.navigate(['/']).then();
                  }
                },
                (error) => {
                  this.snackbar.open(error.error.message, '', {
                    duration: 2500,
                  });
                  this.ngxService.stop();
                }
              );
          }
        },
        (error) => {
          this.snackbar.open(error.error.message, '', {
            duration: 2500,
          });
          this.ngxService.stop();
        }
      );
  }

  public agree() {
    this.statusAgree = !this.statusAgree;
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  numericOnly(event): boolean {
    const patt = /^([0-9])$/;
    const result = patt.test(event.key);
    return result;
  }

  // create forms
  public createFirstStepForm() {
    this.firstStepForm1 = this.fb.group(
      {
        img: ['', []],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        country: ['', [Validators.required]],
        isPhoneVerified: [''],
        phoneNumber: ['', [Validators.required, Validators.minLength(6)]],
        email: ['', [Validators.required, Validators.email, Validators.pattern('^[aA-zZ0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
        role: [this.roleId],
        recaptchaReactive: [null]
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
    this.firstStepForm = this.fb.group(
      {
        img: ['', []],
        name: ['', [Validators.required]],
        country: ['', [Validators.required]],
        isPhoneVerified: [''],
        phoneNumber: ['', [Validators.required, Validators.minLength(6)]],
        email: ['', [Validators.required, Validators.email, Validators.pattern('^[aA-zZ0-9._%+-]+@[aA-zZ0-9.-]+\\.[aA-zZ]{2,4}$')]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
        role: [this.roleId],
        recaptchaReactive: [null]
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
    if (this.role !== 'individual') {
      this.activeForm = this.firstStepForm;
    } else if (this.role === 'individual') {
      this.activeForm = this.firstStepForm1;
    }
  }

  public createSecondStepForm() {
    this.secondStepForm = this.fb.group({
      companyName: '',
      // logoUrl: ['https://api.interior.ru/media/images/erico_nazaro/00_bred_pitt.jpg'],
      headOfficeAddress: [{
        value: null,
        disabled: true
      }],
      companyPhoneNumber: ['', [Validators.required, Validators.minLength(6)]],
      companyWebSite: [
        '',
        [
          Validators.required,
          Validators.pattern(
           '^(?:http(s)?:\\/\\/)[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$'
          ),
        ],
      ],
      country: ['', []],
      city: [{
        value: null,
        disabled: true
      }, [Validators.required]],
      state: ['', [Validators.required]],
      primaryContact: ['', [Validators.required]],
      email: [''],
      companySize: ['', [Validators.required]],
      industry: ['', [Validators.required]],
    });
  }

  public createChangeForm() {
    this.changeForm = this.fb.group({
      country: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
    });
  }

  public openLoginPopup(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      autoFocus: false,
      height: '600px',
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        skipWhile((result) => !result)
      )
      .subscribe((result) => this.sendLoginEmitter.emit(result));
  }
}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
