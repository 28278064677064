import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Article, ArticleCategory, ArticleStatus, IArticleCount, RelatedArticleList } from '../models/knowledge-hub/article';
import { PaginationResponse } from '../models/response/pagination-response';
import { FilterArticleRequest } from '../models/knowledge-hub/filter-articles-request';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  public getCategories() {
    return this.http.get<ArticleCategory[]>(
      `${this.apiUrl}article/getAllArticleCategories`,
    );
  }

  public getTopCategories() {
    return this.http.get<ArticleCategory[]>(
      `${this.apiUrl}article/article-categories-with-top`,
    );
  }

  public create(body: Article | { userId: string; providerId: string }) {
    return this.http.post<Article>(`${this.apiUrl}article/create`, body);
  }

  public uploadCoverPhoto(id: string, data: FormData) {
    return this.http.post(
      `${this.apiUrl}article/uploadCoverPhotoArticle/${id}`,
      data,
    );
  }

  public uploadAuthorPhoto(id: string, data: FormData) {
    return this.http.post(
      `${this.apiUrl}article/uploadAuthorPhotoArticle/${id}`,
      data,
    );
  }

  public getArticle(id: string) {
    return this.http.get<Article>(`${this.apiUrl}article/getById/${id}`);
  }

  public edit(body: Partial<Article>) {
    return this.http.patch<Article>(`${this.apiUrl}article/saveChanges`, body);
  }

  public getArticlesByStatus(
    status: ArticleStatus,
    limit: number,
    skip: number,
  ) {
    const params = new HttpParams().set('limit', limit).set('skip', skip);
    return this.http.get<PaginationResponse<Article>>(
      `${this.apiUrl}article/getAllByStatus/${status}`,
      { params },
    );
  }

  public getFilteredArticles(
    limit: number,
    skip: number,
    options: FilterArticleRequest,
  ) {
    const params = new HttpParams().set('limit', limit).set('skip', skip);
    return this.http.post<PaginationResponse<Article>>(
      `${this.apiUrl}article/filteredArticles`,
      options,
      { params },
    );
  }

  public deleteArticle(id: string) {
    return this.http.delete(`${this.apiUrl}article/deleteArticle/${id}`);
  }

  public getProvidersAndArticlesCount() {
    return this.http.get<IArticleCount[]>(
      `${this.apiUrl}provider/list-with-article-count`,
    );
  }

  public getArticleWithRelatedArticles(id: string) {
    return this.http.get<RelatedArticleList>(`${this.apiUrl}article/articleWithRelatedArticles/${id}`);
  }

  public addViewToArticle(id: string) {
    return this.http.post(`${this.apiUrl}article/viewed/${id}`, {});
  }

  public addSharedToArticle(id: string) {
    return this.http.post(`${this.apiUrl}article/shared/${id}`, {});
  }
}
