<app-header></app-header>
<div class="wrapper">
  <div class="back" (click)="onBackClick()">
    <img src="./../../../assets/arrow.svg" alt="img" />
    <p>{{ backBtnTitle }}</p>
  </div>

  <div class="profile-container">
    <div class="profile-background">
      <img class="logo" [src]="coverImage" alt="logo">
    </div>

    <div class="profile-header">
      <div class="profile-header-logo">
        <img [src]="providerLogo" alt="logo">
      </div>
      <div class="profile-header-title">
        <h4>{{ provider.companyName }}</h4>
        <p>
          {{ provider?.city?.name ? provider.city.name + ',' : '' }}
          {{ provider.country?.name }}
          <img [src]="provider?.country?.flag" alt="flag">
        </p>
        <div class="reputation">
          REPUTATION:
          <div class="stars">
            <div class="rate">
              <input
                disabled
                [checked]="provider?.clutchScore === 5"
                type="radio"
                id="rating10"
                name="rating"
                value="10"
              />
              <label for="rating10" title="5 stars"></label>
              <input
                disabled
                [checked]="
                      provider?.clutchScore >= 4.5 && provider?.clutchScore != 5
                    "
                type="radio"
                id="rating9"
                name="rating"
                value="9"
              />
              <label class="half" for="rating9" title="4 1/2 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore < 4.5 && provider?.clutchScore >= 4"
                type="radio"
                id="rating8"
                name="rating"
                value="8"
              />
              <label for="rating8" title="4 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 3.5 && provider?.clutchScore < 4"
                type="radio"
                id="rating7"
                name="rating"
                value="7"
              />
              <label class="half" for="rating7" title="3 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 3.5 && provider?.clutchScore >= 3"
                type="radio"
                id="rating6"
                name="rating"
                value="6"
              />
              <label for="rating6" title="3 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 2.5 && provider?.clutchScore < 3"
                type="radio"
                id="rating5"
                name="rating"
                value="5"
              />
              <label class="half" for="rating5" title="2 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 2.5 && provider?.clutchScore >= 2"
                type="radio"
                id="rating4"
                name="rating"
                value="4"
              />
              <label for="rating4" title="2 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 1.5 && provider?.clutchScore < 2"
                type="radio"
                id="rating3"
                name="rating"
                value="3"
              />
              <label class="half" for="rating3" title="1 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 1.5 && provider?.clutchScore >= 1"
                type="radio"
                id="rating2"
                name="rating"
                value="2"
              />
              <label for="rating2" title="1 star"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 0.5 && provider?.clutchScore < 1"
                type="radio"
                id="rating1"
                name="rating"
                value="1"
              />
              <label class="half" for="rating1" title="1/2 star"></label>
            </div>
            <p class="paragraph2">{{ provider?.clutchScore }}</p>
            <mat-icon matTooltip="This represents this company's overall reputation based on quality, schedule cost and customer satisfaction. These data points are gathered from various reputable sources such as past clients and credible review sites to help you with your vendor selection process.">info</mat-icon>
          </div>
        </div>
      </div>
      <div class="profile-header-buttons">
        <a [href]="provider.companyWebSite" (click)="onWebsiteClick($event)">Visit Website</a>
        <ng-container *ngIf="activeAccountType$ | async as activeAccountType">
          <div
            *ngIf="activeAccountType === UserTypesAccount.Client || user?.userProvider._id === provider._id">
            <button
              [disabled]="(isOwnProfile && activeAccountType === UserTypesAccount.Provider) || (isProjectLimitPerMonth)"
              (click)="onSendBriefClick()"
              class="primary-btn"
            >
              Send Brief
            </button>
            <ng-container *ngIf="activeAccountType === UserTypesAccount.Client">
              <p *ngIf="(sentBriefs$ | async) as sentBriefs" class="briefs-amount">
                You have {{ sentBriefs }} {{ sentBriefs > 1 ? 'briefs' : 'brief' }} in progress
              </p>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="profile-details">
      <div class="profile-details-item">
        <div class="d-flex">
          <div class="detail-title">About</div>
          <div class="info">{{ provider.about }}</div>
        </div>
      </div>
      <div class="profile-details-item" *ngIf="isSocialMediaLinks">
        <div class="d-flex">
          <div class="detail-title">Online Presence</div>
          <div class="info">
            <div class="socialMediaLinks">
              <a *ngIf="provider.socialMediaLinks.LinkedIn" [href]="provider.socialMediaLinks.LinkedIn" target="_blank">
                <img src="assets/onlinePresenceIcons/LinkedIn.svg" alt="linkedIn">
              </a>
              <a *ngIf="provider.socialMediaLinks.YouTube" [href]="provider.socialMediaLinks.YouTube" target="_blank">
                <img src="assets/onlinePresenceIcons/Youtube.svg" alt="YouTube">
              </a>
              <a *ngIf="provider.socialMediaLinks.Facebook" [href]="provider.socialMediaLinks.Facebook" target="_blank">
                <img src="assets/onlinePresenceIcons/Facebook.svg" alt="Facebook">
              </a>
              <a *ngIf="provider.socialMediaLinks.Instagram" [href]="provider.socialMediaLinks.Instagram" target="_blank">
                <img src="assets/onlinePresenceIcons/Instagram.svg" alt="Instagram">
              </a>
              <a *ngIf="provider.socialMediaLinks.X" [href]="provider.socialMediaLinks.X" target="_blank">
                <img src="assets/onlinePresenceIcons/X.svg" alt="X">
              </a>
              <a *ngIf="provider.socialMediaLinks.TikTok" [href]="provider.socialMediaLinks.TikTok" target="_blank">
                <img src="assets/onlinePresenceIcons/TikTok.svg" alt="X">
              </a>
              <ng-container *ngIf="provider.socialMediaLinks.other.length">
                <a
                  *ngFor="let other of provider.socialMediaLinks.other"
                  [href]="provider.socialMediaLinks.other"
                  target="_blank"
                >
                  <img src="assets/onlinePresenceIcons/other.svg" alt="other">
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-details-item">
        <div class="d-flex">
          <div class="detail-title">Industry</div>
          <div class="info">{{ provider.industry.title }}</div>
        </div>

        <div class="d-flex">
          <div class="detail-title">Company Size</div>
          <div class="info">{{ provider.companySize }}</div>
        </div>

        <div class="d-flex">
          <div class="detail-title">Hourly Rate (Avg)</div>
          <div class="info">{{ provider.hourlyRate }}</div>
        </div>

        <div class="d-flex">
          <div class="detail-title">Min. Project Amount</div>
          <div class="info">${{ provider.minProjectAmount.toLocaleString('en') }} USD</div>
        </div>
      </div>
      <div class="profile-details-item">
        <div class="d-flex">
          <div class="detail-title">Specialization Areas</div>
          <div class="info">
            <div class="tiles">
              <p *ngFor="let spec of provider.specializationAreas">{{ spec }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-details-item">
        <div class="d-flex">
          <div class="detail-title">Industry Specializations</div>
          <div class="info">
            <div class="tiles">
              <p *ngFor="let spec of provider.industrySpecialization">{{ spec }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-details-item">
        <div class="d-flex">
          <div class="detail-title">Skill Areas</div>
          <div class="info">
            <div class="tiles">
              <p *ngFor="let skill of provider.skillAreas">{{ skill }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="profile-details-item">
        <div class="d-flex">
          <div class="detail-title">Headquarter Location</div>
          <div class="info">
            <app-google-map [location]="provider.headquarterLocationModel" />
          </div>
        </div>
      </div>
      <div class="profile-details-item" *ngIf="provider.otherLocationModels.length">
        <div class="d-flex">
          <div class="detail-title">Other Locations</div>
          <div class="info">
            <div class="maps-container">
              <app-google-map
                *ngFor="let location of provider.otherLocationModels"
                [location]="location"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
