import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  public dialogRef = inject(MatDialogRef<MessageComponent>);
  public dialog = inject(MatDialog);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
  }

  click(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
