import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthDataService } from '../../services/auth-data.service';
import { FiltersPopupComponent } from '../popups/filters/filters-popup.component';
import { IProvider } from '../../interfaces/provider';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { first } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ICountry } from '../../interfaces/project';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../create-brief/create-brief.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup = this.fb.group({
    rating2: this.fb.control(4),
  });
  public providers: IProvider[] = [];
  public totalCount = 0;
  public filters;
  public search = '';
  public sortList = [
    { value: 0, viewValue: 'Name' },
    { value: 1, viewValue: 'Country' },
    { value: 2, viewValue: 'Reputation' },
  ];

  public specializationAreas = [];
  public countries = [];
  public selectedSortItem = 0;
  public specializationTag = '';
  sum = 20;
  direction = '';
  skip = 0;
  limit = 20;

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authDS: AuthDataService,
    private ngxService: NgxUiLoaderService,
  ) {
  }

  ngOnInit(): void {
    this.search = this.route.snapshot.queryParams.search;
    this.filters = JSON.parse(localStorage.getItem('filters'));

    this.doSearch(this.search, this.filters);

    forkJoin([
      this.authDS.getCountries(),
      this.authDS.getSpecialization(),
      this.authDS.getProvidersCountry(),
    ])
      .pipe(
        // mergeMap((data) =>
        //   // this.authDS.doSearch().pipe(map((providers) => [...data, providers]))
        // ),
        first(),
      )
      .subscribe(([countries, specializations, providersCountry]) => {
        this.countries = this.getCountriesByProviders(countries, providersCountry);
        this.specializationAreas = this.getSortedSpecs(specializations);
      });
  }

  onScrollDown(ev: any) {
    if (this.providers.length < this.sum) {
      return;
    }
    this.skip += 20;
    this.appendItems();

    this.direction = 'scroll down';
  }

  onScrollUp(ev: any) {
    if (this.sum <= 20) {
      return;
    }
    this.prependItems();

    this.direction = 'scroll up';
  }

  appendItems() {
    this.addItems('push');
  }

  prependItems() {
    this.addItems('unshift');
  }

  addItems(method: string) {
    if (method === 'push') {
      this.doSearch(this.search, this.filters);
    }
  }

  ngOnDestroy(): void {
    this.authDS.clearFilters();
    localStorage.removeItem('filters');
  }

  public selectSortItem(value) {
    if (value.source.value !== this.selectedSortItem && value.isUserInput) {
      this.selectedSortItem = value.source.value;
      this.providers = [];
      this.skip = 0;
      this.sum = 20;
      this.doSearch(this.search, this.filters, value.source.value);
    }
  }

  public doSearch(item, filter?, sortOf?, headerSearch?) {
    if (headerSearch) {
      this.providers = [];
      this.skip = 0;
      this.sum = 20;
    }
    this.search = item;
    localStorage.setItem('search', item);
    if (this.skip === 0) {
      this.ngxService.start();
    }

    const filters: any = {
      filterOptions: {
        search: item,
      },
      sortingOptions: this.selectedSortItem === 2 ? {
        sortType: this.selectedSortItem,
        ascending: false,
      } : { sortType: this.selectedSortItem, ascending: true },
    };

    if (filter) {
      const {
        countries,
        specializationAreas,
      } = filter;
      filters.filterOptions = {
        search: item,
        country: countries,
        specializationAreas,
      };
    }

    this.authDS
      .doSearch(filters, this.skip, this.sum)
      .pipe(first())
      .subscribe((res: { data: IProvider[], totalCount: number }) => {
        const providers: IProvider[] = res?.data ? res.data : [];
        if (!res && !this.skip) {
          this.totalCount = res?.totalCount || 0;
        } else if (this.skip && res) {
          this.totalCount = res?.totalCount || 0;
        } else if (res) {
          this.totalCount = res?.totalCount || 0;
        }
        this.providers.push(...providers);
        if (this.skip === 0) {
          this.ngxService.stop();
        }
      });
  }

  public openFilters() {
    if (!this.specializationAreas.length || !this.countries.length) {
      return;
    }
    const dialogRef = this.dialog.open(FiltersPopupComponent, {
      data: {
        countries: this.countries,
        specializationAreas: this.specializationAreas,
      },
      autoFocus: false,
      height: '90%',
      width: '40%',
    });

    dialogRef.afterClosed().pipe(first()).subscribe((filter) => {
      if (filter) {
        this.filters = filter;
        this.providers = [];
        this.skip = 0;
        this.sum = 20;
        this.totalCount = 0;
        localStorage.setItem('filters', JSON.stringify(filter));
        this.doSearch(this.search, filter);
      }
    });
  }

  public checkSpecialization(data) {
    let acceptTag = '';
    data.map((value) => {
      if (value?.length <= 15 && !this.specializationTag) {
        this.specializationTag = value;
        acceptTag = value;
      }
    });
    this.specializationTag = '';
    return acceptTag;
  }

  public removeFilter(array: any, i?, secondArray?) {
    array.length ? array?.splice(i, 1) : (array.to = 0);
    secondArray?.splice(i, 1);
    this.authDS.setFilters(this.filters);
    localStorage.setItem('filters', JSON.stringify(this.filters));
    this.totalCount = 0;
    this.providers = [];
    this.skip = 0;
    this.sum = 20;
    this.doSearch(this.search, this.filters);
  }

  public clearFilters() {
    if (
      this.filters?.industry?.length > 0 ||
      this.filters?.countries?.length > 0 ||
      this.filters?.specializationAreas?.length > 0 ||
      this.filters?.price?.to > 0
    ) {
      this.filters.industry = [];
      this.filters.countries = [];
      this.filters.price = { from: 0, to: 0 };
      this.filters.specializationAreas = [];
      this.providers = [];
      this.skip = 0;
      this.sum = 20;
      this.totalCount = 0;
      this.doSearch(this.search);
      localStorage.removeItem('filters');
    }
  }

  private getCountriesByProviders(
    countries: ICountry[],
    providersCountry: string[],
  ): ICountry[] {
    return countries
      .reduce<ICountry[]>((acc, country) => {
        const providersMatchCountry = providersCountry.filter(
          (providerCountry) => country._id === providerCountry,
        );
        if (providersMatchCountry.length) {
          acc.push({
            ...country,
            providersCount: providersMatchCountry.length,
          });
        }
        return acc;
      }, [])
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }

  getSortedSpecs(specs: string[]): string[] {
    const sortedSpecs = specs.sort((a, b) =>
      a?.trim().toLowerCase().localeCompare(b?.trim().toLowerCase()),
    ).filter(i => i.toLowerCase() !== 'any');
    return [...sortedSpecs];
  }
}
