import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
    @Input() currentPage: number;
    @Input() totalPages: number;
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    get isLastPage(): boolean {
        return this.currentPage === this.totalPages;
    }

    get isFirstPage(): boolean {
        return this.currentPage === 1;
    }

    constructor() {}

    nextPage() {
        if (!this.isLastPage) {
            this.pageChange.emit(this.currentPage + 1);
        }
    }

    prevPage() {
        if (!this.isFirstPage) {
            this.pageChange.emit(this.currentPage - 1);
        }
    }

    setPage(pageNumber: number) {
        if (pageNumber >= 1 && pageNumber <= this.totalPages) {
            this.pageChange.emit(pageNumber);
        }
    }

    getPageRange(): number[] {
        const range = [];
        const maxVisiblePages = 5;
        let start = Math.max(
            1,
            this.currentPage - Math.floor(maxVisiblePages / 2)
        );
        const end = Math.min(start + maxVisiblePages - 1, this.totalPages);

        if (end < maxVisiblePages) {
            start = Math.max(1, end - maxVisiblePages + 1);
        }

        // Add ellipsis if necessary
        if (start > 2) {
            range.push(1);
            range.push(-1);
        }

        if (start === 2) {
            range.push(1);
        }

        for (let i = start; i <= end; i++) {
            range.push(i);
        }

        if (end < this.totalPages - 1) {
            range.push(-1);
            range.push(this.totalPages);
        }

        if (end === this.totalPages - 1) {
            range.push(this.totalPages);
        }

        return range;
    }
}
