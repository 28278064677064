<app-header></app-header>
<section class="sign-up-page">
  <div class="container" *ngIf="!showProviders">
    <div
      class="back"
      (click)='back()'
    >
      <img src="./../../../assets/arrow.svg" alt=""/>
      <p>Back</p>
    </div>
    <form [formGroup]="briefForm" class="sign-up-page-content">
      <div class="content-left-side">
        <app-brief-stepper
          [briefTypeSend]="typeSendBrief"
          [percent]="percent"
          [currentStep]="currentStep"
          [answeredSteps]="answeredSteps"
          [showFeatureSubsteps]="showFeatureSubsteps"
          [featureSubstepsLabels]="featureSubstepsLabels"
          [answeredFeatureSubstep]="answeredFeatureSubstep"
          (moveToStep)="move($event)"
          (moveToSubstep)="moveSubstep($event)"
          [briefName]="brief.name || briefFrom?.name"
          [companyName]='companyName'
          (delete)="deleteBrief()"
        ></app-brief-stepper>
      </div>
      <div class="content-right-side">
        <mat-horizontal-stepper #stepper>
          <mat-step>
            <div class="content-right-side-box">
              <app-brief-step-header
                [title]="'The Basics'"
                [currentStep]="1"
                [totalSteps]="company ? 6 : 7"
              ></app-brief-step-header>
              <div class="about-form">
                <div class="text-field-input text-field-input-long">
                  <label class="sub-title" for="name">Give this project a name *</label>
                  <br />
                  <input
                    formControlName="name"
                    type="text"
                    placeholder='Give this project a name for tracking purposes'
                    trimDirective
                    [ngClass]="{
                      'error-input':
                        briefForm.controls['name'].invalid && submitted
                    }"
                  />
                  <div
                    class="error-text"
                    *ngIf="submitted && briefForm.controls['name'].errors"
                  >
                    <p *ngIf="briefForm.controls['name'].errors.required">
                      A project name is required to get started.
                    </p>
                    <p *ngIf="briefForm.controls['name'].errors.minlength">
                      To help identify this project, the name must be at least 5 characters in length.
                    </p>
                  </div>
                </div>
                <div class="list">
                  <h4>Not sure what to name your project?</h4>
                  <p class="paragraph2">Here are some examples:</p>
                  <br/>
                  <ul class="list-content">
                    <li class="paragraph2">Restaurant Mobile App</li>
                    <li class="paragraph2">Uber of Errands App</li>
                    <li class="paragraph2">Airbnb of Babysitters</li>
                  </ul>
                </div>
              </div>

              <div class="what-done">
                <p class="sub-title" style="margin-bottom: 10px">What would you like help with?
                  <mat-icon matTooltip="Select at least one option to help us find you matching service providers. ">info</mat-icon>
                </p>
                <div class="paragraph2">You can choose one or more</div>
                <div class="what-done-wrap">
                  <div
                    class="what-done-wrap-item"
                    *ngFor="let type of getTypes"
                    [class.chosen]="brief.types.includes(type?._id)"
                    (click)="checkNeed(type?._id)"
                  >
                    <img src="{{ type?.icon }}" alt="" />
                    <p class="paragraph">{{ type?.title }}</p>
                  </div>
                </div>
                <mat-error *ngIf="submitted && !brief.types.length">
                  Select at least one option.
                </mat-error>
              </div>
              <div class="button-box">
                <button
                  *ngIf="!briefFrom && !briefInfo"
                  class="primary-btn-transparent sign-up"
                  mat-stroked-button
                  [ngClass]="{
                    'sign-up-active':
                      this.brief.types.length > 0 &&
                      briefForm.controls.name.valid
                  }"
                  (click)="from1Step()"
                >
                  Next
                </button>
                <button
                  *ngIf="briefFrom || briefInfo"
                  class="primary-btn-transparent sign-up sign-up-active"
                  mat-stroked-button
                  [ngClass]="{
                    'sign-up-active':
                      this.brief.types.length > 0 &&
                      briefForm.controls.name.valid
                  }"
                  (click)="from1StepEdit()"
                >
                  Save and Proceed
                </button>
              </div>
            </div>
          </mat-step>
          <mat-step *ngIf="typeSendBrief === 'all'">
            <div class="content-right-side-box">
              <app-brief-step-header
                [title]="'Provider Preferences'"
                [currentStep]="2"
                [totalSteps]="7"
              ></app-brief-step-header>
              <div class="about-form">
                <div class="filters-content-box">
                  <p class="sub-title">Countries where service providers should be from *
                    <mat-icon
                      matTooltip="Select at least one country to help us match you with service providers in this region of the world. By default, we're sharing this brief with providers across the world!">
                      info
                    </mat-icon>
                  </p>

                  <ul class="filters-content-box-wrap filters-countries">
                    <li class="text-field checkbox">
                      <label>
                        <input
                          (click)="clearFiltersCountries()"
                          [checked]="
                            briefForm?.value?.checkArrayCountries?.includes(
                              null
                            ) || !briefForm?.value?.checkArrayCountries?.length
                          "
                          [value]="null"
                          type="checkbox"
                          class="myCheckbox countriesAny"
                        />
                      </label>
                      Any
                    </li>
                    <li
                      class="text-field checkbox"
                      *ngFor="let country of getCountries"
                    >
                      <label>
                        <input
                          (click)="clearFiltersCountriesAny()"
                          [value]="country?._id"
                          [checked]="
                            briefForm?.value?.checkArrayCountries?.includes(
                              country._id
                            )
                          "
                          (change)="
                            onCheckboxChange(
                              $event,
                              briefForm,
                              '',
                              'checkArrayCountries'
                            )
                          "
                          type="checkbox"
                          class="myCheckbox countries"
                        />
                      </label>
                      <span class="country-name">{{ country?.name }}<span class="counter"
                                                                          *ngIf="country?.providersCount">({{ country?.providersCount }}
                        )</span></span>

                    </li>
                  </ul>
                  <mat-error
                    *ngIf="!briefForm?.value?.checkArrayCountries?.length && submitted"
                  >
                    This field is required.
                  </mat-error>
                </div>
              </div>

              <div class="about-form-box">
                <form
                  [formGroup]="formSpecialize"
                  class="text-field-input text-field-input-long"
                >
                  <label class="sub-title">Industry expertise service providers should have *
                    <mat-icon
                      matTooltip="Select at least one area of expertise to help us match you with service providers who have this specialization.">
                      info
                    </mat-icon>
                  </label>
                  <br/>
                  <mat-form-field>
                    <mat-select
                      multiple
                      (closed)="restoreArray(false)"
                      formControlName="spec"
                      [class.error-input]="
                        formSpecialize.get('spec')?.invalid && submitted"
                    >
                      <mat-select-filter
                        #specFilter
                        placeholder="Select Specializations"
                        [array]="getSpecializations"
                        (filteredReturn)="filteredSpec = $event"
                      ></mat-select-filter>
                      <mat-option
                        (onSelectionChange)="getSpecializationValues($event)"
                        *ngFor="let item of filteredSpec"
                        [value]="item"
                      >{{ item }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="formSpecialize.get('spec')?.invalid && submitted"
                    >
                      This field is required.
                    </mat-error>
                  </mat-form-field>
                  <div
                    class="choose-items"
                    *ngIf="brief.specialization.length > 0"
                  >
                    <div
                      class="filters-wrap-item"
                      *ngFor="let filter of brief.specialization; let i = index"
                    >
                      <p class="paragraph2">{{ filter }}</p>
                      <b
                        (click)="
                          removeSpecialization(i)
                        "
                      >&#10005;</b
                      >
                    </div>
                    <p class="close" (click)="deselectAllSpec()">
                      &#10005;
                    </p>
                  </div>
                </form>
              </div>

              <div class="about-form-box">
                <form
                  [formGroup]="skillAreas"
                  class="text-field-input text-field-input-long"
                >
                  <label class="sub-title">Skills service providers should have *
                    <mat-icon
                      matTooltip="Select at least one option of the areas of expertise that you’re looking for in a service provider such as a particular coding language, framework or other skill.">
                      info
                    </mat-icon>
                  </label>
                  <br/>
                  <mat-form-field>
                    <mat-select
                      (closed)="restoreArray(true)"
                      multiple
                      formControlName="skills"
                      [class.error-input]="skillAreas.get('skills')?.invalid && submitted"
                    >
                      <mat-select-filter
                        #specSkills
                        placeholder="Select Skills"
                        [array]="getSkills"
                        (filteredReturn)="filteredSkills = $event"
                      ></mat-select-filter>
                      <mat-option
                        (onSelectionChange)="getValuesSkills($event)"
                        *ngFor="let item of filteredSkills"
                        [value]="item"
                      >{{ item }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="skillAreas.get('skills')?.invalid && submitted"
                    >
                      This field is required.
                    </mat-error>
                  </mat-form-field>
                  <div class="choose-items" *ngIf="brief.skillAreas.length > 0">
                    <div
                      class="filters-wrap-item"
                      *ngFor="let id of brief.skillAreas; let i = index"
                    >
                      <p class="paragraph2">{{ id }}</p>
                      <b
                        (click)="
                          removeFilterSkills(i)
                        "
                      >&#10005;</b
                      >
                    </div>
                    <p class="close" (click)="deleteAllSkills()">
                      &#10005;
                    </p>
                  </div>
                </form>
              </div>

              <form class="about-form" novalidate>
                <div class="filters-content-box">
                  <p class="sub-title">My Compensation Preference
                    <mat-icon
                      matTooltip="Select how you plan on compensating the service provider for their professional assistance.">
                      info
                    </mat-icon>
                  </p>
                  <ul class="filters-content-box-wrap accept filters-countries">
                    <li
                      class="text-field checkbox"
                      *ngFor="let compensation of getAccept"
                    >
                      <label>
                        <input
                          [checked]="
                            brief.compensations.includes(
                              compensation._id
                            )
                          "
                          type="checkbox"
                          class="myCheckbox"
                          [value]="compensation?._id"
                          (change)="compensationChanged(compensation?._id)"
                        />
                      </label>
                      {{ compensation.title }}
                    </li>
                  </ul>
                </div>
              </form>

              <div class="button-box">
                <button
                  class="primary-btn-transparent"
                  mat-stroked-button
                  (click)="saveBrief(1)"
                >
                  Save
                </button>
                <button
                  class="primary-btn-transparent sign-up sign-up-active"
                  mat-stroked-button
                  (click)="fromPref()"
                >
                  Next
                </button>
              </div>
            </div>
          </mat-step>
          <mat-step
            [stepControl]="
              briefForm.controls.describe &&
              briefForm.controls.problem &&
              briefForm.controls.examples
            "
          >
            <div class="content-right-side-box">
              <app-brief-step-header
                [title]="'Project Details'"
                [currentStep]="company ? 2 : 3"
                [totalSteps]="company ? 6 : 7"
              ></app-brief-step-header>
              <div
                class="about-form"
                formArrayName="details"
                *ngIf="getControls(briefForm.controls.details).length"
              >
                <div
                  class="text-field-input text-field-input-long"
                  *ngFor="
                    let detail of getControls(briefForm.controls.details);
                    let index = index
                  "
                >
                  <div [formGroupName]="index">
                    <label class="sub-title"
                    >{{ detail.get("title").value }} *
                      <mat-icon matTooltip="{{detail.get('description').value}}">info</mat-icon>
                    </label
                    >
                    <br/>
                    <textarea
                      *ngIf="detail.get('type').value === 0"
                      [maxlength]="descriptionFieldCharactersLimit"
                      class="description-input"
                      formControlName="value"
                      trimDirective
                      type="text"
                      required
                      [class.error-input]="submitted && detail.get('value')?.errors"
                    ></textarea>

                    <input
                      *ngIf="detail.get('type').value !== 0"
                      formControlName="value"
                      trimDirective
                      type="text"
                      [placeholder]="detail.get('description').value"
                      [class.error-input]="submitted && detail.get('value')?.errors"
                      required
                    />

                    <div
                      class="error-text detail-error"
                      *ngIf="submitted && detail.get('value')?.errors"
                    >
                      <p *ngIf="detail.get('value')?.errors['minlength'] || detail.get('value')?.errors?.required">
                        This description should be at least 50 characters in length.
                      </p>
                    </div>
                    <p *ngIf="detail.get('type').value === 0" class="max-words">
                      <span [class.danger]="detail.value?.value?.length < 50">{{ (detail.value?.value?.length | number: '1.') || 0 }}</span> / {{descriptionFieldCharactersLimit}} characters (min. 50)
                    </p>
                  </div>
                </div>
              </div>
              <div class="button-box">
                <button
                  class="primary-btn-transparent"
                  mat-stroked-button
                  (click)="saveBrief(2)"
                >
                  Save
                </button>
                <button
                  class="primary-btn-transparent sign-up"
                  mat-stroked-button
                  [ngClass]="{
                    'sign-up-active': briefForm.controls['details'].valid
                  }"
                  (click)="from2Step()"
                >
                  Next
                </button>
              </div>
            </div>
          </mat-step>
          <mat-step>
           <app-features-part-one
                   [features]="briefInfo?.featuresForDoc"
                   [allFeatures]="allFeatures"
                   (nextClick)="nextStepFromFeaturesPartOne($event)"
                   (saveClick)="saveFeaturesPartOne($event)"
                   [currentStep]="company ? 3 : 4"
                   [totalSteps]="company ? 6 : 7">
           </app-features-part-one>
          </mat-step>
          <ng-container formArrayName="dynamicSteps" *ngIf="showFeatureSubsteps">
            <mat-step [formGroupName]="i" *ngFor="let featureSection of dynamicSteps.controls; let i = index">
              <app-features-part-two
                [currentStep]="company ? 3 : 4"
                [totalSteps]="company ? 6 : 7"
                [allFeatures]="featureSectionByKey(featureSection.value[0])"
                [chosenFeatures]="featureSection.value[1].feats"
                [currentFeatureSubstep]="currentFeatureSubstep"
                [maxFeatureSubstep]="maxFeatureSubstep"
                (nextClick)="nextStepFromFeaturesPartTwo($event)"
                (saveClick)="saveFeaturesPartTwo($event)"
                (prevClick)="previousFromFeaturesPartTwo()">
              </app-features-part-two>
            </mat-step>
          </ng-container>
          <mat-step>
            <div class="content-right-side-box">
              <app-brief-step-header
                [title]="'Requirements'"
                [currentStep]="company ? 4 : 5"
                [totalSteps]="company ? 6 : 7"
              ></app-brief-step-header>
              <div class="about-form">
                <div class="filters-content-box">
                  <p class="sub-title">
                    Select the prep work you have done to assist with the project *
                  </p>
                  <mat-error
                    *ngIf="submitted && !briefForm?.value?.checkArrayPrep?.length && !otherThings"
                  >
                    Select at least one option.
                  </mat-error>
                  <ul class="filters-content-box-wrap prep">
                    <li
                      class="text-field checkbox"
                      *ngFor="let preparation of getPreps"
                    >
                      <label>
                        <input
                          type="checkbox"
                          class="myCheckbox"
                          [value]="preparation?._id"
                          [checked]="briefForm?.value?.checkArrayPrep?.includes(preparation?._id)"
                          (change)="preparationChanged(preparation?._id)"
                        />
                      </label>
                      {{ preparation.title }}
                    </li>
                    <li class="text-field checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="myCheckbox"
                          [value]="'otherThing'"
                          [checked]="otherThings"
                          (change)="otherThingsChecked()"
                        />
                      </label>
                      Other Things
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="otherThings"
                  class="text-field-input text-field-input-long"
                >
                  <label for="name" class="sub-title">
                    Describe the preparation work *
                  </label>
                  <br/>
                  <textarea
                    formControlName="things"
                    class="description-input"
                    id="name"
                    maxlength="5000"
                    trimDirective
                    type="text"
                    [class.error-input]="submitted && otherThings && !briefForm?.value?.things?.length"
                  ></textarea>
                  <div
                    class="error-text detail-error"
                    *ngIf="submitted && briefForm.get('things')?.errors"
                  >
                    <p
                      *ngIf="briefForm.get('things')?.errors?.required || briefForm.get('things')?.errors['minlength']">
                      This description should be at least 50 characters in length.
                    </p>
                  </div>
                  <p class="max-words">
                    <span
                      [class.danger]="briefForm.get('things')?.value?.length < 50">{{ (briefForm.get('things')?.value?.length | number: '1.') || 0 }}</span>
                    / 5,000 characters (min. 50)
                  </p>
                </div>
              </div>
              <div class="about-form">
                <div class="filters-content-box">
                  <p class="sub-title">
                    Select type of requirements & project assets you may need *
                  </p>
                  <mat-error
                    *ngIf="submitted && briefForm?.value?.typeRequirements === ''"
                  >
                    Select at least one option.
                  </mat-error>
                  <div class="documents-plans" [formGroup]="briefForm">
                    <div class="plans-item-wrapper">
                      <input
                        type="radio"
                        id="radio1"
                        name="typeRequirements"
                        [value]="RequirementsTypes.fnDoc"
                        formControlName="typeRequirements"
                      />
                      <label class="plans-item" for="radio1">
                        <img
                          src="./../../../../assets/functionalRequirementsIcon.svg"
                          alt=""
                        />
                        <p class="paragraph2">
                          Project Requirements
                        </p>
                        <p class="sum">9.99 USD</p>
                      </label>
                      <p class="preview-btn" role="button" (click)="openDocumentPreview()">
                        <img src="./../../../../assets/icons/eye.svg" alt="icon">
                        View an example
                      </p>
                    </div>
                    <div class="plans-item-wrapper">
                      <div class="plans-item">
                        <img
                          src="./../../../../assets/designIcon.svg"
                          alt=""
                        />
                        <p class="paragraph2">
                          Design/Wireframes
                        </p>
                        <p class="coming-soon">
                          <img src="./../../../../assets/icons/star-rate.svg" alt="icon">
                          Coming Soon!
                        </p>
                        <div class="unavailable"></div>
                      </div>
                    </div>
                    <div class="plans-item-wrapper">
                      <input
                        type="radio"
                        id="radio2"
                        name="typeRequirements"
                        [value]="RequirementsTypes.non"
                        formControlName="typeRequirements"
                      />
                      <label class="plans-item" for="radio2">
                        <img
                          src="./../../../../assets/notRequiredIcon.svg"
                          alt=""
                        />
                        <p class="paragraph2">Not required</p>
                      </label>
                    </div>
                  </div>

                </div>
              </div>
              <div class="button-box">
                <button
                  class="primary-btn-transparent"
                  mat-stroked-button
                  (click)="saveBrief(4)"
                >
                  Save
                </button>
                <button
                  class="primary-btn-transparent sign-up"
                  mat-stroked-button
                  [ngClass]="{
                    'sign-up-active':
                      (otherThings ? briefForm?.value?.things?.length >= 50 : briefForm.controls.checkArrayPrep.value.length > 0)
                      && briefForm?.value?.typeRequirements !== ''
                  }"
                  (click)="fromStep4()"
                >
                  Next
                </button>
              </div>
            </div>
          </mat-step>
          <mat-step>
            <div class="content-right-side-box">
              <app-brief-step-header
                [title]="'Budget and Timelines'"
                [currentStep]="company ? 5 : 6"
                [totalSteps]="company ? 6 : 7"
              ></app-brief-step-header>
              <div class="about-form">
                <div class="text-field-input date-field" (click)="picker.open()">
                  <label class="sub-title" for="name">
                    Project Brief End Date *
                    <mat-icon
                      matTooltip="Select an end date of when the project brief can be viewable by the service providers. The project brief will stop being visible to service providers on this date.">
                      info
                    </mat-icon>
                  </label>
                  <br/>
                  <input
                    formControlName="calendar"
                    id="date"
                    type="text"
                    disabled
                    placeholder="Select an end date"
                    [min]="minDate"
                    [matDatepicker]="picker"
                    [ngClass]="{
                      'error-input':
                      briefForm.get('calendar')?.invalid && submitted
                    }"
                  />
                  <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker disabled="false"></mat-datepicker>
                  <div class="error-text" *ngIf="!briefForm.get('calendar')?.value && submitted">
                    Enter a date of when the last day for service providers to express interest.
                  </div>
                </div>

                <div class="filters-content-box">
                  <p class="sub-title">Project Budget *
                    <mat-icon
                      matTooltip="Select your financial budget limit for the work you're trying to get done. A client's budget is valuable insight to service providers. ">
                      info
                    </mat-icon>
                  </p>
                  <div class="range-wrap">
                    <input
                      formControlName="range"
                      type="range"
                      class="range"
                      max="101"
                      #range
                    />
                    <output class="bubble" #bubble></output>
                  </div>
                </div>
                <div class="text-field-input text-field-input-long special-message-ct">
                  <label class="sub-title" for="message">
                    Additional Comments to Provider(s)
                  </label>
                  <br/>
                  <textarea
                    formControlName="message"
                    class="description-input"
                    id="message"
                    maxlength="5000"
                    trimDirective
                    type="text"
                    placeholder="Describe here anything else you'd like to share with service provider(s)..."
                  >
                  </textarea>
                  <p class="max-words">
                    <span>{{ (briefForm.controls['message'].value?.length | number: '1.') || 0 }}</span> / 5,000
                    characters
                  </p>
                </div>
                <div class="button-box">
                  <button
                    class="primary-btn-transparent"
                    mat-stroked-button
                    (click)="saveBrief(5)"
                  >
                    Save
                  </button>
                  <button
                    class="primary-btn-transparent sign-up"
                    mat-stroked-button
                    (click)="fromBudget()"
                    [ngClass]="{ 'sign-up-active': briefForm.get('calendar')?.valid }"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </mat-step>
          <mat-step>
            <div class="content-right-side-box">
              <app-brief-step-header
                [title]="'Project Brief Preview'"
                [currentStep]="company ? 6 : 7"
                [totalSteps]="company ? 6 : 7"
              ></app-brief-step-header>
              <section class="edit-boxes" *ngIf="briefInfo">
                <div class="edit-boxes-box-item-not-border">
                  <p class="paragraph2 silver">
                    Brief ID
                  </p>
                  <p class="paragraph2">
                    {{ briefInfo.briefId || '- - -'}}
                  </p>
                </div>
                <div class="edit-boxes-box">
                  <header>
                    <p class="paragraph2 silver">About the Project</p>
                    <span class="paragraph2" (click)="move(0)">Edit</span>
                  </header>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Project Name</p>
                    <p class="paragraph2">{{ briefInfo?.name }}</p>
                  </div>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Project Needs</p>
                    <p class="paragraph2">
                      <span
                        *ngFor="let item of briefInfo?.types; let i = index"
                      >{{ i > 0 ? ", " + item?.title : item?.title }}</span>
                    </p>
                  </div>
                </div>
                <div class="edit-boxes-box" *ngIf="typeSendBrief === 'all' && !companyName">
                  <header>
                    <p class="paragraph2 silver">Provider Preferences</p>
                    <span class="paragraph2" (click)="move(1)">Edit</span>
                  </header>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">
                      Location Preferences
                    </p>
                    <p *ngIf="briefInfo?.country?.length <= 0" class="paragraph2">
                      Any
                    </p>
                    <p *ngIf="briefInfo?.country?.length > 0" class="paragraph2">
                      <span
                        *ngFor="
                          let item of briefInfo?.country;
                          let index = index
                        "
                      >{{ index > 0 ? ", " + item.name : item.name }}</span>
                    </p>
                  </div>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">
                      Industry Expertise
                    </p>
                    <p
                      *ngIf="briefInfo?.specialization?.length <= 0"
                      class="paragraph2"
                    >
                      - - -
                    </p>
                    <p
                      *ngIf="briefInfo?.specialization?.length > 0"
                      class="paragraph2"
                    >
                      <span
                        *ngFor="
                          let item of briefInfo?.specialization;
                          let index = index
                        "
                      >{{ index > 0 ? ", " + item : item }}</span>
                    </p>
                  </div>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Skills Preferences</p>
                    <p
                      *ngIf="briefInfo?.skillAreas?.length <= 0"
                      class="paragraph2"
                    >
                      - - -
                    </p>
                    <p
                      *ngIf="briefInfo?.skillAreas?.length > 0"
                      class="paragraph2"
                    >
                      <span
                        *ngFor="
                          let item of briefInfo?.skillAreas;
                          let index = index
                        "
                      >{{ index > 0 ? ", " + item : item }}</span>
                    </p>
                  </div>

                  <div
                    class="edit-boxes-box-item"
                    *ngIf="briefInfo?.acceptCompensation?.length"
                  >
                    <p class="paragraph2 silver">Compensation Preference</p>
                    <p class="paragraph2">
                      <span
                        *ngFor="
                          let item of briefInfo?.acceptCompensation;
                          let index = index
                        "
                      >{{ index > 0 ? ", " + item.title : item.title }}</span>
                    </p>
                  </div>
                </div>
                <div class="edit-boxes-box">
                  <header>
                    <p class="paragraph2 silver">Project Details</p>
                    <span class="paragraph2" (click)="move(2)">Edit</span>
                  </header>
                  <div
                    *ngFor="
                      let item of briefInfo?.projectDetails;
                      let i = index
                    "
                  >
                    <div class="edit-boxes-box-item">
                      <p
                        class="paragraph2 silver">{{ item?.model.title === 'Describe this project' ? 'Project Description' : item?.model.title === 'What problems are you trying to solve?' ? 'Problem Statement' : item?.model.title }}</p>
                      <p class="paragraph2" [title]="item?.value">
                        {{ item?.value }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="edit-boxes-box">
                  <header>
                    <p class="paragraph2 silver">Features and Requirements</p>
                    <span class="paragraph2" (click)="move(3)">Edit</span>
                  </header>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Features</p>
                    <p
                      class="paragraph2 checkedFeatures-container"
                      #checkedFeatures
                      [style.max-height]="showAllCheckedFeatures ? 'none' : '4em'">
                      <span class="checkedFeatures" #text
                            [style.display]="showAllCheckedFeatures ? 'block' : '-webkit-box'"
                      >
                        {{ getAllCheckedFeatures() }}
                      </span>
                      <span
                        class="see-all-btn"
                        (click)="showAllCheckedFeatures = true"
                        *ngIf="!showAllCheckedFeatures && text.scrollHeight > text.clientHeight"
                      > ... See all</span>
                    </p>
                  </div>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Prep Work Items</p>
                    <p class="paragraph2">
                      <span
                        *ngFor="let item of briefInfo?.prep; let i = index"
                      >{{ i > 0 ? ", " + item.title : item.title }}</span
                      >
                    </p>
                  </div>
                </div>
                <div class="edit-boxes-box">
                  <header>
                    <p class="paragraph2 silver">Budget and Timelines</p>
                    <span class="paragraph2" (click)="move(5)">Edit</span>
                  </header>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Project Brief End Date</p>
                    <p class="paragraph2">
                      {{ briefInfo?.proposalValidUntil | date: "MMM dd, yyyy" }}
                    </p>
                  </div>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Project Budget</p>
                    <p class="paragraph2">
                      {{ +budget === 1 || +budget === 50500 ? "Open" : budget?.toLocaleString('en-EN') + " USD" }}
                    </p>
                  </div>
                </div>
                <div class="edit-boxes-box">
                  <header>
                    <p class="paragraph2 silver">Additional Comments to Provider(s)</p>
                    <span class="paragraph2" (click)="move(5)">Edit</span>
                  </header>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Message</p>
                    <p class="paragraph2" [title]="briefInfo?.specialMessage">
                      {{ briefInfo?.specialMessage }}
                    </p>
                  </div>
                </div>
              </section>
              <div class="button-box">
                <button
                  class="sign-up sign-up-active primary-btn"
                  (click)="send()"
                  mat-stroked-button
                >
                  Submit Brief
                </button>
              </div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </form>
  </div>
  <div class="container"  *ngIf="showProviders">
    <div class="navigate_buttons">
      <div class="back"  (click)="hideProviders()">
        <img src="./../../../assets/arrow.svg" alt=""/>
        <p>Back</p>
      </div>
      <button class="primary-btn" (click)="nextStep()">
        Next
      </button>
    </div>
    <div class="providers_block">
      <h1 class="title">Providers</h1>

      <div class="providers-content"
           infinite-scroll
           [scrollWindow]="false"
           (scrolled)="onScrollDown($event)"
      >
        <div
          *ngFor="let provider of providersArray"
          class="provider-card"
          (click)="onProviderClick()"
          [routerLink]="['/provider-profile']"
          [queryParams]="{id: provider?._id, fromPopup: true}"
        >
          <span class="provider-logo"><img [src]="provider?.logo || '../assets/pvoviderLogoPlaceholder.png'" alt=""></span>
          <div class="provider-info">
            <span class="provider-name">{{provider?.companyName}}</span>
            <span class="provider-location">{{getProviderLocation(provider)}}</span>
          </div>
          <div class="provider-reputation">
            <span>REPUTATION:</span>
            <div class="rate-container">
              <div class="rate">
                <input
                  disabled
                  [checked]="provider?.clutchScore === 5"
                  type="radio"
                  id="rating10"
                  name="rating"
                  value="10"
                  [class.isChecked]="provider?.clutchScore === 5"
                />
                <label for="rating10" title="5 stars"></label>
                <input
                  disabled
                  [checked]="provider?.clutchScore >= 4.5 && provider?.clutchScore != 5"
                  type="radio"
                  id="rating9"
                  name="rating"
                  value="9"
                  [class.isChecked]="provider?.clutchScore >= 4.5 && provider?.clutchScore != 5"
                />
                <label class="half" for="rating9" title="4 1/2 stars"></label>

                <input
                  disabled
                  [checked]="provider?.clutchScore < 4.5 && provider?.clutchScore >= 4"
                  type="radio"
                  id="rating8"
                  name="rating"
                  value="8"
                  [class.isChecked]="provider?.clutchScore < 4.5 && provider?.clutchScore >= 4"
                />
                <label for="rating8" title="4 stars"></label>
                <input
                  disabled
                  [checked]="provider?.clutchScore >= 3.5 && provider?.clutchScore < 4"
                  type="radio"
                  id="rating7"
                  name="rating"
                  value="7"
                  [class.isChecked]="provider?.clutchScore >= 3.5 && provider?.clutchScore < 4"
                />
                <label class="half" for="rating7" title="3 1/2 stars"></label>

                <input
                  disabled
                  [checked]="provider?.clutchScore < 3.5 && provider?.clutchScore >= 3"
                  type="radio"
                  id="rating6"
                  name="rating"
                  value="6"
                  [class.isChecked]="provider?.clutchScore < 3.5 && provider?.clutchScore >= 3"
                />
                <label for="rating6" title="3 stars"></label>
                <input
                  disabled
                  [checked]="provider?.clutchScore >= 2.5 && provider?.clutchScore < 3"
                  type="radio"
                  id="rating5"
                  name="rating"
                  value="5"
                  [class.isChecked]="provider?.clutchScore >= 2.5 && provider?.clutchScore < 3"
                />
                <label class="half" for="rating5" title="2 1/2 stars"></label>

                <input
                  disabled
                  [checked]="provider?.clutchScore < 2.5 && provider?.clutchScore >= 2"
                  type="radio"
                  id="rating4"
                  name="rating"
                  value="4"
                  [class.isChecked]="provider?.clutchScore < 2.5 && provider?.clutchScore >= 2"
                />
                <label for="rating4" title="2 stars"></label>
                <input
                  disabled
                  [checked]="provider?.clutchScore >= 1.5 && provider?.clutchScore < 2"
                  type="radio"
                  id="rating3"
                  name="rating"
                  value="3"
                  [class.isChecked]="provider?.clutchScore >= 1.5 && provider?.clutchScore < 2"
                />
                <label class="half" for="rating3" title="1 1/2 stars"></label>

                <input
                  disabled
                  [checked]="provider?.clutchScore < 1.5 && provider?.clutchScore >= 1"
                  type="radio"
                  id="rating2"
                  name="rating"
                  value="2"
                  [class.isChecked]="provider?.clutchScore < 1.5 && provider?.clutchScore >= 1"
                />
                <label for="rating2" title="1 star"></label>
                <input
                  disabled
                  [checked]="provider?.clutchScore >= 0.5 && provider?.clutchScore < 1"
                  type="radio"
                  id="rating1"
                  name="rating"
                  value="1"
                  [class.isChecked]="provider?.clutchScore >= 0.5 && provider?.clutchScore < 1"
                />
                <label class="half" for="rating1" title="1/2 star"></label>
              </div>
              <p class="score">{{ provider?.clutchScore }}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
