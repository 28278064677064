import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { LocationModel } from '../../../interfaces/provider';
import { MapGeocoder } from '@angular/google-maps';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {
  @Input({ required: true }) location: LocationModel;

  protected address = '';

  constructor(
    private geocoder: MapGeocoder,
    private dr: DestroyRef
  ) {}

  options: google.maps.MapOptions | null = null;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPosition: google.maps.LatLngLiteral | null = null;

  ngOnInit() {
    this.address = this.formatAddress(this.location);
    this.geocoder.geocode({
      address: this.address
    })
      .pipe(takeUntilDestroyed(this.dr))
      .subscribe(({ results }) => {
        const geocodeLocation = results[0].geometry.location;
        const position = { lat: geocodeLocation.lat(), lng: geocodeLocation.lng() };
        this.options = {
          center: position,
          disableDefaultUI: true,
          zoom: 16,
        };
        this.markerPosition = position;
    });
  }

  private formatAddress(location: LocationModel) {
    let addressString = '';

    addressString += `${location.telephone} ${location.streetAddress}, `;

    // Append city or region if available
    if (location?.locality?.name) {
      addressString += `${location.locality.name}, `;
    } else if (location?.region?.name) {
      addressString += `${location.region.name}, `;
    } else {
      addressString += `${location.country.name}`;
    }

    // Append postal code
    if (location.postalCode) {
      addressString += `${location.postalCode}, `;
    }

    // Append country if it's not already included
    if (location?.region?.name) {
      addressString += `${location.country.name}`;
    }

    return addressString;
  }
}
