import { Component, inject, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  private navigationService = inject(NavigationService);
  private router = inject(Router);

  back() {
    return this.router.navigateByUrl(this.navigationService.getPreviousUrl());
  }
}
