<app-header></app-header>
<section class="sign-up-page">
  <div class="container">
    <div class="back" [routerLink]="['/my-briefs']">
      <img src="./../../../../assets/arrow.svg" alt="" />
      <p>Back to My Projects</p>
    </div>
    <div class="sign-up-page-content">
      <mat-horizontal-stepper #stepper>
        <mat-step>
          <div class="content-left-side">
            <div class="results">
              <p class="paragraph">Results</p>
              <hr />
              <div *ngIf="briefInfo?.status === pendingStatus">
                <div class="results-title">
                  <p class="view-text">Pending Distribution To</p>
                  <p class="paragraph2">
                    {{ briefInfo?.projectBriefWillBeDistributedTo.length }}
                  </p>
                </div>
                <hr />
                <div class="providers-wrap">
                  <div
                    class="providers-wrap-item"
                    *ngFor="
                    let provider of briefInfo?.projectBriefWillBeDistributedTo;
                    let i = index
                  "
                  >
                    <img src="{{ provider?.logo  || '../assets/pvoviderLogoPlaceholder.png'}}" alt="" />
                    <div class="name">
                      <p class="paragraph2">
                        <b>{{ provider?.companyName }}</b>
                      </p>
                      <p class="paragraph2">
                        {{ provider?.city?.name ? provider.city.name + ',' : '' }} {{ provider?.country?.name }}
                      </p>
                    </div>
                    <div class="rating">
                      <p>REPUTATION:</p>
                      <div class="rating-content">
                        <form class="reputationForm" [formGroup]="form">
                          <div class="rate">
                            <input
                              disabled
                              [checked]="provider?.clutchScore === 5"
                              type="radio"
                              id="rating10.3"
                              name="rating"
                              value="10"
                            />
                            <label for="rating10" title="5 stars"></label>
                            <input
                              disabled
                              [checked]="
                              provider?.clutchScore >= 4.5 &&
                              provider?.clutchScore != 5
                            "
                              type="radio"
                              id="rating9.3"
                              name="rating"
                              value="9"
                            />
                            <label
                              class="half"
                              for="rating9"
                              title="4 1/2 stars"
                            ></label>

                            <input
                              disabled
                              [checked]="provider?.clutchScore < 4.5"
                              type="radio"
                              id="rating8.3"
                              name="rating"
                              value="8"
                            />
                            <label for="rating8" title="4 stars"></label>
                            <input
                              disabled
                              type="radio"
                              id="rating7.3"
                              name="rating"
                              value="7"
                            />
                            <label
                              class="half"
                              for="rating7"
                              title="3 1/2 stars"
                            ></label>

                            <input
                              disabled
                              type="radio"
                              id="rating6.3"
                              name="rating"
                              value="6"
                            />
                            <label for="rating6" title="3 stars"></label>
                            <input
                              disabled
                              type="radio"
                              id="rating5.3"
                              name="rating"
                              value="5"
                            />
                            <label
                              class="half"
                              for="rating5"
                              title="2 1/2 stars"
                            ></label>

                            <input
                              disabled
                              type="radio"
                              id="rating4.3"
                              name="rating"
                              value="4"
                            />
                            <label for="rating4" title="2 stars"></label>
                            <input
                              disabled
                              type="radio"
                              id="rating3.3"
                              name="rating"
                              value="3"
                            />
                            <label
                              class="half"
                              for="rating3"
                              title="1 1/2 stars"
                            ></label>

                            <input
                              disabled
                              type="radio"
                              id="rating2.3"
                              name="rating"
                              value="2"
                            />
                            <label for="rating2" title="1 star"></label>
                            <input
                              disabled
                              type="radio"
                              id="rating1.3"
                              name="rating"
                              value="1"
                            />
                            <label
                              class="half"
                              for="rating1"
                              title="1/2 star"
                            ></label>
                          </div>
                          <p class="paragraph2">{{ provider?.clutchScore }}</p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="results-title" *ngIf="briefInfo?.status !== pendingStatus">
                <p class="view-text">Project Brief Distributed To</p>
                <p class="paragraph2">
                  {{ briefInfo?.projectBriefDistributedTo.length }}
                </p>
              </div>
              <hr />
              <div class="providers-wrap">
                <div
                  class="providers-wrap-item"
                  *ngFor="
                    let provider of briefInfo?.projectBriefDistributedTo;
                    let i = index
                  "
                >
                  <img src="{{ provider?.logo || '../assets/pvoviderLogoPlaceholder.png' }}" alt="" />
                  <div class="name">
                    <p class="paragraph2">
                      <b>{{ provider?.companyName }}</b>
                    </p>
                    <p class="paragraph2">
                      {{ provider?.city?.name ? provider.city.name + ',' : '' }} {{ provider?.country?.name }}
                    </p>
                  </div>
                  <div class="rating">
                    <p>REPUTATION:</p>
                    <div class="rating-content">
                      <form class="reputationForm" [formGroup]="form">
                        <div class="rate">
                          <input
                            disabled
                            [checked]="provider?.clutchScore === 5"
                            type="radio"
                            id="rating10"
                            name="rating"
                            value="10"
                          />
                          <label for="rating10" title="5 stars"></label>
                          <input
                            disabled
                            [checked]="
                              provider?.clutchScore >= 4.5 &&
                              provider?.clutchScore != 5
                            "
                            type="radio"
                            id="rating9"
                            name="rating"
                            value="9"
                          />
                          <label
                            class="half"
                            for="rating9"
                            title="4 1/2 stars"
                          ></label>

                          <input
                            disabled
                            [checked]="provider?.clutchScore < 4.5"
                            type="radio"
                            id="rating8"
                            name="rating"
                            value="8"
                          />
                          <label for="rating8" title="4 stars"></label>
                          <input
                            disabled
                            type="radio"
                            id="rating7"
                            name="rating"
                            value="7"
                          />
                          <label
                            class="half"
                            for="rating7"
                            title="3 1/2 stars"
                          ></label>

                          <input
                            disabled
                            type="radio"
                            id="rating6"
                            name="rating"
                            value="6"
                          />
                          <label for="rating6" title="3 stars"></label>
                          <input
                            disabled
                            type="radio"
                            id="rating5"
                            name="rating"
                            value="5"
                          />
                          <label
                            class="half"
                            for="rating5"
                            title="2 1/2 stars"
                          ></label>

                          <input
                            disabled
                            type="radio"
                            id="rating4"
                            name="rating"
                            value="4"
                          />
                          <label for="rating4" title="2 stars"></label>
                          <input
                            disabled
                            type="radio"
                            id="rating3"
                            name="rating"
                            value="3"
                          />
                          <label
                            class="half"
                            for="rating3"
                            title="1 1/2 stars"
                          ></label>

                          <input
                            disabled
                            type="radio"
                            id="rating2"
                            name="rating"
                            value="2"
                          />
                          <label for="rating2" title="1 star"></label>
                          <input
                            disabled
                            type="radio"
                            id="rating1"
                            name="rating"
                            value="1"
                          />
                          <label
                            class="half"
                            for="rating1"
                            title="1/2 star"
                          ></label>
                        </div>
                        <p class="paragraph2">{{ provider?.clutchScore }}</p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class="results-title">-->
              <!--                <p class="paragraph2"></p>-->
              <!--                <p class="view-text">View the List <span>&#8250;</span></p>-->
              <!--              </div>-->
              <div class="results-title">
                <p class="view-text">Providers who viewed your proposal</p>
                <p class="paragraph2">
                  {{ briefInfo?.providersWhoViewedYourProposal.length }}
                </p>
              </div>
              <hr />
              <div class="providers-wrap">
                <div
                  class="providers-wrap-item"
                  *ngFor="
                    let provider of briefInfo?.providersWhoViewedYourProposal;
                    let i = index
                  "
                >
                  <img src="{{ provider?.logo || '../assets/pvoviderLogoPlaceholder.png' }}" alt=""/>
                  <div class="name">
                    <p class="paragraph2">
                      <b>{{ provider?.companyName }}</b>
                    </p>
                    <p class="paragraph2">
                      {{ provider?.city?.name ? provider.city.name + ',' : '' }} {{ provider?.country?.name }}
                    </p>
                  </div>
                  <div class="rating">
                    <p>REPUTATION:</p>
                    <div class="rating-content">
                      <form class="reputationForm" [formGroup]="form">
                        <div class="rate">
                          <input
                            disabled
                            [checked]="provider?.clutchScore === 5"
                            type="radio"
                            id="rating10.1"
                            name="rating"
                            value="10"
                          />
                          <label for="rating10.1" title="5 stars"></label>
                          <input
                            disabled
                            [checked]="
                              provider?.clutchScore >= 4.5 &&
                              provider?.clutchScore != 5
                            "
                            type="radio"
                            id="rating9.1"
                            name="rating"
                            value="9"
                          />
                          <label
                            class="half"
                            for="rating9.1"
                            title="4 1/2 stars"
                          ></label>

                          <input
                            disabled
                            [checked]="provider?.clutchScore < 4.5"
                            type="radio"
                            id="rating8.1"
                            name="rating"
                            value="8"
                          />
                          <label for="rating8.1" title="4 stars"></label>
                          <input
                            disabled
                            type="radio"
                            id="rating7.1"
                            name="rating"
                            value="7"
                          />
                          <label
                            class="half"
                            for="rating7.1"
                            title="3 1/2 stars"
                          ></label>

                          <input
                            disabled
                            type="radio"
                            id="rating6.1"
                            name="rating"
                            value="6"
                          />
                          <label for="rating6.1" title="3 stars"></label>
                          <input
                            disabled
                            type="radio"
                            id="rating5.1"
                            name="rating"
                            value="5"
                          />
                          <label
                            class="half"
                            for="rating5.1"
                            title="2 1/2 stars"
                          ></label>

                          <input
                            disabled
                            type="radio"
                            id="rating4.1"
                            name="rating"
                            value="4"
                          />
                          <label for="rating4.1" title="2 stars"></label>
                          <input
                            disabled
                            type="radio"
                            id="rating3.1"
                            name="rating"
                            value="3"
                          />
                          <label
                            class="half"
                            for="rating3.1"
                            title="1 1/2 stars"
                          ></label>

                          <input
                            disabled
                            type="radio"
                            id="rating2.1"
                            name="rating"
                            value="2"
                          />
                          <label for="rating2.1" title="1 star"></label>
                          <input
                            disabled
                            type="radio"
                            id="rating1.1"
                            name="rating"
                            value="1"
                          />
                          <label
                            class="half"
                            for="rating1.1"
                            title="1/2 star"
                          ></label>
                        </div>
                        <p class="paragraph2">{{ provider?.clutchScore }}</p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="results-title">
                <p class="view-text">Providers Interested</p>
                <p class="paragraph2">
                  {{ briefInfo?.providersInterested.length }}
                </p>
              </div>
              <hr />
              <div class="providers-wrap">
                <div
                  class="providers-wrap-item"
                  *ngFor="
                    let provider of briefInfo?.providersInterested;
                    let i = index
                  "
                >
                  <img src="{{ provider?.logo || '../assets/pvoviderLogoPlaceholder.png' }}" alt=""/>
                  <div class="name">
                    <p class="paragraph2">
                      <b>{{ provider?.companyName }}</b>
                    </p>
                    <p class="paragraph2">
                      {{ provider?.city?.name ? provider.city.name + ',' : '' }} {{ provider?.country?.name }}
                    </p>
                  </div>
                  <div class="rating">
                    <p>REPUTATION:</p>
                    <div class="rating-content">
                      <form class="reputationForm" [formGroup]="form">
                        <div class="rate">
                          <input
                            disabled
                            [checked]="provider?.clutchScore === 5"
                            type="radio"
                            id="rating10.2"
                            name="rating"
                            value="10"
                          />
                          <label for="rating10.2" title="5 stars"></label>
                          <input
                            disabled
                            [checked]="
                              provider?.clutchScore >= 4.5 &&
                              provider?.clutchScore != 5
                            "
                            type="radio"
                            id="rating9.2"
                            name="rating"
                            value="9"
                          />
                          <label
                            class="half"
                            for="rating9.2"
                            title="4 1/2 stars"
                          ></label>

                          <input
                            disabled
                            [checked]="provider?.clutchScore < 4.5"
                            type="radio"
                            id="rating8.2"
                            name="rating"
                            value="8"
                          />
                          <label for="rating8.2" title="4 stars"></label>
                          <input
                            disabled
                            type="radio"
                            id="rating7.2"
                            name="rating"
                            value="7"
                          />
                          <label
                            class="half"
                            for="rating7.2"
                            title="3 1/2 stars"
                          ></label>

                          <input
                            disabled
                            type="radio"
                            id="rating6.2"
                            name="rating"
                            value="6"
                          />
                          <label for="rating6" title="3 stars"></label>
                          <input
                            disabled
                            type="radio"
                            id="rating5.2"
                            name="rating"
                            value="5"
                          />
                          <label
                            class="half"
                            for="rating5.2"
                            title="2 1/2 stars"
                          ></label>

                          <input
                            disabled
                            type="radio"
                            id="rating4.2"
                            name="rating"
                            value="4"
                          />
                          <label for="rating4.2" title="2 stars"></label>
                          <input
                            disabled
                            type="radio"
                            id="rating3.2"
                            name="rating"
                            value="3"
                          />
                          <label
                            class="half"
                            for="rating3.2"
                            title="1 1/2 stars"
                          ></label>

                          <input
                            disabled
                            type="radio"
                            id="rating2.2"
                            name="rating"
                            value="2"
                          />
                          <label for="rating2.2" title="1 star"></label>
                          <input
                            disabled
                            type="radio"
                            id="rating1.2"
                            name="rating"
                            value="1"
                          />
                          <label
                            class="half"
                            for="rating1.2"
                            title="1/2 star"
                          ></label>
                        </div>
                        <p class="paragraph2">{{ provider?.clutchScore }}</p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <p
                *ngIf="!briefInfo?.providersInterested.length && briefInfo?.status !== pendingStatus"
                class="paragraph2 not-provider-text"
              >
                No provider has shown interest yet.
              </p>

              <button
                *ngIf="briefInfo?.status === 1 || briefInfo?.status === 3 && !briefInfo.provider"
                class="primary-btn"
                (click)="openCompanyPopup(briefInfo)"
              >
                Share with more providers
              </button>
            </div>
          </div>
          <div class="content-right-side">
            <div class="content-right-side-box">
              <header>
                <h2>Project Brief Summary</h2>
              </header>
              <section class="edit-boxes" *ngIf="briefInfo">
                <div class="edit-boxes-box-item-not-border">
                  <p class="paragraph2 silver">
                    Brief ID
                  </p>
                  <p class="paragraph2">
                    {{ briefInfo.briefId || '- - -'}}
                  </p>
                </div>
                <div class="edit-boxes-box">
                  <header>
                    <p class="paragraph2 silver">About your Project</p>
                  </header>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Project Name</p>
                    <p class="paragraph2">{{ briefInfo?.name }}</p>
                  </div>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Project Needs</p>
                    <p class="paragraph2">
                      <span
                        *ngFor="let item of briefInfo?.types; let i = index"
                      >{{ i > 0 ? ", " + item?.title : item?.title }}</span>
                    </p>
                  </div>
                </div>

                <div class="edit-boxes-box">
                  <header>
                    <p class="paragraph2 silver">Provider Preferences</p>
                  </header>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">
                      Location Preferences
                    </p>
                    <p *ngIf="briefInfo?.country?.length <= 0" class="paragraph2">
                      Any
                    </p>
                    <p *ngIf="briefInfo?.country?.length > 0" class="paragraph2">
                      <span
                        *ngFor="
                          let item of briefInfo?.country;
                          let index = index
                        "
                      >{{ index > 0 ? ", " + item.name : item.name }}</span>
                    </p>
                  </div>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">
                      Industry Expertise
                    </p>
                    <p
                      *ngIf="briefInfo?.specialization?.length <= 0"
                      class="paragraph2"
                    >
                      Any
                    </p>
                    <p
                      *ngIf="briefInfo?.specialization?.length > 0"
                      class="paragraph2"
                    >
                      <span
                        *ngFor="
                          let item of briefInfo?.specialization;
                          let index = index
                        "
                      >{{ index > 0 ? ", " + item : item }}</span>
                    </p>
                  </div>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Skills Preferences</p>
                    <p
                      *ngIf="briefInfo?.skillAreas?.length <= 0"
                      class="paragraph2"
                    >
                      Any
                    </p>
                    <p
                      *ngIf="briefInfo?.skillAreas?.length > 0"
                      class="paragraph2"
                    >
                      <span
                        *ngFor="
                          let item of briefInfo?.skillAreas;
                          let index = index
                        "
                      >{{ index > 0 ? ", " + item : item }}</span>
                    </p>
                  </div>

                  <div
                    class="edit-boxes-box-item"
                    *ngIf="briefInfo?.acceptCompensation?.length"
                  >
                    <p class="paragraph2 silver">Compensation Preference</p>
                    <p class="paragraph2">
                      <span
                        *ngFor="
                          let item of briefInfo?.acceptCompensation;
                          let index = index
                        "
                      >{{ index > 0 ? ", " + item.title : item.title }}</span>
                    </p>
                  </div>
                </div>
                <div class="edit-boxes-box">
                  <header>
                    <p class="paragraph2 silver">Project Details</p>
                  </header>
                  <div
                    *ngFor="
                      let item of briefInfo?.projectDetails;
                      let i = index
                    "
                  >
                    <div class="edit-boxes-box-item" *ngIf="i === 0">
                      <p class="paragraph2 silver">Project Description</p>
                      <p class="paragraph2" [title]="item?.value">
                        {{ item?.value }}
                      </p>
                    </div>
                  </div>
                  <div
                    *ngFor="
                      let item of briefInfo?.projectDetails;
                      let i = index
                    "
                  >
                    <div class="edit-boxes-box-item" *ngIf="i === 1">
                      <p class="paragraph2 silver">Problem Statement</p>
                      <p class="paragraph2" [title]="item?.value">
                        {{ item?.value }}
                      </p>
                    </div>
                  </div>
                  <div
                    *ngFor="
                      let item of briefInfo?.projectDetails;
                      let i = index
                    "
                  >
                    <div class="edit-boxes-box-item" *ngIf="i === 2">
                      <p class="paragraph2 silver">
                        Examples of Similar Products/Services
                      </p>
                      <p class="paragraph2" [title]="item?.value">
                        {{ item?.value }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="edit-boxes-box">
                  <header>
                    <p class="paragraph2 silver">Features and Requirements</p>
                  </header>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Features</p>
                    <p
                      class="paragraph2 checkedFeatures-container"
                      #checkedFeatures
                      [style.max-height]="showAllCheckedFeatures ? 'none' : '4em'">
                      <span class="checkedFeatures" #text
                            [style.display]="showAllCheckedFeatures ? 'block' : '-webkit-box'"
                      >
                        {{ getAllCheckedFeatures() }}
                      </span>
                      <span
                        class="see-all-btn"
                        (click)="showAllCheckedFeatures = true"
                        *ngIf="!showAllCheckedFeatures && text.scrollHeight > text.clientHeight"
                      > ... See all</span>
                    </p>
                  </div>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Prep Work Items</p>
                    <p class="paragraph2">
                      <span
                        *ngFor="let item of briefInfo?.prep; let i = index"
                      >{{ i > 0 ? ", " + item.title : item.title }}</span
                      >
                    </p>
                  </div>
                  <div class="edit-boxes-box-item" *ngIf="briefInfo.isDocumentPurchased">
                    <p class="paragraph2 silver">Requirements Document</p>
                    <p class="paragraph2 document-link" (click)="opedRequirementsDocInNewTab()">{{briefInfo.name}}.pdf</p>
                  </div>
                </div>
                <div class="edit-boxes-box">
                  <header>
                    <p class="paragraph2 silver">Budget and Timelines</p>
                  </header>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Project Brief End Date</p>
                    <p class="paragraph2">
                      {{ briefInfo?.proposalValidUntil | date: "MMM dd, yyyy" }}
                    </p>
                  </div>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Project Budget</p>
                    <p class="paragraph2">{{ budget }}</p>
                  </div>
                </div>
                <div class="edit-boxes-box">
                  <header>
                    <p class="paragraph2 silver">Additional Comments to Provider(s)</p>
                  </header>
                  <div class="edit-boxes-box-item">
                    <p class="paragraph2 silver">Message</p>
                    <p class="paragraph2" [title]="briefInfo?.specialMessage">
                      {{ briefInfo?.specialMessage }}
                    </p>
                  </div>
                </div>
              </section>
              <div class="button-box">
                <button
                  *ngIf="briefInfo?.status === 1 || briefInfo?.status === 3"
                  class="primary-btn"
                  (click)="closeProject(briefInfo?._id)"
                >
                  Close Project
                </button>
              </div>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</section>
