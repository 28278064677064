<app-header
  [displaySearchField]="false"
  [displaySignUp]="false"
></app-header>
<section class="sign-up-page">
  <div class="container">
    <div class="back" [queryParams]="{ popupSignUp: true }" [routerLink]="['']">
      <img src="./../../../../assets/arrow.svg" alt="" />
      <p>Back to signing up options</p>
    </div>
    <div class="sign-up-page-content">
      <mat-horizontal-stepper [linear]="isLinear" #stepper>
        <mat-step>
          <div class="content-left-side">
            <form [formGroup]="imgForm" class="profile-photo-box">
              <div class="profile-photo-box-content"
              >
                <img
                  *ngIf="!url"
                  src="../../../../assets/auth/Shape.svg"
                  alt="picture"
                />
                <img class="selected-img" [src]="url" *ngIf="url" />
              </div>
              <input
                class="select-file"
                type="file"
                accept="image/jpeg, image/png, image/svg"
                (change)="selectFile($event)"
              />
              <input formControlName="img" type="hidden" />
              <p class="update">
                Update Display Image
              </p>
            </form>
            <div class="step-box" [ngClass]="{ 'step-box-active': step === 1 }">
              <p>About</p>
              <div
                class="check-oval"
                [ngClass]="{ 'check-oval-done': step === 2 }"
              ></div>
            </div>
            <div
              *ngIf="role !== 'individual'"
              class="step-box"
              [ngClass]="{ 'step-box-active': step === 2 }"
            >
              Details
              <div class="check-oval"></div>
            </div>
            <div
              class="step-box"
              [ngClass]="{
                'step-box-active':
                  (step === 3 && role !== 'individual') ||
                  (step === 2 && role == 'individual')
              }"
            >
              Verification
              <div class="check-oval"></div>
            </div>
          </div>
          <div class="content-right-side">
            <header>
              <div class="header-title">
                <h2>About</h2>
              </div>
              <p *ngIf="role === 'individual'">
                Step <span>{{ step }}</span> of 2
              </p>
              <p *ngIf="role !== 'individual'">
                Step <span>{{ step }}</span> of 3
              </p>
            </header>
            <form
              *ngIf="role !== 'individual'"
              class="about-form"
              [formGroup]="firstStepForm"
            >
              <div class="text-field-input">
                <label for="name">Company Name *</label> <br />
                <input
                  [ngClass]="{
                    'error-input':
                      firstStepForm.controls['name'].invalid && submitted
                  }"
                  id="name"
                  type="text"
                  placeholder="Enter company name"
                  formControlName="name"
                  required
                />
                <div
                  class="error-text"
                  *ngIf="submitted && firstStepForm.controls['name'].errors"
                >
                  <p
                    *ngIf="
                      firstStepForm.controls['name'].errors.required &&
                      role != 'individual'
                    "
                  >
                    Company Name is required.
                  </p>
                </div>
              </div>
              <br />
              <div class="text-field-input">
                <label for="email">Email *</label> <br />
                <input
                  [ngClass]="{
                    'error-input':
                      firstStepForm.controls['email'].invalid && submitted
                  }"
                  id="email"
                  type="text"
                  placeholder="Email to be used for logging in"
                  formControlName="email"
                  required
                />
                <div
                  class="error-text"
                  *ngIf="submitted && firstStepForm.controls['email'].errors"
                >
                  <p *ngIf="firstStepForm.controls['email'].errors.required">
                    Email is required.
                  </p>
                  <p *ngIf="firstStepForm.controls['email'].errors.email || firstStepForm.controls['email'].errors.pattern">
                    Please enter the correct email address is required.
                  </p>
                </div>
              </div>
              <br />
              <div class="text-field-input">
                <label for="email">Country *</label> <br />
                <input
                  type="text"
                  [ngClass]="{
                    'error-input':
                      firstStepForm.controls['country'].invalid && submitted
                  }"
                  placeholder="Enter location of headquarters"
                  formControlName="country"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="getDisplayName"
                  (optionSelected)="onCountrySelected($event.option.value)"
                >
                  <mat-option
                    *ngFor="let country of filteredCountry | async"
                    [value]="country"
                  >
                    {{getDisplayName(country)}}
                  </mat-option>
                </mat-autocomplete>
                <img
                  class="mat-select-arrow select-arrow"
                  src="./../../../../assets/icons/select.svg"
                  alt="img"
                />
                <div
                  class="error-text"
                  *ngIf="submitted && firstStepForm.controls['country'].errors"
                >
                  <p *ngIf="firstStepForm.controls['country'].errors.required">
                    Country is required.
                  </p>
                  <p *ngIf="firstStepForm.controls['country'].errors.invalidValue">
                    Please select a valid Country.
                  </p>
                </div>
              </div>
              <br />
              <div class="text-field-input">
                <label for="phoneNumber">Mobile Number *</label> <br />
                <p *ngIf="selectCountry" class="code-country">
                  {{ selectCountry }}
                </p>
                <input
                  class="phone-number-code"
                  [ngClass]="{
                    'error-input':
                      firstStepForm.controls['phoneNumber'].invalid && submitted,
                      'move-right': selectCountry && selectCountry.length < 5,
                      'move-right-long': selectCountry && selectCountry.length >= 5
                  }"
                  id="phoneNumber"
                  type="number"
                  (keypress)="numericOnly($event)"
                  placeholder="Mobile number for user verification."
                  formControlName="phoneNumber"
                  required
                />
                <div
                  class="error-text"
                  *ngIf="
                    submitted && firstStepForm.controls['phoneNumber'].errors
                  "
                >
                  <p
                    *ngIf="
                      firstStepForm.controls['phoneNumber'].errors.required
                    "
                  >
                    Enter a valid mobile number with no spaces.
                  </p>
                </div>
              </div>
              <br />
              <div class="password-inputs">
                <div class="confirm-password text-field-input">
                  <label for="password">Password *</label> <br />
                  <input
                    [ngClass]="{
                      'error-input':
                        firstStepForm.controls['password'].invalid && submitted
                    }"
                    class="password-input"
                    id="password"
                    type="{{ typePassword }}"
                    placeholder="Create a login password"
                    formControlName="password"
                    required
                  />
                  <button class="mask" (click)="changePasswordType()">
                    <img
                      *ngIf="!showPassword"
                      src="../../../../assets/eye.svg"
                      alt=""
                    />
                    <img
                      *ngIf="showPassword"
                      src="../../../../assets/Eye.slash.svg"
                      alt=""
                    />
                  </button>

                  <div
                    class="error-text"
                    *ngIf="
                      submitted && firstStepForm.controls['password'].errors
                    "
                  >
                    <p
                      *ngIf="
                        firstStepForm.controls['password'].errors.minlength
                      "
                    >
                      Password must be at least 6 characters.
                    </p>
                    <p
                      *ngIf="firstStepForm.controls['password'].errors.required"
                    >
                      Password is required.
                    </p>
                  </div>
                  <br />
                </div>
                <div class="confirm-password text-field-input">
                  <label for="confirmPassword">Confirm Password *</label> <br />
                  <input
                    [ngClass]="{
                      'error-input':
                        firstStepForm.controls['confirmPassword'].invalid &&
                        submitted
                    }"
                    id="confirmPassword"
                    type="{{ typeConfirmPassword }}"
                    placeholder="Re-enter password"
                    formControlName="confirmPassword"
                    required
                  />
                  <button class="mask" (click)="changeConfirmPasswordType()">
                    <img
                      *ngIf="!showConfirmPassword"
                      src="../../../../assets/eye.svg"
                      alt=""
                    />
                    <img
                      *ngIf="showConfirmPassword"
                      src="../../../../assets/Eye.slash.svg"
                      alt=""
                    />
                  </button>
                  <div
                    class="error-text"
                    *ngIf="
                      submitted &&
                      firstStepForm.controls['confirmPassword'].errors
                    "
                  >
                    <p
                      *ngIf="
                        firstStepForm.controls['confirmPassword'].errors
                          .minlength
                      "
                    >
                      Password must be at least 6 characters.
                    </p>
                    <p
                      *ngIf="
                        firstStepForm.controls['confirmPassword'].errors
                          .required
                      "
                    >
                      Confirm Password is required.
                    </p>
                    <p
                      *ngIf="
                        firstStepForm.controls['confirmPassword'].errors
                          .mustMatch
                      "
                    >
                      Passwords must match.
                    </p>
                  </div>
                  <br />
                </div>
              </div>
              <div class="text-field checkbox">
                <p class="agree left">
                  By signing up, I agree to The Unicorn App's
                  <span (click)="goToLink('/terms-of-use')"
                  >Terms of Service</span
                  >
                  and
                  <span (click)="goToLink('/privacy-policy')"
                  >Privacy Policy.</span
                  >
                </p>
              </div>
              <div>
                <div class="re-container">
                  <re-captcha
                    class="re-captcha"
                    formControlName="recaptchaReactive"
                    (resolved)="checkCaptcha($event)"
                    name="captcha"
                    siteKey="6Le7Dn4lAAAAAIwgkoFiYmnqwRNWBvPW5fQwuhGn"
                    [class.error-input]="!this.captchaResolved && submitted"
                  ></re-captcha>
                  <div *ngIf="!this.captchaResolved && submitted" class="error-text">
                    <p *ngIf="!this.captchaResolved">Captcha must be solve.</p>
                  </div>
                </div>

                <button
                  *ngIf="role !== 'individual'"
                  [disabled]="nextStepClicked"
                  [ngClass]="{
                    'sign-up-active':
                      firstStepForm.valid && this.captchaResolved &&
                      this.firstStepForm.controls.password.value ===
                        this.firstStepForm.controls.confirmPassword.value
                  }"
                  class="sign-up"
                  (click)="nextStep(firstStepForm, true)"
                >
                  Sign Up
                </button>

              </div>
              <div class="have-account">
                <p class="paragraph2">Have an account? <span (click)="openLoginPopup()">Log In</span></p>
              </div>
            </form>

            <form
              *ngIf="role === 'individual'"
              class="about-form"
              [formGroup]="firstStepForm1"
            >
              <div class="about-form-box">
                <div class="text-field-input">
                  <label for="name1">First Name *</label> <br />
                  <input
                    [ngClass]="{
                      'error-input':
                        firstStepForm1.controls['firstName'].invalid &&
                        submitted
                    }"
                    id="name1"
                    type="text"
                    placeholder="Enter your first name"
                    formControlName="firstName"
                    required
                  />
                  <div
                    class="error-text"
                    *ngIf="
                      submitted && firstStepForm1.controls['firstName'].errors
                    "
                  >
                    <p
                      *ngIf="
                        firstStepForm1.controls['firstName'].errors.required
                      "
                    >
                      First Name is required.
                    </p>
                  </div>
                </div>
                <div class="text-field-input">
                  <label for="name2">Last Name *</label> <br />
                  <input
                    [ngClass]="{
                      'error-input':
                        firstStepForm1.controls['lastName'].invalid && submitted
                    }"
                    id="name2"
                    type="text"
                    placeholder="Enter your last name"
                    formControlName="lastName"
                    required
                  />
                  <div
                    class="error-text"
                    *ngIf="
                      submitted && firstStepForm1.controls['lastName'].errors
                    "
                  >
                    <p
                      *ngIf="
                        firstStepForm1.controls['lastName'].errors.required
                      "
                    >
                      Last Name is required.
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <div class="text-field-input">
                <label for="email">Email *</label> <br />
                <input
                  [ngClass]="{
                    'error-input':
                      firstStepForm1.controls['email'].invalid && submitted
                  }"
                  id="email"
                  type="text"
                  placeholder="Email to be used for logging in"
                  formControlName="email"
                  required
                />
                <div
                  class="error-text"
                  *ngIf="submitted && firstStepForm1.controls['email'].errors"
                >
                  <p *ngIf="firstStepForm1.controls['email'].errors.required">
                    Email is required.
                  </p>
                  <p *ngIf="firstStepForm1.controls['email'].errors.email || firstStepForm1.controls['email'].errors.pattern">
                    Please enter the correct email address is required.
                  </p>
                </div>
              </div>
              <br />
              <div class="text-field-input">
                <label for="email">Country I live in *</label> <br />
                <input
                  type="text"
                  [ngClass]="{
                    'error-input':
                      firstStepForm1.controls['country'].invalid && submitted
                  }"
                  placeholder="Select country for mobile verification"
                  formControlName="country"
                  [matAutocomplete]="auto1"
                />
                <mat-autocomplete
                  #auto1="matAutocomplete"
                  [displayWith]="getDisplayName"
                  (optionSelected)="onCountrySelected($event.option.value)"
                >
                  <mat-option
                    *ngFor="let country of filteredCountry | async"
                    [value]="country"
                  >
                    {{getDisplayName(country)}}
                  </mat-option>
                </mat-autocomplete>

                <img
                  class="mat-select-arrow select-arrow"
                  src="./../../../../assets/icons/select.svg"
                  alt=""
                />
                <div
                  class="error-text"
                  *ngIf="submitted && firstStepForm1.controls['country'].errors"
                >
                  <p *ngIf="firstStepForm1.controls['country'].errors.required">
                    Country I live in is required.
                  </p>
                  <p *ngIf="firstStepForm1.controls['country'].errors.invalidValue">
                    Please select a valid Country.
                  </p>
                </div>
              </div>
              <br />
              <div class="text-field-input">
                <label for="phoneNumber">Mobile Number *</label> <br />
<!--                <p *ngIf="!selectCountry" class="code-country">+XXX</p>-->
                <p *ngIf="selectCountry" class="code-country">
                  {{ selectCountry }}
                </p>
                <input
                  class="phone-number-code"
                  [ngClass]="{
                    'error-input':
                      firstStepForm1.controls['phoneNumber'].invalid &&
                      submitted,
                       'move-right': selectCountry && selectCountry.length < 5,
                      'move-right-long': selectCountry && selectCountry.length >= 5
                  }"
                  id="phoneNumber"
                  type="number"
                  (keypress)="numericOnly($event)"
                  placeholder="Mobile number for user verification."
                  formControlName="phoneNumber"
                  required
                />
                <div
                  class="error-text"
                  *ngIf="
                    submitted && firstStepForm1.controls['phoneNumber'].errors
                  "
                >
                  <p
                    *ngIf="
                      firstStepForm1.controls['phoneNumber'].errors.required
                    "
                  >
                    Enter a valid mobile number with no spaces.
                  </p>
                </div>
              </div>
              <br />
              <div class="password-inputs">
                <div class="confirm-password text-field-input">
                  <label for="password">Password *</label> <br />
                  <input
                    [ngClass]="{
                      'error-input':
                        firstStepForm1.controls['password'].invalid && submitted
                    }"
                    class="password-input"
                    id="password"
                    type="{{ typePassword }}"
                    placeholder="Create a login password"
                    formControlName="password"
                    required
                  />
                  <button class="mask" (click)="changePasswordType()">
                    <img
                      *ngIf="!showPassword"
                      src="../../../../assets/eye.svg"
                      alt=""
                    />
                    <img
                      *ngIf="showPassword"
                      src="../../../../assets/Eye.slash.svg"
                      alt=""
                    />
                  </button>
                  <div
                    class="error-text"
                    *ngIf="
                      submitted && firstStepForm1.controls['password'].errors
                    "
                  >
                    <p
                      *ngIf="
                        firstStepForm1.controls['password'].errors.minlength
                      "
                    >
                      Password must be at least 6 characters.
                    </p>
                    <p
                      *ngIf="
                        firstStepForm1.controls['password'].errors.required
                      "
                    >
                      Password is required.
                    </p>
                  </div>
                  <br />
                </div>
                <div class="confirm-password text-field-input">
                  <label for="confirmPassword">Confirm Password *</label> <br />
                  <input
                    [ngClass]="{
                      'error-input':
                        firstStepForm1.controls['confirmPassword'].invalid &&
                        submitted
                    }"
                    id="confirmPassword"
                    type="{{ typeConfirmPassword }}"
                    placeholder="Re-enter password"
                    formControlName="confirmPassword"
                    required
                  />
                  <button class="mask" (click)="changeConfirmPasswordType()">
                    <img
                      *ngIf="!showConfirmPassword"
                      src="../../../../assets/eye.svg"
                      alt=""
                    />
                    <img
                      *ngIf="showConfirmPassword"
                      src="../../../../assets/Eye.slash.svg"
                      alt=""
                    />
                  </button>
                  <div
                    class="error-text"
                    *ngIf="
                      submitted &&
                      firstStepForm1.controls['confirmPassword'].errors
                    "
                  >
                    <p
                      *ngIf="
                        firstStepForm1.controls['confirmPassword'].errors
                          .minlength
                      "
                    >
                      Password must be at least 6 characters.
                    </p>
                    <p
                      *ngIf="
                        firstStepForm1.controls['confirmPassword'].errors
                          .required
                      "
                    >
                      Confirm Password is required.
                    </p>
                    <p
                      *ngIf="
                        firstStepForm1.controls['confirmPassword'].errors
                          .mustMatch
                      "
                    >
                      Passwords must match.
                    </p>
                  </div>
                  <br />
                </div>
              </div>
              <div class="text-field checkbox">
<!--                <label>-->
<!--                  <input (click)="agree()" type="checkbox" />-->
<!--                </label>-->
                <p class="agree left">
                  By signing up, I agree to The Unicorn App's
                  <span (click)="goToLink('/terms-of-use')"
                    >Terms of Service</span
                  >
                  and
                  <span (click)="goToLink('/privacy-policy')"
                    >Privacy Policy.</span
                  >
                </p>
              </div>
              <div>
                <div class="re-container">
                  <re-captcha
                    class="re-captcha"
                    formControlName="recaptchaReactive"
                    (resolved)="checkCaptcha($event)"
                    name="captcha"
                    siteKey="6Le7Dn4lAAAAAIwgkoFiYmnqwRNWBvPW5fQwuhGn"
                    [class.error-input]="!this.captchaResolved && submitted"
                  ></re-captcha>
                  <div *ngIf="!this.captchaResolved && submitted" class="error-text">
                    <p *ngIf="!this.captchaResolved">Captcha must be solve.</p>
                  </div>
                </div>

                <button
                  [disabled]="nextStepClicked"
                  [ngClass]="{
                    'sign-up-active':
                      firstStepForm1.valid && this.captchaResolved &&
                      this.firstStepForm1.controls.password.value ===
                        this.firstStepForm1.controls.confirmPassword.value
                  }"
                  class="sign-up"
                  (click)="nextStep(firstStepForm1, true)"
                >
                  Sign Up
                </button>
              </div>
              <div class="have-account">
                <p class="paragraph2">Have an account? <span (click)="openLoginPopup()">Log In</span></p>
              </div>
            </form>
          </div>
        </mat-step>

        <mat-step [stepControl]="secondStepForm" *ngIf="role !== 'individual'">
          <div class="content-left-side">
            <div class="profile-photo-box">
              <div class="profile-photo-box-content">
                <img
                  *ngIf="!url"
                  src="../../../../assets/auth/Shape.svg"
                  alt="picture"
                />
                <img class="selected-img" [src]="url" *ngIf="url" />
              </div>
              <p *ngIf="step === 1">Update Display Image</p>
              <p *ngIf="step > 1">
                Welcome {{ firstStepForm.get("name").value }}!
              </p>
            </div>
            <div class="step-box" [ngClass]="{ 'step-box-active': step === 1 }">
              <p>About</p>
              <div
                class="check-oval"
                [ngClass]="{ 'check-oval-done': step === 2 }"
              ></div>
            </div>
            <div
              *ngIf="role !== 'individual'"
              class="step-box"
              [ngClass]="{
                'step-box-active': step === 2 && role !== 'individual'
              }"
            >
              The Details
              <div class="check-oval"></div>
            </div>
            <div class="step-box" [ngClass]="{ 'step-box-active': step === 3 }">
              Verification
              <div
                [ngClass]="{
                  'check-oval-done': step === 3 && secondStepForm.valid
                }"
                class="check-oval"
              ></div>
            </div>
          </div>
          <div class="content-right-side">
            <header>
              <div class="header-title">
                <h2>Welcome {{ firstStepForm.get("name").value }}!</h2>
                <p class="header-title-sub">We need some info about the company to get started.</p>
              </div>
              <p *ngIf="role === 'individual'">
                Step <span>{{ step }}</span> of 2
              </p>
              <p *ngIf="role !== 'individual'">
                Step <span>{{ step }}</span> of 3
              </p>
            </header>
            <form class="about-form" [formGroup]="secondStepForm">
              <div class="password-inputs text-field-input">
                <div *ngIf="!hideState" class="city text-field-input states">
                  <label class="state">State/Province/Region *</label>
                  <input
                    type="text"
                    placeholder="Head office state/province/region"
                    [ngClass]="{
                      'error-input':
                        secondStepForm.controls['state'].invalid &&
                        submitted
                    }"
                    (input)="onStateSelected($event.target?.value)"
                    formControlName="state"
                    [matAutocomplete]="stateAuto"
                  />
                  <mat-autocomplete
                    #stateAuto="matAutocomplete"
                    (optionSelected)="onStateSelected($event.option.value)"
                    [displayWith]="getDisplayName">
                    <mat-option
                      *ngFor="let state of filteredState | async"
                      [value]="state"
                    >
                      {{getDisplayName(state)}}
                    </mat-option>
                  </mat-autocomplete>

                  <br />
                  <div
                    class="error-text"
                    *ngIf="submitted && secondStepForm.controls['state'].errors"
                  >
                    <p *ngIf="secondStepForm.controls['state'].errors.required">
                      State/Province/Region is required.
                    </p>
                    <p *ngIf="secondStepForm.controls['state'].errors.invalidValue">
                      Please select a valid State/Province/Region.
                    </p>
                  </div>
                </div>
                <div *ngIf="!hideField" class="states">
                  <label class="state">City *</label>
                  <input
                    type="text"
                    placeholder="Enter head office city"
                    [ngClass]="{
                      'error-input':
                        secondStepForm.controls['city'].invalid && submitted
                    }"
                    formControlName="city"
                    [matAutocomplete]="cityAuto"
                  />
                  <mat-autocomplete
                    #cityAuto="matAutocomplete"
                    (optionSelected)="onCitySelected($event.option.value)"
                    [displayWith]="getDisplayName">
                    <mat-option
                      *ngFor="let city of filteredCity | async"
                      [value]="city"
                    >
                      {{getDisplayName(city)}}
                    </mat-option>
                  </mat-autocomplete>

                  <div
                    class="error-text"
                    *ngIf="submitted && secondStepForm.controls['city'].errors"
                  >
                    <p *ngIf="secondStepForm.controls['city'].errors.required">
                      City is required.
                    </p>
                    <p *ngIf="secondStepForm.controls['city'].errors.invalidValue">
                      Please select a valid City.
                    </p>
                  </div>
                  <br />
                </div>
              </div>
              <br />
              <div class="text-field-input">
                <label for="address">Head Office Street Address</label> <br />
                <input
                  id="address"
                  type="text"
                  placeholder="Enter company street address"
                  formControlName="headOfficeAddress"
                />
                <br />
              </div>
              <div class="text-field-input">
                <label for="website">Company Website *</label> <br />
                <input
                  [ngClass]="{
                    'error-input':
                      secondStepForm.controls['companyWebSite'].invalid &&
                      submitted
                  }"
                  id="website"
                  type="text"
                  placeholder="Enter company website/URL"
                  formControlName="companyWebSite"
                  required
                />
                <div
                  class="error-text"
                  *ngIf="
                    submitted &&
                    secondStepForm.controls['companyWebSite'].errors
                  "
                >
                  <p
                    *ngIf="
                      secondStepForm.controls['companyWebSite'].errors.required
                    "
                  >
                    Company Website is required.
                  </p>
                  <p
                    *ngIf="
                      secondStepForm.controls['companyWebSite'].errors.pattern
                    "
                  >
                    Enter a valid website by entering https://www or http://www before the domain name.
                  </p>
                </div>
              </div>
              <br />
              <div class="text-field-input">
                <label for="contact">Company Phone Number *</label>
                <br />
                <input
                  [ngClass]="{
                    'error-input':
                      secondStepForm.controls['companyPhoneNumber'].invalid &&
                      submitted
                  }"
                  id="contact"
                  type="number"
                  (keypress)="numericOnly($event)"
                  placeholder="Enter company contact number"
                  formControlName="companyPhoneNumber"
                  required
                />
                <div
                  class="error-text"
                  *ngIf="
                    submitted &&
                    secondStepForm.controls['companyPhoneNumber'].errors
                  "
                >
                  <p
                    *ngIf="
                      secondStepForm.controls['companyPhoneNumber'].errors
                        .required
                    "
                  >
                    Company Phone Number is required.
                  </p>
                </div>
              </div>
              <br />
              <div class="text-field-input">
                <label for="email">Primary Contact *</label>
                <br />
                <input
                  [ngClass]="{
                    'error-input':
                      secondStepForm.controls['primaryContact'].invalid &&
                      submitted
                  }"
                  type="text"
                  placeholder="Enter full name of primary contact"
                  formControlName="primaryContact"
                  required
                />
                <div
                  class="error-text"
                  *ngIf="
                    submitted &&
                    secondStepForm.controls['primaryContact'].errors
                  "
                >
                  <p
                    *ngIf="
                      secondStepForm.controls['primaryContact'].errors.required
                    "
                  >
                    Primary Contact is required.
                  </p>
                </div>
              </div>
              <br />
              <div class="text-field-input">
                <label for="email">Industry *</label> <br />
                <input
                  type="text"
                  placeholder="Select your industry"
                  formControlName="industry"
                  [class.default]="
                      secondStepForm.get('industry').value === '' && !submitted
                    "
                  [ngClass]="{
                      'error-input':
                        secondStepForm.controls['industry'].invalid && submitted
                    }"
                  [matAutocomplete]="autoIndustry"
                />
                <mat-autocomplete
                  [displayWith]="getDisplayName"
                  #autoIndustry="matAutocomplete"
                >
                  <mat-option
                    *ngFor="let food of filteredIndustries | async"
                    [value]="food"
                  >
                    {{ getDisplayName(food)}}
                  </mat-option>
                </mat-autocomplete>

                <div
                  class="error-text"
                  *ngIf="
                    submitted && secondStepForm.controls['industry'].errors
                  "
                >
                  <p
                    *ngIf="secondStepForm.controls['industry'].errors.required"
                  >
                    Industry is required.
                  </p>
                  <p *ngIf="secondStepForm.controls['industry'].errors.invalidValue">
                    Please select a valid Industry.
                  </p>
                </div>
              </div>
              <br />
              <div class="text-field-input">
                <label for="email">Company Size *</label> <br />

                <mat-form-field
                  [class.default]="
                      secondStepForm.get('companySize').value === '' &&
                      !submitted
                    "
                  [ngClass]="{
                      'error-input':
                        secondStepForm.controls['companySize'].invalid &&
                        submitted
                    }"
                >
                  <mat-select
                    placeholder="Select company size"
                    formControlName="companySize"
                  >
                    <mat-option
                      *ngFor="let food of companySizes"
                      [value]="food.value"
                    >
                      {{ food.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  class="error-text"
                  *ngIf="
                    submitted && secondStepForm.controls['companySize'].errors
                  "
                >
                  <p
                    *ngIf="
                      secondStepForm.controls['companySize'].errors.required
                    "
                  >
                    Company Size is required.
                  </p>
                </div>
              </div>
              <div>
                <button
                  [ngClass]="{ 'sign-up-active': secondStepForm.valid }"
                  class="sign-up"
                  (click)="sendVerifyPhoneNumber()"
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </mat-step>

        <mat-step>
          <div class="content-left-side">
            <div class="profile-photo-box">
              <div class="profile-photo-box-content">
                <img
                  *ngIf="!url"
                  src="../../../../assets/auth/Shape.svg"
                  alt="picture"
                />
                <img class="selected-img" [src]="url" *ngIf="url" />
              </div>
              <p *ngIf="role === 'individual'">
                Welcome {{ firstStepForm1.get("firstName").value }}!
              </p>
              <p *ngIf="role !== 'individual'">
                Welcome {{ firstStepForm.get("name").value }}!
              </p>
            </div>
            <div class="step-box" [ngClass]="{ 'step-box-active': step === 1 }">
              <p>About</p>
              <div
                class="check-oval"
                [ngClass]="{ 'check-oval-done': step === 3 || step === 2 }"
              ></div>
            </div>
            <div
              *ngIf="role !== 'individual'"
              class="step-box"
              [ngClass]="{ 'step-box-active': step === 2 }"
            >
              Details
              <div
                class="check-oval"
                [ngClass]="{ 'check-oval-done': step === 3 }"
              ></div>
            </div>
            <div
              class="step-box"
              [ngClass]="{
                'step-box-active':
                  (step === 3 && role !== 'individual') || step === 2
              }"
            >
              Verification
              <div class="check-oval"></div>
            </div>
          </div>
          <div class="content-right-side">
            <header>
              <div class="header-title">
                <h2>Verification</h2>
              </div>
              <p *ngIf="role === 'individual'">
                Step <span>{{ step }}</span> of 2
              </p>
              <p *ngIf="role !== 'individual'">
                Step <span>{{ step }}</span> of 3
              </p>
            </header>
            <div class="verification-content">
              <div class="verification-content-text-box">
                <h2 class="sub-title">Let's Verify You</h2>
                <p class="paragraph2">
                  Enter the 4-digit number that was sent to <br />
                  <b><{{ activeForm.value?.phoneNumber?.toString() }}></b>
                    to authenticate your account.
                </p>
                <div class="verification-form">
                  <ng-otp-input
                    #ngOtpInput
                    (onInputChange)="onOtpChange($event)"
                    *ngIf="showOtpComponent"
                    [config]="config"
                  >
                  </ng-otp-input>
                </div>
                <p
                  class="paragraph verification-text"
                  (click)="sendVerifyPhoneNumber(true)"
                >
                  Re-send Verification Code
                </p>
                <button
                  (click)="changePhoneNumber()"
                  class="paragraph verification-text"
                >
                  Change Phone Number
                </button>
              </div>
              <div class="verification-content-photo-box">
                <img
                  src="../../../../assets/auth/entercode.png"
                  alt="enter code"
                />
              </div>
            </div>
            <div class="button-box">
              <button
                [ngClass]="{
                  'sign-up-active':
                    (firstStepForm1.valid || firstStepForm.valid) && verifyCode?.length === 4
                }"
                class="sign-up"
                (click)="sendCode()"
              >
                Let's Go!
              </button>
              <button class="sign-up sign-up-active" (click)="signUp()">
                Skip Verification
              </button>
            </div>
          </div>
        </mat-step>

        <mat-step [stepControl]="changeForm">
          <div class="content-left-side">
            <div class="profile-photo-box">
              <div class="profile-photo-box-content">
                <img
                  *ngIf="!url"
                  src="../../../../assets/auth/Shape.svg"
                  alt="picture"
                />
                <img class="selected-img" [src]="url" *ngIf="url" />
              </div>
              <p *ngIf="role === 'individual'">
                Welcome {{ firstStepForm1.get("firstName").value }}!
              </p>
              <p *ngIf="role !== 'individual'">
                Welcome {{ firstStepForm.get("name").value }}!
              </p>
            </div>
            <div class="step-box" [ngClass]="{ 'step-box-active': step === 1 }">
              <p>About</p>
              <div
                class="check-oval"
                [ngClass]="{ 'check-oval-done': step === 3 || step === 2 }"
              ></div>
            </div>
            <div
              *ngIf="role !== 'individual'"
              class="step-box"
              [ngClass]="{ 'step-box-active': step === 2 }"
            >
              Details
              <div
                class="check-oval"
                [ngClass]="{ 'check-oval-done': step === 3 }"
              ></div>
            </div>
            <div
              class="step-box"
              [ngClass]="{
                'step-box-active':
                  (step === 3 && role !== 'individual') || step === 2
              }"
            >
              Verification
              <div class="check-oval"></div>
            </div>
          </div>
          <div class="content-right-side">
            <div class="back-box">
              <button class="back" matStepperPrevious>
                <img src="./../../../../assets/arrow.svg" alt="" />
                Verification
              </button>
            </div>
            <header>
              <div class="header-title">
                <h2>Update Phone Number to Verify You</h2>
              </div>
            </header>
            <div class="verification-content change-phone-content">
              <form class="about-form" [formGroup]="changeForm">
                <div class="text-field-input">
                  <label for="email">{{role === 'individual' ? 'Country I live in *' : 'Country *'}}</label> <br />
                  <input
                    id="changeCountry"
                    type="text"
                    placeholder="{{role === 'individual' ? 'Select country for mobile verification' : 'Enter location of headquarters'}}"
                    [matAutocomplete]="auto2"
                    [ngClass]="{'error-input': changeForm.controls['phoneNumber'].invalid && submitted}"
                    formControlName="country"
                  />
                  <mat-autocomplete
                    [displayWith]="getDisplayName"
                    #auto2="matAutocomplete">
                    <mat-option
                      *ngFor="
                        let country of filteredCountryChangePhoneNumber | async
                      "
                      [value]="country"
                    >
                      {{getDisplayName(country)}}
                    </mat-option>
                  </mat-autocomplete>
                  <img
                    class="mat-select-arrow select-arrow"
                    src="./../../../../assets/icons/select.svg"
                    alt=""
                  />
                  <div
                    class="error-text"
                    *ngIf="submitted && changeForm.controls['country'].errors"
                  >
                    <p *ngIf="changeForm.controls['country'].errors.required">
                      {{role === 'individual' ? 'Country I live in is required.' : ' Country is required.'}}
                    </p>
                  </div>
                </div>
                <br />
                <div class="text-field-input">
                  <label for="phoneNumber">New Mobile Number *</label>
                  <br />
<!--                  <p *ngIf="!selectCountryChangePhone" class="code-country">+XXX</p>-->
                  <p *ngIf="selectCountryChangePhone" class="code-country">
                    {{ selectCountryChangePhone }}
                  </p>
                  <input
                    class="phone-number-code"
                    [ngClass]="{ 'move-right': selectCountryChangePhone && selectCountryChangePhone.length < 5,
                      'move-right-long': selectCountryChangePhone && selectCountryChangePhone.length >= 5,
                       'error-input': changeForm.controls['phoneNumber'].invalid && submitted}"
                    type="number"
                    (keypress)="numericOnly($event)"
                    placeholder="Enter new mobile number"
                    formControlName="phoneNumber"
                    required
                  />
                  <div
                    class="error-text"
                    *ngIf="
                    submitted && changeForm.controls['phoneNumber'].errors
                  "
                  >
                    <p
                      *ngIf="
                      changeForm.controls['phoneNumber'].errors.required
                    "
                    >
                      Enter a valid mobile number with no spaces.
                    </p>
                  </div>
                </div>
                <button
                  (click)="sendVerifyPhoneNumber(false, true)"
                  class="paragraph verification-text"
                >
                  Re-send Verification Code
                </button>
              </form>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</section>
