<div class='block-container'>
  <div class='title'>Delete Account</div>
  <hr />
  <p>If you decide to delete your account, all aspects about your account will be permanently deleted such as your
    profile, account settings, brides and history. If you want to re-join, you will have to sign up again.</p>
  <form>
    <label>
      Type in your login password to confirm account deletion
      <input
        [type]='fieldType'
        placeholder='Enter password'
        [formControl]='password'
        [class.error]='password.invalid && password.touched'
      >
      <button class="mask" (click)="changePasswordType()">
        <img
          *ngIf="fieldType === 'password'"
          src="../../../../../assets/eye.svg"
          alt=""
        />
        <img
          *ngIf="fieldType === 'text'"
          src="../../../../../assets/Eye.slash.svg"
          alt=""
        />
      </button>
      <div
        class='error'
        *ngIf='password.invalid && password.touched'
      >
        <div *ngIf="password.errors?.['required']">
          Password is required.
        </div>
      </div>
    </label>
  </form>
  <button [disabled]='password.invalid' (click)='deleteAccount()' class='primary-btn'>
    Delete My Account
  </button>
</div>
