import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { IProvider } from '../../../interfaces/provider';
import { AuthDataService } from '../../../services/auth-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-provider-for-brief',
  templateUrl: './search-provider-for-brief.component.html',
  styleUrls: ['./search-provider-for-brief.component.scss']
})
export class SearchProviderForBriefComponent {
  public dialogRef = inject(MatDialogRef<SearchProviderForBriefComponent>);

  private authDS = inject(AuthDataService);
  private router = inject(Router);

  public filteredProviders: IProvider[] = [];
  public selectedProvider: IProvider;
  public term = '';
  skip = 0;
  limit = 20;

  defaultLogo = '../../../assets/pvoviderLogoPlaceholder.png';

  public doSearch(item: string, isChange?: boolean) {
    this.selectedProvider = null;
    if (item.length < 3) {
      return;
    }
    if (isChange) {
      this.filteredProviders = [];
      this.skip = 0;
      this.limit = 20;
    }
    const sortOf = 0;
    const filters: any = {
      filterOptions: {
        search: item,
      },
      sortingOptions: sortOf >= 0 ? { sortType: sortOf } : { sortType: 0 },
    };
    this.authDS
      .doSearch(filters, this.skip, this.limit)
      .pipe(first())
      .subscribe((res: { data: IProvider[], totalCount: number }) => {
        this.filteredProviders = [];
        const providers: IProvider[] = res?.data ? res.data : [];
        this.filteredProviders.push(...providers);
      });
  }

  clear() {
    this.selectedProvider = null;
    this.term = '';
  }

  selectProvider(provider?: IProvider) {
    this.term = this.term.trim();
    if (provider) {
      this.term = provider.companyName;
      console.log(provider);
      this.selectedProvider = provider;
    }
  }

  onScrollDown() {
    this.skip += 20;
    this.limit += 20;

    this.appendItems();
  }

  onScrollUp() {
    if (this.limit <= 20) {
      return;
    }
    this.limit -= 20;
    this.prependItems();
  }

  appendItems() {
    this.addItems('push');
  }

  prependItems() {
    this.addItems('unshift');
  }

  addItems(method: string) {
    if (method === 'push') {
      this.doSearch(this.term);
    }
  }

  provider() {
    this.dialogRef.close(undefined);
    return this.router.navigate(['/provider-profile'], { queryParams: { id: this.selectedProvider?._id }});
  }

  next() {
    if (!this.selectedProvider) return;
    this.dialogRef.close(this.selectedProvider);
  }

  close() {
    this.dialogRef.close(undefined);
  }
}
