import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home.component';
import {SharedModule} from '../shared/shared.module';
import {HomeRoutingModule} from './home-routing.module';
import {LoginComponent} from '../popups/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SignUpPopupComponent} from '../popups/sign-up/sign-up-popup.component';
import {FiltersPopupComponent} from '../popups/filters/filters-popup.component';
import {MatIconModule} from '@angular/material/icon';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatSliderModule} from '@angular/material/slider';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  declarations: [HomeComponent, LoginComponent, SignUpPopupComponent, FiltersPopupComponent],
  exports: [
    HomeComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    MatMenuModule,
    MatDialogModule,
    SharedModule,
    HomeRoutingModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSliderModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
    InfiniteScrollModule
  ]
})
export class HomeModule {
}
