import { Component } from '@angular/core';

@Component({
  selector: 'app-close-brief-confirmation',
  templateUrl: './close-brief-confirmation.component.html',
  styleUrls: ['./close-brief-confirmation.component.scss']
})
export class CloseBriefConfirmationComponent {

}
