import { Injectable } from '@angular/core';
import { interval, Subject, takeWhile } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthDataService } from '../../../services/auth-data.service';

@Injectable({
    providedIn: 'root'
})
export class AnimatedLoaderService {
    public onEnd = new Subject<boolean>();

    public showFirst = new Subject<void>();
    public showSecond = new Subject<void>();

    constructor(private authDS: AuthDataService) {}

    public showLoadingBrief(projectId: string) {
      window.scroll(0, 0);
      document.querySelector('html').style.overflowY = 'hidden';
      this.showFirst.next();
      interval(7000)
        .pipe(
          switchMap(() => this.authDS.getIsDocReady(projectId)),
          takeWhile((res) => !res.isDocReady, true)
        )
        .subscribe((res) => {
          if (res.isDocReady) {
            this.showSecond.next();
          }
        });
    }

    public showLoadingDoc(projectId: string) {
      window.scroll(0, 0);
      document.querySelector('html').style.overflowY = 'hidden';
      this.showFirst.next();
      interval(7000)
        .pipe(
          switchMap(() => this.authDS.getIsDocWithoutBriefReady(projectId)),
          takeWhile((res) => !res.isDocReady, true)
        )
        .subscribe((res) => {
          if (res.isDocReady) {
            this.showSecond.next();
          }
        });
    }

    public stop() {
        document.querySelector('html').style.overflowY = 'scroll';
        this.onEnd.next(true);
    }
}
