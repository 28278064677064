import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-is-interested',
  templateUrl: './is-interested.component.html',
  styleUrls: ['./is-interested.component.scss']
})
export class IsInterestedComponent {
  constructor(
    private dialogRef: MatDialogRef<IsInterestedComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IsInterestedComponent,
  ) {
  }

  public dismiss(): void {
    this.dialogRef.close();
  }
}
