import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RelatedArticle } from '../../../../models/knowledge-hub/article';

@Component({
  selector: 'app-related-article-item',
  templateUrl: './related-article-item.component.html',
  styleUrls: ['./related-article-item.component.scss']
})
export class RelatedArticleItemComponent {
  @Input({ required: true }) relatedArticle: RelatedArticle;

  private router = inject(Router);

  protected defaultCoverImg = 'assets/articles/articleCardDefaultCover.png';

  toArticle() {
    return this.router.navigate(['/public-articles', this.relatedArticle.id]);
  }
}
