<div class="login-content">
  <img *ngIf="isFirstStep"
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick(false)"
  />

  <div *ngIf="isFirstStep" class="first-step">
    <div class="login-content-logo">
      <img src="../../../../assets/logo/Logo.svg" alt="" />
    </div>
    <p class="title">Great News!</p>

    <p class="paragraph2">
      There are about {{ data.numberProviders }} service providers that <br />
      match your initial preferences. <br />
      <br />
      We’re excited for you!
    </p>

    <button class="primary-btn" type="submit" [mat-dialog-close]="'next'">
      Continue
    </button>
    <p [mat-dialog-close]="'list'" class="view-list-btn">View the List</p>
  </div>

  <div *ngIf="isListView" class="list-view">
    <div class="top">
      <span (click)="showList(false)" class="back-btn"></span>
      <span class="small-title">Providers</span>
      <img
        class="close-icon"
        src="./../../../../assets/icons/x.svg"
        alt=""
        (click)="onNoClick(false)"
      />
    </div>

    <div class="providers-content"
         infinite-scroll
         [scrollWindow]="false"
         (scrolled)="onScrollDown($event)"
         (scrolledUp)="onScrollUp($event)">
      <div *ngFor="let provider of listArray" class="provider-card" (click)="onNoClick(true)" [routerLink]="['provider-profile']"
           [queryParams]="{id: provider?._id, fromPopup: true}">
        <span class="provider-logo"><img src="{{provider?.logo ?? '../assets/pvoviderLogoPlaceholder.png'}}" alt=""></span>
        <div class="provider-info">
          <span class="provider-name">{{provider?.companyName}}</span>
          <span class="provider-location">{{getProviderLocation(provider)}}</span>
        </div>
        <div class="provider-reputation">
          <span>REPUTATION:</span>
          <div class="rate-container">
            <div class="rate">
              <input
                disabled
                [checked]="provider?.clutchScore === 5"
                type="radio"
                id="rating10"
                name="rating"
                value="10"
                [class.isChecked]="provider?.clutchScore === 5"
              />
              <label for="rating10" title="5 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 4.5 && provider?.clutchScore != 5"
                type="radio"
                id="rating9"
                name="rating"
                value="9"
                [class.isChecked]="provider?.clutchScore >= 4.5 && provider?.clutchScore != 5"
              />
              <label class="half" for="rating9" title="4 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 4.5 && provider?.clutchScore >= 4"
                type="radio"
                id="rating8"
                name="rating"
                value="8"
                [class.isChecked]="provider?.clutchScore < 4.5 && provider?.clutchScore >= 4"
              />
              <label for="rating8" title="4 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 3.5 && provider?.clutchScore < 4"
                type="radio"
                id="rating7"
                name="rating"
                value="7"
                [class.isChecked]="provider?.clutchScore >= 3.5 && provider?.clutchScore < 4"
              />
              <label class="half" for="rating7" title="3 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 3.5 && provider?.clutchScore >= 3"
                type="radio"
                id="rating6"
                name="rating"
                value="6"
                [class.isChecked]="provider?.clutchScore < 3.5 && provider?.clutchScore >= 3"
              />
              <label for="rating6" title="3 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 2.5 && provider?.clutchScore < 3"
                type="radio"
                id="rating5"
                name="rating"
                value="5"
                [class.isChecked]="provider?.clutchScore >= 2.5 && provider?.clutchScore < 3"
              />
              <label class="half" for="rating5" title="2 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 2.5 && provider?.clutchScore >= 2"
                type="radio"
                id="rating4"
                name="rating"
                value="4"
                [class.isChecked]="provider?.clutchScore < 2.5 && provider?.clutchScore >= 2"
              />
              <label for="rating4" title="2 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 1.5 && provider?.clutchScore < 2"
                type="radio"
                id="rating3"
                name="rating"
                value="3"
                [class.isChecked]="provider?.clutchScore >= 1.5 && provider?.clutchScore < 2"
              />
              <label class="half" for="rating3" title="1 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 1.5 && provider?.clutchScore >= 1"
                type="radio"
                id="rating2"
                name="rating"
                value="2"
                [class.isChecked]="provider?.clutchScore < 1.5 && provider?.clutchScore >= 1"
              />
              <label for="rating2" title="1 star"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 0.5 && provider?.clutchScore < 1"
                type="radio"
                id="rating1"
                name="rating"
                value="1"
                [class.isChecked]="provider?.clutchScore >= 0.5 && provider?.clutchScore < 1"
              />
              <label class="half" for="rating1" title="1/2 star"></label>
            </div>
            <p class="score">{{ provider?.clutchScore }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
