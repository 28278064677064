<app-header></app-header>
<section class="container">
  <h2 class="title">Privacy Policy</h2>
  <div class="text-block">
    <p class="sub-title">Scope</p>
    <p class="paragraph">
      This Privacy Policy describes how we collect information from users of our
      Platform, those who use our products and services (the “Services”) but do
      not have accounts (“Visitors”), and those register and subscribe to our
      services (“Members”). It describes what we do with the information we
      collect, and the choices Visitors and Members have concerning the
      collection and use of such information. When this policy mentions “The
      Unicorn App,” “we,” “us,” or “our,” it refers to The Unicorn App platform
      operated by Thriller Innovations, Inc. that is responsible for your
      information under this Privacy Policy.  The practices described in this
      policy are subject to applicable laws in the places in which we operate.
      This means that we only engage in the practices described in this policy
      in a particular country or region if permitted under the laws of those
      places. Thus this privacy notice explains how and why The Unicorn App uses
      personal data about individual users of our website www.theunicorn.app
      (the “Website”) and iOS and Android mobile application (the “Apps”), and
      collectively (the “Service” or “Services”) that access our services
      through APIs and through third-parties, including Service users, user
      clients and/or Service Providers (whether prospective, current or past)
      (together, “you”). You should read this notice so that you know what we
      are doing with your personal data.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Data Protection Responsibility</p>
    <p class="paragraph">
      “Personal data” is any information that relates to an identifiable natural
      person. Your name, address and contact details are examples of your
      personal data, if they identify you. The term “process” means any activity
      relating to personal data, including collection, storage, use and
      transmission. Whether you are a client user, a Service Provider or just a
      Service user (or a combination of those), The Unicorn App is a
      “controller” of your personal data. This means that we make decisions
      about how and why we process your personal data and are responsible for
      making sure it is used in accordance with data protection laws.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Information We Collect</p>
    <p class="paragraph">
      If you are reviewing this then you will fall into at least one of the
      following categories of individuals about whom we process personal data:
    </p>
    <br />
    <br />
    <p class="paragraph list">
      <span class="item">Prospective, current or past end user such as individual user,
          start-up user, enterprise user of The Unicorn App who registers for an
          account and/or place a project request (“Clients”);</span>
      <span class="item">Current or past service providers that are onboarded into The Unicorn
          App platform to provide a service to Clients, who we identify as being
          of interest to our Service Providers and who we may then contact with
          potential business opportunities relating to our Service Providers
          (“Providers”); and/or</span>
      <span class="item">Individuals that access our Service (“Service Users”). Accordingly, we
          will collect and process different types of personal data about you
          depending on which of these categories are relevant to you. The
          different types of personal data that we collect and the sources we
          collect it from are summarised below.</span>
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Data Categories</p>
    <br />
    <p class="paragraph list">
      <span class="item"><b>Account Data:</b> The Unicorn App profile and details, user names,
          passwords and other log-in information, alert/activity history and
          referral data, Analysis we may undertake on account usage from time to
          time, billing details (where applicable), including card details and
          billing address.</span>
      <span class="item"><b>Analytics Data:</b> Information to monitor and analyse use of our
          services, for example for our service’s technical administration, to
          increase our service’s functionality and user-friendliness, and to
          verify users have the authorisation needed to process their requests.</span>
      <span class="item"><b>Basic Data:</b> Basic contact information and identity information.</span>
      <span class="item"><b>Communication Data:</b> Communications we may have with you or
          other information which you may voluntarily provide, including images,
          videos or documents you may upload as part of a project brief. Your
          contact/marketing preferences.</span>
      <span class="item"><b>Website Data:</b> Information about your device, which may include
          the device’s IP address, browser type, browsing history, preferences,
          identification numbers, your mobile carrier, date and time stamps and
          other interactions with the service, Questions raised with us,
          Information you provide us when you visit our Website.</span>
      <span class="item"><b>Professional Data:</b> For Service Providers, details about the
          service(s) you provide The Unicorn App profile, The Unicorn App
          analysis we may undertake from time to time, Account details and
          activity history, user names, passwords and other log-in information.</span>
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Data Sources</p>
    <p class="paragraph">
      We obtain data from several different sources. For example, this could be
      from you, our Service, a website as well as third party sources such as
      social media and online listing services.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">How we use your information</p>
    <p class="paragraph">
      We use, store, and process information, including personal information,
      about you to provide, understand, improve, and develop The Unicorn App
      platform, create and maintain a trusted and safer environment and comply
      with our legal obligations. We process your personal data for various
      purposes, which are relevant to your project brief, and relationship and
      engagement with us. We are required by law to always have a “lawful basis”
      for processing your personal data. We use the personal data we collect for
      a number of different reasons, including to provide our services to you or
      with you, to communicate with you, to verify your identity, to make sure
      our Services are functioning properly and provide the best services to
      you, to keep accurate records and for legal reasons. We rely on a number
      of different lawful grounds or ‘bases’ to process your personal data.
      These include that we have your consent to the processing, we need to
      process your personal data to enter into or perform a contract with you,
      we need to process your personal data to comply with our legal
      obligations, and/or the processing is necessary for our legitimate
      interests (or the legitimate interests of a third party). It is important
      that you are aware that where we have indicated that our processing of
      your personal data is either (i) necessary for us to comply with a legal
      obligation; or (ii) necessary for us to take steps, at your request, to
      potentially enter into a contract with you, or to perform it, and you
      choose not to provide the relevant personal data to us, we may not be able
      to enter into or continue our contract with you or the provision of our
      services. We may also convert your personal data into statistical or
      aggregated form to better protect your privacy, or so that you are not
      identified or identifiable from it. Anonymized data cannot be linked back
      to you. We may use it to conduct research and analysis, including to
      produce statistical research and reports. For example, to help us
      understand and improve the use of our Service.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Sharing Your Information</p>
    <p class="paragraph">
      We may use certain trusted third party companies and individuals to help
      us provide, analyse, and improve our services (including but not limited
      to Client/Service Provider verification, data storage, maintenance
      services, database management, web analytics, payment processing, and
      improvement of our services). These third parties will process your
      personal data on our behalf (as our processor). We will disclose your
      personal data to these parties so that they can perform those functions
      necessary functions. We may also share your information with third
      parties, who then use your personal data for their own purposes. For
      example, to process payments from you or where you choose to access our
      services through the website or mobile application. These organizations
      will also use your personal data for their own, separate purposes (also as
      a “controller”) – they will have their own privacy notices which you
      should read, and they have their own responsibilities to comply with
      applicable data protection laws. In certain circumstances, where
      necessary, we may disclose your personal data to:
    </p>
    <br />
    <br />
    <p class="paragraph list">
      <span class="item">Consultants and professional advisors including legal advisors, auditors and accountants;</span>
      <span class="item">Business partners and joint ventures;</span>
      <span class="item">A prospective seller or buyer of our business and their advisors;</span>
      <span class="item">insurers;</span>
      <span class="item">Courts, court-appointed persons/entities, receivers and liquidators;</span>
      <span class="item">Third parties where necessary to comply with a legal obligation, to enforce a contract or to protect the rights, property or safety of our employees, customers or others; and</span>
      <span class="item">To governmental departments, local government, statutory and regulatory bodies (in Canada).</span>
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Where We Share Your Information</p>
    <p class="paragraph">
      When we share personal data about you (such as your name, contact
      information, social media), as set out above, we may include a transfer of
      your personal data to recipients outside Canada. We do this so that
      Service Providers are able to contact you and have assurance that a
      project brief is being submitted by a real person with an online presence
      that is not of any concern to a Service Provider. If any disclosures of
      personal data mean that your personal data will be transferred outside
      Canada, we will only make that transfer in accordance with our obligations
      under applicable data protection laws.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Retainment Period</p>
    <p class="paragraph">
      We will only retain your personal data for a limited period of time and
      for no longer than is necessary for the purposes for which we are
      processing it for (including as necessary to comply with our legal or
      regulatory obligations, resolve disputes, and enforce our agreements).
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Your Rights</p>
    <p class="paragraph">
      You have certain legal rights in relation to any personal data about you
      which we hold. If you have any concerns or queries about this notice or
      how we process your personal data, please contact us at
      support@theunicorn.app.
    </p>
    <br />
    <br />
    <p class="paragraph list">
      <span class="item">The Service retains user profile and other information for as long as you maintain your account.</span>
      <span class="item">The Service retains transaction, location, usage, and other information for 7 years in connection with regulatory, tax, insurance, or other requirements in the places in which it operates. The Service thereafter deletes or anonymizes such information in accordance with applicable laws.</span>
      <span class="item">Users may request deletion of their accounts at any time by contacting us at support@theunicorn.app. Following verification of such account deletion requests, the Service deletes the information that it is not required to retain and restricts access to or use of any information it is required to retain.</span>
      <span class="item">The Service may be unable to delete your account, such as if there is an outstanding credit on your account or an unresolved claim or dispute. Upon resolution of the issue preventing deletion, the Service will delete your account as described above.</span>
      <span class="item">The Service may also retain certain information if necessary, for its legitimate business interests, such as fraud prevention and enhancing users’ safety and security. For example, if the Service shuts down a user’s account because of unsafe behaviour or security incidents, the Service may retain certain information about that account to prevent that user from opening a new Unicorn App account in the future.</span>
      </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Cookies</p>
    <p class="paragraph">
      Cookies are small text files that are stored on your browser or device by
      websites, apps, online media, and advertisements that are used to remember
      your browser or device during and across website visits. We also utilize
      other technologies that may identify you or the devices you use. For
      example, “pixel tags” (also called beacons) are small blocks of code
      installed on (or called by) a webpage, app, or advertisement which can
      retrieve certain information about your device and browser, including for
      example: device type, operating system, browser type and version, website
      visited, time of visit, referring website, IP address, advertising
      identifiers, and other similar information, including the small text file
      (the cookie) that uniquely identifies the device. Pixels provide the means
      by which third parties can set and read browser cookies from a domain that
      they do not themselves operate and collect information about visitors to
      that domain, typically with the permission of the domain owner. “Local
      storage” refers generally to other places on a browser or device where
      information can be stored by websites, ads, or third parties (such as
      HTML5 local storage and browser cache). “Software Development Kits” (also
      called SDKs) function like pixels and cookies but operate in the mobile
      app context where pixels and cookies cannot always function. The primary
      app developer can install pieces of code (the SDK) from partners in the
      app, and thereby allow the partner to collect certain information about
      user interaction with the app and information about the user device and
      network information. The Service uses cookies and other identification
      technologies on our app, website, emails, and online ads for purposes
      described in this policy. The Service uses cookies and similar
      technologies for purposes such as:
      <br />
      <br />
    </p>
    <p class="paragraph list">
      <span class="item">Authenticating users</span>
      <span class="item">Remembering user preferences and settings</span>
      <span class="item">Determining the popularity of content</span>
      <span class="item">Delivering and measuring the effectiveness of advertising campaigns</span>
      <span class="item">Analyzing site traffic and trends, and generally understanding the online behaviours and interests of people who interact with our services.</span>
    </p>
    <br />
    <br />
    <p class="paragraph">
      We may also allow others to provide audience measurement and analytics
      services for us, to serve advertisements on our behalf across the
      Internet, and to track and report on the performance of those
      advertisements. These entities may use cookies, web beacons, SDKs, and
      other technologies to identify your device when you visit our site and use
      our services, as well as when you visit other online sites and services.
      <br />
      <br />
      To facilitate the most relevant ads possible, Service works with various
      service providers who assist us in delivering similar ads to end users
      across devices and platforms. For example, we work with social media
      advertising services to provide you with relevant ads based on your
      Service activity through their media channels. We may also use service
      providers to provide you with a similar ad on a mobile website or mobile
      application as with a traditional website ad.
      <br />
      <br />
      You have the right to choose whether to accept cookies. However, they are
      an important part of how our Services work, so you should be aware that if
      you choose to refuse or remove cookies, this could affect the availability
      and functionality of the Services.
      <br />
      <br />
      Most web browsers are set to accept cookies by default. If you prefer, you
      can usually choose to set your browser to remove or reject browser
      cookies. To do so, please follow the instructions provided by your browser
      which are usually located within the “Help” or “Preferences” menu. Some
      third parties also provide the ability to refuse their cookies directly by
      clicking on an opt-out link, and we have indicated where this is possible
      in the table below.
      <br />
      <br />
      For mobile users, you have controls in your device Operating System that
      enables you to choose whether to allow cookies or share your advertising
      ID with Services like The Unicorn App or our advertising service
      providers. For information on controlling your mobile choices, you can
      visit www.networkadvertising.org/mobile-choices.<br />
      <br />
      To help control or block certain ads in mobile applications, you may
      choose to download and utilize the DAA mobile
      app, https://youradchoices.com/appchoices.
      <br />
      <br />
      The following sets out the different categories of cookies that our
      Services use and why we use them. The lists of third-party cookie
      providers are intended merely as illustrative and should not be viewed as
      a comprehensive list.
    </p>
    <br />
    <br />
    <p class="paragraph list">
      <span class="item"><b>Strictly Necessary:</b>These cookies (including local storage and similar technologies) are essential to enable your use of the site or services, such as assisting with your account login, so we can show you the appropriate experience and features such as your account information, session/project brief history, and to edit youraccount settings. They may also provide authentication, site security, and help localize the language based on thegeography of your visit.</span>
      <span class="item"><b>Site features and Services:</b> These provide functionality that helps us deliver products and Services. For example, cookies help you log in by pre-filling fields. We may also use cookies and similar technologies to help us provide you and others with social plugins and other customized content and experiences, such as makingsuggestions to you and others.</span>
      <span class="item"><b>Analytics, performance, and research:</b> These are used to understand, improve, and research products and Services, including when you access the Service website, mobile app or related Services. For example, we may use cookies to understand how you are using mobile app, site features, and segmenting audiences for feature testing. We and our partners may use these technologies and the information we receive to improve and understand how you usewebsites, apps, products, services and ads.</span>
      <span class="item"><b>Advertising:</b> These cookies and pixels are used to deliver relevant ads, track email marketing or ad campaign performance and efficiency. For example, we and our ad partners may rely on information gleaned through these cookies to serve you ads that may be interesting to you on other websites. Similarly, our partners may use a cookie, attribution service or another similar technology to determine whether we have served an ad and how it performed or provide us with information about how you interact with them.</span>
      <span class="item"><b>Mobile-Specific:</b> These service providers use various technologies to deliver relevant mobile ads, track mobile marketing or mobile ad campaign performance and efficiency.</span>
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">General</p>
    <p class="paragraph">
      We may update this notice from time to time to reflect changes to the type
      of personal data that we process and/or the way in which it is processed.
      We also encourage you to check this notice on a regular basis. If you want
      more information about any of the subjects covered in this privacy notice
      or if you would like to discuss any issues or concerns with us, you can
      contact us at <a href="mailto:https://support@theunicorn.app"
        >support@theunicorn.app.</a
      >
    </p>
  </div>
</section>
