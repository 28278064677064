import {
  Component,
  DestroyRef,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import { AuthDataService } from '../../../services/auth-data.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ILogin, UserTypesAccount } from '../../../interfaces/auth-interfaces';
import { catchError, first, skipWhile, switchMap } from 'rxjs/operators';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { SignUpPopupComponent } from '../sign-up/sign-up-popup.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ChooseAccountTypeComponent } from '../choose-account-type/choose-account-type.component';
import { EMPTY, throwError } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public typePassword = 'password';
  showPassword = false;
  submitted = false;

  public loginForm: UntypedFormGroup = this.fb.group({
    email: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern(
          '^[aA-zZ0-9._%+-]+@[aA-zZ0-9.-]+\\.[aA-zZ]{2,4}$',
        ),
      ],
    ],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private authDS: AuthDataService,
    private fb: UntypedFormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    private ngxService: NgxUiLoaderService,
    public destroyRef: DestroyRef,
  ) {
  }

  @Output() sendLoginEmitter: EventEmitter<ILogin> =
    new EventEmitter<ILogin>();

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public doLogin() {
    this.submitted = true;

    if (
      this.loginForm.controls.email.valid &&
      this.loginForm.controls.password.value?.length &&
      this.loginForm.controls.password.value?.length < 6
    ) {
      this.snackbar.open('Password must be at least 6 characters.', '', {
        duration: 2500,
        horizontalPosition: 'center',
      });
      return;
    }

    if (
      this.loginForm.controls.email.invalid &&
      this.loginForm.controls.password.valid
    ) {
      if (this.loginForm.invalid) {
        this.snackbar.open('Enter your email address.', '', {
          duration: 2500,
          horizontalPosition: 'center',
        });
        return;
      }
    }

    if (
      this.loginForm.controls.email.valid &&
      this.loginForm.controls.password.invalid &&
      !this.loginForm.controls.password.value?.length
    ) {
      if (this.loginForm.invalid) {
        this.snackbar.open('Enter your password.', '', {
          duration: 2500,
          horizontalPosition: 'center',
        });
        return;
      }
    }

    if (this.loginForm.invalid) {
      this.snackbar.open('Enter your email address and password.', '', {
        duration: 2500,
        horizontalPosition: 'center',
      });
      return;
    }

    const loginData = {
      email: this.loginForm.get('email').value.toLowerCase(),
      password: this.loginForm.get('password').value,
    };

    this.ngxService.start();

    this.authDS
      .doLogin(loginData)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(error => {
          this.snackbar.open(error.error.message, '', {
            duration: 2500,
            horizontalPosition: 'center',
          });
          this.ngxService.stop();
          return throwError(error);
        }),
        switchMap(token => {
          localStorage.setItem('token', token.tokenId);
          this.authDS.setUserToken(token.tokenId);
          this.onNoClick();
          this.ngxService.stop();
          return this.authDS.getUserProfile();
        }),
      )
      .subscribe(user => {
        if (user.user.typeAccount !== UserTypesAccount.Multiple) {
          const redirectTo =
            user.user.typeAccount === UserTypesAccount.Client
              ? this.data?.redirectTo ?? '/'
              : '/provider/dashboard';
          if (user.user.typeAccount === UserTypesAccount.Client && this.data?.fromCreateBrief) {
            this.router.navigate([redirectTo], { queryParams: { fromCreateBrief: true } });
          } else {
            this.router.navigate([redirectTo]);
          }
          this.ngxService.stop();
          this.dialogRef.close();
        } else {
          this.dialog
            .open(ChooseAccountTypeComponent, {
              autoFocus: false,
              width: '800px',
              data: {
                redirectTo: this.data?.redirectTo,
                fromCreateBrief: this.data?.fromCreateBrief,
              },
            })
            .afterClosed()
            .subscribe(res => {
              if (!res) {
                this.authDS.forceLogout();
              }
            });
          this.ngxService.stop();
          this.dialogRef.close();
          return EMPTY;
        }
      });
  }

  public openLoginPopup(): void {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      autoFocus: false,
      height: '600px',
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        skipWhile(result => !result),
      )
      .subscribe(result => this.sendLoginEmitter.emit(result));
  }

  public changePasswordType(event) {
    if (event.detail === 0) return;
    if (this.typePassword === 'password') {
      this.typePassword = 'text';
      this.showPassword = true;
    } else {
      this.typePassword = 'password';
      this.showPassword = false;
    }
  }

  public openSignUpPopup(): void {
    this.onNoClick();
    const dialogRef = this.dialog.open(SignUpPopupComponent, {
      autoFocus: false,
      height: '600px',
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        skipWhile(result => !result),
      )
      .subscribe(result => this.sendLoginEmitter.emit(result));
  }
}
