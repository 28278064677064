import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ILogin } from "../../../interfaces/auth-interfaces";
import { AuthDataService } from "../../../services/auth-data.service";
import { MessageSentComponent } from "../message-sent/message-sent.component";
import { first } from "rxjs/operators";
import { IProvider } from "src/app/interfaces/provider";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: "app-let-us",
  templateUrl: "./let-us.component.html",
  styleUrls: ["./let-us.component.scss"],
})
export class LetUsComponent {
  @Output() sendLoginEmitter: EventEmitter<ILogin> = new EventEmitter<ILogin>();

  public letUsForm: UntypedFormGroup = this.fb.group({
    email: ["", [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    name: ["", [Validators.required, Validators.minLength(5)]],
    needUpdate: ["", [Validators.required, Validators.minLength(5)]],
    providerName: ["", [Validators.required]],
    companyWebSite: [
      "",
      [
        Validators.required,
        Validators.pattern(
          '^(?:http(s)?:\\/\\/)[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$'
        ),
      ],
    ],
    myRole: ["", [Validators.required]],
    contactPhoneNumber: ["", [Validators.required, Validators.minLength(6)]],
  });
  public submitted = false;
  public isValidForm = false;
  public providers: string[] = [];
  public filteredProviders: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<LetUsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ILogin,
    private authDS: AuthDataService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public snackbar: MatSnackBar
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public doSearch(search?) {
    if (search.length < 3) return;

    if (this.filteredProviders.includes(search)) return;

    const filters: any = {
      filterOptions: {
        name: search
      },
      sortingOptions: { sortType: 0 },
    };

    this.authDS
      .doSearch(filters)
      .pipe(first())
      .subscribe((res: {data: IProvider[], totalCount: number}) => {
        const providers = res.data ? res?.data.map((provider) => provider.companyName) : [];
        this.filteredProviders = providers;
      });
  }

  public send() {
    this.submitted = true;

    if (this.letUsForm.valid) {
      this.isValidForm = true;
      this.letUsForm
        .get("contactPhoneNumber")
        .setValue(String(this.letUsForm.value.contactPhoneNumber));

      this.authDS
        .sendLetUsKnow(this.letUsForm.value)
        .pipe(first())
        .subscribe((res: any) => {
          this.dialog.open(MessageSentComponent, {
            autoFocus: false,
            width: "500px",
            data: {
              title: "Message Sent!",
              message: "The service provider profile update request has been sent. A representative from our team will be in touch!",
              button: "Great!",
            }
          });
          this.onNoClick();
        });
    }
  }
}
