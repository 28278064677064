import {Component, OnInit, ViewChild} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { AuthDataService } from '../../services/auth-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { IAlert } from '../../interfaces/alert-interface';
import { logging } from 'protractor';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit {
  public activeTab = 'Open';
  @ViewChild('stepper') stepper: MatStepper;
  public alertsSent = [];
  public alertsOpen = [];
  public alertsInterest = [];
  sumSent = 20;
  sumOpen = 20;
  sumInterest = 20;
  skipSent = 0;
  skipOpen = 0;
  skipInterest = 0;
  direction = '';
  isAlertsSentRead = false;
  isAlertsInterestRead = false;


  constructor(private authDS: AuthDataService, private ngxService: NgxUiLoaderService) {}

  ngOnInit(): void {
    this.ngxService.start();

    forkJoin([this.authDS.getAlertsSent(), this.authDS.getAlertsOpen(), this.authDS.getAlertsInterest()])
      .pipe(first())
      .subscribe(([alertsSent, alertsOpen, alertsInterest]) => {
        this.stepper.selectedIndex = 1;
        this.alertsSent = alertsSent;
        this.alertsOpen = alertsOpen;
        this.alertsInterest = alertsInterest;
        this.updateAlertsViewStatus(alertsOpen);
        this.ngxService.stop();
      });
  }

  private updateAlertsViewStatus(alerts: IAlert[]) {
    const alertIds = alerts.filter(alert => !alert.isViewedByUser).map(alert => alert._id);
    this.authDS.updateAlertsViewStatus(alertIds).subscribe(() => {
        this.authDS.getUnreviewedAlertsAmount();
    });
  }

  changeTab(activeTab: string, index: number) {
    this.activeTab = activeTab;
    this.stepper.selectedIndex = index;
    if (index === 0 && !this.isAlertsSentRead) {
      this.isAlertsSentRead = true;
      this.updateAlertsViewStatus(this.alertsSent);
    } else if (index === 2 && !this.isAlertsInterestRead) {
      this.isAlertsInterestRead = true;
      this.updateAlertsViewStatus(this.alertsInterest);
    }
  }

  onScrollDown(ev: any) {
    if (this.stepper.selectedIndex === 0 && this.sumSent <= this.alertsSent.length) {
      this.skipSent += 20;
      this.appendItems();
    } else if (this.stepper.selectedIndex === 1 && this.sumOpen <= this.alertsOpen.length) {
      this.skipOpen += 20;
      this.appendItems();
    } else if (this.stepper.selectedIndex === 2 && this.sumInterest <= this.alertsInterest.length) {
      this.skipInterest += 20;
      this.appendItems();
    }

    this.direction = 'scroll down';
  }

  appendItems() {
    this.addItems('push');
  }

  addItems(method: string) {
    if (method === 'push') {
      this.doSearch();
    }
  }

  public doSearch() {
    if (this.stepper.selectedIndex === 0) {
      this.authDS.getAlertsSent(this.skipSent, this.sumSent).pipe().subscribe(res => {
        const result = res ? res : [];
        this.alertsSent.push(...result);
        this.updateAlertsViewStatus(result);
      });
    } else if (this.stepper.selectedIndex === 1) {
      this.authDS.getAlertsOpen(this.skipOpen, this.sumOpen).pipe().subscribe(res => {
        const result = res ? res : [];
        this.alertsOpen.push(...result);
        this.updateAlertsViewStatus(result);
      });
    } else if (this.stepper.selectedIndex === 2) {
      this.authDS.getAlertsInterest(this.skipInterest, this.sumInterest).pipe().subscribe((res) => {
        const result = res ? res : [];
        this.alertsInterest.push(...result);
        this.updateAlertsViewStatus(result);
      });
    }
  }
}
