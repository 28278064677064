import { Injectable } from '@angular/core';
import { AuthApiService } from './auth-api.service';
import { ILogin, User, UserFullInfo, UserTypesAccount } from '../interfaces/auth-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { IProvider, SubscriptionModel } from '../interfaces/provider';
import { map, tap } from 'rxjs/operators';
import { IAlert } from '../interfaces/alert-interface';
import { AllFeatures, ChosenFeatures, ICity, ICountry, IIndustry, IProject, IProjectInfo, IState } from '../interfaces/project';
import { ITestimonial } from '../interfaces/testimonial.interface';
import { IPreferences } from '../interfaces/preferences.interface';
import { environment } from '../../environments/environment';

export type ClientOrProvider = UserTypesAccount.Client | UserTypesAccount.Provider;

@Injectable({
  providedIn: 'root',
})
export class AuthDataService {
  constructor(private authAS: AuthApiService, private router: Router) {}

  private _token$: BehaviorSubject<string> = new BehaviorSubject('');
  public token$: Observable<string> = this._token$.asObservable();

  private _user$: BehaviorSubject<UserFullInfo> = new BehaviorSubject(null);
  public user$: Observable<UserFullInfo> = this._user$.asObservable();

  private _providers$: BehaviorSubject<{data: IProvider[], totalCount: number}> = new BehaviorSubject(null);
  public providers$: Observable<{data: IProvider[], totalCount: number}> = this._providers$.asObservable();

  private _alerts$: BehaviorSubject<IAlert[]> = new BehaviorSubject(null);
  public alerts$: Observable<IAlert[]> = this._alerts$.asObservable();

  private _unreviewedAlerts$ = new BehaviorSubject<number>(0);
  public unreviewedAlerts$ = this._unreviewedAlerts$.asObservable();

  private _briefs$: BehaviorSubject<IProject[]> = new BehaviorSubject(null);
  public briefs$: Observable<IProject[]> = this._briefs$.asObservable();

  private _filters$: BehaviorSubject<any> = new BehaviorSubject(null);
  public filters$: Observable<any> = this._filters$.asObservable();

  private _filtersResults$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public filtersResults$: Observable<boolean> = this._filtersResults$.asObservable();

  private _countries$: BehaviorSubject<ICountry[]> = new BehaviorSubject(null);
  public countries$: Observable<ICountry[]> = this._countries$.asObservable();

  private _activeAccount$: BehaviorSubject<ClientOrProvider> = new BehaviorSubject(UserTypesAccount.Client);
  public activeAccount$: Observable<ClientOrProvider> = this._activeAccount$.asObservable();
  public setActiveAccount(activeAccount: ClientOrProvider) {
    this._activeAccount$.next(activeAccount);
  }

  public getUser() {
    return this._user$.getValue();
  }

  public getUserSubscriptionType() {
    return this._user$.getValue()?.userProvider.plan;
  }

  public markProjectAsViewed(id: string) {
    return this.authAS.markProjectAsViewed(id);
  }

  public addPaymentMethod(paymentMethodId: string) {
    return this.authAS.addPaymentMethod(paymentMethodId);
  }

  public switchAccount() {
    const value =
      this._activeAccount$.getValue() === UserTypesAccount.Client
        ? UserTypesAccount.Provider
        : UserTypesAccount.Client;
    localStorage.setItem('currentAccountType', value);
    this.setActiveAccount(value);
    return value;
  }

  public getCurrentAccountType() {
    return this._activeAccount$.getValue();
  }

  public uploadLogo(userType: string, payload: FormData, options: any) {
    return this.authAS.uploadLogo(userType, payload, options);
  }

  public getIsDocReady(id: string) {
    return this.authAS.getIsDocReady(id);
  }

  public getIsDocWithoutBriefReady(id: string) {
    return this.authAS.getIsDocWithoutBriefReady(id);
  }

  public getDocRequirementsWithoutBriefById(id: string) {
    return this.authAS.getDocRequirementsWithoutBriefById(id);
  }

  public saveFeats(body: { _id: string } | ChosenFeatures) {
    return this.authAS.saveFeats(body);
  }

  public saveFeatsForDocWithoutBrief(body: { _id: string } | ChosenFeatures) {
    return this.authAS.saveFeatsForDocWithoutBrief(body);
  }

  public getFeatures(): Observable<AllFeatures> {
    return this.authAS.getFeatures();
  }

  public getClosedAndDeclinedBriefs() {
    return this.authAS.getClosedAndDeclinedBriefs();
  }
  public getOpenAndPendingBriefs() {
    return this.authAS.getOpenAndPendingBriefs();
  }

  public getDocsAndProjects(status) {
    return this.authAS.getDocsAndProjects(status);
  }

  public getSpec() {
    return this.authAS.getSpec();
  }

  public getSkills() {
    return this.authAS.getSkills();
  }

  public getCountries(): Observable<ICountry[]> {
    return this.authAS.getCountries();
  }

  public getStates(countryId: string): Observable<IState[]> {
    return this.authAS.getStates(countryId);
  }

  public getCities(stateID: string): Observable<ICity[]> {
    return this.authAS.getCities(stateID);
  }

  public getIndustries(): Observable<IIndustry[]> {
    return this.authAS.getIndustries();
  }

  public getProviderIndustries(): Observable<IIndustry[]> {
    return this.authAS.getProviderIndustries();
  }

  public getCompensation(): Observable<any[]> {
    return this.authAS.getCompensation();
  }

  public getSpecialization(): Observable<any[]> {
    return this.authAS.getSpecialization();
  }

  public getProvidersCountry(): Observable<any[]> {
    return this.authAS.getProvidersCountry();
  }

  public getPrep() {
    return this.authAS.getPrep();
  }

  public getAccept() {
    return this.authAS.getAccept();
  }

  public getDetails() {
    return this.authAS.getDetails();
  }

  public getProjectTypes() {
    return this.authAS.getProjectTypes();
  }

  public getDocRequirementsTypes() {
    return this.authAS.getDocRequirementsTypes();
  }

  public createBrief(credential) {
    return this.authAS.createBrief(credential);
  }

  public createDoc(credential) {
    return this.authAS.createDoc(credential);
  }

  public sendGetListed(credential) {
    return this.authAS.sendGetListed(credential);
  }

  public sendLetUsKnow(credential) {
    return this.authAS.sendLetUsKnow(credential);
  }

  public sendBrief(credential) {
    return this.authAS.sendBrief(credential);
  }

  public sendBriefToProvider(providerId, projectId) {
    return this.authAS.sendBriefToProvider(providerId, projectId);
  }

  public getProjectAlertStatus(projectId, providerId) {
    return this.authAS.getProjectAlertStatus(projectId, providerId);
  }

  public checkEmail(credential) {
    return this.authAS.checkEmail(credential);
  }

  public editBrief(credential): Observable<IProjectInfo> {
    return this.authAS.editBrief(credential);
  }

  public editDoc(credential): Observable<IProjectInfo> {
    return this.authAS.editDoc(credential);
  }

  public closeProject(credential) {
    return this.authAS.closeProject(credential);
  }

  public setProviders(pies: {data: IProvider[], totalCount: number}) {
    this._providers$.next(pies);
  }

  public setUser(user: UserFullInfo) {
    this._user$.next(user);
  }

  public setUserToken(token: string): void {
    this._token$.next(token);
  }

  public setFilters(filters: any) {
    this._filters$.next(filters);
  }

  public clearFilters() {
    this._filters$.next([]);
  }

  public setAlerts(pies: IAlert[]) {
    this._alerts$.next(pies);
  }

  public setBriefs(pies: IProject[]) {
    this._briefs$.next(pies);
  }

  public setCountries(counties: ICountry[]) {
    this._countries$.next(counties);
  }

  public setAllNewlyAddedAndFeatured(counties: ICountry[]) {
    this._countries$.next(counties);
  }

  public doLogin(credential: ILogin) {
    return this.authAS.login(credential);
  }

  public doSearch(credential?, skip?, limit?) {
    return this.authAS.doSearch(credential, skip, limit);
  }

  public createCustomer(credential) {
    return this.authAS.createCustomer(credential);
  }

  public afterCustomerPaymentSuccess(credential) {
    return this.authAS.afterCustomerPaymentSuccess(credential);
  }

  public addTokenToCustomer(credential) {
    return this.authAS.addTokenToCustomer(credential);
  }

  public chargeCustomerFirstPrice() {
    return this.authAS.chargeCustomerFirstPrice();
  }

  public chargeCustomerSecondPrice() {
    return this.authAS.chargeCustomerSecondPrice();
  }

  public chargeCustomerThirdPrice() {
    return this.authAS.chargeCustomerThirdPrice();
  }

  public chargeCustomerOnlyDoc() {
    return this.authAS.chargeCustomerOnlyDoc();
  }

  public chargeCustomerDocRequirements() {
    return this.authAS.chargeCustomerDocRequirements();
  }

  public chargeCustomerFirstPriceAndDoc() {
    return this.authAS.chargeCustomerFirstPriceAndDoc();
  }

  public chargeCustomerSecondPriceAndDoc() {
    return this.authAS.chargeCustomerSecondPriceAndDoc();
  }

  public chargeCustomerThirdPriceAndDoc() {
    return this.authAS.chargeCustomerThirdPriceAndDoc();
  }

  public getCustomer() {
    return this.authAS.getCustomer();
  }

  public getCustomerCard() {
    return this.authAS.getCustomerCard();
  }

  public getCustomerInvoices() {
    return this.authAS.getCustomerInvoices();
  }

  public setupIntent() {
    return this.authAS.setupIntent();
  }

  public deleteCustomerCard() {
    return this.authAS.deleteCustomerCard();
  }

  public chargeCustomerAddCard(): Observable<any> {
    return this.authAS.chargeCustomerAddCard();
  }

  public resetPassword(credential: string) {
    return this.authAS.resetPassword(credential);
  }

  public checkToken(credential: string): Observable<any> {
    return this.authAS.checkToken(credential);
  }

  public signUpFirstStep(credential: User) {
    return this.authAS.signUpFirstStep(credential);
  }

  public editAccount(credential, role): Observable<void> {
    return this.authAS.editAccount(credential, role).pipe(
      map((user) => {
        this.user$.pipe(
          tap((item) => {
            this._user$.next({ user, userRole: item.userRole });
          })
        );
      })
    );
  }

  public verifyEmail(credential): Observable<void> {
    return this.authAS.verifyEmail(credential).pipe(
      map((user) => {
        this.user$.pipe(
          tap((item) => {
            this._user$.next({ user, userRole: item.userRole });
          })
        );
      })
    );
  }

  public confirmEmail(token) {
    return this.authAS.confirmEmail(token);
  }

  public sendVerifyPhoneNumber(credential) {
    return this.authAS.sendVerifyPhoneNumber(credential);
  }

  public sendVerifyData(credential) {
    return this.authAS.sendVerifyData(credential);
  }

  public sendVerifyDataEdit(credential) {
    return this.authAS.sendVerifyDataEdit(credential);
  }

  public signUpSecondStep(credential, link: string) {
    return this.authAS.signUpSecondStep(credential, link);
  }

  public forceLogout(): void {
    localStorage.clear();
    this.router.navigate(['./']).then(() => window.location.reload());
  }

  public getUserProfile(): Observable<UserFullInfo> {
    return this.authAS.getUserProfile().pipe(
      tap((user) => {
        if (user) {
          localStorage.setItem('userProfile', JSON.stringify(user));
          this.setUser(user);
          if (user.user.typeAccount !== UserTypesAccount.Multiple) {
            this.setActiveAccount(user.user.typeAccount);
            localStorage.setItem('currentAccountType', this.getCurrentAccountType());
          }
        } else {
          this.setUser(null);
          this.forceLogout();
        }
      })
    );
  }

  public deleteBrief(id: string): Observable<void> {
    return this.authAS.deleteBrief(id);
  }

  public deleteDoc(id: string): Observable<void> {
    return this.authAS.deleteDoc(id);
  }

  public deleteAccount(credential: any): Observable<void> {
    return this.authAS.deleteUser(credential);
  }

  public editPreferences(preferences: IPreferences): Observable<any> {
    return this.authAS.editPreferences(preferences);
  }

  public getProviderById(id: string): Observable<IProvider> {
    return this.authAS.getProviderById(id);
  }

  public providerSaveChanges(body: any) {
    return this.authAS.providerSaveChanges(body);
  }

  public getBriefById(id) {
    return this.authAS.getBriefById(id);
  }

  public getAllNewlyAddedAndFeatured() {
    return this.authAS.getAllNewlyAddedAndFeatured();
  }

  public getAlertsSent(skip?, limit?): Observable<IAlert[]> {
    return this.authAS.getAlertsSent(skip, limit).pipe(tap((alerts: IAlert[]) => this.setAlerts(alerts)));
  }

  public getAlertsOpen(skip?, limit?): Observable<IAlert[]> {
    return this.authAS.getAlertsOpen(skip, limit).pipe(tap((alerts: IAlert[]) => this.setAlerts(alerts)));
  }

  public getAlertsInterest(skip?, limit?): Observable<IAlert[]> {
    return this.authAS.getAlertsInterest(skip, limit).pipe(tap((alerts: IAlert[]) => this.setAlerts(alerts)));
  }

  public getUnreviewedAlertsAmount(): Observable<number> {
    this.authAS.getUnreviewedAlertsAmount().subscribe((amount) => this._unreviewedAlerts$.next(amount.totalCountFiltered));
    return this.unreviewedAlerts$;
  }

  public updateAlertsViewStatus(alerts: string[]){
    return this.authAS.updateAlertsViewStatus(alerts);
  }

  public getAllCountries(): Observable<ICountry[]> {
    return this.authAS.getAllCountries().pipe(tap((countries: ICountry[]) => this.setCountries(countries)));
  }

  public getAllTestimonials(): Observable<ITestimonial[]> {
    return this.authAS.getAllTestimonials();
  }

  public contactUs(userMessage): Observable<any> {
    return this.authAS.contactUs(userMessage);
  }

  public getBriefsSentByUserToProvider(providerId: string): Observable<any> {
    return this.authAS.getBriefsSentByUserToProvider(providerId);
  }

  public getDoc(projectId: string) {
    return this.authAS.getDoc(projectId);
  }

  public getDocWithoutBrief(projectId: string) {
    return this.authAS.getDocWithoutBrief(projectId);
  }

  public getExampleDoc() {
    return this.authAS.getExampleDoc();
  }

  public getUserById(id: string) {
    return this.authAS.getUserById(id);
  }

  public editHeadquarterLocation(credential, id) {
    return this.authAS.editHeadquarterLocation(credential, id);
  }

  public editOtherLocation(credential, id) {
    return this.authAS.editOtherLocation(credential, id);
  }

  public deleteOtherLocation(otherLocationId, id) {
    return this.authAS.deleteOtherLocation(otherLocationId, id);
  }

  public createSubscription(data, saveCard?: boolean) {
    return this.authAS.createSubscription(data, saveCard);
  }

  public getAllSubscriptions() {
    return this.authAS.getAllSubscriptions();
  }

  public getCustomerCurrentSubscription(subId: string) {
    return this.authAS.getCustomerCurrentSubscription(subId);
  }

  public cancelCurrentSubscription() {
    return this.authAS.cancelCurrentSubscription();
  }

  public resumeCurrentSubscription() {
    return this.authAS.resumeCurrentSubscription();
  }
}
