import { AfterViewInit, Component, Input } from "@angular/core";
// import Swiper from "swiper";
import { Router } from "@angular/router";
import { AuthDataService } from "../../../services/auth-data.service";
import { first } from "rxjs/operators";
import {IProvider} from '../../../interfaces/provider';
import { SwiperConfigInterface } from "ngx-swiper-wrapper-v-13";

declare var Swiper: any;

@Component({
  selector: "app-main-slider",
  templateUrl: "./main-slider.component.html",
  styleUrls: ["./main-slider.component.scss"],
})
export class MainSliderComponent implements AfterViewInit {
  @Input() slides: any[];
  @Input() type: any;

  public config: SwiperConfigInterface = {
    navigation: {
      nextEl: ".swiper-button-next.swiper-button-next1",
      prevEl: ".swiper-button-prev.swiper-button-prev1",
    },
    slidesPerView: 4,
    speed: 700,
    loop: true,
    updateOnWindowResize: true,
  };

  constructor(private router: Router, private authDS: AuthDataService) {}

  ngAfterViewInit(): void {
    const swiperContainer = document.querySelector(
      '.swiper-container.swiper-container1'
    );
    const swiper = new Swiper(swiperContainer, this.config);
    swiper.init();
    swiper.update();
  }

  search(item) {
    localStorage.setItem("search", item);
    const search = {
      filterOptions: {
        search: item,
      },
    };
    this.authDS
      .doSearch(search)
      .pipe(first())
      .subscribe((res: {data: IProvider[], totalCount: number}) => {
        this.authDS.setProviders(res);
      });
    this.router.navigate(["/search-results"], {
      queryParams: { search: item },
    });
  }
}
