<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="cancel()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">{{ data.title }}</p>

  <p class="paragraph2">
    {{ data.message }}
  </p>
  <ng-container *ngTemplateOutlet="data.messageRef"></ng-container>

  <button class="primary-btn" type="submit" (click)="click()">
    {{ data.successMessage }}
  </button>
  <p *ngIf='data.cancelMessage' class="cancel-msg" (click)="cancel()">
    {{ data.cancelMessage }}
  </p>
</div>
