<div class='block-container'>
  <div class='title'>Profile</div>
  <hr>
  <form [formGroup]='profileInfoForm' (ngSubmit)='profileSaveChanges()'>
    <div class='d-flex'>
      <label>
        First Name *
        <input
          type='text'
          placeholder='Enter your first name'
          formControlName='firstName'
          [class.error]='profileInfoControls.firstName.invalid && profileInfoControls.firstName.touched'
        >
        <div
          class='error'
          *ngIf='profileInfoControls.firstName.invalid && profileInfoControls.firstName.touched'
        >
          <div *ngIf="profileInfoControls.firstName.errors?.['required']">
            First Name is required.
          </div>
        </div>
      </label>
      <label>
        Last Name *
        <input
          type='text'
          placeholder='Enter your last name'
          formControlName='lastName'
          [class.error]='profileInfoControls.lastName.invalid && profileInfoControls.lastName.touched'
        >
        <div
          class='error'
          *ngIf='profileInfoControls.lastName.invalid && profileInfoControls.lastName.touched'
        >
          <div *ngIf="profileInfoControls.lastName.errors?.['required']">
            Last Name is required.
          </div>
        </div>
      </label>
    </div>
    <label class='select-field'>
      Country I live in *
      <input type='text'
             placeholder='Country'
             formControlName='country'
             [class.error]='profileInfoControls.country.invalid && profileInfoControls.country.touched'
             [matAutocomplete]='countryAutocomplete'>
      <mat-autocomplete
        #countryAutocomplete='matAutocomplete'
        [displayWith]='displayName'
      >
        <mat-option *ngFor='let country of (filteredCountries$ | async)' [value]='country'>
          {{ country.name }}
        </mat-option>
      </mat-autocomplete>
      <img class='mat-select-arrow select-arrow' src='/src/assets/icons/select.svg' alt=''>
      <div
        class='error'
        *ngIf='profileInfoControls.country.invalid && profileInfoControls.country.touched'
      >
        <div *ngIf="profileInfoControls.country.errors?.['required']">
          Country I live in is required.
        </div>
        <div *ngIf="profileInfoControls.country.errors?.['invalidValue']">
          Please select a valid Country.
        </div>
      </div>
    </label>
    <hr />
    <button type='submit' class='primary-btn mt-0'>
      Save Changes
    </button>
  </form>
</div>

<div class='block-container'>
  <form [formGroup]='occupationForm' (ngSubmit)='occupationSaveChanges()'>
    <label>
      Occupation
      <input
        type='text'
        placeholder='Enter information about your occupation'
        formControlName='occupation'
      >
    </label>
    <label>
      Education
      <input
        type='text'
        placeholder='This establishes credibility to providers'
        formControlName='education'
      >
    </label>
    <label>
      LinkedIn
      <input
        type='text'
        placeholder='Enter LinkedIn URL'
        formControlName='linkedin'
        [class.error]='occupationControls.linkedin.invalid && occupationControls.linkedin.touched'
      >
      <div class='error' *ngIf='occupationControls.linkedin.invalid && occupationControls.linkedin.touched'>
        Enter a valid URL by entering https:// or http:// before the domain name.
      </div>
    </label>
    <hr />
    <button type='submit' class='primary-btn mt-0'>
      Save Changes
    </button>
  </form>
</div>

<div class='block-container'>
  <form (ngSubmit)='emailSaveChanges()'>
    <label class='email-label'>
      Email *
      <input
        class='email-input'
        type='text'
        placeholder='Email to be used for logging in'
        [formControl]='email'
        [class.error]='email.invalid && email.touched'
      />
      <div
        *ngIf='user?.user.confirmed && (!email.dirty || email.value === user.user.email)'
        class='verification-box info'>
        <img src='../../../../../assets/icons/correct.svg' alt='img' width='10' height='10' />
        <label>verified</label>
      </div>
      <div *ngIf='!user?.user.confirmed' (click)='verifyEmail()' class='verification-box danger' matTooltip='Verify'
           matTooltipPosition='above'>
        <label>verify email</label>
      </div>
      <div
        class='error'
        *ngIf='email.invalid && email.touched'
      >
        <div *ngIf="email.errors?.['required']">
          Email is required.
        </div>
        <div *ngIf="email.errors?.['email']">
          Enter a valid email address.
        </div>
      </div>
    </label>
    <hr />
    <button type='submit' class='primary-btn mt-0'>
      Save Changes
    </button>
  </form>
</div>

<div class='block-container'>
  <form (ngSubmit)='phoneNumberSaveChanges()'>
    <label class='email-label'>
      Mobile Number *
      <input
        class='email-input'
        type='number'
        placeholder='Enter new mobile number'
        [formControl]='phoneNumber'
        [class.error]='phoneNumber.invalid && phoneNumber.touched'
      />
      <div
        *ngIf='user?.user.isPhoneVerified && (!phoneNumber.dirty || phoneNumber.value === user.user.phoneNumber)'
        class='verification-box info'>
        <img src='../../../../../assets/icons/correct.svg' alt='img' width='10' height='10' />
        <label>verified</label>
      </div>
      <div *ngIf='!user?.user.isPhoneVerified' (click)='verifyPhoneNumber()' class='verification-box danger' matTooltip='Verify'
           matTooltipPosition='above'>
        <label>verify number</label>
      </div>
      <div
        class='error'
        *ngIf='phoneNumber.invalid && phoneNumber.touched'
      >
        <div *ngIf="phoneNumber.errors?.['required']">
          Enter a valid mobile number with no spaces.
        </div>
      </div>
    </label>
    <hr />
    <button type='submit' class='primary-btn mt-0'>
      Save Changes
    </button>
  </form>
</div>


<ng-template #inboxPopupMsg>
  <div class='popup-message-section'>
    <p class='paragraph2'>
      An email was sent to have you verify this email address associated to this account.
    </p>
    <p class='paragraph2 mt-2'>
      Be sure to check your junk mail if it did not arrive in your inbox.
    </p>
  </div>
</ng-template>
