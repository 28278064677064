import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-unverified-account',
  templateUrl: './unverified-account.component.html',
  styleUrls: ['./unverified-account.component.scss']
})
export class UnverifiedAccountComponent {

  constructor(public dialogRef: MatDialogRef<UnverifiedAccountComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
