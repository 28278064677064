<ng-container *ngIf="options">
  <p>
    {{ address }}
    <img [src]="location.country.flag" alt="flag" class="flag">
  </p>
  <div class="google-map-container">
    <google-map
      width="100%"
      height="180px"
      [options]="options"
    >
      <map-marker [position]="markerPosition" [options]="markerOptions" />
    </google-map>
  </div>
</ng-container>
