import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MyBriefsComponent} from './my-briefs.component';
import {PreviewSubmitComponent} from './preview-submit/preview-submit.component';

const routes: Routes = [
  {path: '', component: MyBriefsComponent},
  {path: 'preview', component: PreviewSubmitComponent}
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyBriefsRoutingModule {
}
