import { Component, Inject } from "@angular/core";
import { Router } from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: "app-not-avilable",
  templateUrl: "./not-avilable.component.html",
  styleUrls: ["./not-avilable.component.scss"],
})
export class NotAvilableComponent {
  constructor(
    public dialogRef: MatDialogRef<NotAvilableComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
