<section class="main-slider">
    <div class="container">
        <div class="slider" *ngIf="type === 'Pained'">
            <!-- Slider main container -->
            <div class="swiper-container swiper swiper-container1" [swiper]="config">
                <!-- Additional required wrapper -->
                <div class="swiper-wrapper">
                    <!-- Slides -->
                    <div class="swiper-slide" *ngFor="let slide of slides" [routerLink]="['provider-profile']"
                         [queryParams]="{id: slide?._id}">
                        <div class="swiper-slide-content">
                            <div class="swiper-slide-content-logo">
                              <img [src]="slide?.logo || '../assets/pvoviderLogoPlaceholder.png'" alt="">
                            </div>
                            <div class="swiper-slide-content-text">
                                <p class="sub-title">{{slide?.companyName}}</p>
                                <p class="paragraph">{{slide?.country?.name + (slide?.city?.name ? ', ' + slide.city.name : '')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-button-prev swiper-button-prev1"></div>
            <div class="swiper-button-next swiper-button-next1"></div>
        </div>
    </div>
</section>
