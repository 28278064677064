import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AuthDataService } from '../../../../services/auth-data.service';
import { IProvider } from '../../../../interfaces/provider';
import { IndustryEnum } from '../../../../enums/industry.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LoginComponent } from '../../../popups/login/login.component';
import { first, skipWhile, switchMap, takeUntil } from 'rxjs/operators';
import { ILogin, UserFullInfo, UserTypesAccount } from '../../../../interfaces/auth-interfaces';
import { SignUpPopupComponent } from '../../../popups/sign-up/sign-up-popup.component';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('insideElement') insideElement: ElementRef<HTMLInputElement>;
  @Input() disabled: boolean = false;
  @Input() displaySearchField: boolean = true;
  @Input() displaySignUp: boolean = true;
  @Input() inputPlaceholder = 'Search a company to help launch your big ideas...';
  @Output() sendLoginEmitter: EventEmitter<ILogin> = new EventEmitter<ILogin>();
  @Output() searchValue: EventEmitter<string> = new EventEmitter<string>();

  protected readonly UserTypesAccount = UserTypesAccount;
  public providers: IProvider[] = [];
  public industries = IndustryEnum;
  public term = '';
  public user: UserFullInfo;
  public userIdFromRout: string;
  public token: string;
  public openSearchResults = false;
  public subscription = new Subscription();

  private destroy$ = new Subject<void>();
  public unreviewedAlerts$: Observable<number>;

  constructor(
    public authDS: AuthDataService,
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    public dialog: MatDialog,
  ) {
    this.token = localStorage.getItem('token');
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((param) => (this.userIdFromRout = param.id));

    this.router.url.includes('/search-service-providers') ? this.displaySearchField = false : this.displaySearchField = true;
  }

//   @HostListener("document:click", ["$event.target"])
  ngOnInit(): void {
    if (this.token && (this.userIdFromRout !== '0' || !this.userIdFromRout)) {
      this.subscription.add(
        this.getUser()
          .pipe(
            switchMap((user) => {
              this.user = user;
              this.unreviewedAlerts$ = this.authDS.getUnreviewedAlertsAmount();
              return [];
            }),
            first(),
          )
          .subscribe((providers) => {
          }));
      this.subscription.add(
        this.authDS.user$.subscribe((user) => {
          this.user = user;
        }));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription.unsubscribe();
  }

  private getUser(): Observable<UserFullInfo> {
    return this.authDS.user$.pipe(
      switchMap((user: UserFullInfo) => {
        if (user) {
          return of(user);
        } else {
          return this.authDS.getUserProfile();
        }
      }),
    );
  }

  canViewArticlesPage() {
    const typeAccount = this.user?.user?.typeAccount;
    if (typeAccount !== UserTypesAccount.Provider && typeAccount !== UserTypesAccount.Multiple) return false;
    const subType: number = this.authDS.getUserSubscriptionType();
    return !!subType;
  }

  public logout() {
    this.ngxService.start();
    localStorage.clear();
    this.router.navigate(['/']).then(() => location.reload());
    this.ngxService.stop();
  }

  public openLoginPopup(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      autoFocus: false,
      height: '600px',
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        skipWhile((result) => !result),
      )
      .subscribe((result) => this.sendLoginEmitter.emit(result));
  }

  public openSignUpPopup(): void {
    const dialogRef = this.dialog.open(SignUpPopupComponent, {
      autoFocus: false,
      height: '600px',
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        skipWhile((result) => !result),
      )
      .subscribe((result) => this.sendLoginEmitter.emit(result));
  }

  public doSearch(filter?) {
    this.term = this.term.trim();

    if (filter) {
      this.term =
        filter?.industry.title +
        ',' +
        ' ' +
        filter.companyName +
        ' ' +
        '-' +
        ' ' +
        filter.city +
        ',' +
        ' ' +
        filter.country.name;
    }
    if (this.term.length > 0) {
      this.router.navigate(['/search-service-providers'], {
        queryParams: { search: this.term },
      });
      this.searchValue.emit(this.term);
    }
  }

  public onSwitchAccountClick() {
    const accountType = this.authDS.switchAccount();
    const redirectTo =
      accountType === UserTypesAccount.Client ? '/' : '/provider/dashboard';
    this.router.navigate([redirectTo], { queryParams: { fromSwitchAccount: true } });
  }

  public onImageClick() {
    const accountType = this.authDS.getCurrentAccountType();
    const navigateTo = accountType === UserTypesAccount.Provider ? '/provider/dashboard' : '/';
    this.router.navigate([navigateTo]);
  }
}
