import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-congarts-brief',
  templateUrl: './congarts-brief.component.html',
  styleUrls: ['./congarts-brief.component.scss'],
})
export class CongartsBriefComponent {
  constructor(
    public dialogRef: MatDialogRef<CongartsBriefComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
