import { Component } from "@angular/core";
import { Router } from "@angular/router";
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: "app-confirm-email",
  templateUrl: "./confirm-email.component.html",
  styleUrls: ["./confirm-email.component.scss"],
})
export class ConfirmEmailComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmEmailComponent>,
    private router: Router
  ) {}

  onNoClick(): void {
    this.router.navigate(['/']).then();
    this.dialogRef.close();
  }
}
