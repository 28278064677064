import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UserTypesAccount } from '../interfaces/auth-interfaces';
import { AuthDataService } from '../services/auth-data.service';
import { inject } from '@angular/core';
import { filter, map } from 'rxjs/operators';

export function requireAccountType(accountType: UserTypesAccount): CanActivateFn {
  return (ars: ActivatedRouteSnapshot, rss: RouterStateSnapshot) => {
    const router = inject(Router);
    return inject(AuthDataService).user$.pipe(
      filter(user => !!user),
      map(user => {
        const typeAccount = user.user.typeAccount;
        if (typeAccount === accountType || typeAccount === UserTypesAccount.Multiple) {
          return true;
        } else {
          router.navigate(['/']);
          return false;
        }
      })
    );
  };
}
