import { ArticleStatus } from './article';

export enum SortingTypes {
    createdAt,
    viewed,
}

export interface FilterOptions {
    search?: string;
    categories?: string[];
    countries?: string[];
    providerNames?: string[];
    status?: ArticleStatus;
}

export interface SortingOptions {
    sortType?: SortingTypes;
    ascending?: boolean;
}

export interface FilterArticleRequest {
    filterOptions: FilterOptions;
    sortingOptions: SortingOptions;
}
