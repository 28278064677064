<app-header></app-header>
<section class="container">
  <h2 class="title">Terms of Use</h2>
  <div class="text-block">
    <p class="sub-title">General Terms</p>
    <p class="paragraph">
      The Unicorn App provides a referral service via a website, accessible via https://www.theunicorn.app, and set of
      mobile applications available on iOS and Android (collectively referred to herein as “Service” or ‘Services”)
      provided by Thriller Innovations Incorporated. The Unicorn App finds potential clients which we refer to
      trustworthy service providers on The Unicorn App platform. These terms of service ("Terms") govern your access and
      use of The Unicorn App services so please read them carefully before using the services. By using the Service, you
      agree to be bound by these Terms in their entirety. If you are using the services on behalf of an organization
      then you are agreeing to these terms for that organization and are warranting that you have the authority to bind
      that organization to these terms. In that case "you" and "your" will refer to that organization. You may use the
      Services only in compliance with these Terms. You may use the Services only if you have the power to form a
      contract with Thriller Innovations Incorporated and are not barred under any applicable laws from doing so. The
      Services may continue to change over time as we refine and add more features. We may stop, suspend, or modify the
      Services at any time without prior notice to you. We may also remove any content from our Services at our
      discretion. By accessing The Unicorn App Services, you agree to submit to the Conditions of use, to the
      instructions and to the rules that are mentioned in this agreement. If you do not wish to accept these conditions,
      do not use The Unicorn App services.
      <br>
      <br>
      The Unicorn App is not responsible for any data lost during the transmission of information over the Internet.
      Although the goal of The Unicorn App is that the service is available 24 hours a day and seven days a week, the
      website or mobile apps may be unavailable at certain times, for any possible reason, including, but not limited
      to, routine maintenance. You understand and recognize that, owing to circumstances that are both within and out of
      the control of The Unicorn App, access to the Website or mobile apps could be interrupted, suspended or rescinded
      from time to time. At any moment in time, The Unicorn App through Thriller Innovations Incorporated has the right
      to modify or interrupt any aspect or characteristic relating to the Website, including, but not limited to, the
      content, the hours of availability and the equipment used to access and use the services. Moreover, The Unicorn
      App may interrupt the transmission of any part of the data. To conclude an enforceable contract, you hereby
      declare that you are of required legal age. You must be at least 18 years old to have the right to use this
      Service. However, if you are at least 13 years old, but not 18 yet, you can use this Service if accompanied by a
      parent or a tutor that accepts the present contract. Use of this Service is forbidden for people under 13 years
      old. 
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Modified Conditions</p>
    <p class="paragraph">At any moment in time, The Unicorn App through Thriller Innovations Incorporated, reserves the
      right to interrupt or modify these Conditions of use and/or its Privacy policy as they deem necessary or
      beneficial. These modifications may include, among other things, adding certain taxes or royalties. If Service
      applies any major changes, we will let you know through a modification notice on the Website. Any modification
      applied to these Conditions of use will be in effect from the moment where we publish the modification notice on
      our Website. These modifications will be in effect right away for new Service users and for the project requests
      that these new users send in. Therefore, we suggest that from time to time, you read this important notice which
      includes the Terms of Use and the Privacy Policy, to make sure that you are fully up-to-date on any modifications
      that have been applied. If the user makes use of the Service after the notification has been published, it will be
      seen as a tacit acceptance of these modifications.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Conduct for Users

    </p>
    <p class="paragraph">
      This Service and all individual Websites or sites that are specific to service providers that are currently found
      or will hereafter be found on our Website (known as “Microsites” or that are available through external hyperlinks
      are all private properties. Each interaction on this Website and/or on the Microsites must conform to these
      Conditions of use. Although we accept and encourage user interactions on our Service, we require and insist that
      all users refrain from any activity that is related to the use of this Service and the Microsites, for legitimate
      and legal purposes only. Through this Service, users must not publish or transmit any material that violates or
      infringes in any way or matter the rights of others or any material that is illegal, includes threatening speech
      or verbal abuse, that is libelous or disrespectful to the private life of a person or of any publicity rights,
      that is vulgar, obscene or otherwise reprehensible, that promotes a mode of conduct which would constitute a
      criminal infraction, which would give way to a civil liability or would otherwise violate a law or that, without
      the previous written consent of The Unicorn App or Thriller Innovations Incorporated, contains any form of
      publicity or any solicitation of products or services. Any conduct, on the part of the User, which, at the
      exclusive discretion of The Unicorn App/Thriller Innovations Incorporated, limits or blocks any other User from
      using or visiting the Service and/or the Microsites to advertise or make any commercial, religious, political or
      non-commercial solicitations, including, but not limited to, soliciting the users of this Service and/or the
      Microsites to become users of online or offline services which are directly or indirectly competing with The
      Unicorn App.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Account Security
    </p>
    <p class="paragraph"> You are responsible for safeguarding the password that you use to access the Services and you
      agree not to disclose your password to any third party. You are responsible for any activity using your account,
      whether you authorized that activity. You should immediately notify The Unicorn App via the support channels
      listed herein of any unauthorized use of your account.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Your General Responsibilities

    </p>
    <p class="paragraph">Files and other content in the Services may be protected by intellectual property rights of
      others. Please do not copy, upload, download, or share files unless you have the right to do so. You, not The
      Unicorn App or Thriller Innovations Incorporated, will be fully responsible and liable for what you copy, share,
      upload, download or otherwise use while using the Services. You must not upload spyware or any other malicious
      software to the Service. You, and not The Unicorn App or Thriller Innovations Incorporated, are responsible for
      maintaining and protecting your account. If your contact information, or other information related to your
      account, changes, you must notify us promptly and keep your information current.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Limitation of Liability
    </p>
    <p class="paragraph"> THE SERVICES ARE PROVIDED "AS IS", AT YOUR OWN RISK, WITHOUT EXPRESS OR IMPLIED WARRANTY OR
      CONDITION OF ANY KIND. WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
      NON-INFRINGEMENT. The Unicorn App and Thriller Innovations Incorporated will have no responsibility for any harm
      to your computer system, loss or corruption of data, or other harm that results from your access to or use of the
      Services. Some jurisdictions in the United States do not allow the types of disclaimers in this paragraph, so they
      may not apply to you.
      <br>
      TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE UNICORN APP, THRILLER INNOVAIONS INCOROPROATED, ITS
      AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS OR LICENSORS BE LIABLE FOR (A) ANY INDIRECT, SPECIAL,
      INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL (INCLUDING LOSS OF USE, DATA, BUSINESS, OR PROFITS) DAMAGES,
      REGARDLESS OF LEGAL THEORY, WHETHER OR NOT HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A
      REMEDY FAILS OF ITS ESSENTIAL PURPOSE; (B) AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES MORE THAN
      THE GREATER OF $20 OR THE AMOUNTS PAID BY YOU TO FOR THE PAST THREE MONTHS OF THE SERVICES IN QUESTION. Some
      jurisdictions in the United States do not allow the types of disclaimers in this paragraph, so they may not apply
      to you.
      <br>
      <br>
      Although we rigorously examine the professional reputation, accreditations, title and qualifications of all the
      service providers that are a part of The Unicorn App Services, we make no representation and offer no guarantees
      concerning the qualifications and the service quality offered by service providers from The Unicorn App service,
      who you will hire following a project request. It is fully up to the user of the Service and the service provider
      (from The Unicorn App network) who you will hire to execute a project request, to write up a service contract and
      to agree on the terms and conditions of the project and its completion. In no case do we guarantee the service
      provider’s efficiency, nor the quality or the result of the requested project work, in accordance with the service
      offer.
    </p>
  </div>
  <!--  <div class="text-block">-->
  <!--    <p class="sub-title">Sharing Your Information-->
  <!--    </p>-->
  <!--    <p class="paragraph">We may use certain trusted third party companies and individuals to help us provide, analyse,-->
  <!--      and improve our services (including but not limited to Client/Service Provider verification, data storage,-->
  <!--      maintenance services, database management, web analytics, payment processing, and improvement of our services).-->
  <!--      These third parties will process your personal data on our behalf (as our processor). We will disclose your-->
  <!--      personal data to these parties so that they can perform those functions necessary functions. We may also share-->
  <!--      your information with third parties, who then use your personal data for their own purposes. For example, to-->
  <!--      process payments from you or where you choose to access our services through the website or mobile application.-->
  <!--      These organizations will also use your personal data for their own, separate purposes (also as a “controller”) –-->
  <!--      they will have their own privacy notices which you should read, and they have their own responsibilities to comply-->
  <!--      with applicable data protection laws. In certain circumstances, where necessary, we may disclose your personal-->
  <!--      data to:-->
  <!--    </p>-->
  <!--    <br>-->
  <!--    <br>-->
  <!--    <pre class="paragraph">-->
  <!--          •      Consultants and professional advisors including legal advisors, auditors and accountants;-->

  <!--          •      Business partners and joint ventures;-->

  <!--          •      A prospective seller or buyer of our business and their advisors;-->

  <!--          •      insurers;-->

  <!--          •      Courts, court-appointed persons/entities, receivers and liquidators;-->

  <!--          •      Third parties where necessary to comply with a legal obligation, to enforce a contract or to protect the rights,-->
  <!--                  property or safety of our employees, customers or others; and-->

  <!--          •     s To governmental departments, local government, statutory and regulatory bodies (in Canada).-->
  <!--    </pre>-->
  <!--  </div>-->
  <div class="text-block">
    <p class="sub-title">Termination</p>
    <p class="paragraph">You can stop using our Services any time. We reserve the right to suspend or end the Services
      at any time, with or without cause, and with or without notice. For example, we may suspend or terminate your use
      if you are not complying with these Terms or use the Services in any way that would cause us legal liability or
      disrupt others’ use of the Services. If we suspend or terminate your use, we will try to let you know in advance,
      though there may be some cases (for example, repeatedly or flagrantly violating these Terms, a court order, or
      danger to other users) where we may suspend immediately. If we terminate your service for breach of these terms no
      refund of any fees paid to Thriller Innovations Incorporated relating to your service will be offered. If we
      terminate your service for any other reason than breach of these terms, or at our sole discretion, then we may
      refund unused portion of fees paid for the Services on a pro-rate basis.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Jurisdiction
    </p>
    <p class="paragraph"> THESE TERMS AND THE USE OF THE SERVICES WILL BE GOVERNED BY UNITED STATES LAW EXCEPT FOR ITS
      CONFLICTS OF LAWS PRINCIPLES. ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR THE SERVICES MUST BE
      LITIGATED EXCLUSIVELY IN THE COURTS OF DELAWARE, US AND BOTH PARTIES CONSENT TO VENUE AND PERSONAL JURISDICTION
      THERE. These Terms constitute the entire and exclusive agreement between you and The Unicorn App/Thriller
      Innovations Incorporated with respect to the Services, and supersede and replace any other agreements, terms, and
      conditions applicable to the Services. These Terms create no third-party beneficiary rights. Thriller Innovations’
      failure to enforce a provision is not a waiver of its right to do so later. If a provision is found unenforceable
      the remaining provisions of the Agreement will remain in full effect and an enforceable term will be substituted
      reflecting our intent as closely as possible. You may not assign any of your rights in these Terms, and any such
      attempt is void, but Thriller Innovations Incorporated may assign its rights to any of its affiliates or
      subsidiaries, or to any successor in interest of any business associated with the Services. The Unicorn App or
      Thriller Innovations Incorporated and you are not legal partners or agents; instead, our relationship is that of
      independent contractors.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Sales Conditions</p>
    <p class="paragraph">Once you have created a user account and completed the project brief, in which you describe the
      type of services you need, the Service will transmit this information to the service providers based on your
      preferred preferences, such as country, specialized industry, and skill specialization. Service providers that are
      interested, if applicable, will be provided with your project brief and your contact information who will then
      directly to present theirs project approach and prices. However, we cannot guarantee that you will find a service
      provider to carry out the type of project that you are considering. The Unicorn App or Thriller Innovations
      Incorporated does not recommend or prefer the services of any one of our service providers. The Unicorn App or
      Thriller Innovations Incorporated does not work as a representative for these Service Providers.
    </p>
    <br>
    <br>
    <p class="paragraph list">
      <span class="item">The Service retains user profile and other information for as long as you maintain your account.</span>
      <span class="item">The Service retains transaction, location, usage, and other information for 7 years in connection with
              regulatory, tax, insurance, or other requirements in the places in which it operates. The Service thereafter
              deletes or anonymizes such information in accordance with applicable laws.</span>
      <span class="item">Users may request deletion of their accounts at any time by contacting us at support@theunicorn.app. Following
              verification of such account deletion requests, the Service deletes the information that it is not required to
              retain and restricts access to or use of any information it is required to retain.</span>
      <span class="item">The Service may be unable to delete your account, such as if there is an outstanding credit on your account or
              an unresolved claim or dispute. Upon resolution of the issue preventing deletion, the Service will delete your
              account as described above.</span>
      <span class="item">The Service may also retain certain information if necessary, for its legitimate business interests, such as
              fraud prevention and enhancing users’ safety and security. For example, if the Service shuts down a user’s account
              because of unsafe behaviour or security incidents, the Service may retain certain information about that account
              to prevent that user from opening a new Unicorn App account in the future.</span>
  </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Fees</p>
    <p class="paragraph">
      All charges payable by you for The Unicorn App Services shall be in accordance with the scale of charges and rates
      published from time to time by us on our web site, errors and omissions excepted, and shall be due and payable in
      advance of provision of The Unicorn App Services. You agree that, except where otherwise specified in these terms,
      these fees are not refundable on any basis. We reserve the right to alter pricing, including ceasing to offer
      elements of The Unicorn App Services. The Unicorn App /Thriller Innovations Incorporated will inform you by email
      if the charge for a service is to be altered. You can then decide if you want to continue to use such service.
      Your continued use of the service after the proposed fee modification has been notified will be considered
      acceptance of the proposed fee modification. All fees for our services are due in advance and in the case of
      subscription payments, your contract will automatically renew on its anniversary month, quarter or year, at which
      point fees for the following month, quarter or year become payable. If you choose to pay by credit or debit card,
      then you authorise Thriller Innovations Incorporated to debit your account renewal fees from your card. If you
      wish to cancel your contract with The Unicorn App, you must do so in writing before the renewal of your contract.
      All fees paid to us are non-refundable except in circumstances set out in these Terms. You agree not to issue a
      chargeback via your bank in relation to any fees charged by us. If you do so you accept that you will be liable
      for our costs in dealing with the chargeback and recovering any fees properly due to us under the Terms.

    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Service Provider Selection Criteria and Provider Information

    </p>
    <p class="paragraph">
      The Unicorn App uses a proprietary algorithm to find Service Providers with a satisfactory online reputation to
      add and maintain on The Unicorn App platform. The Unicorn App or Thriller Innovations Incorporated does not check
      insurance coverage for professionals that operate in fields which do not require this type of coverage, including
      but not limited to, designers, technology engineers, 3D printing architects or printing providers, architects,
      mechanical or construction engineers, consultants, workers, designers, license issuers. The Unicorn App engages no
      responsibility with regards to updating service provider profiles for service providers that are part of The
      Unicorn App platform. The Unicorn App tries to keep the service provider information on the Service up to date,
      following information requests, and to modify them if necessary. The Service suggests that each user check the
      information of the service provider that they choose before they sign a contract with a Service Provider following
      a project brief request, in line with the present terms and conditions. The Unicorn App and Thriller Innovations
      Incorporated rejects any responsibility and makes no guarantees regarding the selection process, the selection
      criteria, the information that is received or presented during the selection process or the sharing of said
      information. The Unicorn App and Thriller Innovations Incorporated is also not responsible for making sure that
      the selection and verification process is sufficient or that the information received during the selection process
      and the verification is accurate, up-to-date, and error-free.

    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Information Shared Upon Submission of Project Briefs
    </p>
    <p class="paragraph">
      By using the services of The Unicorn App, during a project brief, the user will have to share some personal
      information as well as information concerning their project needs. All Service users share this information with
      fitting service providers that match with the user’s preferences at the time of creating the project brief that
      are a part of The Unicorn App. Some of this information will be sent to the Service Providers, who will use it to
      contact the user and to assist with their project who also may offer a price in order to follow through with your
      project brief. By sending us this information and your project brief, any user formally consents to be contacted
      by The Unicorn App or by service providers in The Unicorn App platform, by phone, by fax, by email, by mail or by
      any other reasonable means of communications, using the phone numbers and addresses that the user has provided in
      their project brief request. In doing so, we can offer a better follow-up for the services that we offer on our
      Service, resolve any question pertaining to your account or any other reasonable subject relating to your service
      request. If the information submitted by the user, is fully or partly inaccurate, not up-to-date, false or
      incomplete or that The Unicorn App has any reasonable grounds to believe that said information is inaccurate, not
      up-to-date, false or incomplete, The Unicorn App or Thriller Innovations Incorporated reserves the right to refuse
      to follow through with the service request and to refuse website access, both at the present moment and in the
      future, as well as access to its services or parts of its services following this type of request as completed by
      any user. Each user is responsible for the use of The Unicorn App’s services that they or any other person would
      intentionally make through their account.

    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">How the Service Works for Service Providers
    </p>
    <br>
    <p class="paragraph list">
      <span class="item">The Unicorn App determines if the criteria of a project brief match a Service Provider (based on the services
              the service provider offerings, industry specializations, skills, geography and more.)</span>
      <span class="item">Each project brief is sent to an average of 5 service providers, but a client user can choose to send to more.</span>
      <span class="item">Project briefs are referred to according to an order that is based on an algorithm determined by an online
              reputation score, active subscriptions, user activity, views, provider interest count, attributed to each service
              provider. The score is based on several different factors such as how engaged the Service Provider is with
              attending to submitted project briefs.</span>
      <span class="item">If a Service Provider is chosen for a project brief to be shared with them, we will send an email which includes
              all necessary client and project information related to the project to allow the Service Provider to make a
              conscience decision to show interest in learning more about the project. Name and contact information of the
              client and budget are not yet shared to the Service Provider. This email will contain the option to express
              interest or decline interest.</span>
      <span class="item">If a Service Provider clicks on “YES, I’M INTERESTED”, The Unicorn App will send a follow up email containing
              all project information and client contact information. The Unicorn App tracks this as one count successfully sent
              brief. Service Providers can be provided up to 5 project briefs on a free subscription plan and more on paying plan.</span>
      <span class="item">If a Service Provider clicks on “NO, THANKS!”, the service provider will not be provided with the follow up
              project brief email with all the project information and contact client information. However, The Unicorn App
              tracks this as one count successfully sent brief. Service Providers can be provided up to 5 project briefs on a
              free subscription plan and more on paying plan.</span>
      <span class="item">We may contact user clients for follow-ups throughout the process to make sure that they are satisfied with the
              Service and Service Provider research (when applicable)</span>
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Use by Users & Access

    <p class="paragraph">
      Each user recognizes and accepts that the services offered by The Unicorn App can be dedicated to your personal or
      commercial use and purposes. However, The Unicorn App reserves the exclusive right to revoke access to the Service
      for any user or Service Provider, at any time. The Unicorn App or Thriller Innovations Incorporated reserves the
      exclusive right to suspend Service access to any user, without prior notice.

    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">False Information
    </p>
    <p class="paragraph">
      Each user that provides information that is false, including name, phone number, address and email address, which
      end up causing losses and/or damages to The Unicorn App or Thriller Innovations Incorporated, to the service
      providers that are on The Unicorn App platform and to any other user, will be subject to legal action and damage
      claims on behalf of The Unicorn App or Thriller Innovations Incorporated.  
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Refunds
    </p>
    <p class="paragraph">
      Ultimately, our service is based on the information provided by the client user, for this reason we can never be
      100% convinced of the seriousness of a project that a customer submits on The Unicorn App platform. Our team makes
      sure to ask the right questions and will disqualify a project when it does not meet our qualification standards.
      Any user or Service Provider who provides a payment to use The Unicorn App Services does so with the agreement
      that no refunds will be allowed. The Unicorn App or Thriller Innovations Incorporated does not guarantee and may
      respond to requests for refunds on a case by case bases and reserves all rights to determine if any refund or
      credit requests are to be provided.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Intellectual Property

    </p>
    <p class="paragraph">
      Please be aware that we may use any feedback, comments, or suggestions that you send us or post in our forums
      without any obligation to you.
      <br>
      The technology we use to provide the Services are protected by copyright, trademark, and other laws of both the
      United States and foreign countries. These Terms do not grant you any rights to use The Unicorn App or Thriller
      Innovations Incorporated trademarks, logos, domain names, or other brand features.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Acceptable Use
    </p>
    <p class="paragraph">
      You agree not to misuse The Unicorn App Services. For example, you must not, and must not attempt to, use the
      services to do the following things.
    </p>
    <br>
    <p class="paragraph list">
      <br>
      <span class="item">Probe, scan, or test the vulnerability of any system or network;</span>
      <span class="item">Breach or otherwise circumvent any security or authentication measures;</span>
      <span class="item">Access, tamper with, or use non-public areas of the Service, shared areas of the Service which you have
                   not been invited to, The Unicorn App or Thriller Innovations Incorporated (or our service providers’)
                   computer systems;</span>
      <span class="item">Plant malware or otherwise use the Services to distribute malware;</span>
      <span class="item">Access or search the Services by any means other than our publicly supported interfaces (for example,
                    "scraping");</span>
      <span class="item">Send unsolicited communications, promotions or advertisements, or spam; send altered, deceptive
                    or false source-identifying information, including "spoofing" or "phishing";</span>
      <span class="item">Publish anything that is fraudulent, misleading, or infringes another's rights; promote or advertise
                    products or services other than your own without appropriate authorization;</span>
      <span class="item">Impersonate or misrepresent your affiliation with any person or entity;</span>
      <span class="item">Violate the law in any way, or violate the privacy of others, or defame others.</span>
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Release
    </p>
    <p class="paragraph">
      Each user is responsible for the interactions with the service providers that are a part of The Unicorn App
      network. To the extent that is permitted by current laws, each user releases The Unicorn App or Thriller
      Innovations Incorporated from any claim or responsibility related to any product or service offered by a Merchant,
      any action or inaction by a service provider from The Unicorn App’s network, including the inability on the
      Merchant’s part, to comply with legislation that is currently in effect and/or the inability to comply with the
      terms of a service offer. All users agree to exonerate The Unicorn App, its affiliates and its administrators,
      leaders, employees and respective agents from any and all claims and expenses, including the legal fees resulting
      from or related to services offered by a service provider from The Unicorn App’s network following a service
      request on the Service by any user, in connection with the Service or the Microsites.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title">Third-Party Content
    </p>
    <p class="paragraph">
      The Service contains links towards third-party websites managed by suppliers that supply other content. These
      links are provided only as a matter of convenience for you and not as an approval on The Unicorn App or Thriller
      Innovation Incorporated’s part for the content of said third-party websites and The Unicorn App excludes any
      guarantee concerning the content or the accuracy of the material on these third-party websites. If the user
      decides to access these third-party websites, they do so at their own risk, Unless you have signed a written
      contract with The Unicorn App or Thriller Innovations Incorporated specifically allowing you to do so, you may not
      provide a hypertext link towards the Website or Microsites from other websites. At any moment, The Unicorn App
      through Thriller Innovations Incorporated reserves the right to revoke its consent to any link, at their own
      discretion.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title"> Surveillance

    </p>
    <p class="paragraph">
      The Unicorn App through Thriller Innovations Incorporated reserves the right, but not the obligation, to monitor
      the usage of the Service, at all moments in order to determine the conformity of this Contract and all the usage
      rules as established by The Unicorn App, as well as to satisfy any law, rule or authorized government request.
    </p>
  </div>
  <div class="text-block">
    <p class="sub-title"> Copyright Policy


    </p>
    <p class="paragraph">
      In accordance with the Digital Millennium Copyright Act of 1998, Thriller Innovations Incorporated will respond
      expeditiously to claims of copyright infringement committed using The Unicorn App and/or The Unicorn App website
      (the "Site") if such claims are reported to Thriller Innovations Incorporated in the manner identified in the
      sample notice below. If you are a copyright owner, authorized to act on behalf of one, or authorized to act under
      any exclusive right under copyright, please report alleged copyright infringements taking place on or through the
      Site by completing the following DMCA Notice of Alleged Infringement and delivering it by postal mail to the
      address below.

  </div>

  <div class="text-block">
    <p class="sub-title">Names and Information
    </p>
    <p class="paragraph">
      All the rights regarding the name of The Unicorn App are expressly reserved to Thriller Innovations Incorporated.
      Unless otherwise specified, all the names that appear on The Unicorn App are the property of their respective
      owners. All the content and information that is put online on the Service, including, but not limited to, the
      service provider profiles, the information relating to their selection and the evaluations and reviews concerning
      them (including the evaluations made by any user about our professionals, or any other content published on the
      Service by a user) is the exclusive property of Thriller Innovations Incorporated. Distribution of all the
      documents on the Service requires the express written permission on the part of The Unicorn App. The Unicorn App
      or Thriller Innovations Incorporated does not do any representations regarding the accuracy or the reliability of
      the information contained in the documents and graphs with which they are related and published on this Service.
    </p>
    <br>
    <br>
    <p class="sub-title">DMCA Notice of Alleged Infringement ("Notice")
    </p>
    <p class="paragraph">
      All the rights regarding the name of The Unicorn App are expressly reserved to Thriller Innovations Incorporated.
      Unless otherwise specified, all the names that appear on The Unicorn App are the property of their respective
      owners. All the content and information that is put online on the Service, including, but not limited to, the
      service provider profiles, the information relating to their selection and the evaluations and reviews concerning
      them (including the evaluations made by any user about our professionals, or any other content published on the
      Service by a user) is the exclusive property of Thriller Innovations Incorporated. Distribution of all the
      documents on the Service requires the express written permission on the part of The Unicorn App. The Unicorn App
      or Thriller Innovations Incorporated does not do any representations regarding the accuracy or the reliability of
      the information contained in the documents and graphs with which they are related and published on this Service.
    </p>
    <p class="paragraph list">
      <br>
      <span class="item">Identify the copyrighted work that you claim has been infringed, or – if multiple copyrighted works are
                    covered by this Notice - you may provide a representative list of the copyrighted works that you claim
                    have been infringed.</span>
      <span class="item">Identify the material or link you claim is infringing (or the subject of infringing activity) and to which access
                    is to be disabled, including at a minimum, if applicable, the URL of the link shown on the Site or the exact
                    location where such material may be found.</span>
      <span class="item"> Provide your company affiliation (if applicable), mailing address, telephone number, and, if available,
                    email address.</span>
    </p>
    <p class="paragraph">
      Include both of the following statements in the body of the Notice:
      "I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by
      the copyright owner, its agent, or the law (e.g., as a fair use)."
      "I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner,
      or authorized to act on behalf of, the owner, of the copyright or of an exclusive right under the copyright that is
      allegedly infringed."
      Provide your full legal name and your electronic or physical signature.
      Deliver this Notice, with all items completed, to: <a
      href="mailto:https://support@theunicorn.app">support@theunicorn.app</a>
    </p>
  </div>


  <div class="text-block">
    <p class="sub-title"> Cancellations


    </p>
    <p class="paragraph">
      The Unicorn App’s goal is to provide assistance in helping people build and launch great and meaningful projects
      and build a relationship that serves interests for both the client user submitting project briefs but also the
      service providers, thus meaning that there is no long term engagement. If at any moment you feel that our services
      are not reaping expected results, simply contact us and we will deactivate your profile. However, you will have to
      pay any unpaid bills before you are able to close your profile.
    </p>
  </div>

</section>
