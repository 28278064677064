import { UserRoleEnum } from '../enums/user-role.enum';
import { CompanySizeEnum } from '../enums/company-size.enum';
import { GenderEnum } from '../enums/gender.enum';
import {ICity, ICountry, IState} from './project';
import {IPreferences} from './preferences.interface';
import { IProvider } from './provider';

export interface ILogin {
  email: string;
  password: string;
}

export enum UserTypesAccount {
  Client = 'client',
  Provider = 'provider',
  Multiple = 'multiple'
}

export class User {
  public confirmed?: boolean;
  public isPhoneVerified?: boolean;
  public _id?: string;
  public passwordHash?: string;
  public name?: string;
  public email: string;
  public password: string;
  public subscriptionType: number;
  public phoneNumber: number;
  public role: UserRoleEnum;
  public country?: ICountry;
  public preferences?: IPreferences;
  public typeAccount?: UserTypesAccount;

  constructor(data: any) {
    this.confirmed = data?.confirmed;
    this.isPhoneVerified = data?.isPhoneVerified;
    this._id = data?._id;
    this.passwordHash = data?.passwordHash;
    this.name = data?.name;
    this.email = data?.email;
    this.password = data?.password;
    this.phoneNumber = data?.phoneNumber;
    this.subscriptionType = data?.subscriptionType;
    this.role = data?.role;
    this.country = data?.country;
    this.preferences = data?.preferences;
    this.typeAccount = data?.typeAccount;
  }
}

export class UserRole {
  public _id?: string;
  public userId?: string;
  public companyName?: string;
  public logoUrl?: string;
  public headOfficeAddress?: string;
  public city?: ICity;
  public postalCode?: string;
  public state?: IState;
  public fullName?: string;
  public firstName?: string;
  public lastName?: string;
  public companyWebSite?: string;
  public companyPhoneNumber?: 0;
  public primaryContact?: string;
  public industry?: any;
  public homeCity?: string;
  public country?: ICountry;
  public education?: string;
  public occupation?: string;
  public linkedin?: string;
  public birthday?: Date;
  public companySize?: CompanySizeEnum;
  public gender?: GenderEnum;
  public description?: string;

  constructor(data: any) {
    this.companyName = data?.companyName;
    this.industry = data?.industry;
    this.companySize = data?.companySize;
    this.country = data?.country;
    this.city = data?.city;
    this.state = data?.state;
    this.companyWebSite = data?.companyWebSite;
    this.companyPhoneNumber = data?.companyPhoneNumber;
    this.postalCode = data?.postalCode;
    this._id = data?._id;
    this.userId = data?.userId;
    this.logoUrl = data?.logoUrl;
    this.headOfficeAddress = data?.headOfficeAddress;
    this.primaryContact = data?.primaryContact;
    this.fullName = data?.fullName;
    this.firstName = data?.firstName;
    this.lastName = data?.lastName;
    this.homeCity = data?.homeCity;
    this.education = data?.education;
    this.occupation = data?.occupation;
    this.linkedin = data?.linkedin;
    this.birthday = new Date(data?.birthday);
    this.gender = data?.gender;
    this.description = data?.description;
  }
}

export class UserFullInfo {
  public user: User;
  public userRole?: UserRole;
  public userProvider?: IProvider;

  constructor(data: any) {
    this.user = new User(data?.user);
    this.userRole = new UserRole(data?.userRole);
    this.userProvider = data?.userProvider;
  }
}

export interface ICustomer {
  _id: string;
}

export interface IInvoice {
  _id: string;
  status: string;
  created: number;
  amount: number;
  pdfUrl: string;
}

export interface ICard {
  id: string;
  billing_details: {
    name: string;
    address: {
      country: string,
    },
  };
  card: {
    last4: string;
    exp_month: number;
    exp_year: number;
  };
}
