import {NgModule} from '@angular/core';
import {ProviderProfileRoutingModule} from './provider-profile-routing.module';
import {ProviderProfileComponent} from './provider-profile.component';
import {SharedModule} from '../shared/shared.module';
import {NgxStarRatingModule} from 'ngx-star-rating';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { GoogleMapComponent } from './google-map/google-map.component';


@NgModule({
  declarations: [ProviderProfileComponent, GoogleMapComponent],
  imports: [
    ProviderProfileRoutingModule,
    SharedModule,
    NgxStarRatingModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTooltipModule,
    GoogleMap,
    MapMarker
  ]
})
export class ProviderProfileModule {
}
