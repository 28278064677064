<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="dismiss()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">{{ title }}</p>

  <p class="paragraph2">{{ message }}</p>

  <button class="primary-btn" (click)="dismiss()">
    {{ button }}
  </button>
</div>
