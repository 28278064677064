<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Oops!</p>

  <p class="paragraph2">
    You need to have a verified email and phone number in order to perform this type of activity.
  </p>

  <button class="primary-btn" [routerLink]="['/account-settings']" fragment="verify" (click)="onNoClick()">
    Let's verify my account
  </button>

  <p class="link" (click)="onNoClick()">Verify later</p>
</div>
