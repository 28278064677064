<div class='block-container'>
  <div class='title'>Notification Preferences</div>
  <hr />
  <form class="notifications-box" [formGroup]="preferencesForm" (ngSubmit)='preferencesSaveChanges()'>
    <div class="notifications-content">
      <p class="paragraph2 notifications-content-title">
        When Provider Views Your Proposal
      </p>
      <div class="notifications-content-box">
        <p class="paragraph2">Email</p>
        <mat-slide-toggle color="primary" formControlName="viewsProposalEmail"></mat-slide-toggle>
      </div>
    </div>
    <div class="notifications-content">
      <p class="paragraph2 notifications-content-title">
        When Provider Shows Interest
      </p>
      <div class="notifications-content-box">
        <p class="paragraph2">Email</p>
        <mat-slide-toggle color="primary" formControlName="showsInterestEmail"></mat-slide-toggle>
      </div>
    </div>
    <button type='submit' class='primary-btn'>
      Save Changes
    </button>
  </form>
</div>
