<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Are you sure?!</p>

  <p class="paragraph2">
    If you close project before the proposal <br />
    deadline, it will get closed and you’ll need to <br />
    create a new brief.
  </p>

  <button class="primary-btn" type="submit" (click)="onNoClick()">
    Keep Project
  </button>
  <p class="view">Yes, Close Project</p>
</div>
