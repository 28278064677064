import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  UserFullInfo,
  UserTypesAccount,
} from '../../interfaces/auth-interfaces';
import { IProvider } from '../../interfaces/provider';
import { AuthDataService } from '../../services/auth-data.service';
import { LoginComponent } from '../popups/login/login.component';
import { UnverifiedAccountComponent } from '../popups/unverified-account/unverified-account.component';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-provider-profile',
  templateUrl: './provider-profile.component.html',
  styleUrls: ['./provider-profile.component.scss'],
})
export class ProviderProfileComponent implements OnInit {
  protected readonly UserTypesAccount = UserTypesAccount;
  public provider: IProvider;

  public coverImage = '';
  public providerLogo = '';
  public sentBriefs$: Observable<number>;

  public user: UserFullInfo;
  public activeAccountType$: Observable<UserTypesAccount>;

  public backBtnTitle = 'Back to main page';

  constructor(
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private authDS: AuthDataService,
    private router: Router,
    private dialog: MatDialog,
    private dr: DestroyRef
  ) {}

  ngOnInit() {
    this.provider = this.route.snapshot.data.provider;
    this.authDS.user$
      .pipe(
        takeUntilDestroyed(this.dr),
        filter((user) => !!user)
      )
      .subscribe((user) => {
        this.user = user;
        this.sentBriefs$ = this.authDS.getBriefsSentByUserToProvider(this.provider._id);
      });

    this.coverImage =
      this.provider.coverPhotoUrl ||
      'assets/EmptyBackgroundProviderProfile.png';
    this.providerLogo =
      this.provider.logo || 'assets/pvoviderLogoPlaceholder.png';
    this.activeAccountType$ = this.authDS.activeAccount$;

    this.initBackBtnOptions();
  }

  get isOwnProfile() {
    return this.user?.userProvider?._id === this.provider?._id;
  }
  get isSocialMediaLinks() {
    return Object.values(this.provider.socialMediaLinks).some(el => {
      return typeof el === 'string' ? !!el : el.length;
    });
  }

  get isProjectLimitPerMonth() {
    return this.provider.sharedProjectsInMonth >= 10 && !this.provider.plan;
  }

  private initBackBtnOptions() {
    const fromSearchPage = !!this.route.snapshot.queryParams?.fromSearch;
    const fromPopup = !!this.route.snapshot.queryParams?.fromPopup;
    const fromEditProfile = !!this.route.snapshot.queryParams?.fromEditProfile;
    if (fromEditProfile) {
      this.backBtnTitle = 'Back to Manage Profile';
    } else if (fromSearchPage) {
      this.backBtnTitle = 'Back to search results';
    } else if (fromPopup) {
      this.backBtnTitle = 'Back to project brief creation';
    } else {
      this.backBtnTitle = 'Back to main page';
    }
  }

  public onBackClick() {
    const fromSearchPage = !!this.route.snapshot.queryParams?.fromSearch;
    const fromPopup = !!this.route.snapshot.queryParams?.fromPopup;
    const fromEditProfile = !!this.route.snapshot.queryParams?.fromEditProfile;
    const searchValue = localStorage.getItem('search');
    if (fromSearchPage) {
      if (searchValue) {
        this.router.navigate(['/search-service-providers'], {
          queryParams: { search: searchValue ?? '' },
        });
      } else {
        this.router.navigate(['/search-service-providers']);
      }
    } else if (fromPopup) {
      const prevUrl = localStorage.getItem('urlFrom');
      this.router.navigateByUrl(prevUrl);
    } else if (fromEditProfile) {
      this.router.navigate(['/provider/edit-profile']);
    } else {
      this.router.navigate(['/'], { queryParams: { searchProviderPopup: true }});
    }
  }

  public onSendBriefClick() {
    if (this.user) {
      if (this.user?.user.isPhoneVerified && this.user?.user.confirmed) {
        this.router
          .navigate(['/create-brief'], {
            queryParams: {
              company: this.provider?._id,
              type: 'provider',
              name: this.provider?.companyName
            },
          })
          .then();
      } else {
        this.dialog.open(UnverifiedAccountComponent, {
          autoFocus: false,
          width: '450px',
          disableClose: true
        });
      }
    } else {
      this.dialog.open(LoginComponent, {
        autoFocus: false,
        width: '500px',
        data: {
          title: ' ',
          redirectTo: `/create-brief?company=${this.provider._id}&type=provider&name=${encodeURIComponent(this.provider.companyName)}`,
          message: 'You need to be authorized to be able to send a project brief.',
          button: 'Got it!',
        }
      });
    }
  }

  public onWebsiteClick(e: Event) {
    e.preventDefault();
    window.open(this.provider.companyWebSite, 'mywin', 'width=500,height=500');
  }
}
