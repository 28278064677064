<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">About Us</p>
  <br />
  <p class="sub-title">What We Do</p>
  <p class="paragraph2">
    If you’re an individual or company looking to develop mobile apps, websites, or custom software, The Unicorn App makes it easy to connect with quality service providers as part of your vendor selection process. We offer a quick and simple way to create project briefs to be sent to the best service providers from across the world that matches your needs and preferences.
  </p>
  <p class="paragraph2">
    We also help our valued and highly skilled service providers by bringing forward potential opportunities that drive new business and growth.
  </p>
  <br />
  <p class="sub-title">How We Do It</p>
  <p class="paragraph2">
    We feature only the best service providers from all over the world so that people can have the confidence and trust to connect with companies that have a track record of delivering credible client feedback and positive reviews. We also do this by providing quick and efficient ways to distribute project briefs of people looking to get work done and track progress/results. Service providers simply contact you back so you can handle business going forward.
  </p>
  <br />
  <p class="sub-title">What Else?</p>
  <p class="paragraph2">
    As the term “unicorn” is used in venture capital to describe a company with a valuation of over $1 billion, our goal are to help people reach big dreams and collaborate with service providers to launch great product/services so that one day their project, venture or side hustle becomes..yep a unicorn.
  </p>
</div>
