<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="dismiss()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Awesome!</p>

  <p class="paragraph2">The client will now know that your company is interested in working with them!</p>
  <p class="paragraph2">We’ve sent you a follow-up email that includes more information about the project and the client’s contact details. </p>

  <button class="primary-btn" (click)="dismiss()">
    Great!
  </button>
</div>
