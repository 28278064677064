import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserFullInfo } from '../../interfaces/auth-interfaces';
import { AuthDataService } from '../../services/auth-data.service';
import { FormControl, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserRoleEnum } from '../../enums/user-role.enum';
import { AccountSettingsService } from './account-settings.service';

@Component({
  selector: 'app-account-settings2',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  providers: [AccountSettingsService],
})
export class AccountSettingsComponent implements OnInit {
  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  public accountSettingsService = inject(AccountSettingsService);
  private authDS = inject(AuthDataService);
  private loader = inject(NgxUiLoaderService);

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  public $activeTab = this.accountSettingsService.$activeTab;
  public userRoleEnum = UserRoleEnum;

  public tabs = [
    'Profile',
    'Notification Preferences',
  ];

  public url: string | ArrayBuffer;
  public user: UserFullInfo;

  logoUrl = new FormControl<any>('', Validators.required);

  ngOnInit() {
    this.accountSettingsService.init(this.stepper);
    this.route.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (params) => {
          this.$activeTab.set(params.activeTab ?? this.tabs[0]);
          if (this.$activeTab() === 'Verification' && this.accountSettingsService.$hasAccessToVerification()) {
            this.stepper.selectedIndex = 2;
            return;
          }
          if (this.$activeTab() === 'Verification' && !this.accountSettingsService.$hasAccessToVerification()) {
            this.onTabChange(this.tabs[0]);
            return;
          }
          if (this.$activeTab() === 'Delete') {
            this.stepper.selectedIndex = 4;
            return;
          }
          const idx = this.tabs.indexOf(this.$activeTab());
          this.stepper.selectedIndex = idx;
        },
      });
    this.authDS.user$.subscribe({
      next: (user) => {
        if (!user) {
          return;
        }
        this.user = user;
      },
    });
  }

  onTabChange(activeTab: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        activeTab,
      },
      queryParamsHandling: 'merge',
    });
    this.$activeTab.set(activeTab);
  }

  public selectFile(event) {
    if (!event.target.files[0] || event.target.files[0].length === 0) {
      return;
    }
    const file = event.target.files[0];
    this.logoUrl.setValue(file ? file.name : '');
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      this.url = reader.result;
      this.onUpload(this.userRoleEnum[this.user?.user?.role], file);
    };
  }

  onUpload(userType: string, file: File) {
    const payload = new FormData();
    payload.append('fileLogo', file, file.name);
    const options = {
      reportProgress: true,
      observe: 'events',
    };
    this.loader.start();
    this.authDS.uploadLogo(userType, payload, options)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => this.getUserProfile().subscribe(() => this.loader.stop()),
      });
  }

  private getUserProfile() {
    return this.authDS.getUserProfile().pipe(takeUntilDestroyed(this.destroyRef));
  }

  deleteAccount() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        activeTab: 'Delete',
      },
      queryParamsHandling: 'merge',
    });
  }
}
