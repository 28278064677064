<app-header (searchValue)="doSearch($event, filters, selectedSortItem, true)"></app-header>
<section class="search-results">
  <div class="container">
    <div class="search-results-content">
      <h2 *ngIf="search === ''" class="title">Search Service Providers</h2>
      <div class="filters">
        <button (click)="openFilters()" class="primary-btn-transparent">
          <img src="./../../../assets/icons/filters.svg" alt="" /> Filter Your
          Search
        </button>
        <div class="filters-wrap">
          <div *ngFor="let filter of filters?.countries; let i = index">
            <div class="filters-wrap-item">
              <p class="paragraph2">{{ filter }}</p>
              <b (click)="removeFilter(filters?.countries, i)">&#10005;</b>
            </div>
          </div>
          <div
            *ngFor="let filter of filters?.specializationAreas; let i = index"
          >
            <div class="filters-wrap-item">
              <p class="paragraph2">{{ filter }}</p>
              <b (click)="removeFilter(filters?.specializationAreas, i)"
                >&#10005;</b
              >
            </div>
          </div>
        </div>
        <div
          *ngIf="
            filters?.countries.length > 0 ||
            filters?.specializationAreas.length > 0
          "
          class="clear-button"
        >
          <img src="./../../../assets/icons/Trash.svg" alt="" />
          <p class="paragraph2" (click)="clearFilters()">Clear Filters</p>
        </div>
      </div>
      <div class="results-sorting">
        <h2 *ngIf="totalCount === 0" style="font-size: 1.5rem">
          We found 0 results matching your search.
        </h2>
        <h2 *ngIf="totalCount === 1" style="font-size: 1.5rem">
          We found 1 result matching your search.
        </h2>
        <h2 *ngIf="totalCount > 1" style="font-size: 1.5rem">
          We found {{ totalCount }} results matching your search.
        </h2>

        <div class="sorting">
          <p class="paragraph">Sort by</p>
          <mat-form-field appearance="fill">
            <mat-select [value]="sortList[0].value">
              <mat-option
                *ngFor="let item of sortList"
                [value]="item.value"
                (onSelectionChange)="selectSortItem($event)"
              >
                {{ item.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


        <div class="providers-content"
             infinite-scroll
             [scrollWindow]="true"
             (scrolled)="onScrollDown($event)"
             (scrolledUp)="onScrollUp($event)">
          <div class="search-results-content-wrap">
          <div
          class="company"
          *ngFor="let company of providers"
          [routerLink]="['/provider-profile']"
          [queryParams]="{ id: company._id, fromSearch: true }"
        >
          <div class="company-img-title">
            <img src="{{ company?.logo || '../assets/pvoviderLogoPlaceholder.png' }}" alt="img" />
            <div class="company-img-title-text">
              <p class="paragraph">{{ company?.companyName }}</p>
              <p class="paragraph2">
                {{ company?.city?.name ? company.city.name +  ', ' : '' }}{{ company.country.name }}
              </p>
              <p class="paragraph2">{{ company?.minProjectAmount }}$</p>
            </div>
          </div>
          <div class="company-description">
            <p class="paragraph2">{{ company?.about }}</p>
          </div>
          <div class="company-score">
            <div class="company-score-item">
              <p class="paragraph2">REPUTATION:</p>
              <form [formGroup]="form">
                <!--              <ngx-star-rating formControlName="rating2" [id]="'rating2'" [attr.disabled]="true"></ngx-star-rating>-->
                <div class="rate">
                  <input
                    disabled
                    [checked]="company?.clutchScore === 5"
                    type="radio"
                    id="rating10"
                    name="rating"
                    value="10"
                  />
                  <label for="rating10" title="5 stars"></label>
                  <input
                    disabled
                    [checked]="
                      company?.clutchScore >= 4.5 && company?.clutchScore != 5
                    "
                    type="radio"
                    id="rating9"
                    name="rating"
                    value="9"
                  />
                  <label class="half" for="rating9" title="4 1/2 stars"></label>

                  <input
                    disabled
                    [checked]="company?.clutchScore < 4.5"
                    type="radio"
                    id="rating8"
                    name="rating"
                    value="8"
                  />
                  <label for="rating8" title="4 stars"></label>
                  <input
                    disabled
                    type="radio"
                    id="rating7"
                    name="rating"
                    value="7"
                  />
                  <label class="half" for="rating7" title="3 1/2 stars"></label>

                  <input
                    disabled
                    type="radio"
                    id="rating6"
                    name="rating"
                    value="6"
                  />
                  <label for="rating6" title="3 stars"></label>
                  <input
                    disabled
                    type="radio"
                    id="rating5"
                    name="rating"
                    value="5"
                  />
                  <label class="half" for="rating5" title="2 1/2 stars"></label>

                  <input
                    disabled
                    type="radio"
                    id="rating4"
                    name="rating"
                    value="4"
                  />
                  <label for="rating4" title="2 stars"></label>
                  <input
                    disabled
                    type="radio"
                    id="rating3"
                    name="rating"
                    value="3"
                  />
                  <label class="half" for="rating3" title="1 1/2 stars"></label>

                  <input
                    disabled
                    type="radio"
                    id="rating2"
                    name="rating"
                    value="2"
                  />
                  <label for="rating2" title="1 star"></label>
                  <input
                    disabled
                    type="radio"
                    id="rating1"
                    name="rating"
                    value="1"
                  />
                  <label class="half" for="rating1" title="1/2 star"></label>
                </div>
                <p class="paragraph2">{{ company?.clutchScore }}</p>
              </form>
            </div>
            <div class="company-score-item know-for">
              <p class="paragraph2">KNOWN FOR:</p>
              <div class="specializations-wrap">
                <p
                  class="specializations-wrap-item"
                  *ngIf="company?.specializationAreas.length > 0"
                >
                  <!--{{!specializationTag ? checkSpecialization(company?.specializationAreas) : null}}-->
                  {{ company?.specializationAreas[0] }}
                </p>
                <p *ngIf="company?.specializationAreas.length > 1">
                  +{{ company?.specializationAreas.length - 1 }}
                </p>
              </div>
            </div>
          </div>
        </div>
        </div>
          <div *ngIf="totalCount !== providers.length" class="loading">Loading...</div>
        </div>
    </div>
  </div>
</section>
