import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, CardNumberPipe } from './app.component';
import { HomeModule } from './modules/home/home.module';
import { SharedModule } from './modules/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './modules/popups/reset-password/reset-password.component';
import { CheckEmailComponent } from './modules/popups/check-email/check-email.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { CustomInterceptor } from './services/interceptor.service';
import { MatStepperModule } from '@angular/material/stepper';
import { AccountSettingsModule } from './modules/account-settings/account-settings.module';
import { ProviderProfileModule } from './modules/provider-profile/provider-profile.module';
import { ConfirmEmailComponent } from './modules/popups/confirm-email/confirm-email.component';
import { ChangePasswordComponent } from './modules/popups/change-phone-number/change-password.component';
import { SearchResultsModule } from './modules/search-results/search-results.module';
import { CreateBriefModule } from './modules/create-brief/create-brief.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LetUsComponent } from './modules/popups/let-us/let-us.component';
import { GetListedComponent } from './modules/popups/get-listed/get-listed.component';
import { MessageSentComponent } from './modules/popups/message-sent/message-sent.component';
import { CongartsBriefComponent } from './modules/popups/congarts-brief/congarts-brief.component';
import { MyBriefsModule } from './modules/my-briefs/my-briefs.module';
import { AlertsModule } from './modules/alerts/alerts.module';
import { GreatNewsComponent } from './modules/popups/great-news/great-news.component';
import { ProjectSaveComponent } from './modules/popups/project-save/project-save.component';
import { ProvidersComponent } from './modules/popups/providers/providers.component';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { CloseProjectComponent } from './modules/popups/close-project/close-project.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { PrivacyComponent } from './modules/privacy/privacy.component';
import { OhNoComponent } from './modules/popups/oh-no/oh-no.component';
import { NotAvilableComponent } from './modules/popups/not-avilable/not-avilable.component';
import { AboutUsComponent } from './modules/popups/about-us/about-us.component';
import { ContactUsComponent } from './modules/popups/contact-us/contact-us.component';
import { DeleteBriefDialogComponent } from 'src/app/modules/popups/delete-brief-dialog/delete-brief-dialog.component';
import { MobileDetectedComponent } from './modules/popups/mobile-detected/mobile-detected.component';
import { NgxStripeModule } from 'ngx-stripe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { IsInterestedComponent } from './modules/popups/is-interested/is-interested.component';
import { IsNotInterestedComponent } from './modules/popups/is-not-interested/is-not-interested.component';
import { TokenInvalidComponent } from './modules/popups/token-invalid/token-invalid.component';
import { AccountDeletedComponent } from './modules/popups/account-deleted/account-deleted.component';
import { LinkDeactivatedComponent } from './modules/popups/link-deactivated/link-deactivated.component';
import { UnverifiedAccountComponent } from './modules/popups/unverified-account/unverified-account.component';
import { AuthGuardService } from './services/auth-guard.service';
import { RecaptchaFormsModule, RecaptchaV3Module } from 'ng-recaptcha';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper-v-13';
import { TermsModule } from './modules/terms/terms.module';
import { MatSelectFilterModule } from '@grothar/mat-select-filter';
import { CheckoutComponent } from './modules/checkout/checkout.component';
import { AreYouSureComponent } from './modules/popups/are-you-sure/are-you-sure.component';
import { DocumentPreviewComponent } from './modules/popups/document-preview/document-preview.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CloseBriefConfirmationComponent } from './modules/popups/close-brief-confirmation/close-brief-confirmation.component';
import { AnimatedLoaderModule } from './modules/animated-loader/animated-loader.module';
import { CancelingOrDeletingDialog } from './modules/popups/delete-doc-dialog/canceling-or-deleting-dialog.component';
import { DocSaveComponent } from './modules/popups/doc-save/doc-save.component';
import { ChooseAccountTypeComponent } from './modules/popups/choose-account-type/choose-account-type.component';
import { GenericConfirmationComponent } from './modules/popups/generic-confirmation/generic-confirmation.component';
import { DeleteProviderAccountComponent } from './modules/popups/delete-provider-account/delete-provider-account.component';
import { QuillModule } from 'ngx-quill';
import { ArticleSaveComponent } from './modules/popups/article-save/article-save.component';
import { MessageComponent } from './modules/popups/message/message.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { NgOptimizedImage } from '@angular/common';
import { SpecificMultipleProviderComponent } from './modules/popups/specific-multiple-provider/specific-multiple-provider.component';
import { SearchProviderForBriefComponent } from './modules/popups/search-provider-for-brief/search-provider-for-brief.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordComponent,
    CheckEmailComponent,
    ConfirmEmailComponent,
    ChangePasswordComponent,
    LetUsComponent,
    GetListedComponent,
    MessageSentComponent,
    CongartsBriefComponent,
    GreatNewsComponent,
    DeleteBriefDialogComponent,
    ProjectSaveComponent,
    ProvidersComponent,
    CloseProjectComponent,
    PrivacyComponent,
    CardNumberPipe,
    OhNoComponent,
    NotAvilableComponent,
    AboutUsComponent,
    ContactUsComponent,
    MobileDetectedComponent,
    IsInterestedComponent,
    IsNotInterestedComponent,
    TokenInvalidComponent,
    AccountDeletedComponent,
    LinkDeactivatedComponent,
    UnverifiedAccountComponent,
    CheckoutComponent,
    AreYouSureComponent,
    DocumentPreviewComponent,
    CloseBriefConfirmationComponent,
    CancelingOrDeletingDialog,
    DocSaveComponent,
    ChooseAccountTypeComponent,
    GenericConfirmationComponent,
    DeleteProviderAccountComponent,
    ArticleSaveComponent,
    MessageComponent,
    NotFoundComponent,
    SpecificMultipleProviderComponent,
    SearchProviderForBriefComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgOtpInputModule,
    HomeModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatStepperModule,
    AccountSettingsModule,
    ProviderProfileModule,
    SearchResultsModule,
    InfiniteScrollModule,
    NgxStripeModule.forChild('pk_live_51HhFqNJXGQlfMzXtMma9UTewD1ThS59hBwnfecsdfAOwCDGVJsXuhKzFE7h8WRME1A1hA0bwdMKUyQz9bIaQXLxv00PzoYz4n6'),
    // NgxStripeModule.forChild('pk_test_51HhFqNJXGQlfMzXtIstqMd6Nt8IlQeO4OZ3zEeudBOLpWSfTZ9gNRSu9OPEZce7tiTpugqBzprHlgTQrqAxBjbc000bP1M57hx'),
    MatFormFieldModule,
    MatSelectModule,
    MatSelectFilterModule,
    MyBriefsModule,
    AlertsModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    MatDialogModule,
    MatTabsModule,
    NgxStarRatingModule,
    NgxUiLoaderModule,
    SwiperModule,
    MatAutocompleteModule,
    MatInputModule,
    TermsModule,
    CreateBriefModule,
    PdfViewerModule,
    AnimatedLoaderModule,
    QuillModule.forRoot(),
    NgOptimizedImage,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
    MatSlideToggleModule,
  ],
  providers: [
    AuthGuardService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true,
    }],
  exports: [CardNumberPipe, RecaptchaV3Module, RecaptchaFormsModule],
  bootstrap: [AppComponent],
})
export class AppModule {
}
