import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ILogin } from '../../../interfaces/auth-interfaces';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { first, skipWhile, switchMap } from 'rxjs/operators';
import { AuthApiService } from '../../../services/auth-api.service';
import { MustMatch } from '../../auth/sign-up/sign-up.component';
import { Router } from '@angular/router';
import { AuthDataService } from '../../../services/auth-data.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-change-phone-number',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  @Output() sendLoginEmitter: EventEmitter<ILogin> = new EventEmitter<ILogin>();
  public typePassword = 'password';
  showPassword = false;
  public typeConfirmPassword = 'password';
  showConfirmPassword = false;

  public loginForm: UntypedFormGroup = this.fb.group(
    {
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirm: ['', [Validators.required, Validators.minLength(6)]],
    },
    {
      validator: MustMatch('newPassword', 'passwordConfirm'),
    }
  );
  public submitted = false;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ILogin,
    private authAS: AuthApiService,
    private authDS: AuthDataService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private router: Router
  ) {}

  onNoClick(): void {
    this.router.navigate(['/']).then(() => location.reload());
    this.dialogRef.close();
  }

  public doLogin() {
    this.submitted = true;
    if (this.loginForm.controls.newPassword.invalid && this.loginForm.controls.passwordConfirm.invalid) {
      return;
    }

    if (this.loginForm.controls.newPassword.value === this.loginForm.controls.passwordConfirm.value) {
      const password = {
        token: localStorage.getItem('tokenReset'),
        newPassword: this.loginForm.controls.passwordConfirm.value,
      };
      this.authAS
        .changePassword(password)
        .pipe(
          switchMap(({ tokenId }) => {
            localStorage.setItem('token', tokenId);
            this.authDS.setUserToken(tokenId);
            return this.authDS.getUserProfile();
          }),
          first()
        )
        .subscribe(() => {
          this.onNoClick();
        });
    }
  }

  public changePasswordType() {
    if (this.typePassword === 'password') {
      this.typePassword = 'text';
      this.showPassword = true;
    } else {
      this.typePassword = 'password';
      this.showPassword = false;
    }
  }

  public changeConfirmPasswordType() {
    if (this.typeConfirmPassword === 'password') {
      this.typeConfirmPassword = 'text';
      this.showConfirmPassword = true;
    } else {
      this.typeConfirmPassword = 'password';
      this.showConfirmPassword = false;
    }
  }

  public openLoginPopup(): void {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      autoFocus: false,
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        skipWhile((result) => !result)
      )
      .subscribe((result) => this.sendLoginEmitter.emit(result));
  }
}
