import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import countries from './../../files/countries.json';
import { delay, filter, finalize, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ILogin } from '../../interfaces/auth-interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthDataService } from '../../services/auth-data.service';
import { ProjectSaveComponent } from '../popups/project-save/project-save.component';
import { GreatNewsComponent } from '../popups/great-news/great-news.component';
import { AllFeatures, ChosenFeatures, ICountry, IProject, IProjectInfo, RequirementsTypes } from '../../interfaces/project';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OhNoComponent } from '../popups/oh-no/oh-no.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
// @ts-ignore
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { BriefTypeSend } from 'src/app/enums/brief.enum';
import { DeleteBriefDialogComponent } from 'src/app/modules/popups/delete-brief-dialog/delete-brief-dialog.component';
import { MatSelectFilterComponent } from '@grothar/mat-select-filter';
import { IProvider } from '../../interfaces/provider';
import { DocumentPreviewComponent } from '../popups/document-preview/document-preview.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs/internal/operators/tap';
import { NavigationService } from '../../services/navigation.service';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM DD, yyyy',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const BRIEF_FIRST_STEP_PERCENTAGE = 0;

@Component({
  selector: 'app-create-brief',
  templateUrl: './create-brief.component.html',
  styleUrls: ['./create-brief.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CreateBriefComponent implements OnInit, AfterViewInit {

  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private authDS: AuthDataService,
    private ngxService: NgxUiLoaderService,
    private navigationService: NavigationService,
    private router: Router,
    private elRef: ElementRef,
    private destroyRef: DestroyRef
  ) {}

  get dynamicSteps() {
    return this.briefForm.get('dynamicSteps') as FormArray;
  }
  @ViewChild('range', { static: false }) range: ElementRef<HTMLInputElement>;
  @ViewChild('specFilter', { static: false }) specFilter: MatSelectFilterComponent;
  @ViewChild('specSkills', { static: false }) specSkills: MatSelectFilterComponent;
  @ViewChild('bubble', { static: false }) bubble: ElementRef<HTMLOutputElement>;
  @ViewChildren('checkbox') checkbox;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  public briefForm: UntypedFormGroup = this.fb.group({
    range: [101, [Validators.required]],
    message: [
      '',
      [
        Validators.maxLength(5000),
      ],
    ],
    calendar: ['', [Validators.required]],
    spec: '',
    types: this.fb.array([''], this.minSelectedCheckboxes()),
    checkArray: this.fb.array([]),
    things: '',
    name: [
      '',
      [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5000),
      ],
    ],
    details: this.fb.array([]),
    checkArrayCountries: this.fb.array([]),
    checkArrayCompensation: this.fb.array([]),
    checkArrayPrep: this.fb.array([]),
    typeRequirements: ['', Validators.required],
    dynamicSteps : this.fb.array([])
  });
  public formSpecialize: UntypedFormGroup = this.fb.group({
    spec: [],
  });
  public skillAreas: UntypedFormGroup = this.fb.group({
    skills: [],
  });

  chooseSpeciliaze = [];
  public stepBrief = 0;
  skills = [];
  public step;
  public array = [];
  public progress = 30;
  public countries = Object.keys(countries);
  public otherThings = false;
  public company;
  @Output() sendLoginEmitter: EventEmitter<ILogin> = new EventEmitter<ILogin>();
  public minDate;
  public submitted = false;
  public nextClicked = false;
  public getSkills: string[] = [];
  private skillAny = 'Any';
  public getSpecializations = [];
  private specializationAny = 'Any';
  public getPreps = [];
  public getAccept = [];
  public getTypes = [];
  public getDetails = [];
  public getCountries: ICountry[] = [];
  public brief = {
    provider: '',
    done: false,
    name: '',
    types: [],
    specialization: [],
    skillAreas: [],
    compensations: [],
  };
  public typeSendBrief: BriefTypeSend;
  public budget;
  public briefInfo: IProject;
  public briefFrom: IProject;
  public briefId = '';
  public filteredSpec;
  public filteredSkills: string[] = [];
  public companyName: string;
  public isDraft = false;
  public briefFormTemp: string;
  public briefTemp = {
    provider: '',
    done: false,
    name: '',
    types: [],
    specialization: [],
    skillAreas: [],
    compensations: [],
  };

  // Stepper properties
  public answeredSteps: number[] = [];
  private stepToPercentMap: { [key: number]: number } = {
    0: 0,
    1: 10,
    2: 30,
    3: 50,
    4: 60,
    5: 70,
    6: 90
  };
  private percentToStepMap: { [key: number]: number } = {
    0: 0,
    10: 1,
    30: 2,
    50: 3,
    60: 4,
    70: 5,
    90: 6,
  };
  public currentStep = 0;
  public percent = 0;
  public descriptionFieldCharactersLimit = 2000;

  allFeatures: AllFeatures = [];

  typeRequirementsNeeded = null;

  showProviders = false;


    sum = 20;
    skip = 0;
    providersArray = [];

  protected readonly RequirementsTypes = RequirementsTypes;

  showAllCheckedFeatures = false;

  showFeatureSubsteps = false;
  featureSubstepsLabels: string[] = [];
  currentFeatureSubstep = 1;
  maxFeatureSubstep = 1;
  answeredFeatureSubstep: number[] = [];

  ngOnInit(): void {
    this.ngxService.start();

    this.company = this.route.snapshot.queryParamMap.get('company');
    this.companyName = this.route.snapshot.queryParamMap.get('name');
    this.typeSendBrief = (this.route.snapshot.queryParamMap.get(
      'type'
    ) ?? 'all') as BriefTypeSend ;
    const step = this.route.snapshot.queryParamMap.get('step');
    this.isDraft = !!this.route.snapshot.queryParamMap.get('step');
    this.stepBrief = step && +step ? +step : 0;
    this.setInitStep(this.stepBrief);
    this.minDate = new Date();

    forkJoin([
      this.authDS.getSkills(),
      this.authDS.getCountries(),
      this.authDS.getSpec(),
      this.authDS.getAccept(),
      this.authDS.getProjectTypes(),
      this.authDS.getPrep(),
      this.authDS.getDetails(),
      this.authDS.getProvidersCountry(),
      this.authDS.getFeatures()
    ])
      .pipe(
        mergeMap((data) =>
          this.authDS.doSearch().pipe(map((providers) => [...data, providers]))
        ),
        first()
      )
      .subscribe(this.setData.bind(this));
  }

  ngAfterViewInit(): void {
    this.range.nativeElement.addEventListener('input', () => {
      this.setBubble(this.range.nativeElement, this.bubble.nativeElement);
    });
    this.setBubble(this.range.nativeElement, this.bubble.nativeElement);
  }

  back() {
    if (this.stepper.selectedIndex !== 0) {
      const prevStepIdx = this.stepper.selectedIndex - 1;
      this.setStep(prevStepIdx);
      return this.move(prevStepIdx);
    }
    if (this.route.snapshot.queryParamMap.get('fromMyBriefs')) {
      return this.router.navigate(['/my-briefs']);
    }
    const prevUrl = this.navigationService.getPreviousUrl();
    if (prevUrl?.includes('provider-profile')) {
      return this.router.navigate(['/provider-profile'], { queryParams: { id: this.company }});
    }
    if (this.companyName) {
      return this.router.navigate(['/'], { queryParams: { searchProviderPopup: true }});
    } else {
      return this.router.navigate(['/'], { queryParams: { selectProviderTypePopup: true }});
    }
  }

  private setInitStep(briefPercentStep: number): void {
    this.currentStep = this.percentToStepMap[briefPercentStep] || 0;
    this.answeredSteps = new Array(this.currentStep)
        .fill(0)
        .map((item, index) => index);
    this.percent = this.stepToPercentMap[this.currentStep] || BRIEF_FIRST_STEP_PERCENTAGE;
    const chosenFeatures =
        Object
            .entries(this.briefInfo?.featuresForDoc ?? {})
            .filter(el => el[1].isChosen);
    const dynamicSteps = this.briefForm.get('dynamicSteps') as FormArray;
    dynamicSteps.clear();
    this.featureSubstepsLabels = [];
    chosenFeatures.forEach(el => {
      dynamicSteps.push(this.fb.control(el));
      this.featureSubstepsLabels.push(this.allFeatures.find(feature => feature.key === el[0]).nameSection);
    });
    this.maxFeatureSubstep = chosenFeatures.length;
    this.answeredFeatureSubstep =
        chosenFeatures
            .filter(el => !!el[1].feats.length)
            .map((el, index) => index + 1);
    this.currentFeatureSubstep = this.answeredFeatureSubstep.length;
    if (this.currentStep === 3) {
      this.showFeatureSubsteps = true;
      if ((chosenFeatures.some(el => el[1].feats.length !== 0))) {
        if (this.stepper) {
          this.moveSubstep(this.currentFeatureSubstep - 1);
          return;
        }
      } else if (chosenFeatures.length) {
        this.move(this.currentStep);
        return;
      }
    }
    this.move(briefPercentStep !== BRIEF_FIRST_STEP_PERCENTAGE ? this.currentStep - 1 : this.currentStep);
  }

  private setStep(index: number): void {
    this.percent =
      this.answeredSteps.length - 1 < index
        ? this.stepToPercentMap[index] || BRIEF_FIRST_STEP_PERCENTAGE
        : this.percent;
    if (this.answeredSteps.length - 1 < index - 1) {
      if (this.typeSendBrief === BriefTypeSend.Provider && !this.answeredSteps.length) {
        this.answeredSteps.push(0);
      }
      this.answeredSteps.push(index - 1); // add step to answered steps
    }
    this.currentStep = index;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  private getCountriesByProviders(
    countries: ICountry[],
    providers: any[]
  ): ICountry[] {
    return countries
      .reduce<ICountry[]>((acc, country) => {
        const providersMatchCountry = providers.filter(
          (providerCountry) => country._id === providerCountry
        );
        if (providersMatchCountry.length) {
          acc.push({
            ...country,
            // providersCount: providersMatchCountry.length,
          });
        }
        return acc;
      }, [])
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }

  private setData([
    skills,
    countries,
    spec,
    accept,
    types,
    prep,
    details,
    providersCountry,
    features
  ]: [
    string[],
    ICountry[],
    any[],
    any[],
    any[],
    any[],
    any[],
    any[],
    AllFeatures
  ]): void {
    this.allFeatures = features;
    this.getSkills = this.getSortedSkills(skills);
    this.filteredSkills = this.getSkills.slice();
    this.patchForm({
      form: this.skillAreas,
      controller: 'skills',
      value: [this.skillAny],
    });

    this.getCountries = this.getCountriesByProviders(countries.filter(i => i !== null), providersCountry);

    this.getSpecializations = this.getSortedSpecs(spec);
    this.filteredSpec = this.getSpecializations.slice();

    this.patchForm({
      form: this.formSpecialize,
      controller: 'spec',
      value: [this.specializationAny],
    });

    this.getAccept = accept;
    const checkArrayCompensation =
      this.briefFrom?.acceptCompensation?.map((comp) => comp._id) || [];
    this.briefForm.setControl(
      'checkArrayCompensation',
      this.fb.array(checkArrayCompensation)
    );
    this.getTypes = types;
    this.getPreps = prep;
    this.getDetails = details;

    this.getDetails.map((detail) => {
      (this.briefForm.get('details') as UntypedFormArray).push(
        this.fb.group({
          value: new UntypedFormControl(
            '',
            detail.type === 0
              ? [
                  Validators.required,
                  Validators.minLength(50),
                  Validators.maxLength(this.descriptionFieldCharactersLimit),
                ]
              : [Validators.required]
          ),
          model: new UntypedFormControl(detail._id),
          title: new UntypedFormControl(detail.title),
          description: new UntypedFormControl(detail.description),
          type: new UntypedFormControl(detail.type),
        })
      );
    });

    if (this.route.snapshot.queryParamMap.get('briefId') !== null) {
      this.authDS
        .getBriefById(this.route.snapshot.queryParamMap.get('briefId'))
        .pipe(first())
        .subscribe((project: {project: IProject}) => {
          localStorage.setItem(
            'briefId',
            this.route.snapshot.queryParamMap.get('briefId')
          );
          this.briefFrom = project.project;
          this.setBriefInfo(project.project);
          this.briefForm.controls.typeRequirements.setValue(project.project.typeRequirements);
          this.patchForm({
            form: this.briefForm,
            controller: 'name',
            value: project.project.name,
            validators: [
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(5000),
            ],
            markAsTouched: true,
          });
          this.brief.types = project.project.types?.map((type) => type._id);
          this.briefForm
            .get('calendar')
            .patchValue(
              project.project.proposalValidUntil ? project.project.proposalValidUntil : ''
            );

          this.briefForm
            .get('range')
            .patchValue(project.project.budget && +project.project.budget === 1 ? 101 * 500 : project.project.budget ? project.project.budget / 500 : 101 * 500);

          this.setBubble(this.range.nativeElement, this.bubble.nativeElement);

          this.patchForm({
            form: this.briefForm,
            controller: 'message',
            value: project.project.specialMessage,
            validators: [
              Validators.maxLength(5000),
            ],
            markAsTouched: true,
          });


          const prepIds = prep.map((prepItem) => prepItem._id);
          const briefPrepIds = this.briefFrom.prep
            .map((prepItem) => {
              if (prepIds.includes(prepItem._id)) {
                return prepItem._id;
              }
              else {
                this.otherThings = true;
                this.briefForm.get('things')?.patchValue(prepItem.title);
                this.otherThingsRequired(true);
              }
            })
            .filter((prepItem) => !!prepItem);

          this.briefForm.setControl(
            'checkArrayPrep',
            this.fb.array(briefPrepIds || [])
          );

          const specialization = this.briefFrom.specialization?.length
            ? this.briefFrom.specialization
            : [this.specializationAny || 'Any'];

          this.patchForm({
            form: this.formSpecialize,
            controller: 'spec',
            value: specialization,
            validators: [],
            markAsTouched: true,
          });

          this.brief.skillAreas = this.briefFrom.skillAreas
            .map((skill) => skill)
            .filter((id) => id !== this.skillAny);

          this.patchForm({
            form: this.skillAreas,
            controller: 'skills',
            value: this.brief.skillAreas.length ? this.brief.skillAreas : [this.skillAny],
            validators: [],
            markAsTouched: true,
          });

          this.brief.specialization = this.briefFrom.specialization.filter(
            (spec) => spec !== this.specializationAny
          );

          this.brief.compensations =
            this.briefFrom.acceptCompensation?.map((ac) => ac._id) || [];

          this.briefForm.setControl(
            'checkArrayCountries',
            this.fb.array(project.project.country.length > 0 ? project.project.country.map(i => i._id) : [null])
          );

          this.getDetails.map((detail, i) => {
            (this.briefForm.get('details') as UntypedFormArray).controls[i].patchValue(
              { value: this.getBriefDetailsValue(detail._id) || '' }
            );
            (this.briefForm.get('details') as UntypedFormArray).controls[
              i
            ].setValidators([
              Validators.required,
              Validators.minLength(50),
              Validators.maxLength(this.descriptionFieldCharactersLimit),
            ]);
          });

          this.briefForm.updateValueAndValidity();
          this.briefTemp = JSON.parse(JSON.stringify(this.brief));
          this.setInitStep(this.stepBrief);
        });
    }

    this.ngxService.stop();
  }

  getSortedSpecs(specs: string[]): string[] {
    const anySpecIndex = specs.findIndex((spec) => spec === 'Any');
    const anySpec = specs.splice(anySpecIndex, 1);
    this.specializationAny = anySpec.length ? anySpec[0] : 'Any';
    const sortedSpecs = specs.sort((a, b) =>
      a?.trim().toLowerCase().localeCompare(b?.trim().toLowerCase())
    );
    return [...anySpec, ...sortedSpecs];
  }

  private getSortedSkills(skills: string[]): string[] {
    const anySkillIndex = skills.findIndex((skill) => skill === 'Any');
    const anySkill = skills.splice(anySkillIndex, 1);
    this.skillAny = anySkill.length ? anySkill[0] : undefined;
    const sortedSkills = skills.sort((a, b) =>
      a
        ?.trim()
        .toLowerCase()
        .localeCompare(b?.trim().toLowerCase())
    );
    return [...anySkill, ...sortedSkills];
  }

  private getBriefDetailsValue(detailId: string): string {
    return this.briefFrom?.projectDetails?.find(
      (detail) => detail.model._id === detailId
    )?.value;
  }

  public getControls(controlPath: any): any {
    return (controlPath as UntypedFormGroup).controls;
  }

  public move(index: number): void {
    this.showFeatureSubsteps = index === 3;
    if (this.stepper) {
      this.stepper.selectedIndex = this.typeSendBrief === BriefTypeSend.Provider && index ? index - 1 : index;
      this.setStep(this.typeSendBrief === BriefTypeSend.Provider ? index : index);
      if (this.briefTemp && (JSON.stringify(this.briefTemp) !== JSON.stringify(this.brief))){
        for (const [key, value] of Object.entries(this.briefTemp)) {
          this.brief[key] = value;
          if (key === 'skillAreas') {
            [...value as []].length
                ? this.skillAreas.get('skills').reset(value)
                : this.skillAreas.get('skills').reset([this.skillAny]);
          }
          if (key === 'specialization') {
            [...value as []].length ? this.formSpecialize.get('spec').reset(value) : this.formSpecialize.get('spec').reset(['Any']);
          }
        }
      } else {
        this.briefTemp = JSON.parse(JSON.stringify(this.brief));
      }
      if (this.briefFormTemp && (JSON.stringify(this.briefFormTemp) !== JSON.stringify(this.briefForm.value))) {
        const data = JSON.parse(this.briefFormTemp);
        for (const [key, value] of Object.entries(data)) {
          if (key === 'dynamicSteps') break;
          this.briefForm.value[key] = value;
          if (value && Array.isArray(value)) {
            if (key === 'checkArrayCountries') {
              this.elRef.nativeElement.querySelector('.countriesAny').checked = false;
              const checkboxes = this.elRef.nativeElement.querySelectorAll('.countries');
              for (let i = 0; i < checkboxes.length; i++) {
                const county = this.getCountries.find(c => c._id === checkboxes[i].value);
                checkboxes[i].checked = false;
                if (value.includes(county._id)) {
                  checkboxes[i].checked = true;
                }
              }

              if (value[0] === null && value.length === 1 || !value.length) {
                this.briefForm.setControl(
                    'checkArrayCountries',
                    this.fb.array([null])
                );
                const checkbox = this.elRef.nativeElement.querySelector('.countriesAny');
                checkbox.checked = true;
              }
              this.briefForm.get(key).reset(value);
            } else {
              this.briefForm.get(key).reset(value);
            }
            if (key === 'checkArrayPrep') {
              this.briefForm.setControl(
                  'checkArrayPrep',
                  this.fb.array(value || [])
              );
            }
          } else if (key === 'things') {
            if ((value as string).length < 50) {
              this.otherThings = false;
              this.briefForm.get(key).setValue('');
            } else {
              this.otherThings = true;
              this.briefForm.get(key).setValue(value);
            }
          } else {
            this.briefForm.get(key).setValue(value);
          }
        }
        this.setBubble(this.range.nativeElement, this.bubble.nativeElement);
      } else {
        this.briefFormTemp = JSON.stringify(this.briefForm.value);
      }
    }
  }

  public deleteBrief(): void {
    const dialog = this.dialog.open(DeleteBriefDialogComponent, {
      autoFocus: false,
      width: '500px',
      data: {
        briefName: this.brief.name || this.briefFrom.name,
      },
    });

    dialog
      .afterClosed()
      .pipe(
        first(),
        filter((result) => !!result),
        switchMap(() => this.authDS.deleteBrief(this.briefFrom?._id || localStorage.getItem('briefId')))
      )
      .subscribe(() => {
        this.router.navigate(['/my-briefs']);
      });
  }

  // public checkPrepChecked(prepId: string): boolean {
  //   return this.briefFrom
  //     ? this.briefFrom.prep.some((elem) => elem._id === prepId)
  //     : false;
  // }

  public send() {
    if (this.nextClicked) { return; }
    this.nextClicked = true;

    this.ngxService.start();

    const brief = {
      done: true,
      _id: this.briefId || this.briefFrom?._id,
      briefCreatedDate: new Date()
    };

    this.authDS
      .editBrief(brief)
      .pipe(first())
      .subscribe(
        (res) => {
          if (this.typeSendBrief === BriefTypeSend.All) {
            this.router.navigate(['/checkout'], { queryParams: {briefId : res.project._id} }).then();
          } else if (this.briefForm.value.typeRequirements !== RequirementsTypes.non) {
            this.router.navigate(['/checkout'], { queryParams: {briefId : res.project._id},  state: { onlyDocument: true }}).then();
          } else if (this.typeSendBrief === BriefTypeSend.Provider) {
            this.router.navigate(
                ['/my-briefs/preview'],
                {
                  queryParams: {
                    briefId: this.briefId
                  }}
            ).then();
          }
          this.ngxService.stop();
          this.nextClicked = false;
        },
        () => (this.nextClicked = false)
      );
  }

  public saveBrief(step: number): void {
    if (this.nextClicked) { return; }
    this.nextClicked = true;

    let save$ = of(null);
    switch (step) {
      case 1:
        save$ = this.saveStep1();
        break;
      case 2:
        const projectDetailsValues = this.briefForm.get('details').value.map((item) => item.value);
        let check = false;
        for (let i = 0; i < this.briefInfo.projectDetails.length; i++) {
          if (projectDetailsValues[i] === this.briefInfo.projectDetails[i].value) {
            check = true;
          } else {
            check = false;
            break;
          }
        }

        if (check) {
          const saveDialog = this.dialog.open(ProjectSaveComponent, {
            autoFocus: false,
            width: '500px',
            data: {
              provider: this.company,
            },
          });

          saveDialog
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
              if (result?.goBriefs) {
                this.router.navigate(['/my-briefs']);
              } else {
                this.nextClicked = false;
                this.step++;
                this.stepper.next();
                this.setStep(this.currentStep + 1);
              }
            });
          return;
        }
        save$ = this.saveStep2();
        break;
      case 4:
        save$ = this.saveStep4();
        break;
      case 5:
        save$ = this.saveStep5();
        break;
    }
    if (typeof save$ === 'undefined') {
      this.nextClicked = false;
      return;
    }
    save$.pipe(first()).subscribe(
      (res: IProjectInfo) => {
        this.setBriefInfo(res?.project);
        this.submitted = false;
        this.ngxService.stop();
        this.nextClicked = false;
        const saveDialog = this.dialog.open(ProjectSaveComponent, {
          autoFocus: false,
          width: '500px',
          data: {
            provider: this.company,
          },
        });

        saveDialog
          .afterClosed()
          .pipe(first())
          .subscribe((result) => {
            if (result?.goBriefs) {
              this.router.navigate(['/my-briefs']);
            } else {
              this.stepper.next();
              this.setStep(this.currentStep + 1);
              (document.activeElement as HTMLElement).blur();
            }
          });
      },
      () => {
        this.ngxService.stop();
        this.nextClicked = false;
      }
    );
  }

  public setBubble(range: HTMLInputElement, bubble: HTMLOutputElement) {
    const val = range.value && +range.value ? +range.value : 0;
    const min = range.min ? +range.min : 0;
    const max = range.max ? +range.max : 100;
    const newVal = Number(((val - min) * 100) / (max - min));
    const res: number =  val * 500;
    bubble.innerHTML = res.toLocaleString('en-EN') + ' USD';
    // Sorta magic numbers based on size of the native UI thumb
    bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
    if (val === 101) {
      bubble.innerHTML = 'Open';
    }
    localStorage.setItem('budget', String( val === 101 ? 50500 : val * 500));
  }

  public checkNeed(typeId: string) {
    const types: string[] = [...(this.brief.types || [])];
    const index = types.findIndex((type) => type === typeId);

    if (index !== -1) {
      types.splice(index, 1);
    } else {
      types.push(typeId);
    }
    this.brief.types = types;
  }

  public minSelectedCheckboxes(min = 1) {
    return (formArray: UntypedFormArray) => {
      return formArray.controls.length >= min ? null : { required: true };
    };
  }

  public otherThingsChecked() {
    this.otherThings = !this.otherThings;
    this.otherThingsRequired(this.otherThings);
  }

  public preparationChanged(id: string): void {
    const preparations = this.briefForm.value.checkArrayPrep.filter(i => !!i) || [];
    const prepIndex = preparations.findIndex((prepId) => prepId === id);
    if (prepIndex > -1) {
      preparations.splice(prepIndex, 1);
    }
    else {
      preparations.push(id);
    }

    this.briefForm.setControl(
      'checkArrayPrep',
      this.fb.array(preparations || [])
    );
  }

  public from1Step() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.briefForm.controls.name.invalid && this.brief.types.length === 0) {
      return;
    }
    if (!this.briefForm.controls.name.valid || this.brief.types.length < 1) {
      return;
    }
    if (this.nextClicked) { return; }
    this.nextClicked = true;

    this.brief.name = this.briefForm.controls.name.value;
    this.brief.provider = this.company ? this.company : null;
    const { provider, name, types, done } = this.brief;
    const brief = { provider, name, types, done };

    this.authDS
      .createBrief(brief)
      .pipe(first())
      .subscribe(
        (res: IProjectInfo) => {
          this.step++;
          this.submitted = false;
          this.nextClicked = false;
          this.setBriefInfo(res?.project);
          this.briefId = res?.project?._id;
          localStorage.setItem('briefId', res?.project?._id);
          this.stepper.next();
          this.setStep(this.typeSendBrief === BriefTypeSend.Provider ? 2 : this.currentStep + 1);
        },
        () => {
          this.nextClicked = false;
        }
      );
  }

  public from1StepEdit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.briefForm.controls.name.invalid && this.brief.types.length === 0) {
      return;
    }
    if (!this.briefForm.controls.name.valid || this.brief.types.length < 1) {
      return;
    }
    if (this.nextClicked) { return; }
    this.nextClicked = true;

    this.brief.name = this.briefForm.controls.name.value;
    const brief = {
      _id: this.route.snapshot.queryParamMap.get('briefId') || this.briefInfo._id,
      done: false,
      name: this.briefForm.controls.name.value,
      types: this.brief.types,
    };
    this.authDS
        .editBrief(brief)
        .pipe(first())
        .subscribe(
            (res: IProjectInfo) => {
              this.nextClicked = false;
              this.step++;
              this.submitted = false;
              this.setBriefInfo(res?.project);
              this.briefId = res?.project?._id;
              localStorage.setItem('briefId', res?.project?._id);
              this.stepper.next();
              this.setStep(this.typeSendBrief === BriefTypeSend.Provider ? 2 : this.currentStep + 1);
            },
            () => (this.nextClicked = false)
        );
  }

  public saveStep2(): Observable<any> {
    this.submitted = true;
    if (this.briefForm.get('details').valid) {
      const brief = {
        _id: this.briefId || this.briefFrom?._id,
        done: false,
        projectDetails: this.briefForm.get('details').value.map((item) => {
          return { model: item.model, value: item.value };
        }),
      };
      this.ngxService.start();
      this.submitted = false;
      return this.authDS.editBrief(brief);
    } else {
      return throwError(() => new Error('Form is invalid'));
    }
  }
  public from2Step() {
    if (this.nextClicked) { return; }
    this.nextClicked = true;

    this.saveStep2()
        .pipe(first())
        .subscribe(
            (res: IProjectInfo) => {
              this.nextClicked = false;
              this.setBriefInfo(res?.project);
              this.step++;
              this.stepper.next();
              this.setStep(this.currentStep + 1);
              this.ngxService.stop();
            },
            () => {
              this.nextClicked = false;
            }
        );
  }

  private saveStep1(): Observable<any> {
    this.submitted = true;
    const brief = {
      specialization: this.brief.specialization,
      skillAreas: this.brief.skillAreas,
      country: this.briefForm.get('checkArrayCountries').value,
      acceptCompensation: this.brief.compensations,
      _id: localStorage.getItem('briefId'),
      done: false,
    };

    brief.country = brief.country.filter((country) => country !== null);
    brief.specialization = brief.specialization?.length
      ? brief.specialization
      : ['Any'];
    brief.skillAreas = brief.skillAreas?.length
      ? brief.skillAreas
      : [this.skillAny];

    this.submitted = false;
    this.ngxService.start();
    return this.authDS.editBrief(brief);
  }

  public fromPref() {
    if (this.nextClicked) { return; }
    this.nextClicked = true;

    this.saveStep1()
      .pipe(first())
      .subscribe(
        (res: IProjectInfo) => {
          this.nextClicked = false;
          this.setBriefInfo(res?.project);
          if (res?.numberProviders > 0) {
            this.submitted = false;
            const dialogRef = this.dialog.open(GreatNewsComponent, {
              autoFocus: false,
              width: '500px',
              data: {
                numberProviders: res?.numberProviders,
                id: this.briefId,
                specialization: this.brief.specialization,
                country: this.getCountries.filter(i => this.briefForm.get('checkArrayCountries').value.includes(i._id)),
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result === 'list') {
                this.initProvidersView();
              } else if (result === 'next') {
                this.stepper.next();
                this.setStep(this.currentStep + 1);
              }
              window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
              });
            });
          } else {
            this.dialog.open(OhNoComponent, {
              autoFocus: false,
              width: '500px',
            });
          }
          this.ngxService.stop();
        },
        () => {
          this.nextClicked = false;
          this.ngxService.stop();
        }
      );
  }
  initProvidersView() {
      setTimeout(() => {
        this.showProviders = true;
        this.providersArray = [];
        this.fetchProviders();
      }, 0);
  }

  fetchProviders() {
      const filters: any = {
          filterOptions: {
              country: this.getCountries.filter(i => this.briefForm.get('checkArrayCountries').value.includes(i._id)).map(i => i.name),
              specializationAreas: this.brief.specialization,
          },
          sortingOptions: {sortType: 0},
      };

      this.authDS
          .doSearch(filters, this.skip, this.sum)
          .pipe(first())
          .subscribe((res: { data: IProvider[], totalCount: number }) => {
            const providers = res?.data ? res.data : [];
            this.providersArray.push(...providers);
          });
  }

  getProviderLocation(provider): string {
      return `${provider?.city?.name ? provider.city.name + ', ' : ''}${provider?.country.name}`;
  }
  onScrollDown(ev: any) {
      this.skip += 20;
      this.fetchProviders();
  }

  onProviderClick() {
      if (window.location.search.includes('briefId')) {
          localStorage.setItem('urlFrom', window.location.pathname + window.location.search);
      } else {
          localStorage.setItem('urlFrom', window.location.pathname + window.location.search + '?briefId=' + this.briefId + '&step=' + String(this.briefInfo.completionPercentage));
      }
  }

  nextStep() {
    this.showProviders = false;
    setTimeout(() => {
      this.stepper.selectedIndex = this.currentStep + 1;
      this.setStep(this.currentStep + 1);
      this.range.nativeElement.addEventListener('input', () => {
        this.setBubble(this.range.nativeElement, this.bubble.nativeElement);
      });
      this.setBubble(this.range.nativeElement, this.bubble.nativeElement);
    }, 0);
  }

  hideProviders() {
    this.showProviders = false;
    setTimeout(() => {
      this.stepper.selectedIndex = this.currentStep;
      this.range.nativeElement.addEventListener('input', () => {
        this.setBubble(this.range.nativeElement, this.bubble.nativeElement);
      });
      this.setBubble(this.range.nativeElement, this.bubble.nativeElement);
    }, 0);
  }

  public chooseSpeciliazation(item) {
    this.chooseSpeciliaze.push(item);
  }

  public chooseSkill(item) {
    this.skills.push(item);
  }

  private saveStep4(): Observable<any> {
    this.submitted = true;
    if (this.otherThings && this.briefForm.value.typeRequirements !== '') {
        const brief = {
          prep: this.briefForm.controls.checkArrayPrep.value,
          _id: localStorage.getItem('briefId'),
          done: false,
          otherThings: true,
          elseSomeAssist: this.briefForm.controls.things.value,
          typeRequirements: this.briefForm.value.typeRequirements,
        };

        this.submitted = false;
        this.ngxService.start();
        return this.authDS.editBrief(brief);
      } else {
        const brief = {
          prep: this.briefForm.controls.checkArrayPrep.value,
          _id: localStorage.getItem('briefId'),
          typeRequirements: this.briefForm.value.typeRequirements,
          done: false,
        };

        if (brief.prep.length > 0 && brief.typeRequirements !== '') {
          this.submitted = false;
          this.ngxService.start();
          return this.authDS.editBrief(brief);
        }
      }
  }

  public fromStep4() {
    this.submitted = true;
    if (this.otherThings
      ? this.briefForm.get('things').invalid
      : !(this.briefForm.get('checkArrayPrep').value.length > 0)) { return; }
    if (this.briefForm.value.typeRequirements === '') { return; }
    if (this.nextClicked) { return; }
    this.nextClicked = true;

    this.saveStep4()
      .pipe(first())
      .subscribe(
        (res: IProjectInfo) => {
          this.nextClicked = false;
          this.setBriefInfo(res?.project);
          this.ngxService.stop();
          this.stepper.next();
          this.setStep(this.currentStep + 1);
          this.typeRequirementsNeeded = this.briefForm.value.typeRequirements;
        },
        () => {
          this.nextClicked = false;
          this.ngxService.stop();
        }
      );
  }

  private saveStep5(): Observable<any> {
    this.submitted = true;
    const brief = {
      proposalValidUntil: this.briefForm.controls.calendar.value,
      budget: Number(localStorage.getItem('budget')),
      specialMessage: this.briefForm.controls.message.value,
      _id: localStorage.getItem('briefId'),
      done: false,
    };
    if (
      this.briefForm.controls.calendar.valid
    ) {
      this.ngxService.start();
      this.submitted = false;
      return this.authDS.editBrief(brief);
    }
    return throwError(() => new Error('Please choose at least one option'));
  }

  public fromBudget() {
    if (this.nextClicked) { return; }
    this.nextClicked = true;

    this.saveStep5()
      .pipe(first(), delay(1000))
      .subscribe(
        (res: IProjectInfo) => {
          this.setBriefInfo(res?.project);
          this.ngxService.stop();
          this.stepper.next();
          this.nextClicked = false;
          this.setStep(this.currentStep + 1);
        },
        () => {
          this.nextClicked = false;
          this.ngxService.stop();
        }
      );
  }

  private setBriefInfo(project: IProject): void {
    this.briefInfo = this.briefInfo ?
      {...this.briefInfo, ...project} :
      project;
    this.budget = project?.budget;

    if (this.briefFormTemp) {
      this.briefFormTemp = JSON.stringify(this.briefForm.value);
    }
    this.briefTemp = JSON.parse(JSON.stringify(this.brief));
  }

  private proceedAnySpecialization() {
    if (!this.brief.specialization.length) {
      this.patchForm({
        form: this.formSpecialize,
        controller: 'spec',
        value: ['Any'],
        validators: [],
        markAsTouched: true,
      });

      return;
    }

    const index = this.brief.specialization.findIndex((spec) => spec === 'Any');
    if (index !== -1 && this.brief.specialization.length) {
      const { specialization } = this.brief;
      specialization.splice(index, 1);
      this.brief.specialization = specialization;
    }

    this.patchForm({
      form: this.formSpecialize,
      controller: 'spec',
      value: this.brief.specialization,
      validators: [],
      markAsTouched: true,
    });
  }

  getSpecializationValues(event: {
    isUserInput: any;
    source: { value: any; selected: any };
  }) {
    if (event.isUserInput) {
      if (event.source.selected === true) {
        if (event.source.value === 'Any') {
          this.brief.specialization = ['Any'];
        } else {
          this.brief.specialization.push(event.source.value);
        }
      } else {
        for (let i = 0; i < this.brief.specialization.length; i++) {
          if (event.source.value === this.brief.specialization[i]) {
            this.brief.specialization?.splice(i, 1);
          }
        }
      }

      this.proceedAnySpecialization();
    }
  }

  private proceedAnySkills() {
    if (!this.brief.skillAreas.length) {
      this.patchForm({
        form: this.skillAreas,
        controller: 'skills',
        value: [this.skillAny],
        validators: [],
        markAsTouched: true,
      });

      return;
    }

    const index = this.brief.skillAreas.findIndex(
      (skill) => skill === this.skillAny
    );
    if (index !== -1 && this.brief.skillAreas.length) {
      const { skillAreas } = this.brief;
      skillAreas.splice(index, 1);
      this.brief.skillAreas = skillAreas;
    }

    this.patchForm({
      form: this.skillAreas,
      controller: 'skills',
      value: this.brief.skillAreas,
      validators: [],
      markAsTouched: true,
    });
  }

  getValuesSkills(event: {
    isUserInput: any;
    source: { value: any; selected: any };
  }) {

    if (event.isUserInput) {
      if (event.source.selected === true) {
        if (event.source.value === this.skillAny) {
          this.brief.skillAreas = [this.skillAny];
        } else {
          this.brief.skillAreas.push(event.source.value);
        }
      } else {
        for (let i = 0; i < this.brief.skillAreas.length; i++) {
          if (event.source.value === this.brief.skillAreas[i]) {
            this.brief.skillAreas?.splice(i, 1);
            this.skills.splice(i, 1);
          }
        }
      }
      this.proceedAnySkills();
    }
  }

  public deselectAllSpec(): void {
    this.brief.specialization = [];
    this.patchForm({
      form: this.formSpecialize,
      controller: 'spec',
      value: ['Any'],
      validators: [Validators.required],
      markAsTouched: true,
    });
  }

  public deleteAllSkills(): void {
    this.skills = [];
    this.brief.skillAreas = [];
    this.patchForm({
      form: this.skillAreas,
      controller: 'skills',
      value: [this.skillAny],
      validators: [Validators.required],
      markAsTouched: true,
    });
  }

  public clearFiltersCountries(): void {
    const checkboxes = this.elRef.nativeElement.querySelectorAll('.countries');
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
    const checkbox = this.elRef.nativeElement.querySelector('.countriesAny');
    checkbox.checked = true;
    this.briefForm.setControl('checkArrayCountries', this.fb.array([null]));
  }

  public clearFiltersCountriesAny(): void {
    const checkbox = this.elRef.nativeElement.querySelector('.countriesAny');
    checkbox.checked = false;
    this.briefForm.get('checkArrayCountries').value.slice(null, 1);
  }

  public compensationChanged(id: string): void {
    const compensations = this.brief.compensations || [];
    if (compensations.includes(id)) {
      this.brief.compensations =
        compensations?.filter((compensationId) => compensationId !== id) || [];
    } else {
      this.brief.compensations.push(id);
    }
  }

  public onCheckboxChange(e?, form?, word?, array?): void {
    const checkArray: UntypedFormArray = form.get(array) as UntypedFormArray;
    if (e.target.checked) {
      checkArray.push(
        new UntypedFormControl(e.target.value === '' ? null : e.target.value)
      );
    } else {
      checkArray.controls.forEach((item: UntypedFormControl, index) => {
        if (item.value === e.target.value) {
          checkArray.removeAt(index);
          checkArray.value.filter(i => i !== e.target.value);
          return;
        }
      });
    }

    if (checkArray.length === 1 && !checkArray[0]) {
      this.elRef.nativeElement.querySelector('.countriesAny').checked = true;
    }
  }

  private otherThingsRequired(flag: boolean) {
    if (flag) {
      this.briefForm
        .get('things')
        ?.setValidators([
          Validators.required,
          Validators.minLength(50),
          Validators.maxLength(5000),
        ]);
    } else {
      this.briefForm.get('things')?.clearValidators();
    }
    this.briefForm.get('things')?.updateValueAndValidity();
  }

  public removeSpecialization(i: number) {
    this.brief.specialization.splice(i, 1);
    const specs = this.brief.specialization;
    this.patchForm({
      form: this.formSpecialize,
      controller: 'spec',
      value: specs.length ? specs : ['Any'],
      validators: [Validators.required],
      markAsTouched: true,
    });
  }

  public removeFilterSkills(i: number) {
    this.brief.skillAreas.splice(i, 1);
    const skills = this.brief.skillAreas;
    this.patchForm({
      form: this.skillAreas,
      controller: 'skills',
      value: skills.length ? skills : [this.skillAny],
      validators: [Validators.required],
      markAsTouched: true,
    });
  }

  public removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  public clearArraySkills() {
    this.skills = [];
  }

  public restoreArray(isSkills) {
    if (isSkills) {
      this.filteredSkills = this.getSkills;
      (this.specSkills as MatSelectFilterComponent).noResults = false;
      (this.specSkills?.input.nativeElement as HTMLInputElement).value = '';
    } else {
      this.filteredSpec = this.getSpecializations;
      (this.specFilter as MatSelectFilterComponent).noResults = false;
      (this.specFilter?.input.nativeElement as HTMLInputElement).value = '';
    }
  }

  private patchForm({
    form,
    controller,
    value,
    validators,
    markAsTouched,
  }: {
    form: UntypedFormGroup;
    controller: string;
    value: any;
    validators?: ValidatorFn[];
    markAsTouched?: boolean;
  }) {
    form.get(controller)?.patchValue(value);
    if (validators && validators.length) {
      form.get(controller)?.setValidators(validators);
    }
    form.get(controller)?.updateValueAndValidity();
    form.updateValueAndValidity();
    if (markAsTouched) {
      form.get(controller)?.markAsTouched();
    }
  }

  public openDocumentPreview(fromPreview?: boolean) {
    this.dialog.open(DocumentPreviewComponent, {
      autoFocus: false,
      width: '75%',
      height: '95vh',
      data: {
        fromPreview,
        requirementDoc: this.briefForm.value.typeRequirements,
      },
    });
  }

  public getAllCheckedFeatures() {
    const res: string[] = [];
    for (const value of Object.values(this.briefInfo.featuresForDoc)) {
      if (!value.feats) continue;
      if (typeof value.feats === 'string' && value.feats) {
        res.push(value.feats);
      } else {
        res.push(...value.feats);
      }
    }
    return res.join(', ');
  }

  private sendFeaturesTypes(features: ChosenFeatures) {
    const handleResponse = (project: IProject) => {
      this.briefInfo = project;
      const chosenFeatures =
          Object
              .entries(project.featuresForDoc)
              .filter(el => el[1].isChosen);
      this.maxFeatureSubstep = chosenFeatures.length;
      this.answeredFeatureSubstep = this.answeredFeatureSubstep.slice(0, this.maxFeatureSubstep);
      this.dynamicSteps.clear();
      this.featureSubstepsLabels = [];
      chosenFeatures.forEach(el => {
        this.dynamicSteps.push(this.fb.control(el));
        this.featureSubstepsLabels.push(this.allFeatures.find(feature => feature.key === el[0]).nameSection);
      });
      this.showFeatureSubsteps = true;
    };
    this.ngxService.start();
    return this.authDS.saveFeats({_id: this.briefInfo._id, ...this.clearFeatures(features) })
        .pipe(
            takeUntilDestroyed(this.destroyRef),
            tap(handleResponse),
            finalize(() => this.ngxService.stop())
        );
  }

  private clearFeatures(features: ChosenFeatures) {
    return Object.entries({
      ...this.briefInfo.featuresForDoc,
      ...features
    }).reduce((cleared, [key, feature]) => {
      cleared[key] = feature.isChosen
        ? { ...feature }
        : { ...feature, feats: [] };
      return cleared;
    }, {} as ChosenFeatures);
  }

  private sendFeatures(features: ChosenFeatures) {
    this.ngxService.start();
    return this.authDS.saveFeats({_id: this.briefInfo._id, ...this.clearFeatures(features) })
        .pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((project) => this.briefInfo = project),
            finalize(() => this.ngxService.stop())
        );
  }

  public nextStepFromFeaturesPartOne(features: ChosenFeatures) {
    this.sendFeaturesTypes(features).subscribe(() => {
        this.step++;
        this.stepper.next();
        this.currentFeatureSubstep = 1;
    });
  }

  public saveFeaturesPartOne(features: ChosenFeatures) {
    this.sendFeaturesTypes(features).subscribe(() => {
      const saveDialog = this.dialog.open(ProjectSaveComponent, {
        autoFocus: false,
        width: '500px',
        data: {
          provider: this.company,
        },
      });

      saveDialog
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((result) => {
          if (result?.goBriefs) {
            this.router.navigate(['/my-briefs']);
          } else {
            this.step++;
            this.stepper.next();
            this.currentFeatureSubstep = 1;
            (document.activeElement as HTMLElement).blur();
          }
        });
    });
  }

  public nextStepFromFeaturesPartTwo(features: ChosenFeatures) {
    this.sendFeatures(features).subscribe(() => {
      this.step++;
      if (!this.answeredFeatureSubstep.includes(this.currentFeatureSubstep)) {
        this.answeredFeatureSubstep.push(this.currentFeatureSubstep);
      }
      this.currentFeatureSubstep += 1;
      if (this.currentFeatureSubstep > this.maxFeatureSubstep) {
        this.showFeatureSubsteps = false;
        this.setStep(this.currentStep + 1);
        this.stepper.selectedIndex = this.typeSendBrief === BriefTypeSend.Provider
          ? this.currentStep - 2
          : this.currentStep - 1;
      }
      this.stepper.next();
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });
  }

  public previousFromFeaturesPartTwo() {
    this.stepper.previous();
    this.currentFeatureSubstep--;
  }

  public saveFeaturesPartTwo(features: ChosenFeatures) {
    this.sendFeatures(features).subscribe(() => {
      const saveDialog = this.dialog.open(ProjectSaveComponent, {
        autoFocus: false,
        width: '500px',
        data: {
          provider: this.company,
        },
      });

      saveDialog
          .afterClosed()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((result) => {
            if (result?.goBriefs) {
              this.router.navigate(['/my-briefs']);
            } else {
              this.step++;
              if (!this.answeredFeatureSubstep.includes(this.currentFeatureSubstep)) {
                this.answeredFeatureSubstep.push(this.currentFeatureSubstep);
              }
              this.currentFeatureSubstep += 1;
              if (this.currentFeatureSubstep > this.maxFeatureSubstep) {
                this.showFeatureSubsteps = false;
                this.setStep(this.currentStep + 1);
                this.stepper.selectedIndex = this.typeSendBrief === BriefTypeSend.Provider
                  ? this.currentStep - 2
                  : this.currentStep - 1;
              }
              this.stepper.next();
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              (document.activeElement as HTMLElement).blur();
            }
          });
    });
  }

  public featureSectionByKey(key: string) {
    return this.allFeatures.find(el => el.key === key);
  }

  public moveSubstep(step: number) {
    this.stepper.selectedIndex = this.typeSendBrief === BriefTypeSend.Provider ? 3 + step : 3 + step + 1;
    this.currentFeatureSubstep = step + 1;
  }
}
