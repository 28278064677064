import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'viewCounter',
})
export class ViewsCounterPipe implements PipeTransform {
    transform(value: number): unknown {
        if (value >= 1000000) {
            return (value / 1000000).toFixed(1) + 'KK';
        } else if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'K';
        } else {
            return value.toString();
        }
    }
}
