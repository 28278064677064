<div class='block-container'>
  <div class='back' (click)='back()'>
    <img src='./../../../assets/arrow.svg' alt='' width='12' height='12' />
    <p>Verification</p>
  </div>
  <div class='title'>Update Phone Number to Verify You</div>
  <hr />
  <form [formGroup]='formGroup'>
    <label class='select-field'>
      {{ user?.user?.role === 0 ? 'Country I live in *' : 'Country *' }}
      <input type='text'
             placeholder='Country'
             formControlName='country'
             (change)='chooseCountryValueChangeNumber($event)'
             [class.error]='formControls.country.invalid && formControls.country.touched'
             [matAutocomplete]='countryAutocomplete'>
      <mat-autocomplete
        #countryAutocomplete='matAutocomplete'
        (optionSelected)='chooseCountryValueChangeNumber($event)'
        [displayWith]='displayName'
      >
        <mat-option *ngFor='let country of (filteredCountries$ | async)' [value]='country'>
          {{ country.name }}
        </mat-option>
      </mat-autocomplete>
      <img class='mat-select-arrow select-arrow' src='../../../../../assets/icons/select.svg' alt=''>
      <div
        class='error'
        *ngIf='formControls.country.invalid && formControls.country.touched'
      >
        <div *ngIf="formControls.country.errors?.['required']">
          {{ user?.user?.role === 0 ? 'Country I live in is required.' : ' Country is required.' }}
        </div>
      </div>
    </label>
    <label>
      New Mobile Number *
      <p *ngIf='formControls.selectCountryChangePhone.value' class='code-number'>
        {{ formControls.selectCountryChangePhone.value }}
      </p>
      <input
        type='text'
        (keydown)='numericOnly($event)'
        placeholder='Enter new mobile number'
        formControlName='phoneNumber'
        [class.error]='formControls.phoneNumber.invalid && formControls.phoneNumber.touched'
        [class.right]='formControls.selectCountryChangePhone.value && formControls.selectCountryChangePhone.value.length < 5'
        [class.right-longer]='formControls.selectCountryChangePhone.value && formControls.selectCountryChangePhone.value.length >= 5'
      >
      <div
        class='error'
        *ngIf='formControls.phoneNumber.invalid && formControls.phoneNumber.touched'
      >
        <div *ngIf="formControls.phoneNumber.errors?.['required']">
          New mobile number is required.
        </div>
      </div>
    </label>
    <p (click)='submit()' class='paragraph verification-text'>
      Re-send Verification Code
    </p>
  </form>
  <hr />
</div>
