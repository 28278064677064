<section class="providers">
  <mat-tab-group>
    <mat-tab label="First">
      <div class="providers-wrap">
        <div class="providers-wrap-item">
          <img src="./../../../../assets/icons/iconCompany.png" alt="" />
          <div class="name">
            <p class="paragraph2"><b>Hebron Soft</b></p>
            <p class="paragraph2">Lviv, Ukraine</p>
          </div>
          <div class="rating">
            <p class="paragraph2">CLUTCH SCORE:</p>
            <div class="rating-content">
              <ngx-star-rating
                formControlName="rating2"
                [id]="'rating2'"
                [disabled]="true"
              ></ngx-star-rating>
              <p class="paragraph2">4</p>
            </div>
          </div>
        </div>
        <div class="providers-wrap-item">
          <img src="./../../../../assets/icons/iconCompany.png" alt="" />
          <div class="name">
            <p class="paragraph2"><b>Hebron Soft</b></p>
            <p class="paragraph2">Lviv, Ukraine</p>
          </div>
          <div class="rating">
            <p class="paragraph2">CLUTCH SCORE:</p>
            <div class="rating-content">
              <ngx-star-rating
                formControlName="rating2"
                [id]="'rating2'"
                [disabled]="true"
              ></ngx-star-rating>
              <p class="paragraph2">4</p>
            </div>
          </div>
        </div>
        <div class="providers-wrap-item">
          <img src="./../../../../assets/icons/iconCompany.png" alt="" />
          <div class="name">
            <p class="paragraph2"><b>Hebron Soft</b></p>
            <p class="paragraph2">Lviv, Ukraine</p>
          </div>
          <div class="rating">
            <p class="paragraph2">CLUTCH SCORE:</p>
            <div class="rating-content">
              <ngx-star-rating
                formControlName="rating2"
                [id]="'rating2'"
                [disabled]="true"
              ></ngx-star-rating>
              <p class="paragraph2">4</p>
            </div>
          </div>
        </div>
        <div class="providers-wrap-item">
          <img src="./../../../../assets/icons/iconCompany.png" alt="" />
          <div class="name">
            <p class="paragraph2"><b>Hebron Soft</b></p>
            <p class="paragraph2">Lviv, Ukraine</p>
          </div>
          <div class="rating">
            <p class="paragraph2">CLUTCH SCORE:</p>
            <div class="rating-content">
              <ngx-star-rating
                formControlName="rating2"
                [id]="'rating2'"
                [disabled]="true"
              ></ngx-star-rating>
              <p class="paragraph2">4</p>
            </div>
          </div>
        </div>
        <div class="providers-wrap-item">
          <img src="./../../../../assets/icons/iconCompany.png" alt="" />
          <div class="name">
            <p class="paragraph2"><b>Hebron Soft</b></p>
            <p class="paragraph2">Lviv, Ukraine</p>
          </div>
          <div class="rating">
            <p class="paragraph2">CLUTCH SCORE:</p>
            <div class="rating-content">
              <ngx-star-rating
                formControlName="rating2"
                [id]="'rating2'"
                [disabled]="true"
              ></ngx-star-rating>
              <p class="paragraph2">4</p>
            </div>
          </div>
        </div>
        <div class="providers-wrap-item">
          <img src="./../../../../assets/icons/iconCompany.png" alt="" />
          <div class="name">
            <p class="paragraph2"><b>Hebron Soft</b></p>
            <p class="paragraph2">Lviv, Ukraine</p>
          </div>
          <div class="rating">
            <p class="paragraph2">CLUTCH SCORE:</p>
            <div class="rating-content">
              <ngx-star-rating
                formControlName="rating2"
                [id]="'rating2'"
                [disabled]="true"
              ></ngx-star-rating>
              <p class="paragraph2">4</p>
            </div>
          </div>
        </div>
        <div class="providers-wrap-item">
          <img src="./../../../../assets/icons/iconCompany.png" alt="" />
          <div class="name">
            <p class="paragraph2"><b>Hebron Soft</b></p>
            <p class="paragraph2">Lviv, Ukraine</p>
          </div>
          <div class="rating">
            <p class="paragraph2">CLUTCH SCORE:</p>
            <div class="rating-content">
              <ngx-star-rating
                formControlName="rating2"
                [id]="'rating2'"
                [disabled]="true"
              ></ngx-star-rating>
              <p class="paragraph2">4</p>
            </div>
          </div>
        </div>
        <div class="providers-wrap-item">
          <img src="./../../../../assets/icons/iconCompany.png" alt="" />
          <div class="name">
            <p class="paragraph2"><b>Hebron Soft</b></p>
            <p class="paragraph2">Lviv, Ukraine</p>
          </div>
          <div class="rating">
            <p class="paragraph2">CLUTCH SCORE:</p>
            <div class="rating-content">
              <ngx-star-rating
                formControlName="rating2"
                [id]="'rating2'"
                [disabled]="true"
              ></ngx-star-rating>
              <p class="paragraph2">4</p>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Second"> Content 2</mat-tab>
    <mat-tab label="Third"> Content 3</mat-tab>
  </mat-tab-group>
</section>
