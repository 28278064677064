import {Component, EventEmitter, Inject, Output} from '@angular/core';
import { ILogin } from '../../../interfaces/auth-interfaces';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first, skipWhile } from 'rxjs/operators';
import { CheckEmailComponent } from '../check-email/check-email.component';
import { AuthDataService } from '../../../services/auth-data.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  public loginForm: UntypedFormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email, Validators.pattern('^[aA-zZ0-9._%+-]+@[aA-zZ0-9.-]+\\.[aA-zZ]{2,4}$')]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });
  submitted = false;

  constructor(
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ILogin,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    private authDS: AuthDataService
  ) {}

  @Output() sendLoginEmitter: EventEmitter<ILogin> = new EventEmitter<ILogin>();

  onNoClick(): void {
    this.dialogRef.close();
  }

  public openLoginPopup(): void {
    this.submitted = true;
    if (this.loginForm.get('email').invalid) {
      // this.snackbar.open('Enter a valid email address to reset the password.', '', {
      //   duration: 2500,
      //   horizontalPosition: 'center',
      // });
      return;
    }
    this.authDS
      .resetPassword(this.loginForm.value.email.toLowerCase())
      .pipe(first())
      .subscribe(
        (res) => {
          this.dialogRef.close();
          const dialogRef = this.dialog.open(CheckEmailComponent, {
            autoFocus: false,
            height: '600px',
            width: '500px',
          });

          dialogRef
            .afterClosed()
            .pipe(
              first(),
              skipWhile((result) => !result)
            )
            .subscribe((result) => this.sendLoginEmitter.emit(result));
        },
        (error) => {
          if (error.error.status === 400) {
            this.snackbar.open(' This account does not exist for us to reset password. Sign up to join.', '', {
              duration: 2500,
              horizontalPosition: 'center',
            });
          } else {
            this.snackbar.open(error.error.message, '', {
              duration: 2500,
              horizontalPosition: 'center',
            });
          }
        }
      );
  }
}
