<app-header [displaySearchField]='false'></app-header>
<section class='account-settings-page'>
  <div class='container'>
    <div class='back' [routerLink]="['']">
      <img src='./../../../assets/arrow.svg' alt='' width='14' height='14' />
      <p>Back to Home page</p>
    </div>
    <div class='main-content'>
      <div class='content-left-side-wrapper'>
        <div class='content-left-side'>
          <div>
            <div class='profile-photo-box'>
              <div class='profile-photo-box-content profile-photo-box-content-radius'>
                <img *ngIf='url' src='{{ url }}' alt='picture' />
                <img
                  *ngIf='user?.userRole?.logoUrl && !url'
                  src='{{ user?.userRole?.logoUrl }}'
                  alt='picture'
                />
                <input
                  class='select-file'
                  type='file'
                  accept='image/jpeg, image/png, image/svg'
                  (change)='selectFile($event)'
                />
                <input [formControl]='logoUrl' type='hidden' />
                <p *ngIf='!user?.userRole?.logoUrl && !url && user?.userRole?.firstName && user?.userRole?.lastName'>
                  {{ user?.userRole?.firstName && user?.userRole?.firstName[0] }} {{ user?.userRole?.lastName && user?.userRole?.lastName[0] }}
                </p>
              </div>
              <p class='download-link'>Update Display Image</p>
            </div>
            <div class='step-box' *ngFor='let tab of tabs'>
              <div
                class='step-box-content'
                [class.active]='$activeTab() === tab'
                (click)='onTabChange(tab)'
              >
                <p class='paragraph2'>{{ tab }}</p>
              </div>
            </div>
            <div class='step-box' *ngIf="$activeTab() === 'Verification'">
              <div class='step-box-content active'>
                <p class='paragraph2'>Verification</p>
              </div>
            </div>
          </div>
          <div class='step-box-content delete-profile-btn' (click)='deleteAccount()'>
            Delete Account
          </div>
        </div>
      </div>
      <div class='content-right-side'>
        <mat-horizontal-stepper (selectionChange)='accountSettingsService.stepChange($event)' #stepper>
          <mat-step>
            <ng-template matStepContent>
              <individual-app-profile *ngIf='user?.user?.role === 0'></individual-app-profile>
              <app-startup-enterprise-profile *ngIf='user?.user?.role !== 0'></app-startup-enterprise-profile>
            </ng-template>
          </mat-step>
          <mat-step>
            <ng-template matStepContent>
              <individual-app-notification-preferences></individual-app-notification-preferences>
            </ng-template>
          </mat-step>
          <mat-step>
            <ng-template matStepContent>
              <app-code-verify></app-code-verify>
            </ng-template>
          </mat-step>
          <mat-step>
            <ng-template matStepContent>
              <app-change-number></app-change-number>
            </ng-template>
          </mat-step>
          <mat-step>
            <ng-template matStepContent>
              <app-delete-account></app-delete-account>
            </ng-template>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</section>
