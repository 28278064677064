<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="dismiss()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Maybe Next Time!</p>

  <p class="paragraph2">You’ve confirmed that your company is not interested in exploring this client’s project.</p>
  <p class="paragraph2">One of our goals is to share projects that help create new business, so be sure to click “I’m Interested” when the right project comes your way!</p>

  <div class="btn-ct">
    <button class="primary-btn-transparent" (click)="dismiss()">
      OK!
    </button>

    <button class="primary-btn" (click)="changeOpinion()">
      Actually, I’m Interested
    </button>
  </div>
</div>
