<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="paragraph2"><b>Select a type of account</b></p>

  <button
    class="primary-btn-transparent"
    type="submit"
    (click)="choiceRole('individual', role.individual)"
    [ngClass]="{ activeCategory: chosenRole.role === 'individual' }"
  >
    Individual
  </button>
  <button
    class="primary-btn-transparent"
    type="submit"
    (click)="choiceRole('startUp', role.startUp)"
    [ngClass]="{ activeCategory: chosenRole.role === 'startUp' }"
  >
    Startup / Small Business
  </button>
  <button
    class="primary-btn-transparent"
    type="submit"
    (click)="choiceRole('enterprise', role.enterprise)"
    [ngClass]="{ activeCategory: chosenRole.role === 'enterprise' }"
  >
    Enterprise
  </button>
  <button
    [ngClass]="{
      primaryBtn:
        chosenRole.role === 'enterprise' ||
        chosenRole.role === 'startUp' ||
        chosenRole.role === 'individual'
    }"
    [routerLink]="['sign-up']"
    [queryParams]="chosenRole"
    (click)="onNoClick()"
    class="sign-up"
    type="submit"
  >
    Next
  </button>
</div>
