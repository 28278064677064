import { Component, Inject } from "@angular/core";
import {first} from 'rxjs/operators';
import {IProvider} from '../../../interfaces/provider';
import {AuthDataService} from '../../../services/auth-data.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: "app-great-news",
  templateUrl: "./great-news.component.html",
  styleUrls: ["./great-news.component.scss"],
})
export class GreatNewsComponent {
  public isFirstStep = true;
  public isListView = false;
  listArray: any[] = [];
  sum = 20;
  skip = 0;
  direction = "";

  constructor(
    public dialogRef: MatDialogRef<GreatNewsComponent>,
    private authDS: AuthDataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.appendItems();
  }
  onScrollDown(ev: any) {
    if (this.data.countProviders <= this.sum) return;
    this.skip += 20;
    this.appendItems();

    this.direction = "scroll down";
  }

  onScrollUp(ev: any) {
    if (this.sum <= 20) return;
    this.skip -= 20;
    this.prependItems();

    this.direction = "scroll up";
  }

  appendItems() {
    this.addItems("push");
  }

  prependItems() {
    this.addItems("unshift");
  }

  addItems(method: string) {
    if (method === 'push') {
      this.doSearch();
    }
  }

  public doSearch() {
    const filters: any = {
      filterOptions: {
        country: this.data.country.map(i => i.name),
        specializationAreas: this.data.specialization,
      },
      sortingOptions: { sortType: 0 },
    };

    this.authDS
      .doSearch(filters, this.skip, this.sum)
      .pipe(first())
      .subscribe((res: {data: IProvider[], totalCount: number}) => {
        const providers: IProvider[] = res?.data ? res.data : [];
        this.listArray.push(...providers);
      });
  }


  onNoClick(redirect): void {
    this.dialogRef.close();
    if (redirect) {
      if (window.location.search.includes('briefId')) {
        localStorage.setItem('urlFrom', window.location.pathname + window.location.search);
      } else {
        localStorage.setItem('urlFrom', window.location.pathname + window.location.search + '&briefId=' + this.data.id );
      }
    }
  }

  showList(value: boolean): void {
    this.isListView = value;
    this.isFirstStep = !value;
  }

  getProviderLocation(provider): string {
    if (provider?.headquarterLocationModel) {
      return `${provider?.city.name}, ${provider?.country.name}`;
    } else {
      return provider?.headquarterLocation;
    }
  }
}
