import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthDataService } from '../../../services/auth-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RequirementsTypes } from '../../../interfaces/project';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent implements OnInit{
  uint: Uint8Array;
  constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      private authDS: AuthDataService,
      private loaderService: NgxUiLoaderService,
  ) {}

  ngOnInit() {
    this.loaderService.start();
    this.authDS.getExampleDoc().subscribe({
      next: async (res) => {
        const buffer = await res.arrayBuffer();
        this.uint = new Uint8Array(buffer);
        this.loaderService.stop();
      },
      error: () => this.loaderService.stop()
    });
  }

  isShowButtons() {
    const { fromPreview, requirementDoc } = this.data;
    if (fromPreview && requirementDoc !== RequirementsTypes.non) {
      return false;
    } else {
      return true;
    }
  }
}
