import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-doc-save',
  templateUrl: './doc-save.component.html',
  styleUrls: ['./doc-save.component.scss']
})
export class DocSaveComponent {
  constructor(
    public dialogRef: MatDialogRef<DocSaveComponent>,
  ) {}

  public dismiss(): void {
    this.dialogRef.close();
  }

  public goBriefs() {
    this.dialogRef.close({ goBriefs: true });
  }
}
