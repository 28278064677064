import { Component, Inject } from '@angular/core';
import { AuthDataService } from '../../../services/auth-data.service';
import { first } from 'rxjs/operators';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ProviderPortalService } from '../../provider-portal/services/provider-portal.service';

interface dataModel {
  projectId: string;
  providerId: string;
}

@Component({
  selector: 'app-is-not-interested',
  templateUrl: './is-not-interested.component.html',
  styleUrls: ['./is-not-interested.component.scss'],
})
export class IsNotInterestedComponent {
  constructor(
    private authDS: AuthDataService,
    private providerPortalService: ProviderPortalService,
    private router: Router,
    private dialogRef: MatDialogRef<IsNotInterestedComponent>,
    @Inject(MAT_DIALOG_DATA) private data: dataModel
  ) {}

  public changeOpinion(): void {
    this.authDS
      .sendBriefToProvider(this.data.projectId, this.data.providerId)
      .pipe(first())
      .subscribe(() => {
        this.dialogRef.close({ openInterestedPopup: true });
      });
  }

  public dismiss(): void {
    this.providerPortalService
      .setProjectAsNotInteresting(this.data.providerId, this.data.projectId)
      .pipe(first())
      .subscribe((result) => {
        this.dialogRef.close();
        this.router.navigate(['/']).then();
      });
  }
}
