import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Article, ShareLinkToIcon } from '../../../../models/knowledge-hub/article';
import { ArticleService } from '../../../../services/article.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-share-article',
  templateUrl: './share-article.component.html',
  styleUrls: ['./share-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareArticleComponent implements OnInit {
  @Input() previewMode = false;
  @Input({ required: true }) article: Article;

  private articleService = inject(ArticleService);
  private snackbar = inject(MatSnackBar);

  private clipboard = inject(Clipboard);

  protected linkToIcons: ShareLinkToIcon[] = [
    { shareLink: 'https://www.facebook.com/share.php?u=', icon: 'assets/onlinePresenceIcons/Facebook.svg' },
    { shareLink: 'https://twitter.com/share?&url=', icon: 'assets/onlinePresenceIcons/X.svg' },
    { shareLink: 'https://www.linkedin.com/sharing/share-offsite/?url=', icon: 'assets/onlinePresenceIcons/LinkedIn.svg' },
    { shareLink: null, icon: 'assets/onlinePresenceIcons/copy.svg' },
  ];

  ngOnInit() {
    if (this.previewMode) {
      this.linkToIcons = this.linkToIcons.slice(0, this.linkToIcons.length - 1);
    }
  }

  onShareLink(shareLink: string | null) {
    const link = encodeURI(window.location.href);
    this.articleService.addSharedToArticle(this.article._id).subscribe();
    if (!shareLink) {
      this.snackbar.open('Copied to clipboard!', '', {
        duration: 2500,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
      return this.clipboard.copy(link);
    }
    (window as any).open(shareLink + link, '_blank');
  }
}
