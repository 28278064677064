<section class="home-page">
    <video autoplay muted loop id="myVideo">
        <source src="assets/home/hero-video.mp4" type="video/mp4" />
        Your browser does not support HTML5 video.
    </video>
    <div class="opacity-box"></div>
    <div class="container">
        <header>
            <div class="logo">
                <a href="/">
                    <img
                        src="../../../assets/logo/unicorn-logo.svg"
                        alt="logo"
                    />
                </a>
            </div>
            <span [routerLink]="['/public-articles']" class="paragraph2 knowledge-hub-btn">Knowledge Hub</span>
            <nav *ngIf="!(authDS.user$ | async)">
                <ul class="menu">
                    <li class="paragraph2" (click)="scrollToElement(target)">
                        How it Works
                    </li>
                    <li class="paragraph2" (click)="openLoginPopup()">
                        <svg
                            class="user-icon"
                            viewBox="0 0 14 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 5.66681C12 8.19116 9.81602 10.3336 7 10.3336C4.18398 10.3336 2 8.19116 2 5.66681C2 3.14246 4.18398 1 7 1C9.81602 1 12 3.14246 12 5.66681Z"
                                stroke="white"
                                stroke-width="2"
                            />
                            <path
                                d="M1.70508 13.9994C1.70508 13.9994 7.35489 11.3326 12.2933 13.9994"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                        Log In
                    </li>
                    <li>
                        <button
                            class="primary-btn-transparent"
                            (click)="openSignUpPopup()"
                        >
                            Sign Up
                        </button>
                    </li>
                </ul>
            </nav>

            <div class="profile" *ngIf="authDS.user$ | async">
                <div
                    *ngIf="
                        (authDS.activeAccount$ | async) ===
                        UserTypesAccount.Client
                    "
                >
                    <p *ngIf="user?.userRole?.companyName" class="paragraph2">
                        Welcome, {{ user?.userRole?.companyName }}
                    </p>
                    <p *ngIf="user?.userRole?.firstName" class="paragraph2">
                        Welcome, {{ user?.userRole?.firstName }}
                    </p>
                </div>
                <div
                    *ngIf="
                        (authDS.activeAccount$ | async) ===
                        UserTypesAccount.Provider
                    "
                >
                    <p class="paragraph2">
                        Welcome, {{ user?.userProvider?.companyName }}
                    </p>
                </div>
                <div
                    class="profile-photo"
                    [matMenuTriggerFor]="
                        (authDS.activeAccount$ | async) ===
                        UserTypesAccount.Client
                            ? clientMenu
                            : providerMenu
                    "
                >
                    <div
                        *ngIf="
                            (authDS.activeAccount$ | async) ===
                            UserTypesAccount.Client
                        "
                        style="width: 100%"
                    >
                        <img
                            *ngIf="user?.userRole?.logoUrl"
                            src="{{ user?.userRole?.logoUrl }}"
                            alt=""
                        />
                        <p
                            *ngIf="
                                !user?.userRole?.logoUrl &&
                                user?.userRole?.firstName
                            "
                            class="initial"
                        >
                            {{
                                user?.userRole?.firstName &&
                                    user?.userRole?.firstName[0]
                            }}
                            {{
                                user?.userRole?.lastName &&
                                    user?.userRole?.lastName[0]
                            }}
                        </p>
                        <p
                            *ngIf="
                                !user?.userRole?.logoUrl &&
                                user?.userRole?.companyName
                            "
                            class="initial"
                        >
                            {{
                                user?.userRole?.companyName &&
                                    user?.userRole?.companyName[0]
                            }}
                        </p>
                        <div class="number-alerts">
                            <p>{{ unreviewedAlertsAmount$ | async }}</p>
                        </div>
                    </div>
                    <div
                        *ngIf="
                            (authDS.activeAccount$ | async) ===
                            UserTypesAccount.Provider
                        "
                        style="width: 100%"
                    >
                        <img
                            *ngIf="user?.userProvider?.logo"
                            src="{{ user?.userProvider?.logo }}"
                            alt=""
                        />
                        <p
                            *ngIf="
                                !user?.userProvider?.logo &&
                                user?.userProvider?.companyName
                            "
                            class="initial"
                        >
                            {{
                                user?.userProvider?.companyName &&
                                    user?.userProvider?.companyName[0]
                            }}
                        </p>
                    </div>
                    <mat-menu #clientMenu="matMenu" xPosition="before">
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/my-briefs']"
                        >
                            My Projects
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['alerts']"
                        >
                            Activity Alerts
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['account-settings']"
                        >
                            Account Settings
                        </button>
                        <button
                            *ngIf="
                                user.user.typeAccount ===
                                UserTypesAccount.Multiple
                            "
                            class="paragraph2"
                            mat-menu-item
                            (click)="onSwitchAccountClick()"
                        >
                            Switch Account
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            (click)="logout()"
                        >
                            Log Out
                        </button>
                    </mat-menu>
                    <mat-menu #providerMenu="matMenu" xPosition="before">
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/provider/manage-projects']"
                        >
                            Manage Projects
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            *ngIf='canViewArticlesPage()'
                            [routerLink]="['/provider/articles']"
                        >
                            Knowledge Articles
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/provider/edit-profile']"
                        >
                            Manage Profile
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/provider/edit-profile']"
                            [queryParams]="{ activeTab: 'Billing' }"
                        >
                            Billing
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/provider/edit-profile']"
                            [queryParams]="{ activeTab: 'Subscriptions' }"
                        >
                            Subscriptions
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/provider/edit-profile']"
                            [queryParams]="{ activeTab: 'Preferences' }"
                        >
                            Preferences
                        </button>
                        <button
                            *ngIf="
                                user.user.typeAccount ===
                                UserTypesAccount.Multiple
                            "
                            class="paragraph2"
                            mat-menu-item
                            (click)="onSwitchAccountClick()"
                        >
                            Switch Account
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            (click)="logout()"
                        >
                            Log Out
                        </button>
                    </mat-menu>
                </div>
                <!--        <div class="profile-photo" [routerLink]="['/create-brief']" [queryParams]="{ type: 'all'}">-->
                <!--          <p>+</p>-->
                <!--        </div>-->
            </div>
        </header>
        <div class="banner">
            <div class="banner-content">
                <h1 class="title">
                    Get project estimates from the world's best in the simplest
                    way possible.
                    <br />
                    <br />
                    <span>What do you need to get done today?</span>
                </h1>

                <div class="search" (keyup.enter)="clearSearch()">
                    <img src="../../../assets/icons/search.svg" alt="search" />
                    <input
                        class="data-input"
                        type="text"
                        [(ngModel)]="term"
                        (ngModelChange)="doSearch(term, true)"
                        placeholder="Search a company, specialty, country or city"
                    />
                    <button class="primary-btn" (click)="clearSearch()">
                        Search
                    </button>
                    <div class="search-results" *ngIf="term.length >= 3">
                        <div
                            class="providers-content"
                            infinite-scroll
                            [scrollWindow]="false"
                            (scrolled)="onScrollDown($event)"
                            (scrolledUp)="onScrollUp($event)"
                        >
                            <div
                                class="search-results-item"
                                *ngFor="
                                    let item of filteredProviders;
                                    let i = index
                                "
                            >
                                <p
                                    (click)="clearSearch(item)"
                                    class="paragraph2"
                                    #item
                                >
                                    {{ item?.companyName }}
                                    , {{ item?.industry?.title }}
                                    <span>{{'-' + (item?.city?.name ? ' ' + item.city.name + ', ' : '') + ' ' + item?.country?.name}}</span>
                                </p>
                            </div>
                        </div>
                        <p
                            *ngIf="
                                !filteredProviders?.length && term.length >= 3
                            "
                            class="paragraph2"
                            [ngClass]="{
                                resultNotFound:
                                    filteredProviders?.length &&
                                    term.length >= 3,
                            }"
                        >
                            No results match your search criteria
                        </p>
                    </div>
                </div>
                <div
                    *ngIf="
                        (authDS.activeAccount$ | async) ===
                        UserTypesAccount.Client
                    "
                    class="create-brief"
                >
                    <span>or</span>
                    <div class="btn-block">
                        <span (click)="createBriefOrLogin()"
                            >Create a Project Brief</span
                        >
                        <span (click)="createDocOrLogin()"
                            >Create a Requirements Document</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="popular-services">
    <h1 class="title">Popular Services</h1>
    <app-services-slider
        [slides]="services"
        [type]="slidersTypeEnum[1]"
    ></app-services-slider>
</section>

<section class="how-it-works" #target id="howItWorks">
    <div class="container">
        <h1 class="title">
            We feature the world's BEST companies based on their credibility to
            deliver quality work. We got you.
        </h1>

        <div class="how-it-works-content">
            <div class="content-text-box">
                <p class="sub-title">How it Works</p>

                <div class="content-text-box-item">
                    <div class="item-picture">
                        <img src="../../../assets/home/brief.svg" alt="" />
                    </div>
                    <div class="item-text paragraph">Create a simple brief</div>
                    <div class="item-description">
                        <p class="paragraph2">
                            Explain what your project is all about, define a few
                            preferences and the areas of expertise of your ideal
                            company. You can either create a brief to be send to
                            a specific company or share with many to get a few
                            estimates to help you evaluate things.
                        </p>
                    </div>
                </div>
                <div class="content-text-box-item">
                    <div class="item-picture">
                        <img src="../../../assets/home/Submit.svg" alt="" />
                    </div>
                    <div class="item-text paragraph">Submit</div>
                    <div class="item-description">
                        <p class="paragraph2">
                            Service providers that you share a brief with will
                            get notified. If you'd like to send a brief to more
                            service providers beyond an initial limit, simply
                            select this preference after you submit. You can
                            also track your project all in one place and see who
                            received your project details, who has viewed it and
                            who has shown interest in wanting to help you.
                        </p>
                    </div>
                </div>
                <div class="content-text-box-item">
                    <div class="item-picture">
                        <img src="./../../../assets/home/clock.svg" alt="" />
                    </div>
                    <div class="item-text paragraph">
                        Get estimates and connect
                    </div>
                    <div class="item-description">
                        <p class="paragraph2">
                            After submitting, you'll be contacted by credible
                            service providers that match with your project to
                            kick things off. You're now empowered to negotiate
                            pricing, finalize any work contracts and work with
                            them directly outside the platform to get things
                            going. We also do not take any commission from
                            anyone.
                        </p>
                    </div>
                </div>
            </div>
            <div class="content-video-box">
                <video
                    width="400"
                    height="300"
                    controls="controls"
                    poster="./../../../assets/Mask%20Group.png"
                >
                    <source
                        src="./../../../assets/The%20Unicorn%20App%20Explainer%20Video.mp4"
                        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                    />
                </video>
            </div>
        </div>
    </div>
</section>

<section class="featured-companies" *ngIf="featured">
    <h1 class="title">Featured Companies</h1>
    <app-main-slider
        [slides]="featured"
        [type]="slidersTypeEnum[0]"
    ></app-main-slider>
</section>

<section class="newly-added" *ngIf="newlyAdded">
    <h1 class="title">Newly Added</h1>
    <app-main-added
        [slides]="newlyAdded"
        [type]="slidersTypeEnum[0]"
    ></app-main-added>
</section>

<section class="people-choose">
    <h1 class="title">Why People Choose The Unicorn App</h1>
    <div class="container">
        <div class="people-choose-content">
            <div
                class="people-choose-content-item"
                *ngFor="let testimonial of testimonials"
            >
                <div class="item-photo">
                    <img [src]="testimonial.imageUrl" alt="" />
                </div>
                <h1 class="item-name sub-title">{{ testimonial.title }}</h1>
                <div class="item-description paragraph">
                    {{ testimonial.description }}
                </div>
            </div>
        </div>
    </div>
</section>

<section class="online-providers">
    <h1 class="sub-title">
        Providers that have an online precence in trusted sources like The
        Unicorn
        <br />
        App has a
        <span>60%</span>
        probability increase of new business. Join us.
    </h1>

    <div class="container">
        <div class="online-providers-content">
            <div class="online-providers-content-item">
                <div
                    class="item-photo-box"
                    style="
                        background-image: url('./../../../assets/home/Rectangle 87.png');
                    "
                >
                    <div class="opacity-box"></div>
                </div>
                <div class="item-text-box">
                    <div class="item-text-box-content">
                        <h1 class="big-title">
                            Don’t see your <br />
                            company?
                        </h1>
                        <p class="paragraph">No worries, let’s get you in.</p>
                        <button class="primary-btn" (click)="openGetListed()">
                            Get Listed
                        </button>
                    </div>
                </div>
            </div>
            <div class="online-providers-content-item reverse">
                <div
                    class="item-photo-box reverse-photo-box"
                    style="
                        background-image: url('./../../../assets/home/Rectangle 87 (1).png');
                    "
                >
                    <div class="opacity-box"></div>
                </div>
                <div class="item-text-box reverse-text-box">
                    <div class="item-text-box-content">
                        <h1 class="big-title">
                            Providers, need to <br />
                            make an update to <br />
                            your company profile?
                        </h1>
                        <p class="paragraph">No worries. Send us a note.</p>
                        <button (click)="openLetUs()" class="primary-btn">
                            Let us know
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
