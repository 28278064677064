<div class="content-left-side">
  <div class="profile-photo-box">
    <circle-progress
      [percent]="percent"
      [radius]="100"
      [showUnits]="true"
      [titleColor]="'#7673F6'"
      [subtitleColor]="'#7673F6'"
      [titleFontSize]="'40'"
      [unitsFontSize]="'40'"
      [unitsColor]="'#7673F6'"
      [outerStrokeWidth]="16"
      [innerStrokeWidth]="8"
      [outerStrokeColor]="'#60D2C7'"
      [innerStrokeColor]="'#DFF6F4'"
      [animation]="true"
      [animationDuration]="300"
      [showSubtitle]="false"
      [showInnerStroke]="true"
    ></circle-progress>
    <p class="paragraph2 semi-bold">Completed</p>
    <label *ngIf='companyName' class='shared-text'>Project to be shared with {{ companyName }}</label>
  </div>
  <ng-container *ngFor="let step of steps; let i = index">
    <div
      *ngIf="i !== 1 || briefTypeSend === 'all'"
      class="step-box"
      [class.step-box-active]="currentStep === i"
      (click)="moveTo(i)"
    >
      <div class="step-box-content">
        <p class="paragraph2">{{ step }}</p>
        <div class="check-oval"
             [class.check-oval-done]="answeredSteps.includes(i) && (i === 3 ? answeredFeatureSubstep.length === featureSubstepsLabels.length : true)"
        ></div>
      </div>
      <ng-container *ngIf="step === 'Features' && showFeatureSubsteps">
        <div class="step-box-content substep"
             (click)="moveToSubStep($event, idx)"
             *ngFor="let label of featureSubstepsLabels; let idx = index">
          <p class="paragraph2">{{ label }}</p>
          <div class="check-oval" [class.check-oval-done]="answeredFeatureSubstep.includes(idx + 1)"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div *ngIf="briefName?.length" class="step-box delete-block">
    <span (click)="deleteBrief()">{{ deleteText }}</span>
  </div>
</div>
