<div class='topics-section'>
    <div #defaultOptionRef class='topic-wrapper' (click)='handleDefaultSection($event)'>
        <p>{{ defaultOption }}</p>
    </div>
    <div (click)='onChange($event)' *ngFor='let option of options | slice:0:count' [ngClass]='{ "selected" : value.includes(option.name) }' #optionRef class='topic-wrapper'>
        <p>{{ option.name }}</p>
        <img *ngIf='option.hot' src='assets/knowledge-hub/flame.svg' alt='fire' width='12' height='12' />
    </div>
    <button (click)='toggleListSize()'>
      {{ title }}
    </button>
</div>
