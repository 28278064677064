<app-header [disabled]="true"></app-header>
<div class="login-content">

  <div *ngIf="firstStep && !listView && briefInfo" class="first-step">
    <p class="title">Congrats!</p>

    <p class="paragraph2">
      We've sent your brief to {{ providersForDistributionTo.length }} world class providers.
    </p>

    <div *ngIf="providersForDistributionTo" class="providers">
      <div class="provider" *ngFor="let provider of providersForDistributionTo | slice:0:5">
        <img [src]="provider?.logo || '../assets/pvoviderLogoPlaceholder.png'" alt="" />
      </div>
      <span *ngIf="providersForDistributionTo.length > 5" (click)="showList(true)" class="list-view-btn">View the List</span>
    </div>

    <div class="choose-title">
      Choose a plan to have us distribute your project brief to more.
    </div>

    <div class="plans">
      <div
        class="plans-item"
        [ngClass]="{ activePlan: activePlan === 0 }"
        (click)="choosePlan(0, 5)"
      >
        <div
          *ngIf="availableProvidersCount() < 5"
          class="corner-ribbon top-left sticky red shadow"
        >
          Not Available
        </div>
        <img
          *ngIf="activePlan !== 0"
          src="./../../../../assets/plan.png"
          alt=""
        />
        <img
          *ngIf="activePlan === 0"
          src="./../../../../assets/activePlan.png"
          alt=""
        />
        <p class="paragraph2">
          Send to <br />
          5 More Providers
        </p>
        <p class="paragraph2 sum">9.99 USD</p>
      </div>
      <div
        class="plans-item"
        [ngClass]="{ activePlan: activePlan === 1 }"
        (click)="choosePlan(1, 10)"
      >
        <div
          *ngIf="availableProvidersCount() < 10"
          class="corner-ribbon top-left sticky red shadow"
        >
          Not Available
        </div>
        <img
          *ngIf="activePlan !== 1"
          src="./../../../../assets/plan.png"
          alt=""
        />
        <img
          *ngIf="activePlan === 1"
          src="./../../../../assets/activePlan.png"
          alt=""
        />
        <p class="paragraph2">
          Send to <br />
          10 More Providers
        </p>
        <p class="paragraph2 sum">14.99 USD</p>
      </div>
      <div
        class="plans-item"
        [ngClass]="{ activePlan: activePlan === 2 }"
        (click)="choosePlan(2, 15)"
      >
        <div
          *ngIf="availableProvidersCount() < 15"
          class="corner-ribbon top-left sticky red shadow"
        >
          Not Available
        </div>
        <img
          *ngIf="activePlan !== 2"
          src="./../../../../assets/plan.png"
          alt=""
        />
        <img
          *ngIf="activePlan === 2"
          src="./../../../../assets/activePlan.png"
          alt=""
        />
        <p class="paragraph2">
          Send to <br />
          15 More Providers
        </p>
        <p class="paragraph2 sum">19.99 USD</p>
      </div>
    </div>
    <button [disabled]="!activePlanStr" class="primary-btn" type="submit" (click)="changeStep(true)">
      Choose Service Providers
    </button>
    <p class="paragraph2 view" (click)="onNoClick()">No, skip it for now</p>
  </div>

  <div *ngIf="secondStep && !listView" class="second-step">
    <p class="title">Pick {{activePlanStr}} More!</p>
    <p class="paragraph2">
      Select additional companies to unlock a wider range of service providers to work with.
    <p class="return" (click)="changeStep(false)"><span class="back-btn"></span>Change Add-On Purchase</p>
    <a (click)="onCancelClick()" class="cancel">Cancel</a>
    <button [disabled]="selectedProviders.length < activePlanStr" class="primary-btn" type="submit" (click)="openCompanyPopup()">
      Proceed to Checkout
    </button>
    <div class="counter-ct">
      <div class="search-input-ct">
        <input class="search-input" [value]="search" matInput placeholder="Search a company" (input)="filterValue($event)">
      </div>
      <div *ngIf="activePlanStr" class="counter">
        {{selectedProviders.length}} out of {{activePlanStr}} selected.
        <span *ngIf="selectedProviders.length < activePlanStr" class="left">{{activePlanStr - selectedProviders.length}} to go!</span>
      </div>
    </div>

    <div *ngIf="showSpinner" class="lds-ring"><div></div><div></div><div></div><div></div></div>
    <div class="providers-list"
         infinite-scroll
         [scrollWindow]="false"
         (scrolled)="onScrollDown($event)"
         (scrolledUp)="onScrollUp($event)">

      <div *ngFor="let provider of listArray" class="provider" (click)="selectProvider(provider._id)">
        <div class="provider-logo">
          <img [src]="provider?.logo || '../assets/pvoviderLogoPlaceholder.png'" alt="provider-logo" />
        </div>
        <div class="provider-info">
          <span class="provider-name">{{provider?.companyName}}</span>
          <span class="provider-location">{{getProviderLocation(provider)}}</span>
        </div>

        <div class="provider-reputation">
          <span>REPUTATION:</span>
          <div class="rate-container">
            <div class="rate">
              <input
                disabled
                [checked]="provider?.clutchScore === 5"
                type="radio"
                id="rating10"
                name="rating"
                value="10"
                [class.isChecked]="provider?.clutchScore === 5"
              />
              <label for="rating10" title="5 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 4.5 && provider?.clutchScore != 5"
                type="radio"
                id="rating9"
                name="rating"
                value="9"
                [class.isChecked]="provider?.clutchScore >= 4.5 && provider?.clutchScore != 5"
              />
              <label class="half" for="rating9" title="4 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 4.5 && provider?.clutchScore >= 4"
                type="radio"
                id="rating8"
                name="rating"
                value="8"
                [class.isChecked]="provider?.clutchScore < 4.5 && provider?.clutchScore >= 4"
              />
              <label for="rating8" title="4 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 3.5 && provider?.clutchScore < 4"
                type="radio"
                id="rating7"
                name="rating"
                value="7"
                [class.isChecked]="provider?.clutchScore >= 3.5 && provider?.clutchScore < 4"
              />
              <label class="half" for="rating7" title="3 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 3.5 && provider?.clutchScore >= 3"
                type="radio"
                id="rating6"
                name="rating"
                value="6"
                [class.isChecked]="provider?.clutchScore < 3.5 && provider?.clutchScore >= 3"
              />
              <label for="rating6" title="3 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 2.5 && provider?.clutchScore < 3"
                type="radio"
                id="rating5"
                name="rating"
                value="5"
                [class.isChecked]="provider?.clutchScore >= 2.5 && provider?.clutchScore < 3"
              />
              <label class="half" for="rating5" title="2 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 2.5 && provider?.clutchScore >= 2"
                type="radio"
                id="rating4"
                name="rating"
                value="4"
                [class.isChecked]="provider?.clutchScore < 2.5 && provider?.clutchScore >= 2"
              />
              <label for="rating4" title="2 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 1.5 && provider?.clutchScore < 2"
                type="radio"
                id="rating3"
                name="rating"
                value="3"
                [class.isChecked]="provider?.clutchScore >= 1.5 && provider?.clutchScore < 2"
              />
              <label class="half" for="rating3" title="1 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 1.5 && provider?.clutchScore >= 1"
                type="radio"
                id="rating2"
                name="rating"
                value="2"
                [class.isChecked]="provider?.clutchScore < 1.5 && provider?.clutchScore >= 1"
              />
              <label for="rating2" title="1 star"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 0.5 && provider?.clutchScore < 1"
                type="radio"
                id="rating1"
                name="rating"
                value="1"
                [class.isChecked]="provider?.clutchScore >= 0.5 && provider?.clutchScore < 1"
              />
              <label class="half" for="rating1" title="1/2 star"></label>
            </div>
            <p class="score">{{ provider?.clutchScore }}</p>
          </div>
        </div>
        <div class="is-checked">
          <span *ngIf="selectedProviders.includes(provider?._id)" class="check-oval-done check-oval">
          </span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="listView" class="list-view">
    <p class="return" (click)="showList(false)"><span class="back-btn"></span>Change Add On Purchase</p>
    <p class="title">List of Service Providers</p>
    <div class="providers-list">
      <div *ngFor="let provider of providersForDistributionTo" class="provider">
        <div class="provider-logo">
          <img [src]="provider?.logo || '../assets/pvoviderLogoPlaceholder.png'" alt="provider-logo" />
        </div>
        <div class="provider-info">
          <span class="provider-name">{{provider?.companyName}}</span>
          <span class="provider-location">{{getProviderLocation(provider)}}</span>
        </div>

        <div class="provider-reputation">
          <span>REPUTATION:</span>
          <div class="rate-container">
            <div class="rate">
              <input
                disabled
                [checked]="provider?.clutchScore === 5"
                type="radio"
                id="rating10"
                name="rating"
                value="10"
                [class.isChecked]="provider?.clutchScore === 5"
              />
              <label for="rating10" title="5 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 4.5 && provider?.clutchScore != 5"
                type="radio"
                id="rating9"
                name="rating"
                value="9"
                [class.isChecked]="provider?.clutchScore >= 4.5 && provider?.clutchScore != 5"
              />
              <label class="half" for="rating9" title="4 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 4.5 && provider?.clutchScore >= 4"
                type="radio"
                id="rating8"
                name="rating"
                value="8"
                [class.isChecked]="provider?.clutchScore < 4.5 && provider?.clutchScore >= 4"
              />
              <label for="rating8" title="4 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 3.5 && provider?.clutchScore < 4"
                type="radio"
                id="rating7"
                name="rating"
                value="7"
                [class.isChecked]="provider?.clutchScore >= 3.5 && provider?.clutchScore < 4"
              />
              <label class="half" for="rating7" title="3 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 3.5 && provider?.clutchScore >= 3"
                type="radio"
                id="rating6"
                name="rating"
                value="6"
                [class.isChecked]="provider?.clutchScore < 3.5 && provider?.clutchScore >= 3"
              />
              <label for="rating6" title="3 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 2.5 && provider?.clutchScore < 3"
                type="radio"
                id="rating5"
                name="rating"
                value="5"
                [class.isChecked]="provider?.clutchScore >= 2.5 && provider?.clutchScore < 3"
              />
              <label class="half" for="rating5" title="2 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 2.5 && provider?.clutchScore >= 2"
                type="radio"
                id="rating4"
                name="rating"
                value="4"
                [class.isChecked]="provider?.clutchScore < 2.5 && provider?.clutchScore >= 2"
              />
              <label for="rating4" title="2 stars"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 1.5 && provider?.clutchScore < 2"
                type="radio"
                id="rating3"
                name="rating"
                value="3"
                [class.isChecked]="provider?.clutchScore >= 1.5 && provider?.clutchScore < 2"
              />
              <label class="half" for="rating3" title="1 1/2 stars"></label>

              <input
                disabled
                [checked]="provider?.clutchScore < 1.5 && provider?.clutchScore >= 1"
                type="radio"
                id="rating2"
                name="rating"
                value="2"
                [class.isChecked]="provider?.clutchScore < 1.5 && provider?.clutchScore >= 1"
              />
              <label for="rating2" title="1 star"></label>
              <input
                disabled
                [checked]="provider?.clutchScore >= 0.5 && provider?.clutchScore < 1"
                type="radio"
                id="rating1"
                name="rating"
                value="1"
                [class.isChecked]="provider?.clutchScore >= 0.5 && provider?.clutchScore < 1"
              />
              <label class="half" for="rating1" title="1/2 star"></label>
            </div>
            <p class="score">{{ provider?.clutchScore }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="checkoutStep" class="checkout-step">
    <p class="return-to-plan" (click)="returnToPlane()">
      <span class="back-btn"></span>
      {{ onlyDocument ? 'Back' : docRequirements ? 'Back to Requirements Document creation ' : 'Choose Service Providers' }}
    </p>
    <p class="title">Let's Check Out!</p>
    <br />

    <div class="providers-list-ct" *ngIf="!onlyDocument && !docRequirements">
      <p class="paragraph2 subtitle"><img src="/assets/icons/building_icon.svg" alt="">{{activePlanStr}} Provider Project Brief Distribution</p>
      <p class="paragraph2 price">
        {{
        activePlan === 0 ? "9.99" : activePlan === 1 ? "14.99" : "19.99"
        }}
      </p>
    </div>

    <ul class="providers-list">
      <li *ngFor="let provider of selectedProvidersData" class="provider-logo">
        <div class="logo">
          {{provider?.companyName}}
        </div>
      </li>
    </ul>
    <div *ngIf="(briefInfo?.typeRequirements !== RequirementsTypes.non && !briefInfo?.isDocumentPurchased && !onlyProviders) || onlyDocument || docRequirements" class="providers-list-ct">
      <p class="paragraph2 subtitle"><img src="/assets/icons/fileIcon.svg" alt="">
        Project Requirements Document
      </p>
      <p class="paragraph2 price">9.99</p>
    </div>
    <div class="total">
      <div>Total:</div>
      <div>{{ calculatePrice() }}</div>
    </div>
    <div class="btn-ct">
      <div class="summary">You will be billed {{ calculatePrice() }} USD </div>
      <button
        class="primary-btn"
        (click)="createSecret()"
      >
        Pay
      </button>
      <a (click)="onCancelClick()" class="cancel">Cancel</a>
    </div>
  </div>

  <div *ngIf="payStep" class="pay-step">
    <p class="return-to-plan" (click)="returnToCheckout()"><span class="back-btn"></span>Back to Checkout</p>

    <p class="title">Add your payment information</p>
    <form *ngIf="elementsOptions?.clientSecret" class="stripe login-content-form" [formGroup]="stripeFrom">
      <p class="small-title">Card information</p>
        <div>
          <mat-form-field>
            <mat-label>Cardholder name</mat-label>
            <input matInput
                   [ngClass]="{
                      'error-input':
                        stripeFrom.controls['name'].invalid && submitted
                    }"
                   formControlName="name" />
          </mat-form-field>

          <div
            class="error-text"
            *ngIf="submitted && stripeFrom.controls['name'].errors"
          >
            <p
              *ngIf="stripeFrom.controls['name'].errors.required"
            >
              Check your Cardholder Name
            </p>
          </div>
        </div>
      <div class="form-container">
        <ng-container>
          <ngx-stripe-payment
            (change)="onChange($event)"
            [appearance]="appearance"
            [elementsOptions]="elementsOptions"
            [clientSecret]="elementsOptions?.clientSecret"
          ></ngx-stripe-payment>
        </ng-container>
      </div>
      <button
        [ngClass]="{ 'sign-up-active': stripeFrom.valid }"
        type="submit"
        [disabled]="!isFormValid"
        class="submit-stripe sign-up"
        (click)="submit()"
      >
        Pay $ {{ calculatePrice() }}
        <img src="/assets/icons/lock_fill_icon.svg" alt="">
      </button>
      <a (click)="onCancelClick()" class="cancel">Cancel</a>

    </form>
  </div>
</div>


