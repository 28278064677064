<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="dismiss()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">{{ data.title ? data.title : 'Are you sure?' }}</p>

  <p class="paragraph2">
    {{ data.message }}
  </p>

  <div class="buttons-wrapper">
    <button class="primary-btn" (click)="dismiss()">
      {{ data.dismissText }}
    </button>
    <button class="transparent-button" (click)="deleteIt()">
      {{ data.confirmText }}
    </button>
  </div>
</div>
