import { Component, Inject, Input } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: "app-message-sent",
  templateUrl: "./message-sent.component.html",
  styleUrls: ["./message-sent.component.scss"],
})
export class MessageSentComponent {
  @Input() title: string = 'Message Sent!';
  @Input() message: string = 'Your inquiry for getting listed has been sent. A representative from our team will be in touch!';
  @Input() button: string = 'Great!';

  constructor(
    private dialogRef: MatDialogRef<MessageSentComponent>,
    @Inject(MAT_DIALOG_DATA) private data: MessageSentComponent,
  ) {
    this.title = this.data.title || this.title;
    this.message = this.data.message || this.message;
    this.button = this.data.button || this.button;
  }

  public dismiss(): void {
    this.dialogRef.close();
  }
}
