<section class="filters">
    <img
        class="close-icon"
        src="./../../../../assets/icons/x.svg"
        alt=""
        mat-dialog-close
    />
    <h2 class="sub-title">{{ data.title }}</h2>
    <ng-container *ngTemplateOutlet="data.template"></ng-container>
</section>
