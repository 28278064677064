<div class="mobile-content">
  <div class="content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Welcome!</p>

  <p class="paragraph">
    You’re visiting the site on a
    mobile device. Our app gives you a
    better experience.
  </p>

  <div class="btn-container">
    <img [src]="buttonImage" alt="" (click)="navigateToMarket()"/>

    <button class="transparent-button" type="submit" (click)="onNoClick()">
      Maybe Later
    </button>
  </div>
</div>
