import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthDataService } from '../../../services/auth-data.service';
import { IProject, ProjectStatusEnum } from '../../../interfaces/project';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { first } from 'rxjs/operators';
import { ILogin, UserFullInfo } from '../../../interfaces/auth-interfaces';
import { UnverifiedAccountComponent } from '../../popups/unverified-account/unverified-account.component';
import { LoginComponent } from '../../popups/login/login.component';
import { Subscription } from 'rxjs';
import { DocumentPreviewComponent } from '../../popups/document-preview/document-preview.component';
import { CloseBriefConfirmationComponent } from '../../popups/close-brief-confirmation/close-brief-confirmation.component';

@Component({
  selector: 'app-preview-submit',
  templateUrl: './preview-submit.component.html',
  styleUrls: ['./preview-submit.component.scss'],
})
export class PreviewSubmitComponent implements OnInit, OnDestroy {
  @Output() sendLoginEmitter: EventEmitter<ILogin> = new EventEmitter<ILogin>();
  public briefInfo: IProject;
  public budget: string;
  public form: UntypedFormGroup = this.fb.group({
    rating2: this.fb.control(4),
  });
  private countProviders: number;
  private matchedProviders: [];
  public user: UserFullInfo;
  public subscription = new Subscription();
  public readonly pendingStatus = ProjectStatusEnum.pending;
  showAllCheckedFeatures = false;

  constructor(
    private ngxService: NgxUiLoaderService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private authDS: AuthDataService
  ) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.subscription.add(this.authDS
      .getBriefById(this.route.snapshot.queryParamMap.get('briefId'))
      .pipe(first())
      .subscribe((data: { project: IProject, providers: [], countProviders: any }) => {
        this.briefInfo = data.project;
        this.matchedProviders = data.providers;
        this.countProviders = data.countProviders;
        this.ngxService.stop();
        this.transformProjectAmount();
      }));

    this.subscription.add(this.authDS.user$.subscribe((user) => {
      if (!user) {
        return;
      }
      this.user = user;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public closeProject(credential) {
    if (this.user) {
      if (this.user?.user.isPhoneVerified && this.user?.user.confirmed) {
       const dialogRef = this.dialog.open(CloseBriefConfirmationComponent, {
          autoFocus: false,
            width: '500px',
       });
       dialogRef.afterClosed().subscribe(res => {
          if (res) {
            this.subscription.add(this.authDS.closeProject(credential).pipe(first()).subscribe(() => {
              this.router.navigate(['/my-briefs']).then();
            }));
          }
        });
      } else {
        this.dialog.open(UnverifiedAccountComponent, {
          autoFocus: false,
          width: '450px',
          disableClose: true
        });
      }
    } else {
      this.dialog.open(LoginComponent, {
        autoFocus: false,
        width: '500px',
        data: {
          title: ' ',
          message: 'You need to be authorized to be able to send a project brief.',
          button: 'Got it!',
        }
      });
    }
  }

  public transformProjectAmount() {
    if (this.briefInfo.budget === 1 || this.briefInfo.budget === 50500) {
      this.budget = 'Open';
    } else {
      this.budget = this.briefInfo.budget.toLocaleString('en-EN') + ' USD';
    }
  }

  public openCompanyPopup(brief): void {
    if (this.user) {
      if (this.user?.user.isPhoneVerified && this.user?.user.confirmed) {
        this.router.navigate(
          ['/checkout'],
          { queryParams: {briefId : brief?._id}, state: { onlyProviders: true } }
        ).then();
      } else {
        this.dialog.open(UnverifiedAccountComponent, {
          autoFocus: false,
          width: '450px',
          disableClose: true
        });
      }
    } else {
      this.dialog.open(LoginComponent, {
        autoFocus: false,
        width: '500px',
        data: {
          title: ' ',
          message: 'You need to be authorized to be able to send a project brief.',
          button: 'Got it!',
        }
      });
    }
  }

  public getAllCheckedFeatures() {
    const res: string[] = [];
    for (const value of Object.values(this.briefInfo.featuresForDoc)) {
      if (!value.feats) continue;
      if (typeof value.feats === 'string') {
        res.push(value.feats);
      } else {
        res.push(...value.feats);
      }
    }
    return res.join(', ');
  }

  public openDocumentPreview(fromPreview?: boolean) {
    const dialogRef = this.dialog.open(DocumentPreviewComponent, {
      autoFocus: false,
      width: '75%',
      height: '95vh',
      data: {
        projectName: this.briefInfo.name,
        projectId: this.briefInfo._id,
        fromPreview,
        requirementDoc: this.briefInfo.typeRequirements,
      },
    });
  }

  public opedRequirementsDocInNewTab() {
    this.ngxService.start();
    this.authDS.getDoc(this.briefInfo._id).subscribe(async (response) => {
      const buffer = await response.arrayBuffer();
      const file = new Blob([buffer], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      this.ngxService.stop();
      window.open(fileURL);
    });
  }
}
