import { Component, Inject, OnInit } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent {
  constructor(
    public dialogRef: MatDialogRef<AboutUsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
