import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { AuthDataService } from '../../services/auth-data.service';
import { IProject } from '../../interfaces/project';
import { UserFullInfo } from '../../interfaces/auth-interfaces';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DeleteBriefDialogComponent } from 'src/app/modules/popups/delete-brief-dialog/delete-brief-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UnverifiedAccountComponent } from '../popups/unverified-account/unverified-account.component';
import { DocumentPreviewComponent } from '../popups/document-preview/document-preview.component';
import { CancelingOrDeletingDialog } from '../popups/delete-doc-dialog/canceling-or-deleting-dialog.component';

@Component({
  selector: 'app-my-briefs',
  templateUrl: './my-briefs.component.html',
  styleUrls: ['./my-briefs.component.scss'],
})
export class MyBriefsComponent implements OnInit {
  public activeTab = 'Open';
  @ViewChild('stepper') stepper: MatStepper;
  public briefsDraft: any[] = [];
  public briefsOpen: any[] = [];
  public briefsClosed: any[] = [];
  public user!: UserFullInfo;

  isCreationMenuShown = false;

  constructor(
    private authDS: AuthDataService,
    private ngxService: NgxUiLoaderService,
    private dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.ngxLoader.start();
    if (this.route.snapshot.queryParamMap.get('activeTab')) {
      setTimeout(() => {
        this.activeTab = this.route.snapshot.queryParamMap.get('activeTab');
        this.changeTab(this.activeTab, this.activeTab === 'Draft' ? 1 : this.activeTab === 'Closed' ? 2 : 0);
      }, 100);
    }
    this.getUser()
      .pipe(first())
      .subscribe((user) => (this.user = user));

    forkJoin([
      this.authDS.getDocsAndProjects(0),
      this.authDS.getDocsAndProjects(1),
      this.authDS.getDocsAndProjects(2),
    ]).subscribe(([draft, open, closed]) => {
      this.briefsDraft = draft;
      this.briefsOpen = open;
      this.briefsClosed = closed;
      this.ngxLoader.stop();
    });

  }

  private getUser(): Observable<UserFullInfo> {
    return this.authDS.user$.pipe(
      switchMap((user) => {
        if (user) {
          return of(user);
        } else {
          return this.authDS.getUserProfile();
        }
      })
    );
  }

  public changeTab(activeTab: string, index: number) {
    this.activeTab = activeTab;
    this.stepper.selectedIndex = index;
  }

  public deleteBrief(event: Event, briefID: string, briefName: string): void {
    event.stopPropagation();
    const dialog = this.dialog.open(DeleteBriefDialogComponent, {
      autoFocus: false,
      width: '450px',
      data: {
        briefName
      }
    });

    dialog.afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => this.authDS.deleteBrief(briefID)),
        switchMap(() => this.authDS.getDocsAndProjects(0)),
        first(),
      )
      .subscribe((draft) => {
        this.briefsDraft = draft;
      });
  }

  public deleteDoc(event: Event, briefID: string): void {
    event.stopPropagation();
    const dialog = this.dialog.open(CancelingOrDeletingDialog, {
      autoFocus: false,
      width: '450px',
      data: {
        message: 'If you will delete the draft requirements document, it’ll be gone forever and you’ll have to re-create it.',
        dismissText: 'No, Keep Draft',
        confirmText: 'Yes, Delete Draft'
      }
    });

    dialog.afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => this.authDS.deleteDoc(briefID)),
        switchMap(() => this.authDS.getDocsAndProjects(0)),
        first(),
      )
      .subscribe((draft) => {
        this.briefsDraft = draft;
      });
  }

  public navigateToCreateBrief(id?: string, type?: string, step?: number) {
    if (this.user?.user.isPhoneVerified && this.user?.user.confirmed) {
      if (id && step && type) {
        this.router.navigate(['/create-brief'], {queryParams: {briefId: id, type, step}}).then();
      } else {
        this.router.navigate(['/create-brief'], {queryParams: {type: 'all', fromMyBriefs: true}}).then();
      }
    } else {
      this.dialog.open(UnverifiedAccountComponent, {
        autoFocus: false,
        width: '450px',
        disableClose: true
      });
    }
  }

  public onDownloadClick(event: any, briefId, name) {
    event.stopPropagation();
    this.ngxService.start();
    this.authDS.getDoc(briefId).subscribe(async (response) => {
      const buffer = await response.arrayBuffer();

      const file = new Blob([buffer], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      this.ngxService.stop();
      window.open(fileURL);
    });
  }

  public onDownloadDocClick(event: any, docId) {
    event.stopPropagation();
    this.ngxService.start();
    this.authDS.getDocWithoutBrief(docId).subscribe(async (response) => {
      const buffer = await response.arrayBuffer();

      const file = new Blob([buffer], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      this.ngxService.stop();
      window.open(fileURL);
    });
  }

  public onPurchaseClick(event: any, briefId) {
    event.stopPropagation();
    this.router.navigate(['/checkout'], { queryParams: { briefId },  state: { onlyDocument: true } });
  }

  public openDocumentPreview(event: any, projectName, projectId) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DocumentPreviewComponent, {
      autoFocus: false,
      width: '75%',
      height: '95vh',
      data: {
        projectName,
        projectId,
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.router.navigate(
            ['/checkout'],
            { queryParams: { briefId: projectId },  state: { onlyDocument: true } }
        );
      }
    });
  }

  navigateToCreateDocRequirements(id?: string) {
    if (this.user?.user.isPhoneVerified && this.user?.user.confirmed) {
      if (id) {
        this.router.navigate(['/create-doc-requirements'], {queryParams: {docId: id}}).then();
      } else {
        this.router.navigate(['/create-doc-requirements']).then();
      }
    } else {
      this.dialog.open(UnverifiedAccountComponent, {
        autoFocus: false,
        width: '450px',
        disableClose: true
      });
    }
  }
}
