import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-detected',
  templateUrl: './mobile-detected.component.html',
  styleUrls: ['./mobile-detected.component.scss']
})
export class MobileDetectedComponent implements OnInit {
  buttonImage = '';
  mobileAppLink = '';

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<MobileDetectedComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  ngOnInit(): void {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      this.buttonImage = '../../../../assets/home/Google Play.svg';
      this.mobileAppLink = 'https://play.google.com/store/apps/details?id=com.unicorn.unicornapp.unicorn';
    } else if (/iPad|iPhone|iPod/.test(ua)) {
      this.buttonImage = '../../../../assets/home/App Store.svg';
      this.mobileAppLink = 'https://apps.apple.com/us/app/the-unicorn-app-outsourcing/id1550775284';
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  navigateToMarket() {
    return location.href = this.mobileAppLink;
  }
}
