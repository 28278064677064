<div class="popup">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="close()"
  />

  <p class="title">What would you like to do?</p>
  <div class='body'>
    <div class='card' (click)='changeSelected(false)' [ngClass]='{ "selected": !this.selected}'>
      <img src='./../../../../assets/create-brief-popup/multipleProviders.svg' alt='multiple-providers-icon' />
      <p>Create a project brief to share with <strong>multiple</strong> providers.</p>
      <label>After selecting preferences for your ideal provider, we’ll share your project with
        multiple providers.</label>
    </div>
    <div class='card' (click)='changeSelected(true)' [ngClass]='{ "selected": this.selected}'>
      <img src='./../../../../assets/create-brief-popup/specificProvider.svg' alt='multiple-providers-icon' />
      <p>Create a project brief to share with a <strong>specific</strong> provider.</p>
      <label>The project brief will be shared with a provider that you’d like to work with.</label>
    </div>
  </div>

  <button class="primary-btn" (click)="next()">Next</button>
</div>
