import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilterModalData } from '../../../models/knowledge-hub/filter-modal-data';

@Component({
    selector: 'app-filters-modal',
    templateUrl: './filters-modal.component.html',
    styleUrls: ['./filters-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) protected data: FilterModalData) {}
}
