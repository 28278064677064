import { Component, EventEmitter, Output } from '@angular/core';
import { AboutUsComponent } from '../../../popups/about-us/about-us.component';
import { ContactUsComponent } from '../../../popups/contact-us/contact-us.component';
import { first } from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Output() sendMessage: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public dialog: MatDialog) {}

  public openAbout(): void {
    this.dialog.open(AboutUsComponent, {
      autoFocus: false,
      height: '80%',
      width: '55%',
    });
  }

  public openContact(): void {
    const dialogRef = this.dialog.open(ContactUsComponent, {
      autoFocus: false,
      height: '85%',
      width: '600px',
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((res) => {
        res ? this.sendMessage.emit(true) : this.sendMessage.emit(false);
      });
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  get currentYear() {
    return new Date().getFullYear();
  }
}
