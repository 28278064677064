<section class="main-slider">
  <div class="container">
    <div class="transparent-slider" *ngIf="type === 'Transparent'">
      <!-- Slider main container -->
      <div class="swiper-container swiper swiper-container3" [swiper]="config">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide" *ngFor="let slide of slides" (click)="search(slide?.link)">
            <div class="swiper-slide-content"
                 [ngStyle]="{'background-image': 'url(' + slide?.img + ')'}">
              <div class="opacity-box"></div>
              <div class="swiper-slide-content-text">
                <p class="sub-title">{{slide?.title}}</p>
                <p class="paragraph">{{slide?.text}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-prev swiper-button-prev3"></div>
      <div class="swiper-button-next swiper-button-next3"></div>
    </div>
  </div>

</section>
