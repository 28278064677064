import { Component, Inject } from "@angular/core";
import { ILogin } from "../../../interfaces/auth-interfaces";
import { UserRoleEnum } from "../../../enums/user-role.enum";
import { Router } from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: "app-sign-up-popup",
  templateUrl: "./sign-up-popup.component.html",
  styleUrls: ["./sign-up-popup.component.scss"],
})
export class SignUpPopupComponent {
  public role = UserRoleEnum;
  public chosenRole = { role: "", id: [] };

  constructor(
    public dialogRef: MatDialogRef<SignUpPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ILogin,
    private router: Router
  ) {}

  public onNoClick(): void {
    if (
      this.chosenRole.role !== "enterprise" &&
      this.chosenRole.role !== "startUp" &&
      this.chosenRole.role !== "individual"
    ) {
      this.router.navigate(['/']).then();
    }
    this.dialogRef.close();
  }

  public choiceRole(role, id) {
    this.chosenRole.role = role;
    this.chosenRole.id = [id];
  }
}
