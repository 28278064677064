<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>

  <form class="login-content-form" [formGroup]="loginForm">
    <label class="paragraph2" for="email"><b>Email</b> *</label>
    <input
      [ngClass]="{
        'error-input':
          loginForm.controls['email'].invalid && submitted
      }"
      class="data-input"
      id="email"
      type="text"
      placeholder="Enter email"
      required
      formControlName="email"
    />

    <div class="password-inputs">
      <label class="paragraph2" for="password"><b>Password</b> *</label>
      <input
        [ngClass]="{
        'error-input':
          loginForm.controls['password'].invalid && submitted
      }"
        class="data-input"
        id="password"
        type="{{ typePassword }}"
        placeholder="Enter password"
        required
        formControlName="password"
      />
      <button class="mask" (click)="changePasswordType($event)">
        <img
          *ngIf="!showPassword"
          src="../../../../assets/eye.svg"
          alt=""
        />
        <img
          *ngIf="showPassword"
          src="../../../../assets/Eye.slash.svg"
          alt=""
        />
      </button>
    </div>

  </form>

  <button class="primary-btn" type="submit" (click)="doLogin()">Log In</button>
  <p
    class="paragraph2 forgot-password"
    mat-raised-button
    (click)="openLoginPopup()"
  >
    <b>Forgot password?</b>
  </p>
  <p class="paragraph2 sign-up">
    Don’t have an account?
    <span
      mat-raised-button
      (click)="openSignUpPopup()"><b> Sign up</b></span>
  </p>
</div>
