import {IProvider} from './provider';
import {User} from './auth-interfaces';

export interface IProject {
    _id: any;
    briefId: string;
    name: string;
    types?: any;
    country?: ICountry['_id'][];
    specialization?: string[];
    skillAreas?: ISkill['_id'][];
    acceptCompensation?: IAcceptCompensation['_id'][];
    projectDetails?: ProjectDetailsDto[];
    prep?: IPrep['_id'][];
    otherThings?: boolean;
    elseSomeAssist?: string;
    proposalValidUntil?: string;
    budget?: number;
    specialMessage?: string;
    examples?: string;
    status: ProjectStatusEnum;
    userId: User['_id'];
    lastEdit: Date;
    briefCreatedDate?: Date;
    completionPercentage?: number;
    provider?: IProvider['_id'];
    projectBriefWillBeDistributedTo?: IProvider[];
    projectBriefDistributedTo?: IProvider[];
    providersWhoViewedYourProposal?: IProvider[];
    providersInterested?: IProvider[];
    providersNotInterested?: IProvider[];
    done: boolean;
    field?: [{
        idTitle: string;
        value: string;
    }];
    isDocumentPurchased: boolean;
    featuresForDoc: ChosenFeatures;
    typeRequirements: RequirementsTypes;
    inProgressDoc: boolean;
}

export type FeaturesKeys = 'accountManagement' | 'apiIntegration' | 'communicationMessaging' | 'multimedia' | 'onboarding' | 'privacySecurity' | 'subscriptionsBilling' | 'social' | 'uiux' | 'other';
export type AllFeatures = FeatureSection[]
export type ChosenFeatures = Record<FeaturesKeys, { isChosen: boolean, feats: string | string[] }>

export enum RequirementsTypes {
    'non' = 1,
    'fnDoc',
    'wireframes'
}

export interface FeatureSection {
    nameSection: string;
    key: string;
    features: string[] | string;
}

export interface IProjectInfo {
    numberProviders?: number;
    project: IProject;
    providers: IProvider[];
}

export interface IProjectType {
    _id: any;
    title: string;
    actuality: boolean;
    icon?: string;
}

export interface IProjectDetails {
    _id: any;
    title: string;
    requiredOrNot: boolean;
    description: string;
}

export interface ICountry {
  _id: any;
  number?: number;
  name?: string;
  iso2: string;
  iso3?: string;
  numericCode?: string;
  phoneCode: string;
  capital?: string;
  currency?: string;
  currencyName?: string;
  currencySymbol?: string;
  tld?: string;
  native?: string;
  region?: string;
  subregion?: string;
  latitude?: string;
  longitude?: string;
  flag?: string;
  backgroundImage?: string;
  numberOfUses?: number;
  providersCount?: number;
}

export interface IState {
  _id: any;
  country: ICountry['_id'];
  name: string;
  stateCode: string;
  latitude?: string;
  longitude?: string;
  type?: any;
}

export interface ICity {
  _id: any;
  state: IState['_id'];
  name: string;
  latitude?: string;
  longitude?: string;
}

export interface IIndustry {
  _id: string;
  description?: string;
  imageUrl?: string;
  title?: string;
  name?: string;
  __v?: number;
}

export interface ISkill {
    _id: any;
    title: string;
}

export interface IAcceptCompensation {
    _id: any;
    title: string;
    active: boolean;
}

export interface IPrep {
    _id: any;
    title: string;
    active: boolean;
}

export enum ProjectStatusEnum {
    draft,
    open,
    closed,
    pending,
    declined,
}

export class ProjectDetailsDto {
    model: IProjectDetails['_id'];
    value: string;
}
