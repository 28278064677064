<div class="container">
  <app-brief-step-header
    [title]="'Features: Part Two'"
    [currentStep]="currentStep"
    [totalSteps]="totalSteps"
  ></app-brief-step-header>
  <div class="content">
    <ng-container *ngIf="allFeatures?.key !== 'other'; else otherTemplate" >
      <p class="sub-title">
        Select all the important features that are required *
        <mat-error *ngIf="showError && !isValid">Select at least one option.</mat-error>
      </p>
      <hr>
      <p class="small-sub-title">
        {{allFeatures?.nameSection}} (<span>{{currentFeatureSubstep}}</span> of {{maxFeatureSubstep}})
      </p>
      <ul class="features-container">
        <li class="text-field"
            *ngFor="let feature of allFeatures?.features">
          <label>
            <input type="checkbox"
                   [value]="feature"
                   [checked]="chosenFeatures.includes(feature)"
                   (change)="handleCheckbox($event)"/>
            {{ feature }}
          </label>
        </li>
      </ul>
    </ng-container>
    <ng-template #otherTemplate>
      <div class="other-container">
        <p class="small-sub-title">Other features and functionalities that are required</p>
        <textarea class="description-input"
                  placeholder="List and describe all other features and functionalities that are needed..."
                  maxlength="500"
                  [(ngModel)]="otherFieldValue"
                  [class.error-input]="showError && !isValid"
                  trimDirective
                  required
        ></textarea>
        <div class="other-text-field-error">
          <p [style.opacity]="showError && !isValid ? 1 : 0">Length should be at least 50 characters in length.</p>
          <p>
            <span [class.error]="otherFieldValue.length < 50">{{otherFieldValue.length}}</span>
            /500 characters (min 50)
          </p>
        </div>
      </div>
    </ng-template>
    <div class="button-container">
      <button class="primary-btn-transparent outlined-btn"
              mat-stroked-button
              (click)="handleSaveClick()">
        Save
      </button>
      <button class="primary-btn-transparent outlined-btn"
              mat-stroked-button
              (click)="handlePreviousClick()">
        Previous
      </button>
      <button class="filled-btn"
              mat-stroked-button
              (click)="handleNextClick()">
        Next
      </button>
    </div>
  </div>
</div>
