<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="onNoClick()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Oh No!</p>

  <p class="paragraph2">
    We weren't able to find service providers <br />
    that match your initial preferences.
  </p>

  <button class="primary-btn" type="submit" (click)="onNoClick()">
    Update Preferences
  </button>
</div>
