import { NgModule } from '@angular/core';
import {TermsComponent} from './terms.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [
    TermsComponent
  ],
  exports: [
    TermsComponent
  ],
  imports: [
    SharedModule
  ]
})
export class TermsModule {}
