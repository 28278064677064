<app-header></app-header>
<section class="briefs-page">
  <div class="container">
    <header>
      <h2 class="title">Activity Alerts</h2>
      <hr />
      <button
        (click)="changeTab('Draft', 0)"
        [ngClass]="{ activeTab: this.activeTab === 'Draft' }"
        class="tab"
      >
        Sent to Providers
      </button>
      <button
        (click)="changeTab('Open', 1)"
        [ngClass]="{ activeTab: this.activeTab === 'Open' }"
        class="tab"
      >
        Providers Viewed
      </button>
      <button
        (click)="changeTab('Closed', 2)"
        [ngClass]="{ activeTab: this.activeTab === 'Closed' }"
        class="tab"
      >
        Providers Interested
      </button>
      <hr />
    </header>
    <mat-horizontal-stepper #stepper>
      <mat-step>
        <div class="step-content">
          <div class="briefs-wrap">
            <div *ngIf="!alertsSent.length" class="empty-state">
              <img src="./assets/sendedAlertsEmptyState.png" alt="img">
              <p>Create and submit a brief to receive alerts about it.</p>
            </div>
            <header *ngIf="alertsSent.length">
              <p class="paragraph2">Activity Feed</p>
            </header>
            <div class="providers-content"
                 infinite-scroll
                 [scrollWindow]="true"
                 (scrolled)="onScrollDown($event)">
              <div class="brief-wrap-item" *ngFor="let alert of alertsSent">
                <div class="brief-wrap-item-info">
                  <img [src]="alert?.logo || 'assets/pvoviderLogoPlaceholder.png'" alt="" />
                  <p class="paragraph2">
                    {{ alert?.providerName }} received the project proposal for
                    {{ alert?.projectName }}
                  </p>
                </div>
                <p class="paragraph2">
                  {{ alert?.timestamp | date: "yyyy/MM/dd" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div class="step-content">
          <div class="briefs-wrap">
            <div *ngIf="!alertsOpen.length" class="empty-state">
              <img src="./assets/openedAlertsEmptyState.png" alt="img">
              <p>When a provider has viewed your project, you will see this type of activity here.</p>
            </div>
            <header *ngIf="alertsOpen.length">
              <p class="paragraph2">Activity Feed</p>
            </header>
            <div class="brief-wrap-item" *ngFor="let alert of alertsOpen">
              <div class="brief-wrap-item-info">
                <img [src]="alert?.logo || 'assets/pvoviderLogoPlaceholder.png'" alt="" />
                <p class="paragraph2">
                  {{ alert?.providerName }} viewed the project proposal for
                  {{ alert?.projectName }}
                </p>
              </div>
              <p class="paragraph2 date">
                {{ alert?.timestamp | date: "yyyy/MM/dd" }}
              </p>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div class="step-content">
          <div class="briefs-wrap">
            <div *ngIf="!alertsInterest.length" class="empty-state">
              <img src="./assets/interestedAlertsEmptyState.png" alt="img">
              <p>When a provider has expressed interest in your project, you will see this type of activity here.</p>
            </div>
            <header *ngIf="alertsInterest.length">
              <p class="paragraph2">Activity Feed</p>
            </header>

            <div class="brief-wrap-item" *ngFor="let alert of alertsInterest">
              <div class="brief-wrap-item-info">
                <img [src]="alert?.logo || 'assets/pvoviderLogoPlaceholder.png'" alt="" />
                <p class="paragraph2">
                  {{ alert?.providerName }} showed interest in the project proposal for
                  {{ alert?.projectName }}
                </p>
              </div>
              <p class="paragraph2">
                {{ alert?.timestamp | date: "yyyy/MM/dd" }}
              </p>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</section>
