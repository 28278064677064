<div class="login-content">
  <img
    class="close-icon"
    src="./../../../../assets/icons/x.svg"
    alt=""
    (click)="cancel()"
  />

  <div class="login-content-logo">
    <img src="../../../../assets/logo/Logo.svg" alt="" />
  </div>
  <p class="title">Are you sure?</p>

  <p class="paragraph2">
    {{ data.message }}
  </p>

  <div class="buttons-wrapper">
    <button class="primary-btn" (click)="confirm()">
      {{ data.confirmText }}
    </button>
    <button class="transparent-button" (click)="cancel()">
      {{ data.dismissText }}
    </button>
  </div>
</div>
