<header>
    <div class="container">
        <div class="header-content">
            <div class="logo" (click)="onImageClick()">
                <img
                    src="./../../../../../assets/logo/The-Unicorn-App-Logo-horizontal-gradient%203.svg"
                    alt=""
                />
            </div>
            <div
                *ngIf="displaySearchField"
                class="search"
                (keyup.enter)="doSearch()"
            >
                <img
                    src="../../../../../assets/light-search.svg"
                    alt="search"
                />
                <input
                    #insideElement
                    [(ngModel)]="term"
                    class="data-input"
                    type="text"
                    [placeholder]="inputPlaceholder"
                />
                <button class="primary-btn" (click)="doSearch()">Search</button>
            </div>

            <span [routerLink]="['/public-articles']" class="paragraph2 knowledge-hub-btn"> Knowledge Hub </span>

            <span
                *ngIf="displaySearchField"
                class="paragraph2 how-it-works-btn"
                [routerLink]="['']"
                fragment="howItWorks"
            >
                How it Works
            </span>

            <nav *ngIf="!(authDS.user$ | async)">
                <ul class="menu">
                    <li class="paragraph2" (click)="openLoginPopup()">
                        <svg
                            class="user-icon"
                            viewBox="0 0 14 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 5.66681C12 8.19116 9.81602 10.3336 7 10.3336C4.18398 10.3336 2 8.19116 2 5.66681C2 3.14246 4.18398 1 7 1C9.81602 1 12 3.14246 12 5.66681Z"
                                stroke="white"
                                stroke-width="2"
                            />
                            <path
                                d="M1.70508 13.9994C1.70508 13.9994 7.35489 11.3326 12.2933 13.9994"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                        Log In
                    </li>
                    <li>
                        <button
                            class="primary-btn-transparent"
                            (click)="openSignUpPopup()"
                        >
                            Sign Up
                        </button>
                    </li>
                </ul>
            </nav>

            <div class="profile" *ngIf="authDS.user$ | async">
                <div
                    *ngIf="
                        (authDS.activeAccount$ | async) ===
                        UserTypesAccount.Client
                    "
                >
                    <p *ngIf="user?.userRole?.companyName" class="paragraph2">
                        Welcome, {{ user?.userRole?.companyName }}
                    </p>
                    <p *ngIf="user?.userRole?.firstName" class="paragraph2">
                        Welcome, {{ user?.userRole?.firstName }}
                    </p>
                </div>
                <div
                    *ngIf="
                        (authDS.activeAccount$ | async) ===
                        UserTypesAccount.Provider
                    "
                >
                    <p class="paragraph2">
                        Welcome, {{ user?.userProvider?.companyName }}
                    </p>
                </div>
                <div
                    class="profile-photo"
                    [matMenuTriggerFor]="
                        (authDS.activeAccount$ | async) ===
                        UserTypesAccount.Client
                            ? clientMenu
                            : providerMenu
                    "
                >
                    <div
                        *ngIf="
                            (authDS.activeAccount$ | async) ===
                            UserTypesAccount.Client
                        "
                        style="width: 100%"
                    >
                        <img
                            *ngIf="user?.userRole?.logoUrl"
                            src="{{ user?.userRole?.logoUrl }}"
                            alt=""
                        />
                        <p
                            *ngIf="
                                !user?.userRole?.logoUrl &&
                                user?.userRole?.firstName
                            "
                            class="initial"
                        >
                            {{
                                user?.userRole?.firstName &&
                                    user?.userRole?.firstName[0]
                            }}
                            {{
                                user?.userRole?.lastName &&
                                    user?.userRole?.lastName[0]
                            }}
                        </p>
                        <p
                            *ngIf="
                                !user?.userRole?.logoUrl &&
                                user?.userRole?.companyName
                            "
                            class="initial"
                        >
                            {{
                                user?.userRole?.companyName &&
                                    user?.userRole?.companyName[0]
                            }}
                        </p>
                        <div class="number-alerts" *ngIf='(unreviewedAlerts$ | async) > 0'>
                            <p>{{ unreviewedAlerts$ | async }}</p>
                        </div>
                    </div>
                    <div
                        *ngIf="
                            (authDS.activeAccount$ | async) ===
                            UserTypesAccount.Provider
                        "
                        style="width: 100%"
                    >
                        <img
                            *ngIf="user?.userProvider?.logo"
                            src="{{ user?.userProvider?.logo }}"
                            alt=""
                        />
                        <p
                            *ngIf="
                                !user?.userProvider?.logo &&
                                user?.userProvider?.companyName
                            "
                            class="initial"
                        >
                            {{
                                user?.userProvider?.companyName &&
                                    user?.userProvider?.companyName[0]
                            }}
                            {{ user?.userProvider?.companyName[1] }}
                        </p>
                    </div>
                    <mat-menu #clientMenu="matMenu" xPosition="before">
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/my-briefs']"
                        >
                            My Projects
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/alerts']"
                        >
                            Activity Alerts
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/account-settings']"
                        >
                            Account Settings
                        </button>
                        <button
                            *ngIf="
                                user?.user?.typeAccount ===
                                UserTypesAccount.Multiple
                            "
                            class="paragraph2"
                            mat-menu-item
                            (click)="onSwitchAccountClick()"
                        >
                            Switch Account
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            (click)="logout()"
                        >
                            Log Out
                        </button>
                    </mat-menu>
                    <mat-menu #providerMenu="matMenu" xPosition="before">
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/provider/manage-projects']"
                        >
                            Manage Projects
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            *ngIf='canViewArticlesPage()'
                            [routerLink]="['/provider/articles']"
                        >
                            Knowledge Articles
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/provider/edit-profile']"
                        >
                            Manage Profile
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/provider/edit-profile']"
                            [queryParams]="{ activeTab: 'Billing' }"
                        >
                            Billing
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/provider/edit-profile']"
                            [queryParams]="{ activeTab: 'Subscriptions' }"
                        >
                            Subscriptions
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            [routerLink]="['/provider/edit-profile']"
                            [queryParams]="{ activeTab: 'Preferences' }"
                        >
                            Preferences
                        </button>
                        <button
                            *ngIf="
                                user?.user?.typeAccount ===
                                UserTypesAccount.Multiple
                            "
                            class="paragraph2"
                            mat-menu-item
                            (click)="onSwitchAccountClick()"
                        >
                            Switch Account
                        </button>
                        <button
                            class="paragraph2"
                            mat-menu-item
                            (click)="logout()"
                        >
                            Log Out
                        </button>
                    </mat-menu>
                </div>
                <!--        <div class="profile-photo" [routerLink]="['/create-brief']" [queryParams]="{ type: 'all'}">-->
                <!--          <p>+</p>-->
                <!--        </div>-->
            </div>
        </div>
        <div
            *ngIf="disabled"
            class="opacity-box"
            [ngClass]="{
                searchOpen:
                    (term.length > 0 && !openSearchResults) ||
                    term.length === 0,
            }"
        ></div>
    </div>
</header>
